import { RequestGetInfo, RequestUpdateMobileOTP } from "@/api/profile.js";

import {
  BaseBackButtonCustomComponent,
  BaseTitleComponent,
  BaseInputTextComponent,
  BaseInputMobileComponent,
  BaseErrorMsgComponent,
  BaseButtonComponent,
  BaseTextLineComponent,
} from "@/components";
import { validPhoneNumber } from "@/helpers";

export default {
  name: "SettingPhone",
  components: {
    BaseBackButtonCustomComponent,
    BaseTitleComponent,
    BaseInputTextComponent,
    BaseInputMobileComponent,
    BaseErrorMsgComponent,
    BaseButtonComponent,
    BaseTextLineComponent,
  },
  data() {
    return {
      goBack: true,
      token: this.$store.getters["profile/token"],
      titleData: `เปลี่ยนเบอร์โทรศัพท์`,
      textLine: {
        label:
          "รหัส OTP จะถูกส่งผ่าน SMS ไปยังเบอร์โทรศัพท์ ที่คุณได้ลงทะเบียนไว้",
        value: "",
      },
      inputText: {
        title: "เบอร์โทรศัพท์เดิม",
        value: "",
        type: "text",
        placeholder: "เบอร์โทรศัพท์เดิม",
        maxlength: 9,
        disabled: true,
      },
      inputMobile: {
        name: "mobile",
        value: "",
        type: "text",
        placeholder: "เบอร์โทรศัพท์ใหม่",
        maxlength: 9,
      },
      errorMsg: {
        Mobile: "",
      },
      buttonData: {
        name: "ยืนยัน",
        type: "submit",
        active: false,
      },
    };
  },
  watch: {
    inputMobile: {
      deep: true,
      handler(Mobile) {
        console.log(validPhoneNumber(Mobile.value));
        if (Mobile.value.length === 9 && validPhoneNumber(Mobile.value)) {
          this.buttonData.active = true;
          this.errorMsg.Mobile = "";
        } else if (
          Mobile.value.length === 9 &&
          !validPhoneNumber(Mobile.value)
        ) {
          this.buttonData.active = false;
          this.errorMsg.Mobile = "เบอร์โทรศัพท์ไม่ถูกต้อง กรุณากรอกใหม่";
        } else if (Mobile.value.length > 0 && !validPhoneNumber(Mobile.value)) {
          this.buttonData.active = false;
          this.errorMsg.Mobile = "เบอร์โทรศัพท์ไม่ถูกต้อง กรุณากรอกใหม่";
        } else {
          this.buttonData.active = false;
          this.errorMsg.Mobile = "";
        }
        // this.errorMsg.Mobile = ''
      },
    },
  },
  created() {
    if (this.token) {
      this.GetInfo();
    } else {
      this.$message({
        message: "Invalid Token",
        type: "error",
      });
      this.$router.push("/Gateway");
    }
  },
  inject: ["trackEvent"],
  methods: {
    handleBackward() {
      this.$router.push({
        name: "Account",
      });
    },
    async GetInfo() {
      let RequestData = {
        token: this.token,
      };
      let RequestResp = await RequestGetInfo(RequestData);
      if (RequestResp.result == "complete") {
        var info = RequestResp.info;
        this.inputText.value = info.mobile;
      } else {
        this.$message({
          message: RequestResp.resultdetail,
          type: "error",
        });
      }
    },
    FormValid() {
      let validation = true;
      if (
        this.inputMobile.value.length <= 9 &&
        !validPhoneNumber(this.inputMobile.value)
      ) {
        this.errorMsg.Mobile = "เบอร์โทรศัพท์ไม่ถูกต้อง กรุณากรอกใหม่";
        validation = false;
      }
      return validation;
    },
    SubmitForm() {
      let FormValid = this.FormValid();
      if (FormValid === true) {
        this.RequestUpdateMobileOTP();
      } else {
        this.$store.commit("BUTTON_LOADING", false);
      }
    },

    async RequestUpdateMobileOTP() {
      // if (this.inputMobile.value.slice(0, 1) === '0') {
      // console.log('begin 0')
      //   this.inputMobile.value = this.inputMobile.value.substring(1)
      // } else {
      // console.log('not begin 0')
      // }

      let RequestData = {
        mobile: "0" + this.inputMobile.value,
        token: this.token,
      };
      let RequestResp = await RequestUpdateMobileOTP(RequestData);
      this.$store.commit("BUTTON_LOADING", false);

      if (RequestResp.result == "complete") {
        this.trackEvent("SettingPhone - Confirm", {
          event_category: "SettingPhone",
          event_label: "Confirm",
          value: true,
        });
        // console.log(RequestResp.otpinfo);
        this.$store.commit("profile/OTPinfo", RequestResp.otpinfo);
        this.$router.push({ name: "SettingPhoneOTP" });
      } else {
        this.$message(RequestResp.resultdetail);
      }
    },
  },
};
