<style src="./IconShop.scss"  lang="scss"></style>
<script src="./IconShop.js"></script>

<template>
	<!-- eslint-disable vue/no-v-html -->
	<!-- <span class="icon-shop" v-html="icon" /> -->
	<span class="icon-shop">
	
		<img :src="require(`../../assets/icons-shop/${this.name}.png`)">
	</span>
</template>