import * as dayjs from "dayjs";
import { Icon, IconShop } from "@/components";
import { abbThaiMonth } from "@/helpers";
export default {
  name: "PointhistoryListComponent",
  components: {
    IconShop,
    Icon,
  },
  props: {
    PointLists: {
      type: Object,
      required: true,
    },
  },
  mounted() {},
  data() {
    return {};
  },
  inject: ["trackEvent"],
  methods: {
    GetDate(Date) {
      let FormatDate = dayjs(Date).format("DD");
      return FormatDate;
    },
    GetMonth(Date) {
      let FormatDate = abbThaiMonth(dayjs(Date).format("M"));
      // let FormatDate = dayjs(Date).format('MMM')
      return FormatDate;
    },
    handleOpenLink(id) {
      this.trackEvent(`Home - Point status`, {
        event_category: "Home",
        event_label: "Point status",
        value: id,
      });
      this.$router.push({ path: `/BillDetail/${id}` });
    },
  },
};
