<style src="./PointhistoryFilterComponent.scss" lang="scss" ></style>
<script src="./PointhistoryFilterComponent.js"></script>

<template>
	<div class="point-history-filter">
		<!-- <input type="month" id="start" name="start"  value="2021-05"> -->
		<Icon name="calendar" class="sheet__icon" @click.prevent="clearCalendar" />
		<Icon
			name="arrow-down2"
			class="arrow__icon"
			@click.prevent="toggleCalendar"
		/>
		<el-date-picker
			v-model="FilterMonth2"
			type="month"
			placeholder="Pick a month"
			format="MMM YYYY"
			clear-icon="el-icon-circle-close"
			popper-class="point__history__calendar"
			:default-value="new Date()"
			@change="UpdateFilter"
			@focus="focusCalendar"
		>
		</el-date-picker>
	</div>
</template>