import TheHeader from "@/views/Layout/Header";
import TheFooter from "@/views/Layout/Footer";

export default {
  name: "LandingLayout",
  components: {
    TheHeader,
    TheFooter,
  },
};
