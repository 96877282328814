<style src="./PDPAComponent.scss" lang="scss"></style>
<script src="./PDPAComponent.js"></script>

<template>
	<div class="box__pdpa">
		<PDPAModalCondComponent
			:modal-show="modalShow"
			:modal-data="modals"
			@update:dialogCond="handleModalCond"
		/>
		<!-- # Modal -->
		<BaseModalComponent
			:modal-show="modalStatus"
			:modal-status="modalValue"
			:modal-type="modalType"
			@update:modal="handleModal"
			@closed:modal="closedModal"
		/>

		<!-- # Content Page -->
		<!-- <PDPATitleComponent /> -->
		<!-- <PDPASeparateLineComponent /> -->
		<PDPAServiceComponent
			ref="pdpaTerms"
			accept-type="accept"
			:accept-status="modalValue"
			@modal:cond="handleModalCond"
			@switch:cond="handleModal"
		/>
		<PDPASeparateLineComponent />
		<PDPAPolicyComponent
			ref="pdpaPolicy"
			accept-type="allow"
			:accept-status="modalValue"
			@modal:policy="handleModalPolicy"
			@switch:policy="handleModal"
		/>
	</div>
</template>