import axios from "axios";
import { ElMessageBox, ElMessage } from "element-plus";
import store from "@/store";
import config from "@/constants";

// create an axios instance
const service = axios.create({
  baseURL: config.apiUrl,
  timeout: 10000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (serviceConfig) => {
    const isJWTToken = store.getters["profile/token"];
    if (isJWTToken) {
      // serviceConfig.headers['X-Token'] = getToken();
      // serviceConfig.headers['access-control-allow-origin'] = '*'
      // serviceConfig.headers.common.Authorization = `Bearer ${isJWTToken}`;
      // return serviceConfig;
    }

    let LogsData = store.getters["logs/getLogValue"];

    serviceConfig.data = {
      ...serviceConfig.data,
      ...LogsData,
    };
    // console.log(serviceConfig.data)
    return serviceConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

// respone interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (response.status !== 200) {
      // return ElMessage({
      //   message: res.message,
      //   type: "error",
      //   duration: 5 * 1000,
      // });
      console.log({ response: res.message });
      // }else if(res.result == 'error' ) {
      //   return ElMessage({
      //     message: res.resultdetail,
      //     type: 'error',
      //     duration: 5 * 1000,
      //   });
    }
    return res;
  },
  (error) => {
    // ElMessage({
    //   message: error.message,
    //   type: "error",
    //   duration: 5 * 1000,
    // });
    console.log({ response: error.message });
    return Promise.resolve(error);
  }
);

export default service;
