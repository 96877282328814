import { computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { logger } from "@/helpers";
export default {
  name: "BaseBackButtonCustom",
  props: {
    configButton: {
      type: Object,
      default: {
        name: "Back",
        color: "#000000",
        colorIcon: "#000000",
        backgroundColor: "transparent",
      },
    },
    scanProcess: Boolean,
    captureImages: {
      type: Object,
      default: {
        BillsList: [],
        BillsIndex: 0,
        BillsPrev: null,
        Image_Recheck: null,
        DeviceList: null,
      },
    },
    captureAction: String,
    goBack: Boolean,
  },
  // methods: {
  //     goback() {
  //         this.$router.back();
  //     }
  // },
  setup(props, { emit }) {
    logger("scanProcess", props.scanProcess);
    logger("BillsIndex", props.captureImages.BillsIndex);

    const router = useRouter();
    const route = useRoute();

    let currentImage = computed(() => props.captureImages.BillsIndex + 1);

    logger(`currentImage`, currentImage.value);

    function handleRecheckDone(e) {
      e.preventDefault();
      const btn = document.querySelector(".done__button");
      if (!btn.hasAttribute("data-prevent-double-click")) {
        // logger(`BillsList`, props.captureImages.BillsList);
        btn.setAttribute("data-prevent-double-click", true);
        btn.setAttribute("disabled", true);
        emit("update:recheckdone", true);
      }
      setTimeout(() => {
        btn.removeAttribute("disabled");
        btn.removeAttribute("data-prevent-double-click");
      }, 1000);
    }

    function goback() {
      logger(`goback`, props.captureAction);
      if (props.captureAction === "done") {
        emit("button:action", "recheck");
      } else if (props.captureAction === "recheck") {
        emit("button:action", "capture");
      } else {
        if (props.goBack) {
          emit("button:action");
        } else {
          router.push({
            // name: 'SubmissionReceipt'
            name: "Home",
          });
        }
      }
    }

    function handleCancel() {
      // router.push({
      //   path: `/Submission/scan/${Math.random()}`,
      // });
      router.push({
        name: "Home",
      });
      // window.location.reload();
      // emit("button:action", "capture");
    }

    return {
      handleRecheckDone,
      goback,
      handleCancel,
      currentImage,
    };
  },
};
