import { onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { logger } from "@/helpers";

export default {
  name: "LinkApps",

  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    onMounted(() => {
      store.commit("BUTTON_LOADING", false);

      if (!route.query.crossapptoken) {
        router.push({
          // name: 'SubmissionReceipt'
          name: "Home",
        });
      } else {
        CheckCrosstoken();
      }
    });

    const CheckCrosstoken = async () => {
      // console.log('CheckCrosstoken')
      const sendData = {
        adaptertoken: route.query.crossapptoken,
      };

      const response = await store.dispatch("profile/CheckAdptoken", sendData);

      if (response.result === "complete") {
        store.commit("profile/SetToken", response.token);
        localStorage.setItem("Crptoken", response.token);
      } else {
        if (response.resultdetail == "no_account_linked") {
          store.commit("profile/SetCrossToken", route.query.crossapptoken);
          localStorage.setItem("CrossToken", route.query.crossapptoken);
        } else if (response.resultdetail == "invalid_adapter_token") {
          logger("resultdetail", response.resultdetail);
        }
      }

      router.push({
        // name: 'SubmissionReceipt'
        name: "Home",
      });
    };
  },
};
