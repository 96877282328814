import { 
    BaseAvatarComponent,
    BasePointLegendComponent
  } from '@/components';

  import { RequestGetInfo } from '@/api/profile.js';

  export default {
    name: "BaseProfileHeader",
    components: {
        BaseAvatarComponent,
        BasePointLegendComponent
    },
    data() {
        return {
            token: this.$store.getters['profile/token'],
            profile: null
        };
    },
    created() {
		if (this.token) {
			this.GetInfo();
		} else {
			this.$message({
				message: 'Invalid Token',
				type: 'error'
			})
			this.$router.push('/Gateway');
		}
	},
    methods: {
		async GetInfo() {
			let RequestData = {
				"token": this.token,
			}
			let RequestResp = await RequestGetInfo(RequestData)
			if (RequestResp.result == 'complete') {
                var info = RequestResp.info
                info.pointbalance = parseInt(info.pointbalance)
                this.profile = info
                this.$emit('loadProfileData', this.profile)
			} else {
				this.$message({
					message: RequestResp.resultdetail,
					type: 'error'
				})
			}
		},
    }

    
};
