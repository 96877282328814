<style src="./BaseCarouselComponent.scss" lang="scss"></style>
<script src="./BaseCarouselComponent.js"></script>

<template>
	<div class="box__carousel block">
		<el-carousel height="100%" :interval="3000" arrow="hover"  indicator-position="outside">
			<el-carousel-item v-for="(banner, index) in banners" :key="index">
				<div class="el-carousel-image"><img :src="banner.source" :alt="banner.name" /></div>
				<div class="el-carousel-caption">Lorem Ipsum is simply dummy text of the printing</div>
			</el-carousel-item>
		</el-carousel>
	</div>
</template>