export default {
  name: "BaseErrorMsg",
  props: {
    errorMsg: String,
  },
  data() {
    return {
      error: this.errorMsg,
    };
  },
};
