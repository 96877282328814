<style src="./Home.scss" lang="scss" scoped></style>
<script src="./Home.js"></script>

<template>
	<div class="home__contain" v-if="Myinfo">
		<BaseBannerComponent />
		<ShortProfileComponent :info="Myinfo" />
		<PointBalanceComponent />
		<PointExpireComponent :PointExpires="PointExpire" />
		<PointLastestComponent />
	</div>
</template>