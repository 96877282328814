<style src="./PDPA.scss" lang="scss"></style>
<script src="./PDPA.js"></script>

<template>
	<BaseBackButtonComponent :backward-event="true" />
	<div class="content__view pdpa">
		<PDPAComponent />
		<BaseButtonSimpleComponent
			:button-class="true"
			:button-data="buttonData"
			v-on:buttonClick="buttonClick"
		/>
	</div>
</template>