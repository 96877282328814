<style src="./BaseAvatarComponent.scss" lang="scss"></style>
<script src="./BaseAvatarComponent.js"></script>

<template>
	<div class="box__avatar block">
		<el-avatar
			shape="square"
			fit="fill"
			:src="avatar"
			:class="size"
		></el-avatar>
	</div>
</template>