import { onUpdated, onBeforeUnmount } from "vue";
import $ from "jquery";
import * as dayjs from "dayjs";

import {
  BaseTitleComponent,
  BaseTextLineComponent,
  BaseInputOTPComponent,
  BaseSendOTPComponent,
  BaseButtonComponent,
} from "@/components";

import { RequestApply, RequestCheckOTP } from "@/api/profile.js";

export default {
  name: "ApplyOTP",
  data() {
    return {
      OTPisValid: false,
      InputOTP: "",
      timer: null,
      textLine: {
        label: "OTP ถูกส่งไปที่หมายเลข",
        value: "",
      },
      sendOTPData: {
        msg: "ไม่ได้รับรหัส OTP?",
        // error: 'คุณกรอกรหัส OTP ไม่ถูกต้องกรุณากรอกใหม่อีกครั้ง',
        error: "",
        link: "ส่งใหม่อีกครั้ง",
      },
      buttonData: {
        name: "ยืนยัน",
        type: "submit_otp",
        active: false,
      },
      titleName: "กรุณากรอกรหัส OTP",
      errorMsg: "คุณกรอกเบอร์โทรศัพท์ผิดพลาดหรือไม่ครบถ้วนกรุณาลองใหม่อีกครั้ง",
      OTP: this.$store.getters["profile/getRefcode"],
      CountDown: {
        minute: 0,
        second: 0,
      },
    };
  },
  components: {
    BaseTitleComponent,
    BaseTextLineComponent,
    BaseInputOTPComponent,
    BaseSendOTPComponent,
    BaseButtonComponent,
  },
  inject: ["trackEvent"],
  setup() {
    onUpdated(() => {
      let vm = this;
      // console.log('Component is mounted!')

      // console.log(vm)
    }),
      onBeforeUnmount(() => {
        // console.log('off emitter')
        // this.emitter.off(`button:${this.buttonType}`);
      });
  },
  created() {
    if (!this.OTP) {
      this.$router.push({
        name: "Apply",
      });
    } else {
      this.handleCountDown();

      this.textLine.value = this.OTP.mobile.slice(0, 6) + "XXXX";

      if (this.OTP.otp) {
        this.$message(`OTP ของคุณคือ ${this.OTP.otp}`);
      }
    }
  },
  beforeDestroy() {
    // this.emitter.off(`button:${this.buttonData.type}`);
    // this.emitter.off(`button:${this.buttonData.type}`, () => {
    //   console.log('OFF')
    // });
  },

  methods: {
    SubmitForm() {
      this.CheckOTP();
    },
    handleOnComplete(value) {
      this.sendOTPData.error = "";
      this.InputOTP = value;
      // console.log('handleOnComplete: ', this.InputOTP.length);
      if (this.InputOTP.length == 5) {
        this.buttonData.active = true;
      } else {
        this.buttonData.active = false;
      }
    },
    handleOnChange(value) {
      // console.log(value)
      // console.log('handleOnChange: ', this.InputOTP.length);
      this.sendOTPData.error = "";
      this.InputOTP = value;
      if (this.InputOTP.length == 5) {
        this.buttonData.active = true;
      } else {
        this.buttonData.active = false;
      }
    },
    handleClearInput(ref) {
      this.$refs[ref].clearInput();
    },
    handleCountDown() {
      // console.log('handleCountDown')
      // console.log(this.OTP.expire)

      // const date1 = dayjs(this.OTP.expire).subtract(7, 'hour')
      const date1 = dayjs(this.OTP.expire);

      // console.log(date1.format('DD/MM/YYYY HH:mm:ss'))
      let TimeCount = date1.diff(dayjs(), "second");
      // console.log(date1.format("DD/MM/YYYY HH:mm:ss"));
      // console.log(TimeCount);

      if (TimeCount > 0) {
        this.CountDown.minute =
          Math.floor(TimeCount / 60) < 10
            ? `0${Math.floor(TimeCount / 60)}`
            : Math.floor(TimeCount / 60);
        this.CountDown.second =
          TimeCount - this.CountDown.minute * 60 < 10
            ? `0${TimeCount - this.CountDown.minute * 60}`
            : TimeCount - this.CountDown.minute * 60;
        // T
        this.OTPisValid = true;

        this.timer = setTimeout(() => {
          this.handleCountDown();
        }, 1000);
      } else {
        this.OTPisValid = false;
      }
    },
    async RequestApply() {
      let RequestData = {
        mobile: this.OTP.mobile,
      };

      // api sms otp
      let ApplyResp = await RequestApply(RequestData);

      this.trackEvent("ApplyOTP - Resend", {
        event_category: "ApplyOTP",
        event_label: "Resend",
        value: "ส่งใหม่อีกครั้ง",
      });

      if (ApplyResp.result == "complete") {
        this.OTP = ApplyResp.otpinfo;

        if (this.OTP.otp && this.OTP.otp !== "") {
          this.$message(`OTP ของคุณคือ ${this.OTP.otp}`);
        }

        this.$store.commit("profile/OTPinfo", ApplyResp.otpinfo); //Save OTP INFO To Store
        // console.log(this.$store.getters["profile/getRefcode"]);
        this.$forceUpdate();
      } else {
        if (ApplyResp.resultdetail == "mobile_existing") {
          this.errorMsg.Mobile = "* เบอร์โทรนี้เคยลงทะเบียนแล้ว";
        } else {
          this.$message(ApplyResp.resultdetail);
        }
        this.$store.commit("BUTTON_LOADING", false);
      }
    },
    async CheckOTP() {
      // console.log('RequestCheckOTP')

      this.trackEvent("ApplyOTP - Confirm", {
        event_category: "ApplyOTP",
        event_label: "Confirm",
        value: "ยืนยัน",
      });

      let RequestData = {
        mobile: this.OTP.mobile,
        otp: this.InputOTP,
        refcode: this.OTP.refcode,
      };
      // console.log(RequestData)
      let CheckOtpResp = await RequestCheckOTP(RequestData);
      this.$store.commit("BUTTON_LOADING", false);
      if (CheckOtpResp.result == "complete") {
        clearTimeout(this.timer);
        // exchangecode

        this.$store.commit(
          "profile/SetExchangeCode",
          CheckOtpResp.exchangecode
        );

        this.$router.push({
          name: "Register",
        });
        // this.$message('Complete');
      } else {
        this.sendOTPData.error = "*รหัส OTP ไม่ถูกต้อง";
      }
      // console.log(CheckOtpResp)

      // RequestCheckOTP
    },
  },
};
