<style src="./ShopBillConfirmComponent.scss" lang="scss"></style>
<script src="./ShopBillConfirmComponent.js"></script>

<template>
	<div class="bill__shop__confirm">
		<swiper
			:slides-per-view="4"
			:spaceBetween="0"
			:freeMode="true"
			:lazy="true"
			@swiper="onSwiper"
			@slideChange="onSlideChange"
		>
			<swiper-slide v-for="item in itemBrands" :key="item.id">
				<!-- {{ itemSelected }} -->
				<img
					:src="item.image"
					:alt="item.name"
					:class="{
						selected: item.name == itemSelected,
					}"
					@click.prevent="onSlideClicked(item)"
				/>
			</swiper-slide>
		</swiper>
	</div>
</template>