import { inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import configs from "../../constants/index.js";
import { RequestGetBanner } from "@/api/general.js";
var youtubeThumbnail = require("youtube-thumbnail");
export default {
  name: "BaseBanner",
  props: {
    titleName: String,
    configsData: Object,
  },
  data() {
    return {
      title: this.titleName,
      token: this.$store.getters["profile/token"],
      Banners: [],
    };
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const trackEvent = inject("trackEvent");

    function handleBannerClick(id) {
      trackEvent(`Home - Banner ${id}`, {
        event_category: "Home",
        event_label: "BannerDetail",
        value: id,
      });

      this.$router.push({
        name: "BannerDetail",
        params: {
          id: id,
        },
      });
    }
    return {
      handleBannerClick,
    };
  },
  created() {
    if (this.token) {
      this.GetBanner();
    }
  },
  methods: {
    async GetBanner() {
      this.Banners = [];
      let RequestData = {
        token: this.token,
      };
      let RequestResp = await RequestGetBanner(RequestData);
      if (RequestResp.result == "complete") {
        RequestResp.list.forEach((banner) => {
          if (banner.assetlarge.includes("www.youtube.com")) {
            var thumbnail = youtubeThumbnail(banner.assetlarge);
            banner.cover = thumbnail.high.url;
          } else if (banner.assetlarge.includes(".mp4")) {
            banner.cover = banner.assetcover;
          }
          this.Banners.push(banner);
        });
      } else {
        this.$message({
          message: RequestResp.resultdetail,
          type: "error",
        });
      }
    },
  },
};
