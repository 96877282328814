import { ref, reactive, computed } from "vue";
export default {
  name: "ShopBillExampleComponent",
  props: {
    itemBrands: {
      type: Array,
      default: [
        {
          id: 1,
          name: "7-11",
          image: require("@/assets/images/submission/7-11_brand.jpg"),
          bill: require("@/assets/images/submission/7-11_bill.jpg"),
        },
      ],
    },
    itemBillId: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    // console.log(props.itemBrands)
    // console.log(props.itemBillId)

    const loading = ref(false);

    const data = reactive({
      itemBrands: props.itemBrands,
    });

    const billSelected = computed(() => {
      // loading.value = ref(true)
      // console.log(props.itemBillId)
      return data.itemBrands.filter((v) => v.id === props.itemBillId);
    });

    function openFullScreen() {
      loading.value = ref(false);
    }

    return {
      loading,
      data,
      billSelected,
      openFullScreen,
    };
  },
};
