import { ref, watchEffect, onMounted } from "vue";
import { logger } from "@/helpers";

import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "BaseBillEditComponent",
  props: {
    captureImages: Object,
    coordinates: Object,
  },
  components: {
    Cropper,
  },
  setup(props, { emit }) {
    // logger(`props: ${JSON.stringify(props.captureImages)}`)

    const cropper = ref(null);

    onMounted(() => {
      // the DOM element will be assigned to the ref after initial render
      logger("onMounted", props.captureImages);
      logger("coordinates", props.coordinates);
      // logger(cropper.value.getResult()) // <div>This is a root element</div>
      cropper.value.setCoordinates(props.coordinates);
    });

    // use with component props => :default-size="defaultSize"
    function defaultSize({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
      };
    }

    // use with component props => :default-visible-area="defaultVisibleArea"
    function defaultVisibleArea({ imageSize, coordinates }) {
      return {
        left: imageSize.width / 2 - coordinates.width / 2,
        top: imageSize.height / 2 - coordinates.height / 2,
        width: coordinates.width,
        height: coordinates.height,
      };
    }

    function defaultPosition({ visibleArea, coordinates, imageSize }) {
      return {
        left: imageSize.width / 2 - coordinates.width / 2,
        top: imageSize.height / 2 - coordinates.height / 2,
      };
    }

    function onChange({ coordinates, canvas }) {
      const coordinatesUpdate = {
        coordinates,
        canvas,
      };

      emit("update:cropper", coordinatesUpdate);

      // logger(objs);
      // var dataURL = canvas.toDataURL();
      // logger(dataURL);
      // logger(cropper.value.getResult())
    }

    return {
      cropper,
      onChange,
      defaultSize,
      defaultPosition,
      defaultVisibleArea,
    };
  },
};
