// const svgContext = require.context('../../assets/icons-shop/', false, /\.svg/);

export default {
  name: 'IconShop',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    icon() {
      return `<img src='../../assets/icons-shop/${this.name}.png' w>`;
      // return svgContext(`./${this.name}.svg`);
    },
  },
};