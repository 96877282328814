<style src="./PrivilegePoints.scss" lang="scss" scoped></style>
<script src="./PrivilegePoints.js"></script>

<template>
	<div class="content__privilege__view">
		<BaseProfileHeaderComponent v-on:loadProfileData="getProfileData" />
		<BaseBackButtonComponent />
		<div class="privilege__body">
			<BaseShippingAddressComponent
				:address="primaryAddress"
				v-if="type === 'redemptions'"
			/>
			<div class="privilege__input">
				<div class="privilege__title">เลือกจำนวนสิทธิ์</div>
				<div class="input__section">
					<i
						class="el-icon-remove privilege__icon"
						@click.prevent="buttonDecreaseClick()"
					></i>
					<input
						class="privilege__input"
						type="number"
						v-model="points"
						pattern="[0-9]*"
						inputmode="number"
						maxlength="10"
						@change="privilegeChange"
						@keypress="isNumber($event)"
					/>
					<i
						class="el-icon-circle-plus privilege__icon"
						@click.prevent="buttonIncreaseClick()"
					></i>
				</div>
			</div>
		</div>
		<BaseButtonSimpleComponent
			v-if="buttonData"
			:button-data="buttonData"
			v-on:buttonClick="buttonClick"
		/>
		<!-- # Modal -->
		<BaseModalComponent
			:modal-show="modalStatus"
			:modal-status="modalValue"
			:modal-type="modalType"
			:message="redeemPointMessage"
			@update:modal="handleModal"
		/>
	</div>
</template>