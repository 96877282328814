import request from '@/utils/request';


export function RequestApply(data) {
    // const data = {
    //     mobile :  payload.mobile,
    // };
    return request({
        url: 'profile/applyWithMobileNo',
        method: 'post',
        data,
    });
}


export function RequestCheckOTP(data) {
    // const data = {
    //     mobile :  payload.mobile,
    // };
    return request({
        url: 'profile/applyWithMobileNo/checkOTP',
        method: 'post',
        data,
    });
}

export function RequestRegister(data) {
    // const data = {
    //     "otp": payload.otp,
    //     "firstname": payload.firstname,
    //     "lastname": payload.lastname,
    //     "mobile": payload.mobile,
    //     "email": payload.email,
    //     "password": payload.password,
    //     "bhd": payload.bhd,
    //     "province": payload.province,
    //     "status": payload.status,
    //     "gender": payload.gender,
    //     // "interest": [
    //     //   "977C7491-4A78-4980-819C-8C8E94435BD8",
    //     //   "B3A3203E-2551-4F40-9FB1-E1CF44997E68"
    //     // ]
    //   };
    return request({
        url: 'profile/register',
        method: 'post',
        data,
    });
}


export function RequestLogin(data) {
    // const data = {
    //     mobile :  payload.mobile,
    //     password :  payload.password,
    // };
    return request({
        url: 'profile/login',
        method: 'post',
        data,
    });
}



export function RequestGetInfo(data) {
    return request({
        url: 'profile/getinfo',
        method: 'post',
        data,
    });
}


export function RequestGetPointHistory(data) {
    return request({
        url: 'profile/pointhistory',
        method: 'post',
        data,
    });
}


export function RequestGetRewardHistory(data) {
    return request({
        url: 'profile/rewardhistory',
        method: 'post',
        data,
    });
}

export function RequestGetLuckyHistory(data) {
    return request({
        url: 'profile/luckyhistory',
        method: 'post',
        data,
    });
}

export function RequestGetInterest(data) {
    return request({
        url: 'profile/getinterestlist',
        method: 'get',
        data,
    });
}


export function RequestProfileUpdate(data) {
    return request({
        url: 'profile/update',
        method: 'post',
        data,
    });
}


export function RequestProfilePicUpdate(data) {
    return request({
        url: 'profile/picupdate',
        method: 'post',
        data,
    });
}


export function RequestForgotPassword(data) {
    return request({
        url: 'profile/forgotpassword',
        method: 'post',
        data,
    });
}

export function ForgotCheckOTP(data) {
    return request({
        url: 'profile/forgotpassword/checkOTP',
        method: 'post',
        data,
    });
}

export function RequestForgotCheckname(data) {
    return request({
        url: 'profile/forgotpassword/checkName',
        method: 'post',
        data,
    });
}

export function RequestResetPassword(data) {
    return request({
        url: 'profile/resetpassword',
        method: 'post',
        data,
    });
}

export function RequestConsentUpdate(data) {
    return request({
        url: 'profile/consentupdate',
        method: 'post',
        data,
    });
}


export function RequestUpdateMobileOTP(data) {
    return request({
        url: 'profile/updatemobile/otprequest',
        method: 'post',
        data,
    });
}

export function RequestUpdateMobileCheckOTP(data) {
    return request({
        url: 'profile/updatemobile/checkOTP',
        method: 'post',
        data,
    });
}

export function RequestUpdateMobile(data) {
    return request({
        url: 'profile/updatemobile/update',
        method: 'post',
        data,
    });
}


export function RequestUpdatePassword(data) {
    return request({
        url: 'profile/updatepassword',
        method: 'post',
        data,
    });
}


// export function logout() {
//     return request({
//         url: '/logout',
//         method: 'get',
//     });
// }


export function getUserInfo(token) {
    return request({
        url: '/user/info',
        method: 'get',
        params: {
            token
        },
    });
}

export function ACCOUNTLOCK_RequestOTP(data) {
    return request({
        url: '/profile/accountlocked/otprequest',
        method: 'post',
        data,
    });
}


export function ACCOUNTLOCK_CheckOTP(data) {
    return request({
        url: '/profile/accountlocked/checkOTP',
        method: 'post',
        data,
    });
}

export function ACCOUNTLOCK_UpdateMobileno(data) {
    return request({
        url: '/profile/accountlocked/updatemobileno',
        method: 'post',
        data,
    });
}

export function RequestProfileAddressList(data) {
    return request({
        url: '/profile/address/list',
        method: 'post',
        data,
    });
}

export function RequestProfileAddressAdd(data) {
    return request({
        url: '/profile/address/add',
        method: 'post',
        data,
    });
}

export function RequestProfileAddressUpdate(data) {
    return request({
        url: '/profile/address/update',
        method: 'post',
        data,
    });
}

export function RequestProfileAddressDelete(data) {
    return request({
        url: '/profile/address/delete',
        method: 'post',
        data,
    });
}

export function SetProfileAddressPrimary(data) {
    return request({
        url: '/profile/address/setprimary',
        method: 'post',
        data,
    });
}