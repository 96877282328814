import { onMounted, ref, onUpdated, onBeforeUnmount } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import {
  BaseTitleComponent,
  BaseLinksComponent,
  BaseInputMobileComponent,
  BaseInputPasswordComponent,
  BaseErrorMsgComponent,
  BaseButtonComponent,
  BaseLinksContactComponent,
  BaseModalComponent,
} from "@/components";
import { validPhoneNumber } from "@/helpers";
import { RequestApply } from "@/api/profile";
import { logger } from "../../../helpers";

export default {
  name: "Apply",
  data() {
    return {
      TOS:
        this.$store.getters["profile/getTosConsent"] ||
        localStorage.getItem("TosConsent"),
      Policy:
        this.$store.getters["profile/getPolicyConsent"] ||
        localStorage.getItem("PolicyConsent"),
      inputMobile: {
        name: "mobile",
        value: "",
        type: "text",
        placeholder: "เบอร์โทรศัพท์",
        maxlength: 9,
      },
      inputPassword: {
        name: "password",
        value: "",
        type: "password",
        placeholder: "กรอกรหัสผ่านใหม่",
        maxlength: 50,
      },
      buttonData: {
        name: "สมัครใหม่",
        type: "submit",
        active: false,
      },
      titleName: "สมัครบัญชีใหม่",
      errorMsg: {
        Mobile: "",
        Password: "",
      },
      PasswordCheck: null,
      PasswordScore: 0,
      getScorePassword: "",
      modalType: "duplicate_email",
    };
  },
  components: {
    BaseTitleComponent,
    BaseLinksComponent,
    BaseInputMobileComponent,
    BaseErrorMsgComponent,
    BaseButtonComponent,
    BaseInputPasswordComponent,
    BaseLinksContactComponent,
    BaseModalComponent,
  },
  inject: ["trackEvent"],
  mounted() {
    this.$store.commit("BUTTON_LOADING", false);
    if (
      !localStorage.getItem("TosConsent") &&
      !localStorage.getItem("PolicyConsent")
    ) {
      this.$router.push({ name: "PDPA" });
    }
  },
  watch: {
    inputMobile: {
      deep: true,
      handler(Mobile) {
        if (Mobile.value.length === 9 && validPhoneNumber(Mobile.value)) {
          this.buttonData.active = true;
          this.PasswordCheck = `เบอร์โทรศัพท์นี้จะเป็นเบอร์หลัก <br/>ในการรับรหัส OTP เพื่อใช้ยืนยันตัวตน`;
          this.PasswordScore = 0;
        } else if (
          Mobile.value.length === 9 &&
          !validPhoneNumber(Mobile.value)
        ) {
          this.buttonData.active = false;
          this.PasswordCheck = "คุณกรอกเบอร์โทรศัพท์ผิดพลาดหรือไม่ครบถ้วน";
        } else if (Mobile.value.length > 0 && !validPhoneNumber(Mobile.value)) {
          this.buttonData.active = false;
          this.PasswordCheck = "คุณกรอกเบอร์โทรศัพท์ผิดพลาดหรือไม่ครบถ้วน";
        } else {
          this.buttonData.active = false;
          this.PasswordCheck = "";
        }
        // this.inputMobile.error = false;
        this.errorMsg.Mobile = "";
        // this.FormValid();
      },
    },
    // inputPassword: {
    //   deep: true,
    //   handler(Password) {
    //     if (
    //       Password.value.length >= 6 &&
    //       this.inputMobile.value.length === 9 &&
    //       validPhoneNumber(this.inputMobile.value)
    //     ) {
    //       this.buttonData.active = true;
    //     } else {
    //       this.buttonData.active = false;
    //       this.PasswordCheck = "";
    //     }
    //     this.errorMsg.Password = "";
    //     this.StrengthChecker(Password);
    //   },
    // },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const modalStatus = ref(false);

    onMounted(async () => {
      logger("onMounted");
      if (route.query.crosstoken) {
        let CrossToken = route.query.crosstoken;
        logger(CrossToken);
        // localStorage.setItem('CrpLinetoken', route.query.linetoken)
        // console.log(route.query.linetoken)

        const sendData = {
          adaptertoken: route.query.crosstoken,
        };

        const response = await store.dispatch(
          "profile/CheckAdptoken",
          sendData
        );
        // console.log(response);
        if (response.result === "complete") {
          //เคยมี account อยู่แล้ว
          modalStatus.value = true;

          // remove localstorage
          localStorage.removeItem("CrossToken");
          localStorage.removeItem("TosConsent");
          localStorage.removeItem("PolicyConsent");

          logger("modalStatus", modalStatus.value);
        } else {
          if (response.resultdetail == "no_account_linked") {
            logger("no_account_linked");
            localStorage.setItem("CrossToken", route.query.crosstoken);
            router.push({
              name: "Apply",
            });
          } else if (response.resultdetail == "invalid_adapter_token") {
            logger(response.resultdetail);
            router.push({
              name: "PDPA",
            });
          }
        }
      }
    });

    return { modalStatus };
  },
  methods: {
    GotoLogin() {
      this.$router.push({ name: "Login" });
    },
    handlePasswordOk({ PasswordCheck, password }) {
      logger({ PasswordCheck, password });
      if (
        password.length >= 6 &&
        this.inputMobile.value.length === 9 &&
        validPhoneNumber(this.inputMobile.value)
      ) {
        this.buttonData.active = true;
      } else {
        this.buttonData.active = false;
      }
      this.PasswordCheck = PasswordCheck;
      this.inputPassword.value = password;
      this.errorMsg.Password = "";
    },
    FormValid() {
      let FormCheck = true;
      // alert(this.inputMobile.value.length);
      if (
        this.inputMobile.value.length <= 9 &&
        !validPhoneNumber(this.inputMobile.value)
      ) {
        // this.errorMsg.Mobile = '* คุณกรอกเบอร์โทรศัพท์ผิดพลาดหรือไม่ครบถ้วน';
        this.PasswordCheck = "คุณกรอกเบอร์โทรศัพท์ผิดพลาดหรือไม่ครบถ้วน";
        FormCheck = false;
      } else if (this.inputPassword.value.length < 6) {
        // this.errorMsg.Password = '* รหัสผ่านต้องมีจำนวน 6 ตัวขึ้นไป';
        this.PasswordCheck = `
        <div style="color: #FA5A5A;">
        รหัสผ่านต้องมีขั้นต่ำ 6 ตัวอักษร<br/>
        โดยมีทั้งตัวอักษรภาษาอังกฤษพิมพ์ใหญ่ <br/>
        ตัวอักษรภาษาอังกฤษพิมพ์เล็ก<br/>
        และตัวเลข</div>`;
        FormCheck = false;
      }

      return FormCheck;
    },
    SubmitForm() {
      let FormValid = this.FormValid();
      // alert(FormValid);
      if (FormValid == true) {
        this.RequestApply();
      } else {
        this.$store.commit("BUTTON_LOADING", false);
      }
    },
    async RequestApply() {
      let RequestData = {
        mobile: "0" + this.inputMobile.value,
        password: this.inputPassword.value,
      };

      // api sms otp
      let ApplyResp = await RequestApply(RequestData);

      this.$store.commit("BUTTON_LOADING", false);

      if (ApplyResp.result === "complete") {
        if (ApplyResp.resultdetail == "mobile_existing") {
          // this.errorMsg.Mobile = '* เบอร์โทรนี้เคยลงทะเบียนแล้ว';
          this.PasswordCheck = `<div style="color: #FA5A5A;">* เบอร์โทรนี้เคยลงทะเบียนแล้ว</div>`;
        } else {
          this.$store.commit("profile/OTPinfo", ApplyResp.otpinfo); //Save OTP INFO To Store
          this.$store.commit(
            "profile/SetSigupPassword",
            this.inputPassword.value
          ); //Save OTP INFO To Store
          this.$router.push({ name: "ApplyOTP" });
          this.trackEvent("Apply - Login", {
            event_category: "Apply",
            event_label: "Login",
            value: "เข้าสู่ระบบ",
          });
        }
      } else {
        if (ApplyResp.resultdetail == "mobile_existing") {
          // this.errorMsg.Mobile = '* เบอร์โทรนี้เคยลงทะเบียนแล้ว';
          this.PasswordCheck = `<div style="color: #FA5A5A;">* เบอร์โทรนี้เคยลงทะเบียนแล้ว</div>`;
        } else if (ApplyResp.resultdetail == "mobile_password_existing") {
          // this.errorMsg.Password = '* กรุณาตั้งรหัสผ่านใหม่';
          this.PasswordCheck = `<div style="color: #FA5A5A;">* เบอร์โทรนี้เคยลงทะเบียนแล้ว</div>`;
        } else {
          this.$message(ApplyResp.resultdetail);
        }
      }
    },
  },
};
