<style src="./ShopBillComponent.scss" lang="scss" scoped></style>
<script src="./ShopBillComponent.js"></script>

<template>
	<div class="bill__shop">
		<swiper
			:slides-per-view="4"
			:spaceBetween="0"
			:freeMode="true"
			:lazy="true"
			@swiper="onSwiper"
			@slideChange="onSlideChange"
		>
			<swiper-slide v-for="item in itemBrands" :key="item.id">
				<img
					:src="item.image"
					:alt="item.name"
					:class="{ selected: item.id == data.selectedItem }"
					@click="onSlideClicked(item.id)"
				/>
			</swiper-slide>
		</swiper>
	</div>
</template>