<style src="./ShopBillExampleComponent.scss" lang="scss"></style>
<script src="./ShopBillExampleComponent.js"></script>

<template>
	<div class="bill__example__view">
		<div
			v-loading="loading"
			element-loading-spinner="el-icon-loading"
			element-loading-background="rgba(0, 0, 0, 0.8)"
			style="width: 100%; z-index: 55"
		>
			<img v-if="itemBillId" :src="billSelected[0].bill" />
		</div>
	</div>
</template>