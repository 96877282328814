import { Icon, BaseAddressComponent } from "@/components";

export default {
  name: "BaseShippingAddress",
  components: {
    Icon,
    BaseAddressComponent,
  },
  props: {
    address: Object,
  },
  inject: ["trackEvent"],
  methods: {
    newAddress() {
      this.trackEvent(`Account - Add address`, {
        event_category: "Account",
        event_label: "Add address",
        value: "เพิ่มที่อยู่ใหม่",
      });

      this.$router.push({ name: "Address" });
    },
    updateShippingAddress() {
      this.trackEvent(`Account - Address lists`, {
        event_category: "Account",
        event_label: "Address lists",
        value: "ที่อยู่ทั้งหมด",
      });

      this.$router.push({ name: "AddressLists" });
    },
  },
};
