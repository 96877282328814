import {
  BaseTitleComponent,
  BaseInputRadioComponent,
  BaseButtonComponent
} from '@/components';
import { uuid } from '../../../helpers';

export default {
  name: 'Linking',
  data() {
    return {
      titleName: 'ผูกบัญชี LINE กับระบบ',
      buttonData: {
        name: 'ยืนยัน',
        type: 'submit',
        active: false
      },
      options: [
        {
          name: 'linking_with_line',
          value: 'linked',
          selected: null,
          title: 'ยืนยันการผูกบัญชี LINE เข้ากับระบบ\nเพื่อความสะดวกในการเข้าใช้งาน'
        },
        {
          name: 'linking_with_line',
          value: 'unlink',
          selected: null,
          title: 'ไม่ต้องการผูกบัญชี LINE เข้ากับระบบ'
        }
      ],
      selected: null,
      ProfileLinetoken: this.$store.getters['profile/getLineToken']
    }
  },
  components: {
    BaseTitleComponent,
    BaseInputRadioComponent,
    BaseButtonComponent
  },
  mounted() {
    this.$store.commit("BUTTON_LOADING", false);

    if (!localStorage.getItem('TosConsent') && !localStorage.getItem('PolicyConsent')) {
      this.$router.push({ name: 'PDPA' })
    }

    // case unline account with line
    if (localStorage.getItem('TosConsent') && localStorage.getItem('PolicyConsent') && localStorage.getItem('linkingAccount') === 'unlink') {
      this.$router.push({ name: 'Apply' })
    }


  },
  watch: {
    selected: {
      deep: true,
      handler(value) {
        if (value != null) {
          this.buttonData.active = true
          this.$store.commit("BUTTON_LOADING", false);
        }
      }
    },
    
  },
  methods: {
    selectedValue(data) {
      this.selected = data
      localStorage.setItem('linkingAccount', this.selected)
    },
    LinkingWithLine() {
      if (this.selected === 'linked') {
        // Line login
        // this.getAccessTokenLine()
        
      } else {
        this.$router.push({ name: 'Apply' })
      }
    },
   
    async getAccessTokenLine(code) {



      const response = await this.$store.dispatch('app/SET_LINE_ACCESS_TOKEN', this.ProfileLinetoken)
      if (response){
        this.$router.push({ name: 'Apply' })
      }

    }
  }

};