<style src="./BaseTitleComponent.scss" lang="scss"></style>
<script src="./BaseTitleComponent.js"></script>

<template>
	<div class="box__title">
		<h1
			v-html="titleName"
			:class="[
				{ text__left: titleAlign === 'left' },
				{ text__right: titleAlign === 'right' },
			]"
		></h1>
	</div>
</template>