import {
  BaseTitleComponent,
  BaseButtonComponent 
} from '@/components';

export default {
  name: 'Settings',
  components: {
    BaseTitleComponent,
    BaseButtonComponent
  }
};