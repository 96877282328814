<style src="./RedeemDetails.scss" lang="scss" scoped></style>
<script src="./RedeemDetails.js"></script>

<template>
	<div class="content__redeem__details__view">
		<BaseBackButtonComponent />
		<div class="redeem__details">
			<div class="reward__image">
				<BaseImageFeatureComponent :image-src="redeem" v-if="redeem.image"/>
			</div>
			<div class="reward__title">
				{{redeem.title}}
			</div>
			<div class="reward__price">
				{{redeem.price}}
			</div>
			<div class="redeem__points">
				<div class="redeem__points--spended">
					{{redeem.points_spended}}
				</div>
				<div class="redeem__points--date">
					{{redeem.claimed_datetime}}
				</div>
			</div>
			<div class="redeem__desc">
				<BaseDetailComponent detail-title="รายละเอียด" :detail-data="redeem.description" />
				<BaseDetailComponent detail-title="T & C" :detail-data="redeem.terms" />
			</div>
		</div>
	</div>
</template>