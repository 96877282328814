import {
  BaseBackButtonCustomComponent,
  BaseTitleComponent,
  BaseButtonSimpleComponent,
  BaseModalComponent,
} from "@/components";
import { ref, reactive, provide, onMounted } from "vue";
import { useRouter } from "vue-router";
import store from "@/store";
import { RequestUpdateMobile } from "@/api/profile.js";
export default {
  name: "SettingPhone",
  components: {
    BaseBackButtonCustomComponent,
    BaseTitleComponent,
    BaseButtonSimpleComponent,
    BaseModalComponent,
  },
  data() {
    return {
      goBack: true,
      modalShow: false,
      modalStatus: false,
      modalValue: 0,
      modalType: null,
      titleData: `เปลี่ยนรหัสผ่าน`,
      buttonOldPassword: {
        title: "ใช้รหัสผ่านเดิม",
        outline: true,
        option: "old",
        class: "setting__password",
      },
      buttonUpdatePassword: {
        title: "เปลี่ยนรหัสผ่านใหม่",
        outline: true,
        option: "new",
        class: "setting__password",
      },
    };
  },
  inject: ["trackEvent"],
  setup() {
    const router = useRouter();
    provide("store", store);

    const TOKEN = store.getters["profile/token"];
    const OTP = store.getters["profile/getRefcode"];
    const ExchangeCode = store.getters["profile/getExchangeCode"];

    onMounted(() => {
      if (!OTP || !ExchangeCode) {
        router.push({ name: "SettingPhone" });
      }
    });

    return {
      router,
      ExchangeCode,
      OTP,
      TOKEN,
    };
  },
  methods: {
    handleBackward() {
      this.router.push({
        name: "Home",
      });
    },
    buttonClick(data) {
      if (data.option === "new") {
        this.trackEvent("SettingPasswordOptions - Use old password", {
          event_category: "SettingPasswordOptions",
          event_label: "Change password",
          value: true,
        });
        this.RequestUpdateMobile("redirect");
      } else {
        this.RequestUpdateMobile("done");
      }
    },
    ShowModalComplete() {
      this.modalType = "updated";
      this.modalStatus = true;
    },
    handleModal(value) {
      this.trackEvent("SettingPasswordOptions - Confirm", {
        event_category: "SettingPasswordOptions",
        event_label: "Confirm",
        value: true,
      });
      this.router.push({ name: "Account" });
    },
    async RequestUpdateMobile(action) {
      let RequestData = {
        token: this.TOKEN,
        mobile: this.OTP.mobile,
        otp: this.OTP.otp,
        refcode: this.OTP.refcode,
        exchangecode: this.ExchangeCode,
      };
      let RequestResp = await RequestUpdateMobile(RequestData);
      this.$store.commit("BUTTON_LOADING", false);
      if (RequestResp.result == "complete") {
        if (action === "redirect") {
          this.trackEvent("SettingPasswordUpdate - Change password", {
            event_category: "SettingPasswordUpdate",
            event_label: "Change password",
            value: true,
          });
          this.router.push({
            name: "SettingPasswordUpdate",
          });
        } else {
          this.ShowModalComplete();
        }
      } else {
        this.$message(RequestResp.resultdetail);
      }
    },
  },
};
