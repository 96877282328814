import * as dayjs from "dayjs";
// import $ from 'jquery'

import {
  BaseBackButtonComponent,
  IconShop,
  BillImageViewer,
} from "@/components";

import { RequestGetPointHistory } from "@/api/profile.js";

export default {
  name: "BillDetail",
  data() {
    return {
      token: this.$store.getters["profile/token"],
      BillID: this.$route.params.BillId,
      BillItem: {
        privilege: 0,
        shoptype: "",
        created: "",
        status: "",

        reject_data: {
          reject_date: "",
          reject_detail: "",
        },
      },
      BillPhoto: [],
      Showbill: null,
    };
  },
  components: {
    BaseBackButtonComponent,
    IconShop,
    BillImageViewer,
  },
  mounted() {
    if (this.token) {
      this.GetPointHistory();
    }
  },
  computed: {
    BillStatusMsg: function() {
      if (this.BillItem.contentstatus == "approved") {
        return "ใบเสร็จถูกต้อง";
      } else if (this.BillItem.contentstatus == "rejected") {
        return "ใบเสร็จไม่ถูกต้อง";
      } else {
        return "ใบเสร็จอยู่ระหว่างรอการตรวจสอบ";
      }
    },
    BillUpdateStatusDate: function() {
      if (this.BillItem.contentstatus == "approved") {
        return this.BillItem.contentapprovedate;
      } else if (this.BillItem.contentstatus == "rejected") {
        return this.BillItem.contentrejectdate;
      } else {
        return null;
      }
    },
  },
  inject: ["trackEvent"],
  methods: {
    async GetPointHistory() {
      let RequestData = {
        token: this.token,
        receiptid: this.BillID,
      };
      let RequestResp = await RequestGetPointHistory(RequestData);
      if (RequestResp.result == "complete") {
        this.BillItem = RequestResp.list[0];

        this.BillPhoto = this.BillItem.receiptrawdata.map((bill) => bill.final);
      }
    },
    FormatDate(Date) {
      let FormatDate = dayjs(Date)
        .locale("th")
        .format("DD MMM YYYY");

      return FormatDate;
    },
    async ShowPDF() {
      const pdfjsLib = await import("pdfjs-dist/build/pdf");
      const pdfjsWorker = await import("pdfjs-dist/build/pdf.worker.entry");
      pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

      var loadingTask = pdfjsLib.getDocument(this.BillItem.receiptimage);
      await loadingTask.promise.then(
        function(pdf) {
          var pageNumber = 1;
          pdf.getPage(pageNumber).then(function(page) {
            // console.log('Page loaded');
            var desiredWidth = window.innerWidth - 80;
            var viewport = page.getViewport({
              scale: 1,
            });
            var scale = desiredWidth / viewport.width;
            var scaledViewport = page.getViewport({
              scale: scale,
            });

            // Prepare canvas using PDF page dimensions
            var canvas = document.getElementById("pdfViewer");
            var context = canvas.getContext("2d");
            canvas.height = scaledViewport.height;
            canvas.width = scaledViewport.width;

            // Render PDF page into canvas context
            var renderContext = {
              canvasContext: context,
              viewport: scaledViewport,
            };
            var renderTask = page.render(renderContext);

            renderTask.promise.then(function() {
              // console.log("Page rendered");
            });
          });
        },
        function(reason) {
          // PDF loading error
          console.error(reason);
        }
      );
    },
    ShowBill(BillIndex) {
      this.Showbill = BillIndex;
      this.trackEvent(`PointStatus - Click`, {
        event_category: "PointStatus",
        event_label: "Bill view",
        value: BillIndex,
      });
    },
    HideBill() {
      this.Showbill = null;
    },
    handleClick() {
      this.trackEvent(`PointStatus - Contact admin`, {
        event_category: "PointStatus",
        event_label: "Contact admin",
        value: true,
      });

      this.$router.push({
        path: "/Contact-Us",
      });
    },
  },
};
