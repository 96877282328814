import { inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import { isBrowser } from "@/helpers";
import liff from "@line/liff";
export default {
  name: "FooterSignupComponent",
  props: {
    buttonPosition: Boolean,
  },
  setup() {
    const router = useRouter();
    const trackEvent = inject("trackEvent");
    const handleOpenLink = () => {
      trackEvent("Login - Register", {
        event_category: "Login",
        event_label: "Register",
        value: "สมัครบัญชีใหม่",
      });

      if (isBrowser("line")) {
        // window.location.href = `${process.env.VUE_APP_BASEAPPURL}PDPA?openExternalBrowser=1`;
        liff.openWindow({
          url: `${process.env.VUE_APP_BASEAPPURL}PDPA?utm_source=adapterroxline&utm_medium=liff-browser-forward&utm_campaign=roxlaunch2021nov`,
          external: true,
        });
      } else {
        router.push({
          name: "Apply",
        });
      }
    };

    const handleForgotPassword = () => {
      trackEvent("Login - Forgot pass", {
        event_category: "Login",
        event_label: "Forgot pass",
        value: "ลืมรหัสผ่าน",
      });
      router.push({
        name: "ForgotPass",
      });
    };

    return {
      handleOpenLink,
      handleForgotPassword,
    };
  },
};
