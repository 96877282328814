<style src="./Register.scss" lang="scss" scoped></style>
<script src="./Register.js"></script>

<template>
	<section>
		<BaseAvatarEditorComponent
			ref="avatarEditor"
			v-on:loadImage="getProfileImage"
			v-on:retakeImage="retakeImage"
		/>
		<BaseAvatarCameraComponent
			v-if="page === 'capture'"
			@update:capture="handleCapture"
		/>
		<div class="content__view" v-if="page === 'account'">
			<div class="profile__image">
				<BaseTitleComponent :title-name="profile.title" />
				<div>
					<BaseAvatarComponent
						:image-src="profile.data.image"
						image-size="large"
						:key="profile.data.image"
					/>
				</div>
				<button type="button" @click="imageAction()">
					{{ getButtonLabel() }}
				</button>
			</div>

			<UserProfileComponent
				:configs="profile"
				:editable-status="profile.button.type"
			/>
			<div class="button">
				<button type="button" @click="save()">บันทึก</button>
			</div>

			<BaseModalComponent
				:modal-show="modalStatus"
				:modal-type="modalType"
				@update:modal="handleModal"
			/>
		</div>
	</section>
</template>