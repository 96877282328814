<style src="./Gateway.scss" lang="scss" scoped></style>
<script src="./Gateway.js"></script>

<template>
	<div class="content__view">
		<div class="center">
			<BaseTitleComponent :title-name="titleName" class="title" />
			<div v-if="logoBrand" class="logo__brand">
				<img :src="logoBrand" />
			</div>
			<div v-else class="subtitle" v-html="subtitle"></div>
		</div>
		<div class="button__box">
			<BaseButtonSimpleComponent
				:button-data="buttonSignup"
				v-on:buttonClick="buttonClick"
			/>

			<BaseButtonSimpleComponent
				:button-data="buttonLogin"
				v-on:buttonClick="buttonClick"
			/>
		</div>
	</div>
</template>