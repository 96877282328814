export const types = {
    MUTATION: 'mutation',
};

import platform from 'platform'
import { uuid } from "@/helpers";

export default {
    namespaced: true,
    state: {
        LogValue: {
        access: "web",
        appversion: process.env.VUE_APP_VERSION,
        os: `${platform.os.family} ${platform.os.version}`, //"android 10"
        device: platform.os.family, // "andorid/ios/windows/macos"
        deviceid: (localStorage.getItem('DeviceID')) ? localStorage.getItem('DeviceID') : uuid()
    }
      
    },
    getters: {
        // [types.MUTATION]: state => state.value,
        getLogValue: state => state.LogValue,
    },
    mutations: {
        // [types.MUTATION](state, value) {
        //     state.value = value;
        // },
        SetLogvalue(state, payload) {
            state.LogValue = { ...state.LogValue, payload};
        },
        
    },
    actions: {},
};