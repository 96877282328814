<style src="./BaseListItemComponent.scss" lang="scss"></style>
<script src="./BaseListItemComponent.js"></script>

<template>
	<div class="box__list__item">
		<div
			class="list__item"
			v-for="item in lists"
			:key="item.id"
			@click="viewDetail(item)"
		>
			<div class="item__image">
				<el-image :src="item.image" fit="fill">
					<template #placeholder>
						<div class="image-slot">Loading<span class="dot">...</span></div>
					</template>
				</el-image>
			</div>
			<div class="item__detail">
				<div class="item__detail--title">{{ item.title }}</div>
				<div class="item__detail--price">{{ item.price }}</div>
			</div>
			<div class="item__point" v-if="item.start">
				<div class="item__point__stack">
					<div class="item__point--points">
						{{ item.points.toLocaleString() }}
					</div>
					<div class="item__point--label">Points</div>
					<div class="item__point--action">{{ item.action.label }}</div>
				</div>
			</div>
		</div>
	</div>
</template>