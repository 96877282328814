export default {
    name: 'ScanProcessingComponent',
    props: {
        config: {
            type: Object,
            default: {
                name: 'กำลังประมวลผล'
            }
        }
    }
}