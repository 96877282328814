import { mapGetters } from "vuex";

export default {
  name: "BaseButton",
  props: {
    buttonData: Object,
    buttonPosition: Boolean,
  },
  components: {},
  setup() {},
  computed: {
    ...mapGetters(["getButtonLoading"]),
  },
  methods: {
    handleButtonNext() {
      // console.log('handleButtonNext', this.buttonData.type)
      this.$store.commit("BUTTON_LOADING", true);
      // this.emitter.emit(`button:${this.buttonData.type}`);
      this.$emit("HandleButton");
    },
  },
};
