export default {
  name: "BaseAvatar",
  props: {
    imageSrc: String,
    imageSize: String
  },
  data() {
    return {
      avatar: (localStorage.getItem('avatarChange') == 'true')?this.imageSrc:this.imageSrc.split('?v_')[0],
      size: this.imageSize
    };
  },
  mounted(){
    if(localStorage.getItem('avatarChange')){
      this.avatar = this.imageSrc
      localStorage.setItem("avatarChange", false);
    }
  }
};
