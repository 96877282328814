<template>
	<el-dialog
		:model-value="modalShow"
		width="100%"
		center
		fullscreen
		:before-close="handleClose"
	>
		<div class="modal__head">
			<h1>{{ modalData.title }}</h1>
		</div>
		<div class="modal__body" v-html="modalData.detail"></div>
	</el-dialog>
</template>

<script>
	export default {
		name: "PDPAModalCond",
		data() {
			return {
				DialogVisible: false,
			};
		},
		props: {
			modalShow: Boolean,
			modalData: Object,
		},
		methods: {
			handleClose() {
				this.$emit("update:dialogCond", false);
			},
		},
	};
</script>

<style lang="scss">
	.el-dialog {
		&.is-fullscreen {
			height: calc(100% - 5%) !important;
			margin-top: 10.5% !important;
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;
		}
		.el-dialog__close {
			border: 1px solid #3e3e3e;
			padding: 10px;
			border-radius: 100px;
			color: #3e3e3e;

			&:hover {
				color: #3e3e3e !important;
			}
		}
		.modal__head {
			padding: 0px 25px 0px;
			h1 {
				font-family: $font-family-ibm-semi;
				@include font-size(42);
				color: $black;
			}
		}
		.modal__body {
			padding: 0px 25px 25px;
			p {
				padding: 0;
				margin-top: 8%;
				margin-bottom: 10px;
				font-family: $font-family-ibm;
				@include font-size(20);
				color: $darkGray;
				text-align: left;
			}
		}
	}
</style>