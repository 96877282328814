import { Icon } from "@/components";
import $ from "jquery";
export default {
  name: "AppbarComponent",
  components: {
    Icon,
  },
  data() {
    return {
      menuSlide: false,
    };
  },
  inject: ["trackEvent"],
  watch: {
    $route(to, from) {
      if (this.menuSlide) {
        this.showMore();
      }
    },
  },
  methods: {
    showMore() {
      this.menuSlide = !this.menuSlide;
      $(".backdrop").toggle();
      $(".sheet").slideToggle("fast");
      this.trackEvent("Menu - Open", {
        event_category: "Menu",
        event_label: "Open",
        value: true,
      });
    },
    SignOut() {
      // console.log('signout')
      localStorage.removeItem("Crptoken");
      this.$store.commit("profile/SetToken", null);
      this.$router.push("/Login");

      window.location.reload();

      this.trackEvent("Menu - Sign out", {
        event_category: "Menu",
        event_label: "Sign out",
        value: true,
      });
    },
    handleClick() {
      this.trackEvent(`Menu - Edit Profile`, {
        event_category: "Home",
        event_label: "Edit Profile",
        value: "แก้ไขข้อมูล",
      });

      this.$router.push({
        path: "/Account/edit",
      });
    },
    handleHowtoSend() {
      this.trackEvent(`Menu - How to play`, {
        event_category: "Home",
        event_label: "How to play",
        value: "วิธีการส่งใบเสร็จ",
      });

      this.$router.push({
        path: "/BannerDetail/E2FF238C-B62C-448D-9098-49D8249A379A",
      });
    },
  },
};
