export default {
  name: "BaseTitle",
  props: {
    titleName: String,
    titleAlign: String,
  },
  data() {
    return {
      title: null,
    };
  },
  computed: {
    titleUpdate: function() {
      return this.titleName;
    },
  },
};
