<style src="./Linking.scss" lang="scss" scoped></style>
<script src="./Linking.js"></script>

<template>
	<div class="content__view">
		<div class="content__view__inner">
            <BaseTitleComponent :title-name="titleName" />
			<div class="gaping"></div>
			{{ProfileLinetoken}}
			<BaseInputRadioComponent v-for="option in options" :key="option.value" :inputRadio="option" @selected="selectedValue"/>
      <BaseButtonComponent :button-data="buttonData" @HandleButton="LinkingWithLine" />
		</div>
	</div>
</template>