export default {
  name: "BaseSendOTP",
  props: {
    sendData: Object
  },
  data() {
    return {
      ShowResend:true
    }
  },
  methods: {
    RequestApply(){
      this.ShowResend = false;
      this.$emit('RequestApply')
      setTimeout(() => {
        this.ShowResend= true
      }, 5000);

    }
  },
};
