import { onUpdated, onBeforeUnmount } from "vue";
import $ from "jquery";
import * as dayjs from "dayjs";

import {
  BaseTitleComponent,
  BaseTextLineComponent,
  BaseInputOTPComponent,
  BaseSendOTPComponent,
  BaseButtonComponent,
  BaseModalComponent,
} from "@/components";

import {
  RequestLogin,
  RequestUpdateMobile,
  ACCOUNTLOCK_RequestOTP,
  ACCOUNTLOCK_CheckOTP,
  ACCOUNTLOCK_UpdateMobileno,
} from "@/api/profile";

export default {
  name: "ApplyOTP",
  data() {
    return {
      textLine: {
        label: "OTP ถูกส่งไปที่หมายเลข",
        value: "",
      },
      sendOTPData: {
        msg: "ไม่ได้รับรหัส OTP?",
        // error: 'คุณกรอกรหัส OTP ไม่ถูกต้องกรุณากรอกใหม่อีกครั้ง',
        error: "",
        link: "ส่งใหม่อีกครั้ง",
      },
      buttonData: {
        name: "ส่ง",
        type: "submit_otp",
        active: true,
      },
      titleName: "กรุณากรอกรหัส OTP",
      errorMsg: "คุณกรอกเบอร์โทรศัพท์ผิดพลาดหรือไม่ครบถ้วนกรุณาลองใหม่อีกครั้ง",
      OTP: this.$store.getters["verify/getRefcode"],
      CountDown: {
        minute: 0,
        second: 0,
      },
      OTPisValid: false,
      InputOTP: "",
      exchangecode: "",
      OriginMobile: this.$store.getters["verify/getMobile"],
      OriginPassword: this.$store.getters["verify/getPassword"],
      timer: null,
      modalStatus: null,
      modalType: null,
    };
  },
  components: {
    BaseTitleComponent,
    BaseTextLineComponent,
    BaseInputOTPComponent,
    BaseSendOTPComponent,
    BaseButtonComponent,
    BaseModalComponent,
  },
  setup() {
    onUpdated(() => {
      let vm = this;
      // console.log('Component is mounted!')

      // console.log(vm)
    }),
      onBeforeUnmount(() => {
        // console.log('off emitter')
        // this.emitter.off(`button:${this.buttonType}`);
      });
  },
  created() {
    if (!this.OTP || !this.OriginMobile || !this.OriginPassword) {
      this.$router.push("/LOGIN");
    } else {
      this.handleCountDown();

      this.textLine.value = this.OTP.mobile.slice(0, 6) + "XXXX";

      if (this.OTP.otp && this.OTP.otp !== "") {
        this.$message(`OTP ของคุณคือ ${this.OTP.otp}`);
      }
    }
  },
  beforeDestroy() {
    // this.emitter.off(`button:${this.buttonData.type}`);
    // this.emitter.off(`button:${this.buttonData.type}`, () => {
    //   console.log('OFF')
    // });
  },
  inject: ["trackEvent"],
  methods: {
    SubmitForm() {
      this.CheckOTP();
    },
    handleOnComplete(value) {
      this.sendOTPData.error = "";
      this.InputOTP = value;
      if (this.InputOTP.length == 5) {
        this.buttonData.active = true;
      } else {
        this.buttonData.active = false;
      }
    },
    handleOnChange(value) {
      // console.log(value)
      // console.log('OTP changed: ', value);
    },
    handleClearInput(ref) {
      this.$refs[ref].clearInput();
    },
    handleCountDown() {
      // console.log('handleCountDown')
      // console.log(this.OTP.expire)

      // const date1 = dayjs(this.OTP.expire).subtract(7, 'hour')
      const date1 = dayjs(this.OTP.expire);

      // console.log(date1.format('DD/MM/YYYY HH:mm:ss'))
      let TimeCount = date1.diff(dayjs(), "second");
      // console.log(date1.format('DD/MM/YYYY HH:mm:ss'))
      // console.log(TimeCount)

      if (TimeCount > 0) {
        this.CountDown.minute =
          Math.floor(TimeCount / 60) < 10
            ? `0${Math.floor(TimeCount / 60)}`
            : Math.floor(TimeCount / 60);
        this.CountDown.second =
          TimeCount - this.CountDown.minute * 60 < 10
            ? `0${TimeCount - this.CountDown.minute * 60}`
            : TimeCount - this.CountDown.minute * 60;

        // T
        this.OTPisValid = true;

        this.timer = setTimeout(() => {
          this.handleCountDown();
        }, 1000);
      } else {
        this.OTPisValid = false;
      }
    },
    async RequestApply() {
      this.trackEvent("VerifyOTP - Resend", {
        event_category: "VerifyOTP",
        event_label: "Resend",
        value: true,
      });

      let RequestData = {
        mobile: this.OTP.mobile,
      };
      let ApplyResp = await ACCOUNTLOCK_RequestOTP(RequestData);

      if (ApplyResp.result === "complete") {
        this.OTP = ApplyResp.otpinfo;
        if (this.OTP.otp && this.OTP.otp !== "") {
          this.$message(`OTP ของคุณคือ ${this.OTP.otp}`);
        }
        this.$store.commit("verify/OTPinfo", ApplyResp.otpinfo); //Save OTP INFO To Store
        // console.log(this.$store.getters["verify/getRefcode"]);
        this.$forceUpdate();
      } else {
        if (ApplyResp.resultdetail == "mobile_existing") {
          this.errorMsg.Mobile = "* เบอร์โทรนี้เคยลงทะเบียนแล้ว";
        } else {
          this.$message(ApplyResp.resultdetail);
        }
        this.$store.commit("BUTTON_LOADING", false);
      }
    },
    async CheckOTP() {
      // console.log('ACCOUNTLOCK_CheckOTP')
      let RequestData = {
        mobile: this.OTP.mobile,
        otp: this.InputOTP,
        refcode: this.OTP.refcode,
      };
      // console.log(RequestData)
      let CheckOtpResp = await ACCOUNTLOCK_CheckOTP(RequestData);
      this.$store.commit("BUTTON_LOADING", false);
      if (CheckOtpResp.result == "complete") {
        this.exchangecode = CheckOtpResp.exchangecode;
        clearTimeout(this.timer);

        this.RequestUpdateMobile();
      } else {
        this.sendOTPData.error = "*รหัส OTP ไม่ถูกต้อง";
      }
    },
    async RequestUpdateMobile() {
      let RequestData = {
        exchangecode: this.exchangecode,
        oldmobileprefix: "66",
        oldmobile: this.OriginMobile,
        oldpassword: this.OriginPassword,
        mobileprefix: "66",
        mobile: this.OTP.mobile,
        otp: this.InputOTP,
        refcode: this.OTP.refcode,
      };

      let CheckOtpResp = await ACCOUNTLOCK_UpdateMobileno(RequestData);
      this.$store.commit("BUTTON_LOADING", false);
      if (CheckOtpResp.result == "complete") {
        // console.log('Update complete')
        this.RequestLogin();
        this.trackEvent("VerifyOTP - Confirm", {
          event_category: "VerifyOTP",
          event_label: "Confirm",
          value: true,
        });
      }
    },
    async RequestLogin() {
      let RequestData = {
        mobile: this.OTP.mobile,
        password: this.OriginPassword,
      };

      let RequestResp = await RequestLogin(RequestData);
      this.$store.commit("BUTTON_LOADING", false);

      if (RequestResp.result == "complete") {
        this.$store.commit("profile/SetToken", RequestResp.token);
        this.$router.push("/Home");
      } else {
        if (RequestResp.resultdetail == "cannot_login") {
          this.errorMsg.API = "Login Fail";
        } else {
          this.$message({
            message: RequestResp.resultdetail,
            type: "error",
          });
        }
      }
    },
    ShowModalComplete() {
      this.modalType = "verifycomplete";
      this.modalStatus = true;
    },
    handleModal(value) {
      this.$router.push("/Login");
    },
  },
};
