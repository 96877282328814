<style src="./BaseAddressComponent.scss" lang="scss"></style>
<script src="./BaseAddressComponent.js"></script>

<template>
	<div class="box__address" v-if="addressData !== null && primaryAddress !== null">
		<div class="address__indicator">
			<div class="indicator">
				<div class="indicator__radio">
					<label>
						<input type="radio" name="primary_address" :value="addressData" :checked="(addressData.id===primaryAddress.id)?true:false" v-on:change="primarySelect(addressData)"/>
						<span class="radio"></span>
					</label>
				</div>
			</div>
			<div class="icon" v-if="addressData && addressData.title">
				<Icon :name="`indicator-`+addressData.title"/>
			</div>
		</div>
		<div class="address__line">
			<div class="edit__button" >
				<router-link :to="{ name: 'Address', params: {id: addressData.id } }" v-show="addressData.editMode">แก้ไข</router-link>
			</div>
			<div class="name" v-if="addressData && addressData.firstname">
				{{addressData.firstname}}
			</div>
			<div class="address" v-if="addressData">
				{{ addressParser(addressData)}}
			</div>
		</div>
	</div>
</template>