import { ref, computed, watchEffect, onMounted } from "vue";
import { useStore } from "vuex";
import {
  Icon,
  BaseProfileHeaderComponent,
  BaseTitleComponent,
  BaseTextLineComponent,
  BaseBannerComponent,
  BaseListTitleComponent,
  BaseListItemComponent,
} from "@/components";

export default {
  name: "Rewards",
  components: {
    Icon,
    BaseProfileHeaderComponent,
    BaseTitleComponent,
    BaseTextLineComponent,
    BaseBannerComponent,
    BaseListTitleComponent,
    BaseListItemComponent,
  },
  data() {
    return {
      title: "ของรางวัล",
    };
  },
  methods: {
    viewDetail(item) {
      this.$router.push({
        name: "RewardDetails",
        params: { type: item.action.type, id: item.id },
      });
    },
  },
  setup(props) {
    const store = useStore();
    const rewardItems = ref(null);
    const luckydrawItems = ref(null);
    async function handleluckydrawItems() {
      await store.dispatch("reward/UPDATE_LUCKYDRAW_ITEMS").finally(() => {
        luckydrawItems.value = store.getters["reward/UPDATE_LUCKYDRAW_ITEMS"];
        luckydrawItems.value = luckydrawItems.value.slice(0, 3);
      });
    }
    async function handleLrewardItems() {
      await store.dispatch("reward/UPDATE_REWARD_ITEMS").finally(() => {
        rewardItems.value = store.getters["reward/UPDATE_REWARD_ITEMS"];
        rewardItems.value = rewardItems.value.slice(0, 7);
      });
    }

    onMounted(() => {
      handleluckydrawItems();
      handleLrewardItems();
    });

    return {
      rewardItems,
      luckydrawItems,
      handleluckydrawItems,
      handleLrewardItems,
    };
  },
};
