<style src="./BasePointLegendComponent.scss" lang="scss"></style>
<script src="./BasePointLegendComponent.js"></script>

<template>
	<div class="box__point__border">
		<fieldset class="point__block">
			<span class="point__block--label">{{pointData.toLocaleString()}}</span>
			<legend :class="legendBg">Points</legend>
		</fieldset>
	</div>
</template>