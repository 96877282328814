import { ref } from "vue";

export default {
  name: "BaseInputRadio",
  props: {
    inputRadio: Object
  },
  computed: {
    lineAccessToken() {
      return this.$store.getters['app/SET_LINE_ACCESS_TOKEN']
    },
    lineAccountSelected() {
      return localStorage.getItem('linkingAccount')
    }
  },
  methods: {
    select(value) {
      this.$emit('selected', value)
    }
  }
};
