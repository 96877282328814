<style src="./PointExpireComponent.scss" lang="scss" scoped></style>
<script src="./PointExpireComponent.js"></script>

<template>
	<div class="point-expires">
		<div class="point-expire" v-for="(item,index) in PointExpires" :key="index">	
			<div class="point-expire-list" v-if="item.pointbalance">
					{{item.pointbalance.toLocaleString()}} พอยท์ จะใช้งานได้ถึง {{FormatDate(item.expired)}}
			</div>
		</div>
		
		
	</div>
</template>