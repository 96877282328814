<style src="./BaseModalComponent.scss" lang="scss"></style>
<script src="./BaseModalComponent.js"></script>

<template>
	<div
		:class="{
			modal__allow: modalType === 'allow',
			modal__success: modalType === 'success',
			modal__saved: modalType === 'saved',
		}"
	>
		<el-dialog
			:model-value="modalShow"
			width="80%"
			center
			:show-close="false"
			@closed="handleClosed"
			:close-on-click-modal="false"
		>
			<div class="box__modal">
				<div
					class="modal__contain"
					:class="{
						modal__accept__inner: modalType === 'accept',
						modal__completed__inner: modalType === 'completed',
						modal__success__inner: modalType === 'success',
						modal__allow__inner: modalType === 'allow',
						modal__redeem__inner: modalType === 'redeem',
						modal__reset__inner: modalType === 'reset',
						modal__updated__inner: modalType === 'updated',
						modal__remove__inner: modalType === 'remove',
						modal__saved__inner: modalType === 'saved',
						modal__updatecomplete__inner: modalType === 'updatecomplete',

						modal__verifycompleted__inner: modalType === 'verifycomplete',
						modal__dup__inner: modalType === 'duplicate_email',
					}"
				>
					<div class="modal__content">
						<img
							v-if="
								modalType === 'accept' ||
								modalType === 'completed' ||
								modalType === 'success' ||
								modalType === 'reset' ||
								modalType === 'updated' ||
								modalType === 'saved' ||
								modalType === 'verifycomplete' ||
								modalType === 'updatecomplete'
							"
							src="../../assets/images/pdpa/completed.png"
							alt="accept_terms"
						/>
						<img
							v-if="
								modalType === 'redeem' ||
								modalType === 'remove' ||
								modalType === 'duplicate_email'
							"
							src="../../assets/images/pdpa/information.png"
							alt="accept_terms"
						/>
						<h1 v-html="dataModals[0].title"></h1>
						<p v-if="modalType == 'redeem'" v-html="message"></p>
						<p v-else v-html="dataModals[0].message"></p>
					</div>
					<div v-if="modalType !== 'allow'" class="modal__buttons">
						<ul>
							<li v-for="item in dataModals[0].button" :key="item.name">
								<a @click="item.action ? handleOk() : handleCancel()">
									{{ item.name }}
								</a>
							</li>
						</ul>
					</div>
					<div v-else class="modal__buttons">
						<ul>
							<li v-for="(item, index) in dataModals[0].button" :key="index">
								<a @click="handleAllow(item.action)">
									{{ item.name }}
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>