<style src="./ForgotPass.scss" lang="scss" ></style>
<script src="./ForgotPass.js"></script>

<template>
	<div class="content__view content__forgotpass">
		<div class="content__view__inner">
			<BaseTitleComponent :title-name="titleName" />
			<div class="forget_msg1">กรุณากรอกเบอร์โทรศัพท์เพื่อรีเซ็ตรหัสผ่าน</div>
			<BaseInputMobileComponent
				:input-mobile="inputMobile"
				v-model="inputMobile.value"
				:error="errorMsg.Mobile"
			/>
			<div class="gaping"></div>
			<div
				class="forget_msg_default"
				v-if="!errorMsg.Mobile && !buttonData.active"
			>
				<!-- The OTP will be send as SMS to the mobile phone number registered -->
				รหัส OTP จะถูกส่งไปยังเบอร์โทรศัพท์<br />
				ที่คุณได้ลงทะเบียนไว้
			</div>
			<BaseErrorMsgComponent
				v-else-if="errorMsg.Mobile && !buttonData.active"
				:error-msg="errorMsg.Mobile"
			/>
			<div v-else class="forget_msg_default">
				รหัส OTP จะถูกส่ง SMS ไปยังเบอร์โทรศัพท์<br />ที่คุณได้ลงทะเบียนไว้
			</div>
			<BaseButtonComponent
				:button-data="buttonData"
				@HandleButton="SubmitForm"
			/>
		</div>
	</div>
</template>