import axios from "axios";
import { ElMessageBox, ElMessage } from "element-plus";
import store from "@/store";
import config from "@/constants";

// create an axios instance
const service = axios.create({
  baseURL: config.apiOCRUrl,
  timeout: 10 * 10000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (serviceConfig) => {
    // const isLoggedIn = store.dispatch("app/APP_VALID_TOKEN")
    // const isJWTToken = store.getters["profile/token"]
    // if (isLoggedIn && isJWTToken) {
    // serviceConfig.headers['X-Token'] = getToken();
    // serviceConfig.headers.common.Authorization = `Bearer ${isJWTToken}`;
    // }
    // serviceConfig.headers['accept'] = '*/*'
    // serviceConfig.headers['content-type'] = 'application/json'
    // serviceConfig.headers['access-control-allow-origin'] = '*'
    // serviceConfig.headers['access-control-allow-methods'] = 'GET, POST, OPTIONS, PUT, PATCH, DELETE'
    // serviceConfig.headers['access-control-allow-headers'] = 'X-Requested-With,content-type'
    // serviceConfig.headers['access-control-allow-credentials'] = 'true'
    // serviceConfig.headers['Cache-Control'] = null
    // serviceConfig.headers['X-Requested-With'] = null
    return serviceConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

// respone interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (response.status !== 200) {
      // return ElMessage({
      //   message: res.message,
      //   type: "error",
      //   duration: 10 * 1000,
      // });
      console.log({ response: res.message });
      // }else if(res.result == 'error' ) {
      //   return ElMessage({
      //     message: res.resultdetail,
      //     type: 'error',
      //     duration: 10 * 1000,
      //   });
    }
    return res;
  },
  (error) => {
    // ElMessage({
    //   message: error.message,
    //   type: "error",
    //   duration: 10 * 1000,
    // });
    console.log({ response: error.message });
    return Promise.resolve(error);
  }
);

export default service;
