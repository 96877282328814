import {
  BaseTitleComponent,
  BaseInputMobileComponent,
  BaseButtonComponent,
  BaseErrorMsgComponent,
} from "@/components";

import { RequestForgotPassword } from "@/api/profile";
import { validPhoneNumber } from "@/helpers";

export default {
  name: "Apply",
  data() {
    return {
      inputMobile: {
        name: "mobile",
        value: "",
        type: "text",
        placeholder: "เบอร์โทรศัพท์",
        maxlength: 9,
      },
      buttonData: {
        name: "ถัดไป",
        type: "submit",
        active: false,
      },
      titleName: "ลืมรหัสผ่าน",
      errorMsg: {
        Mobile: "",
      },
    };
  },
  components: {
    BaseTitleComponent,
    BaseInputMobileComponent,
    BaseButtonComponent,
    BaseErrorMsgComponent,
  },
  watch: {
    inputMobile: {
      deep: true,
      handler(Mobile) {
        // if (Mobile.value.length === 9 && Mobile.value.slice(0, 1) !== "0") {
        if (Mobile.value.length === 9 && validPhoneNumber(Mobile.value)) {
          this.buttonData.active = true;
          this.errorMsg.Mobile = "";
        } else if (
          Mobile.value.length > 0 &&
          Mobile.value.length <= 9 &&
          !validPhoneNumber(Mobile.value)
        ) {
          this.buttonData.active = false;
          this.errorMsg.Mobile = "* คุณกรอกเบอร์โทรศัพท์ผิดพลาดหรือไม่ครบถ้วน";
        } else {
          this.buttonData.active = false;
          this.errorMsg.Mobile = "";
        }
      },
    },
  },
  methods: {
    FormValid() {
      let FormCheck = true;
      if (this.inputMobile.value.length < 9) {
        this.errorMsg.Mobile = "* คุณกรอกเบอร์โทรศัพท์ผิดพลาดหรือไม่ครบถ้วน";
        FormCheck = false;
      }
      return FormCheck;
    },
    SubmitForm() {
      let FormValid = this.FormValid();
      if (FormValid === true) {
        this.RequestForgotPassword();
      } else {
        this.$store.commit("BUTTON_LOADING", false);
      }
    },
    async RequestForgotPassword() {
      // if (this.inputMobile.value.slice(0, 1) === '0') {
      // console.log('begin 0')
      //   this.inputMobile.value = this.inputMobile.value.substring(1)
      // } else {
      // console.log('not begin 0')
      // }

      let RequestData = {
        mobile: "0" + this.inputMobile.value,
      };
      let RequestResp = await RequestForgotPassword(RequestData);
      this.$store.commit("BUTTON_LOADING", false);

      if (RequestResp.result == "complete") {
        this.$store.commit("profile/OTPinfo", RequestResp.otpinfo);
        this.$router.push("ForgotOTP");
      } else {
        this.$message(RequestResp.resultdetail);
      }
    },
  },
};
