<style src="./PointhistoryListLuckydrawComponent.scss" lang="scss" scoped></style>
<script src="./PointhistoryListLuckydrawComponent.js"></script>

<template>
	<div class="point-history-lists">
		<div v-for="History in HistoryList" class="point-history-date" :key="History.date">

			<div class="item-date">
				<span class="item-date-day">{{GetDate(History.date)}}</span><br />
				<span class="item-date-month">{{GetMonth(History.date)}}</span>
				<div class="vertical-line" v-if="History.data.length >= 2">
					<span class="dot"></span>
				</div>
			</div>

			<div class="item-lists">


				<div class="list__item" v-for="item in History.data" :key="item.id">
					<div class="list__item_inner">
						<div class="item__image">
							<el-image :src="item.image" fit="contain">
								<template #placeholder>
									<div class="image-slot">
										Loading<span class="dot">...</span>
									</div>
								</template>
							</el-image>
						</div>
						<div class="item__detail">
							<div class="item__detail--title">{{item.title}}</div>
							<div class="item__detail--price" v-if="item.price">{{item.price}}</div>
							<div class="item__point">
								ใช้ {{item.usage.point.toLocaleString()}} points แลก {{item.usage.item.toLocaleString()}} สิทธิ์
							</div>
						</div>


					</div>
					<div class="list__item_arrow">
						<router-link :to="`/RedeemDetails/${item.type}/${item.id}`"><i class="el-icon-arrow-right"></i></router-link>
					</div>


				</div>



			</div>

		</div>


	</div>
</template>