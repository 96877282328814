<style src="./BaseLinksComponent.scss" lang="scss"></style>
<script src="./BaseLinksComponent.js"></script>

<template>
	<div class="box__link" :class="{ custom__links: buttonPosition }">
		<ul class="list__items">
			<li>หากมีบัญชีอยู่แล้ว คุณสามารถคลิก</li>
			<li>
				<router-link to="" @click="handleClick">
					<span class="appbar__text">เข้าสู่ระบบ</span>
				</router-link>
			</li>
		</ul>
	</div>
</template>