<style src="./Rewards.scss" lang="scss" scoped></style>
<script src="./Rewards.js"></script>

<template>
	<div class="content__view">
		<BaseProfileHeaderComponent />
		<BaseBannerComponent />
		<div class="rewards__list">
			<BaseTitleComponent :title-name="title" title-align="left" />
			<BaseListTitleComponent
				title-data="ลุ้นรางวัล"
				link-data="/RewardLists/luckydraws"
			/>  
			<BaseListItemComponent
				v-if="luckydrawItems && luckydrawItems.length"
				:list-data="luckydrawItems"
				v-on:viewDetail="viewDetail"
			/>
			<BaseListTitleComponent
				title-data="แลกรางวัล"
				link-data="/RewardLists/redemptions"
			/>
			<BaseListItemComponent
				v-if="rewardItems && rewardItems.length"
				:list-data="rewardItems"
				v-on:viewDetail="viewDetail"
			/>
		</div>
	</div>
</template>