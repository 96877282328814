import {
  BaseTitleComponent,
  BaseInputTextComponent,
  BaseButtonComponent,
  BaseErrorMsgComponent
} from '@/components';

import { provide, onMounted } from 'vue'
import { useRouter } from "vue-router"
import store from '@/store'
import { RequestForgotCheckname } from '@/api/profile.js';

export default {
  name: 'Apply',
  data() {
    return {
      inputFirstnameText: {
        title: 'ชื่อ',
        value: '',
        type: 'text',
        placeholder: 'ชื่อ'
      },
      inputLastnameText: {
        title: 'นามสกุล',
        value: '',
        type: 'text',
        placeholder: 'นามสกุล'
      },
      buttonData: {
        name: 'ถัดไป',
        type: 'submit',
        active: false
      },
      titleName: 'ลืมรหัสผ่าน',
      errorMsg: ''
    }
  },
  components: {
    BaseTitleComponent,
    BaseInputTextComponent,
    BaseButtonComponent,
    BaseErrorMsgComponent
  },
  watch:{
    inputFirstnameText:{
      deep:true,
      handler(Firstname){
        if (Firstname.value != ''){
          this.buttonData.active =true
        }else{
          this.buttonData.active = false
        }
        this.errorMsg = ''
      }
    },
    inputLastnameText:{
      deep:true,
      handler(Lastname){
        if (Lastname.value != ''){
          this.errorMsg= ''
          this.buttonData.active =true
        }else{
          this.buttonData.active = false
        }
      }
    }
  },
  setup() {
		const router = useRouter();
		provide('store', store)
    const ExchangeCode = store.getters['profile/getExchangeCode'];

		onMounted(() => {
			if (!ExchangeCode){
				router.push('ForgotPass');
			}
		});
		return { 
      ExchangeCode
		}

	},
  methods: {
    FormValid() {
      let FormCheck = true;
      if (this.inputFirstnameText.value === '' || this.inputLastnameText.value === '' ) {
        this.errorMsg = '* คุณกรอกชื่อ-นามสกุลไม่ครบถ้วน';
        FormCheck = false;
      }
      return FormCheck;
    },
    SubmitForm(){
      let FormValid = this.FormValid();
      if (FormValid === true) {
        this.RequestForgotCheckname()
      } else {
        this.$store.commit("BUTTON_LOADING", false);
      }
    },
    async RequestForgotCheckname() {
      let RequestData = {
        "exchangecode": this.ExchangeCode,
        "firstname": this.inputFirstnameText.value,
        "lastname": this.inputLastnameText.value,
      }
      let RequestResp = await RequestForgotCheckname(RequestData)
      this.$store.commit("BUTTON_LOADING", false);

      if (RequestResp.result == 'complete'){
        this.$store.commit("profile/setValidatekey", RequestResp.validatekey);
        this.$router.push({name: 'ResetPass'});
      }else{
        this.errorMsg = 'ชื่อหรือนามสกุลไม่ตรงกับเบอร์ที่ใช้ในการลงทะเบียน';
          // this.$message(RequestResp.resultdetail);
        }
      }
    },
};