<style src="./PointHistory.scss" lang="scss" scoped></style>
<script src="./PointHistory.js"></script>

<template>
	<div class="content__view">
		<PointhistoryFilterComponent
			:FilterMonth="FilterMonth"
			@UpdateFilter="UpdateFilter"
			v-if="FilterMonth"
		/>
		<PointhistoryListComponent :PointLists="PointLists" />
		<TheFooter />
	</div>
</template>