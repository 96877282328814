<template>
	<div class="pdpa__title">
		<!-- <h1>Transparency is <br />the best policy.</h1>
		<p>
			It is a long established fact that a reader will be distracted by the
			readable content of a page when looking at its layout. The point of using
			Lorem Ipsum.
		</p>
		<p>
			Various versions have evolved over the years, sometimes by accident,
			sometimes on purpose (injected humour and the like).
		</p> -->
	</div>
</template>

<style lang="scss" scoped>
.pdpa__title {
	padding-top: 10%;
	h1 {
		padding-top: 8%;
		font-family: $font-family-ibm-semi;
		@include font-size(42);
		color: $black;
	}

	p {
		padding: 0 7%;
		margin-top: 8%;
		margin-bottom: 10px;
		font-family: $font-family-ibm;
		@include font-size(20);
		color: $darkGray;
		text-align: left;
	}
}
</style>