import { createStore } from "vuex";
import { app, profile, reward,logs,verify } from "@/store/modules";

export default createStore({
  state: {
    appLoading: false,
    buttonLoading: false,
    token: "",
  },
  mutations: {
    APP_LOADING(state, appLoading) {
      Object.assign(state, { appLoading });
    },
    BUTTON_LOADING(state, payload) {
      state.buttonLoading = payload;
    },
  },
  actions: {},
  modules: {
    app,
    profile,
    reward,
    logs,
    verify
  },
  getters: {
    getAppLoading: (state) => state.appLoading,
    getButtonLoading: (state) => state.buttonLoading,
  },
});
