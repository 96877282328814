import {
    Icon
} from '@/components';

export default {
    name: 'BaseAddress',
    components: {
        Icon
    },
    props: {
        addressData: Object,
        primaryAddress: Object
    },
    methods: {
        addressParser(address){
            var data = ''
            if(address.address){
                data += ' '+address.address+' '
            }
            if(address.street){
                data += ' '+address.street+' '
            }
            if(address.tumbon){
                data += ' '+address.tumbon+' '
            }
            if(address.district){
                data += ' '+address.district+' '
            }
            if(address.province){
                data += ' '+address.province+' '
            }
            if(address.postal){
                data += ' '+address.postal
            }
            return data
        },
		primarySelect (data) {
			this.$emit('primarySelect', data)
		},
    }
}