import {
  reactive,
  // watch
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

import {
  BaseBackButtonCustomComponent,
  ShopBillComponent,
  ShopBillExampleComponent,
  SubmissionButtonComponent,
} from "@/components";

export default {
  name: "SubmissionReceipt",
  components: {
    BaseBackButtonCustomComponent,
    ShopBillComponent,
    ShopBillExampleComponent,
    SubmissionButtonComponent,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    // console.log(store)

    const data = reactive({
      goBack: true,
      selectedItem: 1,
      title: `เลือกห้างร้าน/ร้านค้าของใบเสร็จ`,
      exBill: `ตัวอย่างใบเสร็จ`,
      buttonData: {
        title: "ต่อไป",
        disabled: false,
        class: "fixed",
        active: true,
      },
      brands: [
        {
          id: 1,
          name: "7-11",
          image: require("@/assets/images/submission/7-11_brand.jpg"),
          bill: require("@/assets/images/submission/7-11_bill.jpg"),
        },
        {
          id: 2,
          name: "Tops",
          image: require("@/assets/images/submission/tops_brand.jpg"),
          bill: require("@/assets/images/submission/7-11_bill.jpg"),
        },
        {
          id: 3,
          name: "Lotus",
          image: require("@/assets/images/submission/lotus_brand.jpg"),
          bill: require("@/assets/images/submission/lotus_bill.jpg"),
        },
        {
          id: 4,
          name: "BigC",
          image: require("@/assets/images/submission/bigc_brand.jpg"),
          bill: require("@/assets/images/submission/7-11_bill.jpg"),
        },
      ],
    });

    function handleSelectedItem(value) {
      data.selectedItem = parseInt(value);
      store.commit("app/SET_SHOPBILL_SELECTED", data.selectedItem);
    }

    function handleEvent() {
      router.push({
        name: "SubmissionScan",
        params: {
          id: data.selectedItem,
        },
      });
    }

    function handleBackward() {
      router.push({
        name: "Home",
      });
    }

    // watch(data.selectedItem, () => {
    // 	data.selectedItem = parseInt(data.selectedItem.value)
    // })

    return {
      data,
      handleEvent,
      handleSelectedItem,
      handleBackward,
    };
  },
};
