<style src="./BaseCameraComponent.scss" lang="scss"></style>
<script src="./BaseCameraComponent.js"></script>

<template>
	<section>
		<div
			class="submission__canvas"
			v-if="hasCameraAccess() && !cameraAccess && !cameraUndefined"
		>
			<BaseTitleComponent
				v-if="settingHeader"
				:title-name="settingHeader.setting.top"
				class="setting-header"
			/>
			<BaseTitleComponent
				v-if="settingHeader"
				:title-name="settingHeader.setting.bottom"
			/>
			<div
				class="settings-title"
				v-if="settingHeader"
				v-html="settingHeader.setting.title"
			></div>
			<div class="settings-steps">
				<div v-if="settingText" v-html="settingText"></div>
			</div>
			<div class="settings-refresh">
				หากอนุญาตให้ระบบเข้าถึงกล้องถ่ายรูปแล้ว
			</div>
			<div class="settings-refresh">
				กรุณาคลิก&nbsp;<span class="refresh-button" @click="pageRefresh"
					>รีเฟรชหน้านี้ใหม่</span
				>
			</div>
		</div>
		<div
			class="submission__canvas"
			v-if="!hasCameraAccess() || cameraUndefined"
		>
			<BaseTitleComponent
				v-if="settingHeader"
				:title-name="settingHeader.info.top"
				class="setting-header"
			/>
			<Icon name="receipt" class="camera-disabled" />
			<div
				class="settings-title"
				v-if="settingHeader"
				v-html="settingHeader.info.title"
			></div>
		</div>
		<div
			class="submission__camara"
			v-if="hasCameraAccess() && cameraAccess && !cameraUndefined"
		>
			<div class="webcam__inner">
				<video
					id="webcam"
					preload="none"
					autoplay
					playsinline
					width="640"
					height="480"
				></video>
				<canvas id="canvas"></canvas>
				<div class="flash"></div>
				<audio
					id="snapSound"
					:src="snapSound"
					preload="auto"
					crossOrigin="anonymous"
				></audio>
			</div>

			<div class="bt_flip" @click="cameraFilp">
				<Icon name="camera-flip" class="flip__icon" />
			</div>

			<div v-if="imagePrevUpdate" class="receipt__overlay">
				<!-- #RECEIPT PREVIOUS -->
				<div
					class="receipt__show"
					:style="`background-image: url(${imagePrevUpdate})`"
				></div>
			</div>
			<div v-if="imagePrevUpdate" class="receipt__box">
				<!-- #RECEIPT TEXT PREVIEW -->
				<span class="receipt__text">ถ่ายใบเสร็จให้เรียงต่อกัน</span>
				<span class="receipt__number">{{ imagePrevIdUpdate }}</span>
			</div>

			<div class="submission__frame">
				<div class="submission__frame__tlh" v-if="!imagePrevUpdate"></div>
				<div class="submission__frame__tlv"></div>
				<div class="submission__frame__trh" v-if="!imagePrevUpdate"></div>
				<div class="submission__frame__trv"></div>
				<div class="submission__frame__blv"></div>
				<div class="submission__frame__brv"></div>
			</div>
		</div>
	</section>
</template>
