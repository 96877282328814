<style src="./Login.scss" lang="scss" scoped></style>
<script src="./Login.js"></script>

<template>
	<div class="content__view">
		<div class="content__view__inner">
			<BaseTitleComponent :title-name="titleName" />
			<div class="gaping"></div>
			<BaseInputMobileComponent
				:input-mobile="inputMobile"
				v-model="inputMobile.value"
				:error="errorMsg.Mobile"
				class="mb__5"
			/>
			<BaseInputPasswordComponent
				:input-password="inputPassword"
				v-model="inputPassword.value"
			/>


	
			<BaseErrorMsgComponent
				v-if="errorMsg.Mobile"
				:error-msg="errorMsg.Mobile"
			/>
			<BaseErrorMsgComponent
				v-if="errorMsg.Password"
				:error-msg="errorMsg.Password"
			/>
			<BaseErrorMsgComponent v-if="errorMsg.API" :error-msg="errorMsg.API" />

			<BaseButtonComponent
				:button-data="buttonData"
				:button-position="true"
				@HandleButton="SubmitForm"
			/>
			<FooterSignupComponent :button-position="true" />
			<BaseLinksContactComponent />
		</div>
	</div>
</template>