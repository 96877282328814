import { inject } from "vue";
import { useRouter, useRoute } from "vue-router";
export default {
  name: "BaseLinksContact",
  setup() {
    const router = useRouter();
    const trackEvent = inject("trackEvent");

    const handleClick = () => {
      trackEvent("Contact us");
      router.push({
        name: "ContactUs",
      });
    };

    return {
      handleClick,
    };
  },
};
