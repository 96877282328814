<style src="./UserProfileComponent.scss" lang="scss"></style>
<script src="./UserProfileComponent.js"></script>

<template>
	<div class="box__user__profile">
		<div class="profile__info">
			<div class="profile__input">
				<label for="firstname">ชื่อ (ใช้สำหรับยืนยันตัวตน)</label>
				<span class="input__error" v-if="v$.profile.firstname.$error"
					>*ข้อมูลผิดพลาด</span
				>
				<input
					type="text"
					id="firstname"
					v-model.trim="v$.profile.firstname.$model"
					@input="$event.target.composing = false"
					placeholder="ใส่ชื่อ"
					maxlength="50"
					:class="{ error: v$.profile.firstname.$error }"
					:disabled="editableStatus === 'upload' ? false : true"
				/>
			</div>
			<div class="profile__input">
				<label for="firstname">นามสกุล (ใช้สำหรับยืนยันตัวตน)</label>
				<span class="input__error" v-if="v$.profile.lastname.$error"
					>*ข้อมูลผิดพลาด</span
				>
				<input
					type="text"
					id="lastname"
					v-model.trim="v$.profile.lastname.$model"
					@input="$event.target.composing = false"
					placeholder="ใส่นามสกุล สำหรับยืนยันตัวตน"
					maxlength="50"
					:class="{ error: v$.profile.lastname.$error }"
					:disabled="editableStatus === 'upload' ? false : true"
				/>
			</div>
			<div class="profile__input">
				<label for="phonenumber">เบอร์โทรศัพท์</label>
				<span class="input__error" v-if="v$.profile.phonenumber.$error"
					>*ข้อมูลผิดพลาด</span
				>
				<input
					type="text"
					id="phonenumber"
					v-model.trim="v$.profile.phonenumber.$model"
					@input="$event.target.composing = false"
					placeholder="ใส่เบอร์โทรศัพท์"
					maxlength="10"
					:class="{ error: v$.profile.phonenumber.$error }"
					readonly
					@keypress="validateNumber"
					@click="updatePhonenumber"
					:disabled="editableStatus === 'upload' ? false : true"
				/>
			</div>
			<div class="profile__input">
				<label for="email">อีเมล</label>
				<span
					class="input__error"
					v-if="v$.profile.email.$error && !v$.profile.email.required.$response"
					>*ข้อมูลผิดพลาด</span
				>
				<span class="input__error" v-else-if="!v$.profile.email.email.$response"
					>*ระบุอีเมลให้ถูกต้อง</span
				>
				<input
					type="text"
					id="email"
					:value="v$.profile.email.$model"
					@input="(e) => (v$.profile.email.$model = e.target.value)"
					placeholder="ใส่อีเมล"
					maxlength="50"
					:class="{ error: v$.profile.email.$error }"
					:disabled="editableStatus === 'upload' ? false : true"
				/>
			</div>
			<div class="profile__input">
				<label for="dob">วัน/เดือน/ปีเกิด</label>
				<span class="input__error" v-if="v$.profile.dob.$error">*โปรดระบุ</span>
				<div class="input__calendar__ico">
					<Icon name="calendar" class="sheet__icon" />
					<el-date-picker
						ref="calendar"
						v-model="v$.profile.dob.$model"
						placeholder="เลือกวัน/เดือน/ปีเกิด"
						format="DD/MM/YYYY"
						prefix-icon="el-icon-date"
						clear-icon="el-icon-circle-close"
						:disabled-date="disabledDate"
						:class="{ error: v$.profile.dob.$error }"
						:readonly="false"
						@focus="focusCalendar($event)"
						@blur="false"
						:disabled="editableStatus === 'upload' ? false : true"
					>
					</el-date-picker>
				</div>
			</div>
			<div class="profile__input">
				<label for="gender">เพศ</label>
				<span class="input__error" v-if="v$.profile.gender.$error"
					>*โปรดระบุ</span
				>
			</div>
			<div class="radio__justify">
				<div class="profile__radio">
					<label>
						<input
							name="gender"
							type="radio"
							v-model="v$.profile.gender.$model"
							value="male"
							required
							:disabled="editableStatus === 'upload' ? false : true"
						/>
						<span class="radio"></span>
						<span class="label">ชาย</span>
					</label>
				</div>
				<div class="profile__radio">
					<label>
						<input
							name="gender"
							type="radio"
							v-model="v$.profile.gender.$model"
							value="female"
							required
							:disabled="editableStatus === 'upload' ? false : true"
						/>
						<span class="radio"></span>
						<span class="label">หญิง</span>
					</label>
				</div>
				<div class="profile__radio">
					<label>
						<input
							name="gender"
							type="radio"
							v-model="v$.profile.gender.$model"
							value="na"
							required
							:disabled="editableStatus === 'upload' ? false : true"
						/>
						<span class="radio"></span>
						<span class="label">ไม่ระบุ</span>
					</label>
				</div>
			</div>
			<div class="profile__input">
				<label for="status">สถานะ</label>
				<span class="input__error" v-if="v$.profile.status.$error"
					>*โปรดระบุ</span
				>
				<Icon name="arrow-down" class="arrow-down" />
				<select
					id="status"
					v-model="v$.profile.status.$model"
					:class="{ error: v$.profile.status.$error }"
					:disabled="editableStatus === 'upload' ? false : true"
				>
					<option value="">เลือกสถานะ</option>
					<option
						:value="item.name"
						v-for="item in profile.statuses"
						:key="item.id"
						:selected="item.status === v$.profile.status.$model ? true : false"
					>
						{{ item.name }}
					</option>
				</select>
			</div>
			<div class="profile__input">
				<label for="province">จังหวัด (ที่อยู่ปัจจุบัน)</label>
				<span class="input__error" v-if="v$.profile.province.$error"
					>*โปรดระบุ</span
				>
				<select
					id="province"
					v-model="v$.profile.province.$model"
					:class="{ error: v$.profile.province.$error }"
					:disabled="editableStatus === 'upload' ? false : true"
				>
					<option value="">เลือกจังหวัด</option>
					<option
						:value="item.name"
						v-for="item in profile.provinces"
						:key="item.id"
						:selected="
							item.province === v$.profile.province.$model ? true : false
						"
					>
						{{ item.name }}
					</option>
				</select>
			</div>
		</div>
	</div>
</template>