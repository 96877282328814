import { CircleStencil, Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import "vue-advanced-cropper/dist/theme.classic.css";
import { Icon } from "@/components";
export default {
  name: "BaseAvatarEditor",
  components: {
    Cropper,
    Icon,
    CircleStencil,
  },
  data() {
    return {
      isToolOpen: false,
      base64_image: null,
      capture: null,
      cropperStyle: {
        "max-height": "calc(100vh - 208px)",
      },
    };
  },
  methods: {
    done() {
      this.isToolOpen = false;
      this.base64_image = null;
    },
    imageFile(data) {
      this.isToolOpen = true;
      this.base64_image = data;
    },
    async change() {
      const image = await this.$refs.cropper.getResult();
      var result = image.canvas.toDataURL("image/jpg");
      this.$emit("loadImage", result);
    },
    rotateImage() {
      this.$refs.cropper.rotate(-90);
    },
    retakeImage() {
      this.isToolOpen = false;
      this.base64_image = null;
      this.$emit("retakeImage", true);
    },
  },
};
