import { computed } from "vue";
import { useStore } from "vuex";
import { Icon } from "@/components";
import {
  uuid,
  isBrowser,
  isOs,
  logger,
  imageOptimizer,
  hasCameraAccess,
} from "@/helpers";
import { ElMessage } from "element-plus";

export default {
  name: "ScanBarComponent",
  props: {
    configButton: {
      type: Object,
      default: {
        name: "สแกน",
        color: "#FFFFFF",
        backgroundColor: "#000000",
      },
    },
    scanProcess: Boolean,
    cameraAccess: Boolean,
  },
  components: {
    Icon,
  },
  setup(props, { emit }) {
    const store = useStore();
    const scanProcessUpdate = computed(() => (props.scanProcess = true));

    function handleScan(e) {
      e.preventDefault();

      const btn = document.querySelector(".scan__button");
      if (!btn.hasAttribute("data-prevent-double-click")) {
        // logger(`BillsList`, props.captureImages.BillsList);
        btn.setAttribute("data-prevent-double-click", true);
        btn.setAttribute("disabled", true);
        emit("update:scaning", true);
      }
    }

    function detectGalleryFiles(e) {
      const extension = e.target.files[0].type;
      logger("detectGalleryFiles", extension);
      if (extension.indexOf("image") !== -1) {
        const file = e.target.files[0];
        this.upload(file, "image");
        e.target.value = "";
      } else {
        ElMessage({
          message: "กรุณาเลือกไฟล์ที่รองรับ",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }
    }

    function detectPDFFiles(e) {
      const extension = e.target.files[0].type;
      logger("detectPDFFiles", extension);
      if (extension.indexOf("pdf") !== -1) {
        const file = e.target.files[0];
        this.uploadPDF(file, "pdf");
        e.target.value = "";
      } else {
        ElMessage({
          message: "กรุณาเลือกไฟล์ที่รองรับ",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }
    }

    async function upload(file, type) {
      const isJWTToken = await store.getters["profile/token"];

      const fileData = {
        status: null,
        message: null,
        type: null,
        picture: null,
        imageWidth: null,
        imageHeight: null,
        cropped: null,
        BillData: {
          Original: null,
          coordinates: null,
          Final: null,
        },
      };

      const maxSize = 15; // 15 mb
      const options = {
        maxwidth: 1000,
        maxheight: 1000,
      };
      const { picture, data } = await imageOptimizer(file, maxSize, options);

      if (!picture) {
        fileData.status = false;
        fileData.message = `ขนาดไฟล์ใหญ่เกินกว่า ${maxSize} MB`;
        emit(`update:process`, fileData);
      } else {
        fileData.status = true;
        fileData.type = type;
        fileData.picture = picture;
        fileData.imageWidth = data.originalWidth;
        fileData.imageHeight = data.originalHeight;
        fileData.BillData = {
          Original: picture,
          coordinates: null,
          Final: null,
        };

        emit(`update:process`, fileData);

        if (type === "image") {
          const requestData = {
            id: uuid(),
            image: picture,
            token: isJWTToken,
          };

          // call get contours ocr
          const billContours = await store.dispatch(
            "app/APP_SCANNING_EBILL",
            requestData
          );
          fileData.cropped = billContours;
          logger("fileData", fileData);
          emit(`update:uploaded`, fileData);
        } else if (type === "pdf") {
          // Call APIs check receipt ...
          emit(`update:uploaded`, fileData);
        }
      }
    }

    async function uploadPDF(file, type) {
      // pdf js lib
      const pdfjsLib = await import("pdfjs-dist/build/pdf");
      const pdfjsWorker = await import("pdfjs-dist/build/pdf.worker.entry");

      // const max_size = 500;
      // const max_size_bytes = file.size / 1024;
      const max_size = 5 * (1024 * 1024); //MB
      const max_size_bytes = file.size;

      pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

      return new Promise((resolve, reject) => {
        const fileData = {
          status: null,
          message: null,
          type: null,
          picture: null,
          BillData: {
            Original: null,
            coordinates: null,
            Final: null,
          },
        };
        if (max_size_bytes > max_size) {
          fileData.status = false;
          fileData.message = `ขนาดไฟล์ใหญ่เกินกว่า 5 MB`;

          emit(`update:process`, fileData);
          reject("File exceeds max size of 5 MB");
        } else {
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = async () => {
            // let picture = reader.result;
            var pdfData = new Uint8Array(reader.result);

            // const base64String = btoa(String.fromCharCode(...new Uint8Array(reader.result)));

            const base64String = btoa(
              new Uint8Array(reader.result).reduce(function(data, byte) {
                return data + String.fromCharCode(byte);
              }, "")
            );
            this.logger("pdfData", base64String);

            var loadingTask = pdfjsLib.getDocument({
              data: pdfData,
            });
            let picture = base64String;
            this.logger("base64StringPDF", picture);

            fileData.status = true;
            fileData.type = type;
            fileData.picture = picture;
            fileData.BillData = {
              Original: picture,
            };

            emit(`update:process`, fileData);

            await loadingTask.promise.then(
              function(pdf) {
                emit(`update:uploaded`, fileData);

                var pageNumber = 1;
                pdf.getPage(pageNumber).then(function(page) {
                  // console.log('Page loaded');
                  var desiredWidth = window.innerWidth - 80;
                  var viewport = page.getViewport({
                    scale: 1,
                  });
                  var scale = desiredWidth / viewport.width;
                  var scaledViewport = page.getViewport({
                    scale: scale,
                  });

                  // Prepare canvas using PDF page dimensions
                  var canvas = document.getElementById("pdfViewer");
                  var context = canvas.getContext("2d");
                  canvas.height = scaledViewport.height;
                  canvas.width = scaledViewport.width;

                  // Render PDF page into canvas context
                  var renderContext = {
                    canvasContext: context,
                    viewport: scaledViewport,
                  };
                  var renderTask = page.render(renderContext);

                  renderTask.promise.then(function() {
                    // console.log("Page rendered");
                    resolve(true);
                  });
                });
              },
              function(reason) {
                // PDF loading error
                console.error(reason);
              }
            );
          };
          reader.onerror = (error) => reject(error);
        }
      });
    }

    return {
      scanProcessUpdate,
      handleScan,
      detectGalleryFiles,
      detectPDFFiles,
      uploadPDF,
      upload,
      isBrowser,
      isOs,
      hasCameraAccess,
    };
  },
};
