import { onUpdated, onBeforeUnmount } from "vue";
import * as dayjs from "dayjs";
import TheFooter from "@/views/Layout/Footer";
// import $ from 'jquery'

import { RequestGetPointHistory } from "@/api/profile.js";

import {
  PointhistoryFilterComponent,
  PointhistoryListComponent,
} from "@/components";

export default {
  name: "PointHistory",
  data() {
    return {
      token: this.$store.getters["profile/token"],
      FilterMonth: null,
      PointLists: [],
    };
  },
  components: {
    PointhistoryFilterComponent,
    PointhistoryListComponent,
    TheFooter,
  },
  mounted() {
    if (this.token) {
      if (this.FilterMonth == null) {
        this.FilterMonth = dayjs().format("YYYY-MM");
      }
      this.GetPointHistory();
    }
  },
  methods: {
    async GetPointHistory() {
      let FilterMonthArray = this.FilterMonth.split("-");
      let RequestData = {
        token: this.token,
        month: FilterMonthArray[1],
        year: FilterMonthArray[0],
      };
      let RequestResp = await RequestGetPointHistory(RequestData);
      if (RequestResp.result == "complete") {
        let list = RequestResp.list;

        let Historylist = this.SetUpPointArray(list);
        this.PointLists = Historylist;
      }
    },
    SetUpPointArray(Historylist) {
      // this gives an object with dates as keys
      const groups = Historylist.reduce((groups, bill) => {
        const date = dayjs(bill.created).format("YYYY-MM-DD");
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(bill);
        return groups;
      }, {});

      // Edit: to add it in the array format instead
      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          data: groups[date],
        };
      });

      return groupArrays;
    },
    UpdateFilter(NewFilter) {
      this.FilterMonth = NewFilter;
      this.GetPointHistory();
      // console.log(NewFilter)
    },
  },

  setup() {
    onUpdated(() => {
      // let vm = this;
      // console.log('Component is mounted!')
    }),
      onBeforeUnmount(() => {
        // console.log('off emitter')
        // this.emitter.off(`button:${this.buttonType}`);
      });
  },
  created() {
    // this.emitter.on(`button:${this.buttonData.type}`, () => {
    //   setTimeout(() => {
    //     this.$store.commit("BUTTON_LOADING", false);
    //     $('.box__alert').fadeIn(500).delay(1000).fadeOut();
    //   }, 1000);
    // })
  },
};
