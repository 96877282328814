import store from "@/store";
import { rewardService } from "@/api/reward";
import * as dayjs from "dayjs";
import { uuid, abbThaiMonth } from "@/helpers";
import platform from 'platform'

export const types = {
    UPDATE_LUCKYDRAW_ITEMS: "UPDATE_LUCKYDRAW_ITEMS",
    UPDATE_LUCKYDRAW_ITEM_BY_ID: "UPDATE_LUCKYDRAW_ITEM_BY_ID",
    REDEEM_LUCKYDRAW_ITEM_BY_ID: "REDEEM_LUCKYDRAW_ITEM_BY_ID",
    UPDATE_REWARD_ITEMS: "UPDATE_REWARD_ITEMS",
    UPDATE_REWARD_ITEM_BY_ID: "UPDATE_REWARD_ITEM_BY_ID",
    REDEEM_REWARD_ITEM_BY_ID: "REDEEM_REWARD_ITEM_BY_ID",
    PRIVILEGE: "PRIVILEGE",
};

export default {
    namespaced: true,
    state: {
        rewardItems: [],
        rewardItemById: [],
        luckydrawItems: [],
        luckydrawItemById: [],
        privilege: [],
    },
    getters: {
        [types.UPDATE_LUCKYDRAW_ITEMS]: (state) => state.luckydrawItems,
        [types.UPDATE_LUCKYDRAW_ITEM_BY_ID]: (state) => state.luckydrawItemById,
        [types.UPDATE_REWARD_ITEMS]: (state) => state.rewardItems,
        [types.UPDATE_REWARD_ITEM_BY_ID]: (state) => state.rewardItemById,
        [types.PRIVILEGE]: (state) => state.privilege,
    },
    mutations: {
        [types.UPDATE_LUCKYDRAW_ITEMS](state, payload) {
            state.luckydrawItems = payload;
        },
        [types.UPDATE_LUCKYDRAW_ITEM_BY_ID](state, payload) {
            state.luckydrawItemById = payload;
        },
        [types.UPDATE_REWARD_ITEMS](state, payload) {
            state.rewardItems = payload;
        },
        [types.UPDATE_REWARD_ITEM_BY_ID](state, payload) {
            state.rewardItemById = payload;
        },
        [types.PRIVILEGE](state, payload) {
            state.privilege = payload;
        },
    },
    actions: {
        async [types.PRIVILEGE]({ commit }, points) {
            commit("PRIVILEGE", points);
        },
        async [types.UPDATE_LUCKYDRAW_ITEMS]({ commit }) {
            const isJWTToken = await store.getters["profile/token"];
            if (isJWTToken) {
                const requestData = {
                    token: isJWTToken,
                    itemid: "",
                };
                await rewardService.getLuckydrawItems(requestData).then((response) => {
                    const luckydrawItems = response.list.map((luckydrawItem) => {
                        const today = dayjs(new Date());
                        const beginDate = dayjs(luckydrawItem.validbegin);
                        return {
                            id: luckydrawItem.itemid,
                            image: luckydrawItem.assetthumb,
                            title: luckydrawItem.titleth,
                            price: luckydrawItem.descth,
                            points: luckydrawItem.point,
                            action: {
                                type: "luckydraws",
                                label: "ลุ้น",
                            },
                            start: beginDate.isBefore(today),
                        };
                    });
                    commit("UPDATE_LUCKYDRAW_ITEMS", luckydrawItems);
                    return luckydrawItems;
                });
            }
        },
        async [types.UPDATE_LUCKYDRAW_ITEM_BY_ID]({ commit }, itemid) {
            const isJWTToken = await store.getters["profile/token"];
            if (isJWTToken) {
                const requestData = {
                    token: isJWTToken,
                    itemid: itemid,
                };
                await rewardService
                    .getLuckydrawItemById(requestData)
                    .then((response) => {
                        const luckydrawItems = response.list.map((luckydrawItem) => {
                            const today = dayjs(new Date());
                            const beginDate = dayjs(luckydrawItem.validbegin);
                            const beginDurationDay = dayjs(luckydrawItem.validbegin).get(
                                "date"
                            );
                            const beginDurationMonth = abbThaiMonth(
                                dayjs(luckydrawItem.validbegin).format("M")
                            );
                            const beginDurationYear = dayjs(luckydrawItem.validbegin).get(
                                "year"
                            );
                            const beginDurationFull = `${beginDurationDay} ${beginDurationMonth}`;
                            const endDurationDay = dayjs(luckydrawItem.validend).get("date");
                            const endDurationMonth = abbThaiMonth(
                                dayjs(luckydrawItem.validend).format("M")
                            );
                            const endDurationYear = dayjs(luckydrawItem.validend).get(
                                "year"
                            );
                            const endDurationFull = `${endDurationDay} ${endDurationMonth} ${parseInt(
                                endDurationYear
                            ) + 543}`;
                            return {
                                id: luckydrawItem.itemid,
                                image: luckydrawItem.assetlarge,
                                title: luckydrawItem.titleth,
                                price: luckydrawItem.descth,
                                points: luckydrawItem.point,
                                maxredeem: luckydrawItem.maxredeem,
                                redeemcount: luckydrawItem.redeemcount,
                                usermaxredeem: luckydrawItem.usermaxredeem,
                                duration: `${beginDurationFull} - ${endDurationFull}`,
                                description: luckydrawItem.descth,
                                terms: luckydrawItem.termth,
                                action: {
                                    type: "luckydraws",
                                    label: "ลุ้น",
                                    all: {
                                        type: "allin",
                                        title: "ใช้สิทธิ์ทั้งหมด",
                                        limit: luckydrawItem.point,
                                        legend: true,
                                    },
                                    select: {
                                        type: "select",
                                        title: "เลือกจำนวนสิทธิ์",
                                        limit: 1,
                                        legend: false,
                                    },
                                    waiting: {
                                        title: "ร่วมสนุกได้ระหว่างวันที่",
                                        info: "",
                                    },
                                    nopoints: {
                                        title: "ไม่สามารถร่วมสนุกได้",
                                        info: "จำนวน Point ที่คุณมีไม่พอ",
                                    },
                                },
                                start: beginDate.isBefore(today),
                            };
                        });
                        commit("UPDATE_LUCKYDRAW_ITEM_BY_ID", luckydrawItems[0]);
                    });
            }
        },
        async [types.REDEEM_LUCKYDRAW_ITEM_BY_ID]({ commit }, redeemItems) {
            const isJWTToken = await store.getters["profile/token"];
            if (isJWTToken) {
                const requestData = {
                    token: isJWTToken,
                    itemid: redeemItems.itemid,
                    privilege: redeemItems.privilege
                };
                const result = await rewardService.redeemLuckydrawItemById(requestData);
                return result
            }
        },
        async [types.UPDATE_REWARD_ITEMS]({ commit }) {
            const isJWTToken = await store.getters["profile/token"];
            if (isJWTToken) {
                const requestData = {
                    token: isJWTToken,
                    itemid: "",
                };
                await rewardService.getRewardItems(requestData).then((response) => {
                    const rewardItems = response.list.map((rewardItem) => {
                        const today = dayjs(new Date());
                        const beginDate = dayjs(rewardItem.validbegin);

                        return {
                            id: rewardItem.itemid,
                            image: rewardItem.assetthumb,
                            title: rewardItem.titleth,
                            price: rewardItem.descth,
                            points: rewardItem.point,
                            action: {
                                type: "redemptions",
                                label: "แลก",
                            },
                            start: beginDate.isBefore(today),
                        };
                    });
                    commit("UPDATE_REWARD_ITEMS", rewardItems);
                    return rewardItems;
                });
            }
        },
        async [types.UPDATE_REWARD_ITEM_BY_ID]({ commit }, itemid) {
            const isJWTToken = await store.getters["profile/token"];
            if (isJWTToken) {
                const requestData = {
                    token: isJWTToken,
                    itemid: itemid,
                };
                await rewardService.getRewardItemById(requestData).then((response) => {
                    const rewardItems = response.list.map((rewardItem) => {
                        const today = dayjs(new Date());
                        const beginDate = dayjs(rewardItem.validbegin);
                        const beginDurationDay = dayjs(rewardItem.validbegin).get("date");
                        const beginDurationMonth = abbThaiMonth(
                            dayjs(rewardItem.validbegin).format("M")
                        );
                        const beginDurationYear = dayjs(rewardItem.validbegin).get("year");
                        const beginDurationFull = `${beginDurationDay} ${beginDurationMonth}`;
                        const endDurationDay = dayjs(rewardItem.validend).get("date");
                        const endDurationMonth = abbThaiMonth(
                            dayjs(rewardItem.validend).format("M")
                        );
                        const endDurationYear = dayjs(rewardItem.validend).get("year");
                        const endDurationFull = `${endDurationDay} ${endDurationMonth} ${parseInt(
                            endDurationYear
                        ) + 543}`;
                        return {
                            id: rewardItem.itemid,
                            image: rewardItem.assetthumb,
                            title: rewardItem.titleth,
                            price: rewardItem.descth,
                            points: rewardItem.point,
                            maxredeem: rewardItem.maxredeem,
                            redeemcount: rewardItem.redeemcount,
                            usermaxredeem: rewardItem.usermaxredeem,
                            duration: `${beginDurationFull} - ${endDurationFull}`,
                            description: rewardItem.descth,
                            terms: rewardItem.termth,
                            action: {
                                type: "redemptions",
                                label: "แลก",
                                all: {
                                    type: "allin",
                                    title: "ใช้สิทธิ์ทั้งหมด",
                                    limit: rewardItem.point,
                                    legend: true,
                                },
                                select: {
                                    type: "select",
                                    title: "เลือกจำนวนสิทธิ์",
                                    limit: 1,
                                    legend: false,
                                },
                                waiting: {
                                    title: "ร่วมสนุกได้ระหว่างวันที่",
                                    info: "",
                                },
                                nopoints: {
                                    title: "ไม่สามารถร่วมสนุกได้",
                                    info: "จำนวน Point ที่คุณมีไม่พอ",
                                },
                            },
                            start: beginDate.isBefore(today),
                        };
                    });
                    commit("UPDATE_REWARD_ITEM_BY_ID", rewardItems[0]);
                    return rewardItems[0];
                });
            }
        },
        async [types.REDEEM_REWARD_ITEM_BY_ID]({ commit }, redeemItems) {
            const isJWTToken = await store.getters["profile/token"];
            if (isJWTToken) {
                const requestData = {
                    token: isJWTToken,
                    itemid: redeemItems.itemid,
                    privilege: redeemItems.privilege
                };
                const result = await rewardService.redeemRewardItemById(requestData);
                return result
            }
        },
    },
};
