export default {
  name: "BaseListTitleComponent",
  props: {
    listData: Object || Array,
    listShowItem: Number,
  },
  data() {
    return {
      lists: this.listData,
    };
  },
  mounted() {
    // console.log(this.listData);
  },
  methods: {
    viewDetail(item) {
      this.$emit("viewDetail", item);
    },
  },
};
