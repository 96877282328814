import {
  Icon,
  BaseBackButtonComponent,
  BaseTitleComponent,
  BaseAddressComponent,
  BaseButtonSimpleComponent,
  BaseModalComponent,
} from "@/components";

import _ from "lodash";
import {
  RequestProfileAddressList,
  SetProfileAddressPrimary,
} from "@/api/profile.js";

export default {
  name: "Home",
  components: {
    Icon,
    BaseBackButtonComponent,
    BaseTitleComponent,
    BaseAddressComponent,
    BaseButtonSimpleComponent,
    BaseModalComponent,
  },
  data() {
    return {
      token: this.$store.getters["profile/token"],
      title: "ที่อยู่ในการจัดส่ง",
      addresses: null,
      primary: null,
      ShowModalComplete: false,
      buttonData: {
        title: "ยืนยัน",
        disabled: false,
        active: true,
        class: "confirm__address",
      },
    };
  },
  mounted() {
    if (this.token) {
      this.GetProfileAddressList();
    }
  },
  methods: {
    checkPrimaryAddress() {
      var addresses = _.filter(
        this.addresses,
        (address) => address.default === true
      );
      addresses.forEach((address) => {
        this.primary = address;
      });

      if (this.primary == null) {
        this.primary = this.addresses[0];
      }
    },
    async GetProfileAddressList() {
      this.addresses = [];
      let RequestData = {
        token: this.token,
      };
      let RequestResp = await RequestProfileAddressList(RequestData);
      if (RequestResp.result == "complete") {
        RequestResp.list.forEach((address) => {
          this.addresses.push({
            ...address,
            editMode: true,
          });
        });
        this.checkPrimaryAddress();
      }
    },
    async SetProfileAddressPrimary() {
      let RequestData = {
        token: this.token,
        id: this.primary.id,
        default: true,
      };
      let RequestResp = await SetProfileAddressPrimary(RequestData);
      if (RequestResp.result == "complete") {
        this.GetProfileAddressList();
        // this.$message({
        // 	message: 'Primary address updated.',
        // 	type: 'success'
        // })
        this.ShowModalComplete = true;
      } else {
        this.$message({
          message: RequestResp.resultdetail,
          type: "error",
        });
      }
    },
    buttonClick(data) {
      this.SetProfileAddressPrimary();
    },
    newAddress() {
      this.$router.push({ name: "Address" });
    },
    primarySelect(data) {
      this.primary = data;
    },
    CloseModal() {
      this.ShowModalComplete = false;
      this.$router.push("/Account/edit");
    },
  },
};
