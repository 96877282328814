import { createApp } from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import mitt from "mitt";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { logger } from "@/helpers";

// gtag
import { useGtag } from "@/composable/useGtag";
// hooks
const { trackView, trackEvent } = useGtag();

//  Event bus
const emitter = mitt();

// Import ElementPlus
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
import locale from "element-plus/lib/locale/lang/th";
import "dayjs/locale/th";

// Import SCSS and Globals
import "./assets/styles/main.scss";
import "./components/_globals";
// import './directives/_globals';
// import './filters/_globals';
// import './mixins/_globals';
import VueZoomer from "vue-zoomer";

const app = createApp(App);
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.logger = logger;
app.config.globalProperties.trackEvent = trackEvent;
app.provide("logger", logger);
app.provide("trackView", trackView);
app.provide("trackEvent", trackEvent);
app.use(store);
app.use(router);
app.use(ElementPlus, { locale });
app.use(VueZoomer);
app.use(VueReCaptcha, { siteKey: "6LfmDS4dAAAAAEIgtiyrO7hGL11hn33mthW1bBS0" });
app.mount("#app");
