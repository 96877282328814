<style src="./ExampleComponent.scss" lang="scss" scoped></style>
<script src="./ExampleComponent.js"></script>

<template>
	<div class="example-component">
		<h3>Example Component</h3>
		<Icon name="vue" />
		Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vel delectus
		molestias maxime quibusdam nisi non tempora tenetur temporibus ipsam quae
		quia expedita, suscipit velit, dicta quos? Quaerat iure totam deleniti.
		Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore eveniet
		fugit debitis dolorem dolore est ea repellat minus facilis temporibus ab
		doloremque, id, veniam tenetur magnam consectetur ipsa enim? Nisi! Lorem
		ipsum dolor sit amet consectetur adipisicing elit. Expedita nobis cum
		assumenda consequuntur, natus accusantium iusto vero dignissimos nesciunt
		accusamus pariatur, suscipit soluta eveniet similique adipisci facilis
		maiores corporis voluptas.
		<br />
		<el-button>Click!!</el-button>
	</div>
</template>