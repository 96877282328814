<style src="./RewardDetails.scss" lang="scss" scoped></style>
<script src="./RewardDetails.js"></script>

<template>
	<div class="content__reward__details__view" v-if="reward">
		<BaseProfileHeaderComponent v-on:loadProfileData="getProfileData" />
		<BaseBackButtonComponent />

		<div class="reward__details">
			<div class="reward__image">
				<BaseImageFeatureComponent :image-src="reward" />
			</div>
			<div class="reward__title">
				{{ reward.title }}
			</div>
			<div class="reward__price">
				{{ reward.price }}
			</div>
			<div class="reward__points">
				<BasePointLegendComponent
					:point-data="reward.points"
					legend-bg="gray"
				/>
				<div class="divider"></div>
				<div class="reward__notes">
					<div class="reward__limit">
						จำนวนสิทธิ์ที่แลกได้ {{ reward.action.all.limit }} สิทธิ์
					</div>
					<div class="reward__duration">
						{{ reward.duration }}
					</div>
				</div>
			</div>
			<div class="reward__desc">
				<BaseDetailComponent
					detail-title="รายละเอียด"
					:detail-data="reward.description"
				/>
				<BaseDetailComponent detail-title="T & C" :detail-data="reward.terms" />
			</div>
		</div>

		<div class="reward__action" v-if="reward.start">
			<div class="action__box">
				<BaseButtonLegendComponent
					v-if="reward.action.all"
					:button-data="reward.action.all"
					v-on:buttonClick="buttonClick"
				/>
			</div>
			<div class="action__box">
				<BaseButtonLegendComponent
					v-if="reward.action.all"
					:button-data="reward.action.select"
					v-on:buttonClick="buttonClick"
				/>
			</div>
		</div>
		<BaseCampaignInfoComponent
			:campaign-title="campaign.title"
			:campaign-info="campaign.info"
			v-if="campaign !== null && !reward.start"
		/>
	</div>
</template>