<style src="./ShortProfileComponent.scss" lang="scss"></style>
<script src="./ShortProfileComponent.js"></script>

<template>
	<div class="short__profile">
		<div class="profile__left">
			<div class="profile__img">
				<BaseAvatarComponent
					:image-src="profile.data.image"
					image-size="small"
				/>
			</div>
			<div class="profile__info">
				<span class="profile__welcome">{{ profile.data.welcomeWord() }}</span>
				<p class="profile__name">{{ info.firstname }}</p>
			</div>
		</div>
		<div class="profile__right">
			<router-link to="" @click="handleClick">
				<Icon :name="profile.edit.image" />
				<span class="profile__edit__text">{{ profile.edit.name }}</span>
			</router-link>
		</div>
	</div>
</template>