<style src="./BaseAvatarEditorComponent.scss" lang="scss"></style>
<script src="./BaseAvatarEditorComponent.js"></script>
<template>
	<div class="box__image__editor">
		<section id="overlay" v-if="isToolOpen">
			<div class="editor__header">
				<span @click="done()" class="done__button"
					>Done <i class="el-icon-right"></i
				></span>
			</div>
			<cropper
				class="editor__cropper"
				ref="cropper"
				:src="base64_image"
				:stencil-component="$options.components.CircleStencil"
				:stencil-props="{ aspectRatio: 1 / 1 }"
				@change="change"
			></cropper>
			<div class="editor__tools">
				<div class="tool__box">
					<span
						class="tool__button tool__button--rounded"
						@click.prevent="retakeImage"
					>
						<Icon name="camera" class="tool__icon" />
						<span class="tool__text">ถ่ายใหม่</span>
					</span>
				</div>
				<div class="tool__box">
					<span
						class="tool__button tool__button--rounded"
						@click.prevent="rotateImage"
					>
						<Icon name="rotate" class="tool__icon" />
						<span class="tool__text">หมุนภาพ</span>
					</span>
				</div>
			</div>
		</section>
	</div>
</template>