import {
  BaseProfileHeaderComponent,
  BaseBackButtonComponent,
  BaseListTitleComponent,
  BaseListItemComponent,
} from "@/components";

export default {
  name: "RewardLists",
  components: {
    BaseProfileHeaderComponent,
    BaseBackButtonComponent,
    BaseListTitleComponent,
    BaseListItemComponent,
  },
  data() {
    return {
      type: null
    };
  },
  created() {
    this.type = this.$route.params.type;
    this.getRewardList(this.type);
    this.getRewardItems();
    this.getLuckydrawItems();
  },
  methods: {
    async getRewardItems() {
      await this.$store.dispatch("reward/UPDATE_REWARD_ITEMS");
    },
    async getLuckydrawItems() {
      await this.$store.dispatch("reward/UPDATE_LUCKYDRAW_ITEMS");
    },
    async getRewardList(selected) {
      this.type = selected;
      if (this.type === "luckydraws") {
        this.dataLists = this.$store.getters["reward/UPDATE_LUCKYDRAW_ITEMS"];
      }
      if (this.type === "redemptions") {
        this.dataLists = this.$store.getters["reward/UPDATE_REWARD_ITEMS"];
      }
    },
    viewDetail(item) {
      this.$router.push({
        name: "RewardDetails",
        params: { type: item.action.type, id: item.id },
      });
    },
  },
};
