import request from '@/utils/request'
import requestLine from '@/utils/requestLine'

export const authService = {
    check,
    lineAuth,
    profileLineLogin,
    profileAppsLogin
}

async function check(requestData) {
    const data = requestData;
    return await request({
        url: 'profile/validateToken',
        method: 'POST',
        data
    })
}

async function lineAuth(requestData) {
    const data = requestData;
    return await requestLine({
        url: 'token',
        method: 'POST',
        data
    })
}

async function profileLineLogin(requestData) {
    const data = requestData;
    return await request({
        url: 'profile/linelogin',
        method: 'POST',
        data
    })
}

async function profileAppsLogin(requestData) {
    const data = requestData;
    return await request({
        url: 'profile/adapterlogin',
        method: 'POST',
        data
    })
}