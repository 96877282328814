<style src="./BaseBannerDetailComponent.scss" lang="scss"></style>
<script src="./BaseBannerDetailComponent.js"></script>

<template>
	<div class="box__banner__detail" v-if="bannerData != null">
		<BaseBackButtonComponent
			class="button__back__pd"
			:class="{
				vdo__button: bannerData[0].type == 'videourl',
			}"
		/>
		<div class="banner__inner type__image" v-if="bannerData[0].type == 'image'">
			<h1 v-html="bannerData[0].titleth" class="banner__head"></h1>
			<img
				:src="bannerData[0].assetlarge"
				:alt="bannerData[0].name"
				class="banner__img"
			/>
			<div v-html="bannerData[0].descth" class="banner__content"></div>
		</div>
		<div class="banner__inner type__vdo" v-else>
			<div class="video__wrapper">
				<iframe
					class="vdo__embeded"
					:src="bannerData[0].source"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
				></iframe>
			</div>
		</div>
		<TheFooter  v-if="bannerData[0].type == 'image'" />
	</div>
</template>