<style src="./ScanBarActionComponent.scss" lang="scss" scoped></style>
<script src="./ScanBarActionComponent.js"></script>

<template>
	<div class="submission__edit">
		<template v-for="(button, index) in configButton" :key="index">
			<div
				class="submission__button"
				v-if="button.show"
				:style="`color: ${button.color}; background-color: ${button.backgroundColor}; border: 2px solid ${button.borderColor}; width: ${button.width}px; height: ${button.height}px`"
				@click.prevent="handleEvent(button.icon)"
			>
				<Icon :name="button.icon" />
				<label for="inputGallery">{{ button.name }}</label>
				<input type="file" id="inputGallery" :accept="button.accept" />
			</div>
		</template>
	</div>
</template>