import { onUpdated, onBeforeUnmount } from "vue";

import $ from "jquery";

import {
  BaseTitleComponent,
  BaseInputMobileComponent,
  BaseErrorMsgComponent,
  BaseButtonComponent,
  FooterSignupComponent,
  BaseLinksContactComponent,
} from "@/components";

import { ACCOUNTLOCK_RequestOTP } from "@/api/profile.js";

export default {
  name: "Verify",
  data() {
    return {
      inputMobile: {
        name: "mobile",
        value: "",
        type: "text",
        placeholder: "เบอร์โทรศัพท์",
        maxlength: 9,
      },

      buttonData: {
        name: "ยืนยัน",
        type: "submit",
        active: false,
      },
      titleName: "โปรดยืนยันตัวตน",
      errorMsg: {
        Mobile: "",
        API: "",
      },
      // MSG: "* กรุณากรอกเบอร์ที่คุณใช้เป็นเบอร์หลัก \nในการรับรหัส OTP เพื่อยืนยันตัวตน",
      OriginMobile: this.$store.getters["verify/getMobile"],
      OriginPassword: this.$store.getters["verify/getPassword"],
    };
  },
  components: {
    BaseTitleComponent,
    BaseInputMobileComponent,
    BaseErrorMsgComponent,
    BaseButtonComponent,
    FooterSignupComponent,
    BaseLinksContactComponent,
  },
  watch: {
    inputMobile: {
      deep: true,
      handler(Mobile) {
        if (Mobile.value.length === 9 && Mobile.value.slice(0, 1) !== "0") {
          this.buttonData.active = true;
          this.errorMsg.Mobile = "";
        } else if (
          Mobile.value.length === 9 &&
          Mobile.value.slice(0, 1) === "0"
        ) {
          this.buttonData.active = false;
          this.errorMsg.Mobile = "* คุณกรอกเบอร์โทรศัพท์ผิดพลาดหรือไม่ครบถ้วน";
        } else {
          this.buttonData.active = false;
          this.errorMsg.Mobile = "";
        }
        // this.inputMobile.error =false;
        // this.errorMsg.Mobile = ''
      },
    },
  },
  methods: {
    FormValid() {
      let FormCheck = true;
      if (this.inputMobile.value.length < 9) {
        this.errorMsg.Mobile = "* คุณกรอกเบอร์โทรศัพท์ผิดพลาดหรือไม่ครบถ้วน";

        FormCheck = false;
      }

      return FormCheck;
    },
    SubmitForm() {
      let FormValid = this.FormValid();

      if (FormValid == true) {
        this.RequestOTP();
      } else {
        this.$store.commit("BUTTON_LOADING", false);
      }
    },
    async RequestOTP() {
      // if (this.inputMobile.value.slice(0, 1) === '0') {
      // console.log('begin 0')
      //   this.inputMobile.value = this.inputMobile.value.substring(1)
      // } else {
      // console.log('not begin 0')
      // }

      let RequestData = {
        mobileprefix: "66",
        mobile: "0" + this.inputMobile.value,
      };

      let RequestResp = await ACCOUNTLOCK_RequestOTP(RequestData);
      this.$store.commit("BUTTON_LOADING", false);

      if (RequestResp.result == "complete") {
        // console.log(RequestResp);
        this.$store.commit("verify/OTPinfo", RequestResp.otpinfo);
        this.$store.commit("verify/SetNewMobile", "0" + this.inputMobile.value);
        this.$router.push("/verifyOTP");
      } else {
        this.$message({
          message: RequestResp.resultdetail,
          type: "error",
        });
      }
    },
  },

  setup() {
    onUpdated(() => {
      // let vm = this;
      // console.log('Component is mounted!')
    }),
      onBeforeUnmount(() => {
        // console.log('off emitter')
        // this.emitter.off(`button:${this.buttonType}`);
      });

    // watch([inputMobile], ([newinputMobile, previnputMobile]) => {
    //   console.log(newinputMobile)
    //   /* ... */
    // })
  },
  mounted() {
    if (!this.OriginMobile || !this.OriginPassword) {
      this.$router.push("/Login");
    } else {
      // this.MSG = `* เบอร์ ${this.OriginMobile} ถูกใช้ลงทะเบียนในระบบแล้ว`;
    }
  },
  created() {},
};
