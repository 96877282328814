import { ref } from "vue";

export default {
  name: "BaseInputCheckbox",
  props: {
    inputData: Object
  },
  computed: {

  },
  methods: {
    
  }
};
