export const types = {
  MUTATION: "mutation",
};
import { authService } from "@/api/auth";
// import store from "@/store";

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem("Crptoken"),
    OTP: null,
    ExchangeCode: null,
    SigupPassword: null,
    TOS: null,
    Policy: null,
    Validatekey: null,
    CrossToken: null,
    LineToken: null,
    userInfo: null,
  },
  getters: {
    getRefcode: (state) => state.OTP,
    getExchangeCode: (state) => state.ExchangeCode,
    getSigupPassword: (state) => state.SigupPassword,
    token: (state) => state.token,
    getCrossToken: (state) => state.CrossToken,
    getLineToken: (state) => state.LineToken,
    getTosConsent: (state) => state.TOS,
    getPolicyConsent: (state) => state.Policy,
    getValidatekey: (state) => state.Validatekey,
    getUserInfo: (state) => state.userInfo,
  },
  mutations: {
    OTPinfo(state, playload) {
      state.OTP = playload;
    },
    SetExchangeCode(state, playload) {
      // console.log('commit SetExchangeCode')
      // console.log(playload)
      state.ExchangeCode = playload;
    },
    SetSigupPassword(state, playload) {
      state.SigupPassword = playload;
    },
    SetToken(state, playload) {
      state.token = playload;
    },
    setTosConsent(state, playload) {
      state.TOS = playload;
    },
    setPolicyConsent(state, playload) {
      state.Policy = playload;
    },
    setValidatekey(state, playload) {
      state.Validatekey = playload;
    },
    SetCrossToken(state, payload) {
      state.CrossToken = payload;
    },
    SetLineToken(state, payload) {
      state.LineToken = payload;
    },
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
  },
  actions: {
    async CheckAdptoken({ commit }, payload) {
      const RequestResp = await authService.profileAppsLogin(payload);
      return RequestResp;
    },
    async CheckLinetoken({ commit }, payload) {
      const RequestResp = await authService.profileLineLogin(payload);

      if (RequestResp.result === "complete") {
        commit("SetToken", RequestResp.token);
        localStorage.setItem("Crptoken", RequestResp.token);
        return RequestResp;
      } else {
        if (RequestResp.resultdetail == "no_account_linked") {
          commit("SetLineToken", payload.linetoken);
          return RequestResp;
        } else if (RequestResp.resultdetail == "invalid_line_token") {
          return RequestResp;
        } else {
          return RequestResp;
        }
      }
    },
  },
};
