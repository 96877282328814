<style src="./Greeting.scss" lang="scss" scoped></style>
<script src="./Greeting.js"></script>

<template>
	<div class="content__view">
		<BaseTitleComponent :title-name="titleName" class="title"/>
		<BaseCarouselComponent />
		<BaseButtonSimpleComponent
			:button-data="buttonData"
			v-on:buttonClick="buttonClick"/>
	</div>
</template>