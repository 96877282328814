import {
  BaseTitleComponent,
  BaseAvatarComponent,
  BaseAvatarEditorComponent,
  UserProfileComponent,
  BaseModalComponent,
  BaseAvatarCameraComponent,
} from "@/components";

import { ref, reactive, computed, provide, watch, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useVuelidate } from "@vuelidate/core";
import { required, numeric, email } from "@vuelidate/validators";

import {
  RequestRegister,
  RequestProfilePicUpdate,
  RequestConsentUpdate,
} from "@/api/profile";
import store from "@/store";
import { showLoading, hideLoading } from "@/helpers";

export default {
  name: "Register",
  components: {
    BaseTitleComponent,
    BaseAvatarComponent,
    BaseAvatarEditorComponent,
    UserProfileComponent,
    BaseModalComponent,
    BaseAvatarCameraComponent,
  },
  inject: ["trackEvent"],
  data() {
    return {
      modalShow: false,
      modalStatus: false,
      modalValue: 0,
      modalType: null,
      profile: {
        title: "ลงทะเบียน",
        button: {
          type: "upload",
          update: "แก้ไขข้อมูล",
          upload: "อัพโหลดภาพของคุณ",
        },
        error: {
          firstname: false,
          lastname: false,
          phonenumber: false,
          email: false,
          dob: false,
          gender: false,
          status: false,
          province: false,
        },
        data: {
          image: require("@/assets/images/account/default_profile.png"),
          firstname: "",
          lastname: "",
          phonenumber: "",
          email: "",
          dob: "",
          gender: "",
          status: "",
          province: "",
        },
        statuses: [
          {
            id: 1,
            name: "โสด",
          },
          {
            id: 2,
            name: "แต่งงานแล้ว แต่ยังไม่มีบุตร",
          },
          {
            id: 3,
            name: "แต่งงานแล้ว มีบุตรแล้ว",
          },
        ],
        provinces: [
          {
            id: "1",
            name_en: "Bangkok",
            name: "กรุงเทพมหานคร",
          },
          {
            id: "3",
            name_en: "Nonthaburi",
            name: "นนทบุรี",
          },
          {
            id: "4",
            name_en: "Pathum Thani",
            name: "ปทุมธานี",
          },
          {
            id: "2",
            name_en: "Samut Prakan",
            name: "สมุทรปราการ",
          },
          {
            id: "64",
            name_en: "Krabi",
            name: "กระบี่",
          },
          {
            id: "56",
            name_en: "Kanchanaburi",
            name: "กาญจนบุรี",
          },
          {
            id: "34",
            name_en: "Kalasin",
            name: "กาฬสินธุ์",
          },
          {
            id: "49",
            name_en: "Kamphaeng Phet",
            name: "กำแพงเพชร",
          },
          {
            id: "28",
            name_en: "Khon Kaen",
            name: "ขอนแก่น",
          },
          {
            id: "13",
            name_en: "Chanthaburi",
            name: "จันทบุรี",
          },
          {
            id: "15",
            name_en: "Chachoengsao",
            name: "ฉะเชิงเทรา",
          },
          {
            id: "11",
            name_en: "Chonburi",
            name: "ชลบุรี",
          },
          {
            id: "9",
            name_en: "Chainat",
            name: "ชัยนาท",
          },
          {
            id: "25",
            name_en: "Chaiyaphum",
            name: "ชัยภูมิ",
          },
          {
            id: "69",
            name_en: "Chumphon",
            name: "ชุมพร",
          },
          {
            id: "45",
            name_en: "Chiang Rai",
            name: "เชียงราย",
          },
          {
            id: "38",
            name_en: "Chiang Mai",
            name: "เชียงใหม่",
          },
          {
            id: "72",
            name_en: "Trang",
            name: "ตรัง",
          },
          {
            id: "14",
            name_en: "Trat",
            name: "ตราด",
          },
          {
            id: "50",
            name_en: "Tak",
            name: "ตาก",
          },
          {
            id: "17",
            name_en: "Nakhon Nayok",
            name: "นครนายก",
          },
          {
            id: "58",
            name_en: "Nakhon Pathom",
            name: "นครปฐม",
          },
          {
            id: "36",
            name_en: "Nakhon Phanom",
            name: "นครพนม",
          },
          {
            id: "19",
            name_en: "Nakhon Ratchasima",
            name: "นครราชสีมา",
          },
          {
            id: "63",
            name_en: "Nakhon Si Thammarat",
            name: "นครศรีธรรมราช",
          },
          {
            id: "47",
            name_en: "Nakhon Sawan",
            name: "นครสวรรค์",
          },
          {
            id: "76",
            name_en: "Narathiwat",
            name: "นราธิวาส",
          },
          {
            id: "43",
            name_en: "Nan",
            name: "น่าน",
          },
          {
            id: "77",
            name_en: "Bueng Kan",
            name: "บึงกาฬ",
          },
          {
            id: "20",
            name_en: "Buriram",
            name: "บุรีรัมย์",
          },
          {
            id: "62",
            name_en: "Prachuap Khiri",
            name: "ประจวบคีรีขันธ์",
          },
          {
            id: "16",
            name_en: "Prachinburi",
            name: "ปราจีนบุรี",
          },
          {
            id: "74",
            name_en: "Pattani",
            name: "ปัตตานี",
          },
          {
            id: "5",
            name_en: "Phra Nakhon Si Ayutthaya",
            name: "พระนครศรีอยุธยา",
          },
          {
            id: "65",
            name_en: "Phang Nga",
            name: "พังงา",
          },
          {
            id: "73",
            name_en: "Phattalung",
            name: "พัทลุง",
          },
          {
            id: "53",
            name_en: "Phichit",
            name: "พิจิตร",
          },
          {
            id: "52",
            name_en: "Phitsanulok",
            name: "พิษณุโลก",
          },
          {
            id: "61",
            name_en: "Phetchaburi",
            name: "เพชรบุรี",
          },
          {
            id: "54",
            name_en: "Phetchabun",
            name: "เพชรบูรณ์",
          },
          {
            id: "42",
            name_en: "Phrae",
            name: "แพร่",
          },
          {
            id: "44",
            name_en: "Phayao",
            name: "พะเยา",
          },
          {
            id: "66",
            name_en: "Phuket",
            name: "ภูเก็ต",
          },
          {
            id: "32",
            name_en: "Maha Sarakham",
            name: "มหาสารคาม",
          },
          {
            id: "37",
            name_en: "Mukdahan",
            name: "มุกดาหาร",
          },
          {
            id: "46",
            name_en: "Mae Hong Son",
            name: "แม่ฮ่องสอน",
          },
          {
            id: "75",
            name_en: "Yala",
            name: "ยะลา",
          },
          {
            id: "24",
            name_en: "Yasothon",
            name: "ยโสธร",
          },
          {
            id: "33",
            name_en: "Roi Et",
            name: "ร้อยเอ็ด",
          },
          {
            id: "68",
            name_en: "Ranong",
            name: "ระนอง",
          },
          {
            id: "12",
            name_en: "Rayong",
            name: "ระยอง",
          },
          {
            id: "55",
            name_en: "Ratchaburi",
            name: "ราชบุรี",
          },
          {
            id: "7",
            name_en: "Lopburi",
            name: "ลพบุรี",
          },
          {
            id: "40",
            name_en: "Lampang",
            name: "ลำปาง",
          },
          {
            id: "39",
            name_en: "Lamphun",
            name: "ลำพูน",
          },
          {
            id: "30",
            name_en: "Loei",
            name: "เลย",
          },
          {
            id: "22",
            name_en: "Sisaket",
            name: "ศรีสะเกษ",
          },
          {
            id: "35",
            name_en: "Sakon Nakhon",
            name: "สกลนคร",
          },
          {
            id: "70",
            name_en: "Songkhla",
            name: "สงขลา",
          },
          {
            id: "71",
            name_en: "Satun",
            name: "สตูล",
          },
          {
            id: "60",
            name_en: "Samut Songkhram",
            name: "สมุทรสงคราม",
          },
          {
            id: "59",
            name_en: "Samut Sakhon",
            name: "สมุทรสาคร",
          },
          {
            id: "18",
            name_en: "Sa Kaeo",
            name: "สระแก้ว",
          },
          {
            id: "10",
            name_en: "Saraburi",
            name: "สระบุรี",
          },
          {
            id: "8",
            name_en: "Sing Buri",
            name: "สิงห์บุรี",
          },
          {
            id: "51",
            name_en: "Sukhothai",
            name: "สุโขทัย",
          },
          {
            id: "57",
            name_en: "Suphan Buri",
            name: "สุพรรณบุรี",
          },
          {
            id: "67",
            name_en: "Surat Thani",
            name: "สุราษฎร์ธานี",
          },
          {
            id: "21",
            name_en: "Surin",
            name: "สุรินทร์",
          },
          {
            id: "31",
            name_en: "Nong Khai",
            name: "หนองคาย",
          },
          {
            id: "27",
            name_en: "Nong Bua Lamphu",
            name: "หนองบัวลำภู",
          },
          {
            id: "6",
            name_en: "Ang Thong",
            name: "อ่างทอง",
          },
          {
            id: "29",
            name_en: "Udon Thani",
            name: "อุดรธานี",
          },
          {
            id: "48",
            name_en: "Uthai Thani",
            name: "อุทัยธานี",
          },
          {
            id: "41",
            name_en: "Uttaradit",
            name: "อุตรดิตถ์",
          },
          {
            id: "23",
            name_en: "Ubon Ratchathani",
            name: "อุบลราชธานี",
          },
          {
            id: "26",
            name_en: "Amnat Charoen",
            name: "อำนาจเจริญ",
          },
        ],
      },
      page: "account",
    };
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    provide("store", store);

    const state = reactive({
      profile: {
        exchangecode: "",
        firstname: "",
        lastname: "",
        phonenumber: "",
        email: "",
        password: "",
        dob: "",
        gender: "",
        status: "",
        province: "",
      },
      consents: {
        TOS: null,
        Policy: null,
      },
    });

    const validations = computed(() => ({
      profile: {
        firstname: { required },
        lastname: { required },
        phonenumber: { required, numeric },
        email: { required, email },
        dob: { required },
        gender: { required },
        status: { required },
        province: { required },
      },
    }));

    const OTP = store.getters["profile/getRefcode"];
    const ExchangeCode = store.getters["profile/getExchangeCode"];
    const SignupPassword = store.getters["profile/getSigupPassword"];
    const TOS =
      store.getters["profile/getTosConsent"] ||
      localStorage.getItem("TosConsent");
    const Policy =
      store.getters["profile/getPolicyConsent"] ||
      localStorage.getItem("PolicyConsent");

    onMounted(() => {
      if (!OTP || !ExchangeCode || !SignupPassword || (!TOS && !Policy)) {
        router.push("Apply");
      } else if (!localStorage.getItem("CrossToken") || (!TOS && !Policy)) {
        router.push("PDPA");
      } else {
        state.profile.exchangecode = ExchangeCode;
        state.profile.phonenumber = OTP.mobile;
        state.profile.password = SignupPassword;
        state.consents.TOS = TOS;
        state.consents.Policy = Policy;
      }
    });

    const v$ = useVuelidate(validations, state);
    provide("v$", v$);

    watch(v$, (v$) => {
      // console.log({ v$ })
    });

    return {
      v$,
      state,
      validations,
      OTP,
      ExchangeCode,
      SignupPassword,
      TOS,
      Policy,
    };
  },
  methods: {
    handleCapture(data) {
      this.page = "account";
      if (data != null) {
        this.$refs.avatarEditor.imageFile(data);
      }
    },
    getButtonLabel() {
      return this.profile.button[this.profile.button.type];
    },
    imageAction() {
      if (this.profile.button.type === "upload") {
        this.page = "capture";
      }
      if (this.profile.button.type === "update") {
        this.profile.button.type = "upload";
      }
    },
    retakeImage() {
      this.page = "capture";
    },
    getProfileImage(base64) {
      this.profile.data.image = base64;
    },
    save() {
      this.v$.$touch();
      if (this.v$.$error) {
        return;
      } else {
        showLoading();
        this.RequestRegister();
        this.trackEvent("Register - Save", {
          event_category: "Register",
          event_label: "Save",
          value: "บันทึก",
        });
      }
    },
    async RequestRegister() {
      this.$store.commit("BUTTON_LOADING", true);
      let RequestData = {
        exchangecode: this.state.profile.exchangecode,
        firstname: this.state.profile.firstname,
        lastname: this.state.profile.lastname,
        mobile: this.state.profile.phonenumber,
        email: this.state.profile.email,
        password: this.state.profile.password,
        bhd: this.state.profile.dob,
        province: this.state.profile.province,
        status: this.state.profile.status,
        gender: this.state.profile.gender,
        interest: [],
        // "linetoken": this.$store.getters['app/SET_LINE_ACCESS_TOKEN']
      };

      let CrossToken = localStorage.getItem("CrossToken");

      if (CrossToken) {
        RequestData.adaptertoken = CrossToken;
      }

      let linetoken = localStorage.getItem("CrpLinetoken");

      if (linetoken) {
        RequestData.linetoken = linetoken;
      }

      let RequestResp = await RequestRegister(RequestData);
      this.$store.commit("BUTTON_LOADING", false);
      hideLoading();
      if (RequestResp.result == "complete") {
        localStorage.removeItem("CrossToken");
        localStorage.removeItem("CrpLinetoken");
        this.$store.commit("profile/SetToken", RequestResp.token);
        localStorage.setItem("Crptoken", RequestResp.token);
        this.RequestUploadProfilePic();
      } else {
        this.$message({
          message: RequestResp.resultdetail,
          type: "error",
        });
      }
    },
    async RequestUploadProfilePic() {
      let Imagedata = this.profile.data.image.split(",");
      this.$store.commit("BUTTON_LOADING", true);
      let RequestData = {
        token: this.$store.getters["profile/token"],
        profilepic: Imagedata[1],
      };
      let RequestResp = await RequestProfilePicUpdate(RequestData);
      this.$store.commit("BUTTON_LOADING", false);

      if (RequestResp.result == "complete") {
        this.ShowModalComplete();
        this.saveConsent();
      } else {
        this.$message(RequestResp.resultdetail);
      }
    },
    async saveConsent() {
      let RequestData = {
        token: this.$store.getters["profile/token"],
        ...this.state.consents.TOS,
        ...this.state.consents.Policy,
      };
      let RequestResp = await RequestConsentUpdate(RequestData);
      this.$store.commit("BUTTON_LOADING", false);
      if (RequestResp.result != "complete") {
        this.$message({
          message: RequestResp.resultdetail,
          type: "error",
        });
      } else {
        localStorage.removeItem("TosConsent");
        localStorage.removeItem("PolicyConsent");
        localStorage.removeItem("linkingAccount");
      }
    },
    ShowModalComplete() {
      this.modalType = "completed";
      this.modalStatus = true;
    },
    handleModal(value) {
      this.$router.push({ name: "Home" });
    },
  },
};
