<style src="./BillImageViewer.scss" lang="scss"></style>
<script src="./BillImageViewer.js"></script>

<template>
	<div class="ImageViewer">
		<div class="image_status" :class="BillStatus">
			<Icon :name="`circle-status-${BillStatus}`" class="icon-circle" />
			<div class="image_status_approve" v-if="BillStatus == 'approved'">
				ใบเสร็จถูกต้อง
			</div>
			<div class="image_status_reject" v-else-if="BillStatus == 'rejected'">
				ใบเสร็จไม่ถูกต้อง
			</div>
			<div class="image_status_todo" v-else>ใบเสร็จอยู่ระหว่างรอการตรวจสอบ</div>
		</div>

		<v-zoomer-gallery
			class="image_preview"
			:list="ImageList"
			v-model="index"
			ref="Zoommer"
		>
		</v-zoomer-gallery>

		<div class="image_control">
			<div class="controlbar">
				<Icon
					name="zoom-in"
					class="control-icon"
					@click.prevent="$refs.Zoommer.zoomIn()"
				/>
				<Icon
					name="zoom-reset"
					class="control-icon"
					@click.prevent="$refs.Zoommer.reset()"
				/>
				<Icon
					name="zoom-out"
					class="control-icon"
					@click.prevent="$refs.Zoommer.zoomOut()"
				/>
			</div>

			<div class="bt_prev" @click="PrevBill" v-if="index > 0">
				<img src="../../assets/icons/arrow-back.png" alt="" />
			</div>
			<div
				class="bt_next"
				@click="NextBill"
				v-if="index < ImageList.length - 1 && ImageList.length > 1"
			>
				<img src="../../assets/icons/arrow-next.png" alt="" />
			</div>
		</div>

		<div class="bt_close">
			<Icon name="bt_close" class="control-icon" @click="handleHide" />
		</div>
	</div>
</template>