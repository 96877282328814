export default {
  name: "BaseButtonLegend",
  props: {
    buttonData: Object
  },
  methods: {
    buttonClick(item) {
      this.$emit('buttonClick', item)
    },
  }
};
