import {
  BaseBackButtonCustomComponent,
  BaseTitleComponent,
  BaseInputPasswordComponent,
  BaseErrorMsgComponent,
  BaseButtonComponent,
  BaseModalComponent,
} from "@/components";

import { ref, reactive, provide, onMounted } from "vue";
import { useRouter } from "vue-router";
import store from "@/store";
import { RequestUpdatePassword } from "@/api/profile";

export default {
  name: "SettingPhone",
  components: {
    BaseBackButtonCustomComponent,
    BaseTitleComponent,
    BaseInputPasswordComponent,
    BaseErrorMsgComponent,
    BaseButtonComponent,
    BaseModalComponent,
  },
  data() {
    return {
      goBack: true,
      token: this.$store.getters["profile/token"],
      titleData: `เปลี่ยนรหัสผ่าน`,
      descData: `ใส่รหัสผ่านที่ปลอดภัย โดยมีอักขระ 6 ตัวขึ้นไป<br/>โดยมีทั้งตัวอักษรภาษาอังกฤษพิมพ์ใหญ่ พิมพ์เล็ก และตัวเลข`,
      modalShow: false,
      modalStatus: false,
      modalValue: 0,
      modalType: null,
      inputPassword: {
        name: "password",
        value: "",
        type: "password",
        placeholder: "รหัสผ่านใหม่",
        maxlength: 50,
      },
      inputRePassword: {
        name: "password",
        value: "",
        type: "password",
        placeholder: "กรอกรหัสผ่านอีกครั้ง",
        maxlength: 50,
      },
      buttonData: {
        name: "เปลี่ยนรหัสผ่าน",
        type: "submit",
        active: false,
      },
      errorMsg: {
        Password: "",
        RePassword: "",
      },
      PasswordCheck: null,
      PasswordScore: null,
    };
  },
  watch: {
    // inputPassword: {
    //   deep: true,
    //   handler(Password) {
    //     if (Password.value != "" && this.inputRePassword.value != "") {
    //       this.buttonData.active = true;
    //     } else {
    //       this.buttonData.active = false;
    //     }
    //     this.errorMsg.Password = "";
    //     this.StrengthChecker();
    //   },
    // },
    // inputRePassword: {
    //   deep: true,
    //   handler(Password) {
    //     if (
    //       Password.value != "" &&
    //       this.inputPassword.value != "" &&
    //       Password.value === this.inputPassword.value
    //     ) {
    //       this.errorMsg.RePassword = "";
    //       this.buttonData.active = true;
    //     } else {
    //       this.errorMsg.RePassword =
    //         "* คุณกรอกรหัสผ่านผิดพลาดหรือไม่ถูกต้อง\n* รหัสผ่านไม่ตรงกัน";
    //       this.buttonData.active = false;
    //     }
    //   },
    // },
  },
  inject: ["trackEvent"],
  methods: {
    handlePasswordOk({ PasswordCheck, PasswordScore, password }) {
      this.logger({ PasswordCheck, PasswordScore, password });
      if (password.length == 0) {
        this.PasswordCheck = "";
        this.buttonData.active = false;
      } else if (password.length > 0 && password.length < 2) {
        this.PasswordCheck = "* คุณกรอกรหัสผ่านผิดพลาดหรือไม่ถูกต้อง";
        this.buttonData.active = false;
      } else if (password.length > 2 && password.length < 6) {
        this.PasswordCheck = PasswordCheck;
        this.buttonData.active = false;
      } else {
        this.PasswordCheck = PasswordCheck;
        this.buttonData.active = false;
      }
      this.PasswordScore = PasswordScore;
      this.inputPassword.value = password;
    },
    handleRePasswordOk({ PasswordCheck, PasswordScore, password }) {
      this.logger({ PasswordCheck, password });
      if (password.length == 0) {
        this.PasswordCheck = "";
        this.buttonData.active = true;
      } else if (
        password.length > 0 &&
        password.length < 2 &&
        password !== this.inputPassword.value
      ) {
        this.PasswordCheck = "* รหัสผ่านไม่ตรงกัน";
        this.buttonData.active = false;
      } else if (password.length > 2 && password.length < 6) {
        this.PasswordCheck = PasswordCheck;
        this.buttonData.active = false;
      } else {
        this.PasswordCheck = PasswordCheck;
        this.buttonData.active = true;
      }
      this.PasswordScore = PasswordScore;
      this.inputRePassword.value = password;
    },
    handleBackward() {
      this.$router.push({
        name: "SettingPasswordOptions",
      });
    },
    // StrengthChecker() {
    //   let password = this.inputPassword.value;
    //   if (password.length == 0) {
    //     this.PasswordCheck = "";
    //   } else if (password.length <= 6) {
    //     this.PasswordCheck = "รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร";
    //   } else {
    //     var rating = [
    //       0,
    //       "Weak",
    //       25,
    //       "Fair",
    //       50,
    //       "Medium",
    //       75,
    //       "Strong",
    //       100,
    //       "Very Strong",
    //     ];
    //     var score = 0;
    //     var pass = "";

    //     if (password.length > 8) {
    //       score += 25;
    //     }
    //     if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
    //       score += 25;
    //     }
    //     /* eslint-disable-next-line */
    //     if (password.match(/[\!\@\#\$\%\^\&\*\?\_\~\-\(\)]+/)) {
    //       score += 25;
    //     }
    //     if (password.match(/[0-9]/)) {
    //       score += 25;
    //     }

    //     for (var i = rating.length - 1; i >= 0; i -= 1) {
    //       if (score >= rating[i]) {
    //         pass = rating[i + 1];
    //         break;
    //       }
    //     }
    //     this.PasswordCheck = `Your Password is ${pass}`;
    //   }
    // },
    FormValid() {
      let FormCheck = true;
      if (this.inputPassword.value !== this.inputRePassword.value) {
        FormCheck = false;
      }
      return FormCheck;
    },
    SubmitForm() {
      let FormValid = this.FormValid();
      if (FormValid === true) {
        this.RequestUpdatePassword();
      } else {
        this.$store.commit("BUTTON_LOADING", false);
      }
    },
    async RequestUpdatePassword() {
      this.$store.commit("BUTTON_LOADING", true);
      let RequestData = {
        token: this.token,
        newpassword: this.inputPassword.value,
      };
      let RequestResp = await RequestUpdatePassword(RequestData);
      this.$store.commit("BUTTON_LOADING", false);
      if (RequestResp.result == "complete") {
        this.trackEvent("SettingPasswordUpdate - Confirm change", {
          event_category: "SettingPasswordUpdate",
          event_label: "Confirm change",
          value: true,
        });
        this.ShowModalComplete();
      } else {
        this.$message(RequestResp.resultdetail);
      }
    },
    ShowModalComplete() {
      this.modalType = "updated";
      this.modalStatus = true;
    },
    handleModal(value) {
      this.trackEvent("SettingPasswordUpdate - Confirm", {
        event_category: "SettingPasswordUpdate",
        event_label: "Confirm",
        value: true,
      });
      this.$router.push({ name: "Account" });
    },
  },
};
