export default {
    name: "BaseListTitleComponent",
    props: {
        titleData: String,
        linkData:  String,
    },
    data() {
      return {
        title: this.titleData,
        link: this.linkData
      };
    },
  };
    