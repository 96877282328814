<style src="./BaseInputRadioComponent.scss" lang="scss"></style>
<script src="./BaseInputRadioComponent.js"></script>

<template>
	<div class="input__radio">
		<label>
			<input
				:name="inputRadio.name"
				type="radio"
				:value="inputRadio.value"
				v-model="inputRadio.selected"
				@change="select(inputRadio.value)"
				:checked="
					lineAccessToken && lineAccountSelected === 'linked' ? true : false
				"
			/>
			<span class="radio"></span>
			<span class="label new-line">{{ inputRadio.title }}</span>
		</label>
	</div>
</template>