<style src="./BaseFileUploadComponent.scss" lang="scss"></style>
<script src="./BaseFileUploadComponent.js"></script>
<template>
	<div class="box__image">
		<el-image :src="base64_image" class="preview__image" lazy>
			<template #error>
				<div class="image-slot">
					<i class="el-icon-picture-outline"></i>
				</div>
			</template>
		</el-image>
		<div class="image__info" v-if="image !== null">
			<div class="image__info__inner">
				<span class="image__info__name">{{ image.name }}</span>
				<span class="image__info__size">&nbsp;({{ image.size }})</span>
			</div>
			<div class="image__info__remove">
				<span @click="remove">
					<img src="../../assets/icons/remove.png" />
				</span>
			</div>
		</div>
		<form ref="form">
			<input
				style="display: none"
				type="file"
				ref="uploadInput"
				accept="image/png, image/jpeg, application/pdf"
				:multiple="false"
				v-bind="capture"
				@change="detectFiles($event)"
			/>
		</form>
	</div>
</template>