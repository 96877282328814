import {
  BaseTitleComponent,
  BaseAvatarComponent,
  UserProfileComponent,
  BaseShippingAddressComponent,
  BaseAvatarEditorComponent,
  BaseAvatarCameraComponent,
  BaseModalComponent,
  BaseBackButtonComponent,
} from "@/components";

var md5 = require("md5");
import _ from "lodash";
import * as dayjs from "dayjs";
import { ref, reactive, computed, provide } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, numeric, email } from "@vuelidate/validators";
import {
  RequestGetInfo,
  RequestProfileUpdate,
  RequestProfilePicUpdate,
  RequestProfileAddressList,
} from "@/api/profile";
import { showLoading, hideLoading,logger } from "@/helpers";

export default {
  name: "Home",
  components: {
    BaseTitleComponent,
    BaseAvatarComponent,
    UserProfileComponent,
    BaseShippingAddressComponent,
    BaseAvatarEditorComponent,
    BaseAvatarCameraComponent,
    BaseModalComponent,
    BaseBackButtonComponent,
  },
  data() {
    return {
      token: this.$store.getters["profile/token"],
      type: null,
      avatar: null,
      hash: null,
      primaryAddress: null,
      ShowModalComplete: false,
      profile: {
        title: "ข้อมูลส่วนตัว",
        button: {
          type: "update",
          update: "แก้ไขข้อมูล",
          upload: "อัพโหลดภาพของคุณ",
        },
        error: {
          firstname: false,
          lastname: false,
          phonenumber: false,
          email: false,
          dob: false,
          gender: false,
          status: false,
          province: false,
        },
        data: {
          image: require("@/assets/images/account/default_profile.png"),
          firstname: "",
          lastname: "",
          phonenumber: "",
          email: "",
          dob: "",
          gender: "",
          status: "",
          province: "",
        },
        statuses: [
          {
            id: 1,
            name: 'โสด'
          },
          {
            id: 2,
            name: 'แต่งงานแล้ว แต่ยังไม่มีบุตร'
          },
          {
            id: 3,
            name: 'แต่งงานแล้ว มีบุตรแล้ว'
          }
        ],
        provinces: [
          {
            id: "1",
            name_en: "Bangkok",
            name: "กรุงเทพมหานคร",
          },
          {
            id: "3",
            name_en: "Nonthaburi",
            name: "นนทบุรี",
          },
          {
            id: "4",
            name_en: "Pathum Thani",
            name: "ปทุมธานี",
          },
          {
            id: "2",
            name_en: "Samut Prakan",
            name: "สมุทรปราการ",
          },
          {
            id: "64",
            name_en: "Krabi",
            name: "กระบี่",
          },
          {
            id: "56",
            name_en: "Kanchanaburi",
            name: "กาญจนบุรี",
          },
          {
            id: "34",
            name_en: "Kalasin",
            name: "กาฬสินธุ์",
          },
          {
            id: "49",
            name_en: "Kamphaeng Phet",
            name: "กำแพงเพชร",
          },
          {
            id: "28",
            name_en: "Khon Kaen",
            name: "ขอนแก่น",
          },
          {
            id: "13",
            name_en: "Chanthaburi",
            name: "จันทบุรี",
          },
          {
            id: "15",
            name_en: "Chachoengsao",
            name: "ฉะเชิงเทรา",
          },
          {
            id: "11",
            name_en: "Chonburi",
            name: "ชลบุรี",
          },
          {
            id: "9",
            name_en: "Chainat",
            name: "ชัยนาท",
          },
          {
            id: "25",
            name_en: "Chaiyaphum",
            name: "ชัยภูมิ",
          },
          {
            id: "69",
            name_en: "Chumphon",
            name: "ชุมพร",
          },
          {
            id: "45",
            name_en: "Chiang Rai",
            name: "เชียงราย",
          },
          {
            id: "38",
            name_en: "Chiang Mai",
            name: "เชียงใหม่",
          },
          {
            id: "72",
            name_en: "Trang",
            name: "ตรัง",
          },
          {
            id: "14",
            name_en: "Trat",
            name: "ตราด",
          },
          {
            id: "50",
            name_en: "Tak",
            name: "ตาก",
          },
          {
            id: "17",
            name_en: "Nakhon Nayok",
            name: "นครนายก",
          },
          {
            id: "58",
            name_en: "Nakhon Pathom",
            name: "นครปฐม",
          },
          {
            id: "36",
            name_en: "Nakhon Phanom",
            name: "นครพนม",
          },
          {
            id: "19",
            name_en: "Nakhon Ratchasima",
            name: "นครราชสีมา",
          },
          {
            id: "63",
            name_en: "Nakhon Si Thammarat",
            name: "นครศรีธรรมราช",
          },
          {
            id: "47",
            name_en: "Nakhon Sawan",
            name: "นครสวรรค์",
          },
          {
            id: "76",
            name_en: "Narathiwat",
            name: "นราธิวาส",
          },
          {
            id: "43",
            name_en: "Nan",
            name: "น่าน",
          },
          {
            id: "77",
            name_en: "Bueng Kan",
            name: "บึงกาฬ",
          },
          {
            id: "20",
            name_en: "Buriram",
            name: "บุรีรัมย์",
          },
          {
            id: "62",
            name_en: "Prachuap Khiri",
            name: "ประจวบคีรีขันธ์",
          },
          {
            id: "16",
            name_en: "Prachinburi",
            name: "ปราจีนบุรี",
          },
          {
            id: "74",
            name_en: "Pattani",
            name: "ปัตตานี",
          },
          {
            id: "5",
            name_en: "Phra Nakhon Si Ayutthaya",
            name: "พระนครศรีอยุธยา",
          },
          {
            id: "65",
            name_en: "Phang Nga",
            name: "พังงา",
          },
          {
            id: "73",
            name_en: "Phattalung",
            name: "พัทลุง",
          },
          {
            id: "53",
            name_en: "Phichit",
            name: "พิจิตร",
          },
          {
            id: "52",
            name_en: "Phitsanulok",
            name: "พิษณุโลก",
          },
          {
            id: "61",
            name_en: "Phetchaburi",
            name: "เพชรบุรี",
          },
          {
            id: "54",
            name_en: "Phetchabun",
            name: "เพชรบูรณ์",
          },
          {
            id: "42",
            name_en: "Phrae",
            name: "แพร่",
          },
          {
            id: "44",
            name_en: "Phayao",
            name: "พะเยา",
          },
          {
            id: "66",
            name_en: "Phuket",
            name: "ภูเก็ต",
          },
          {
            id: "32",
            name_en: "Maha Sarakham",
            name: "มหาสารคาม",
          },
          {
            id: "37",
            name_en: "Mukdahan",
            name: "มุกดาหาร",
          },
          {
            id: "46",
            name_en: "Mae Hong Son",
            name: "แม่ฮ่องสอน",
          },
          {
            id: "75",
            name_en: "Yala",
            name: "ยะลา",
          },
          {
            id: "24",
            name_en: "Yasothon",
            name: "ยโสธร",
          },
          {
            id: "33",
            name_en: "Roi Et",
            name: "ร้อยเอ็ด",
          },
          {
            id: "68",
            name_en: "Ranong",
            name: "ระนอง",
          },
          {
            id: "12",
            name_en: "Rayong",
            name: "ระยอง",
          },
          {
            id: "55",
            name_en: "Ratchaburi",
            name: "ราชบุรี",
          },
          {
            id: "7",
            name_en: "Lopburi",
            name: "ลพบุรี",
          },
          {
            id: "40",
            name_en: "Lampang",
            name: "ลำปาง",
          },
          {
            id: "39",
            name_en: "Lamphun",
            name: "ลำพูน",
          },
          {
            id: "30",
            name_en: "Loei",
            name: "เลย",
          },
          {
            id: "22",
            name_en: "Sisaket",
            name: "ศรีสะเกษ",
          },
          {
            id: "35",
            name_en: "Sakon Nakhon",
            name: "สกลนคร",
          },
          {
            id: "70",
            name_en: "Songkhla",
            name: "สงขลา",
          },
          {
            id: "71",
            name_en: "Satun",
            name: "สตูล",
          },
          {
            id: "60",
            name_en: "Samut Songkhram",
            name: "สมุทรสงคราม",
          },
          {
            id: "59",
            name_en: "Samut Sakhon",
            name: "สมุทรสาคร",
          },
          {
            id: "18",
            name_en: "Sa Kaeo",
            name: "สระแก้ว",
          },
          {
            id: "10",
            name_en: "Saraburi",
            name: "สระบุรี",
          },
          {
            id: "8",
            name_en: "Sing Buri",
            name: "สิงห์บุรี",
          },
          {
            id: "51",
            name_en: "Sukhothai",
            name: "สุโขทัย",
          },
          {
            id: "57",
            name_en: "Suphan Buri",
            name: "สุพรรณบุรี",
          },
          {
            id: "67",
            name_en: "Surat Thani",
            name: "สุราษฎร์ธานี",
          },
          {
            id: "21",
            name_en: "Surin",
            name: "สุรินทร์",
          },
          {
            id: "31",
            name_en: "Nong Khai",
            name: "หนองคาย",
          },
          {
            id: "27",
            name_en: "Nong Bua Lamphu",
            name: "หนองบัวลำภู",
          },
          {
            id: "6",
            name_en: "Ang Thong",
            name: "อ่างทอง",
          },
          {
            id: "29",
            name_en: "Udon Thani",
            name: "อุดรธานี",
          },
          {
            id: "48",
            name_en: "Uthai Thani",
            name: "อุทัยธานี",
          },
          {
            id: "41",
            name_en: "Uttaradit",
            name: "อุตรดิตถ์",
          },
          {
            id: "23",
            name_en: "Ubon Ratchathani",
            name: "อุบลราชธานี",
          },
          {
            id: "26",
            name_en: "Amnat Charoen",
            name: "อำนาจเจริญ",
          },
        ],
      },
      page: "account",
    };
  },
  created() {
    window.scrollTo(0, 0);
    if (this.token) {
      this.type = this.$route.params.type;
      if (this.type === "edit") {
        this.profile.title = "ข้อมูลส่วนตัว";
      }
      this.GetInfo();
      this.GetProfileAddressList();
    } else {
      this.$message({
        message: "Invalid Token",
        type: "error",
      });
      this.$router.push("/Gateway");
    }
  },
  computed: {
    changeTitle: function() {
      return this.profile.title;
    },
  },
  inject: ["trackEvent"],
  setup() {
    const state = reactive({
      profile: {
        firstname: ref(""),
        lastname: ref(""),
        phonenumber: ref(""),
        email: ref(""),
        dob: ref(""),
        gender: ref(""),
        status: ref(""),
        province: ref(""),
      },
    });

    const validations = computed(() => ({
      profile: {
        firstname: { required },
        lastname: { required },
        phonenumber: { required, numeric },
        email: { required, email },
        dob: { required },
        gender: { required },
        status: { required },
        province: { required },
      },
    }));

    const v$ = useVuelidate(validations, state);
    provide("v$", v$);
    return {
      v$,
      state,
    };
  },
  methods: {
    handleCapture(data) {
      this.page = "account";
      if (data != null) {
        this.$refs.avatarEditor.imageFile(data);
      }
    },
    async GetInfo() {
      let RequestData = {
        token: this.token,
      };
      let RequestResp = await RequestGetInfo(RequestData);
      if (RequestResp.result == "complete") {
        var t = "?t=" + dayjs().unix();
        var info = RequestResp.info;
        this.hash = md5(
          info.firstname +
            info.lastname +
            info.email +
            info.bhd +
            info.gender +
            info.status +
            info.province
        );
        this.state.profile.firstname = info.firstname;
        this.state.profile.lastname = info.lastname;
        this.state.profile.phonenumber = info.mobile;
        this.state.profile.email = info.email;
        this.state.profile.dob = dayjs(info.bhd).format('YYYY-MM-DDTHH:mm:ssZ');
        this.state.profile.gender = info.gender;
        this.state.profile.status = info.status;
        this.state.profile.province = info.province;
        this.profile.data.image = info.profilepic + t;
        this.avatar = info.profilepic + t;
      } else {
        this.$message({
          message: RequestResp.resultdetail,
          type: "error",
        });
      }
    },
    async GetProfileAddressList() {
      let RequestData = {
        token: this.token,
      };
      let RequestResp = await RequestProfileAddressList(RequestData);
      if (RequestResp.result == "complete") {
        var addresses = _.filter(
          RequestResp.list,
          (address) => address.default === true
        );
        addresses.forEach((address) => {
          this.primaryAddress = address;
        });
      }
    },
    getButtonLabel() {
      return this.profile.button[this.profile.button.type];
    },
    imageAction() {
      if (this.profile.button.type === "upload") {
        this.page = "capture";
      }
      if (this.profile.button.type === "update") {
        this.profile.title = "แก้ไขข้อมูล";
        this.profile.button.type = "upload";

        this.trackEvent(`Account - Edit profile image`, {
          event_category: "Account",
          event_label: "Edit profile image",
          value: true,
        });
      }
    },
    retakeImage() {
      this.page = "capture";
    },
    getProfileImage(base64) {
      this.profile.data.image = base64;
    },
    updatePhonenumber() {
      if (this.profile.button.type === "upload") {
        this.$router.push({ name: "SettingPhone" });
      }
    },
    save() {
      this.v$.$touch();
      if (this.v$.$error) {
        return;
      } else {
        this.RequestProfileUpdate();
      }
    },
    async RequestProfileUpdate() {
      var new_hash = md5(
        this.state.profile.firstname +
          this.state.profile.lastname +
          this.state.profile.email +
          this.state.profile.dob +
          this.state.profile.gender +
          this.state.profile.status +
          this.state.profile.province
      );
      if (this.hash !== new_hash) {
        this.$store.commit("BUTTON_LOADING", true);
        showLoading();
        let RequestData = {
          token: this.token,
          firstname: this.state.profile.firstname,
          lastname: this.state.profile.lastname,
          email: this.state.profile.email,
          bhd: this.state.profile.dob,
          province: this.state.profile.province,
          status: this.state.profile.status,
          gender: this.state.profile.gender,
          interest: [],
        };
        let RequestResp = await RequestProfileUpdate(RequestData);
        this.$store.commit("BUTTON_LOADING", false);
        if (RequestResp.result == "complete") {
          hideLoading();
          this.hash = new_hash;
          // this.$message({
          // 	message: 'Profile Updated',
          // 	type: 'success'
          // })
          this.ShowModalComplete = true;

          this.trackEvent(`Account - Save`, {
            event_category: "Account",
            event_label: "Save",
            value: true,
          });
        } else {
          this.$message({
            message: RequestResp.resultdetail,
            type: "error",
          });

          this.trackEvent(`Account - Save`, {
            event_category: "Account",
            event_label: "Save",
            value: false,
          });
        }
      }
      if (this.avatar !== this.profile.data.image) {
        showLoading();
        this.RequestUploadProfilePic();
      }
      this.profile.button.type = "update";
    },
    async RequestUploadProfilePic() {
      let Imagedata = this.profile.data.image.split(",");
      this.$store.commit("BUTTON_LOADING", true);
      let RequestData = {
        token: this.token,
        profilepic: Imagedata[1],
      };
      let RequestResp = await RequestProfilePicUpdate(RequestData);
      this.$store.commit("BUTTON_LOADING", false);
      if (RequestResp.result == "complete") {
        localStorage.setItem("avatarChange", true);
        hideLoading();
        this.avatar = this.profile.data.image;
        this.ShowModalComplete = true;
      } else {
        this.$message(RequestResp.resultdetail);
      }
    },
    CloseModal() {
      this.ShowModalComplete = false;
    },
  },
};
