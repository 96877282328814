<style src="./BaseImageFeatureComponent.scss" lang="scss"></style>
<script src="./BaseImageFeatureComponent.js"></script>

<template>
	<div class="box__image__feature">
        <el-image :src="reward.image" fit="fill">
            <template #placeholder>
                <div class="image-slot">
                Loading<span class="dot">...</span>
                </div>
            </template>
        </el-image>
	</div>
</template>