import {
  ref,
  reactive,
  computed,
  nextTick,
  onMounted,
  handleError,
  watchEffect,
  onBeforeUnmount,
} from "vue";
import $ from "jquery";
import Jimp from "jimp";
import { useStore } from "vuex";
import { Icon, BaseTitleComponent } from "@/components";
import Webcam from "webcam-easy-v2";
import { ElMessage } from "element-plus";
import loadImage from "blueimp-load-image";
import { useRouter, useRoute } from "vue-router";
import { uuid, getAndroidDevice, isOs, logger, getBrowser, isBrowser, hasCameraAccess } from "@/helpers";
import { cameraSetting } from "@/api/camera";

export default {
  name: "BaseAvatarCameraComponent",
  props: {
    imageArray: {
      type: Array,
      default: [],
    },
    imagePrevId: Number,
    imagePrev: {
      type: String,
      default: "",
    },
    scanCapture: Boolean,
    currentAction: {
      type: String,
      default: "capture",
    },
    configButton: {
      type: Object,
      default: {
        name: "Capture",
        color: "#FFFFFF",
        backgroundColor: "#000000",
      },
    }
  },
  components: {
    Icon,
    BaseTitleComponent
  },
  emits: ["update:capture"],
  setup(props, { emit }) {

    let settingHeader = ref(null);
    let settingText = ref(null);
    let cameraAccess = ref(true);
    let cameraUndefined = ref(false)

    async function loadCameraInfo () {
      let cameraInfo = await cameraSetting();
      if(cameraInfo) {
        settingHeader.value = cameraInfo.access
        let deviceOs = null
        const browser = getBrowser()
        if (isOs("android")) {
          deviceOs = `android_${browser}`
        }
        if (isOs("ios")) {
          deviceOs = `ios_${browser}`
        } 
        const warningText = cameraInfo.steps.filter((val) => { return val.id == deviceOs })
        if(warningText.length > 0) {
          settingText.value = warningText[0].text
        }
      }
    }
    loadCameraInfo()

    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    onMounted(() => {
      
      const footer = document.getElementById('footer');
      if(footer){
        footer.classList.add("hide-footer")
      }

      if (hasCameraAccess() && cameraAccess.value) {
        cameraStarted();
      }
    });

    onBeforeUnmount(() => {
      $("body").removeClass("yh");
      
      const footer = document.getElementById('footer');
      if(footer){
        footer.classList.remove("hide-footer")
      }

      if (hasCameraAccess() && cameraAccess.value) {
        cameraStoped();
      }
    });

    const imagePrevIdUpdate = computed(() => props.imagePrevId + 1);
    const imagePrevUpdate = computed(() => props.imagePrev);
    const webcamElement = ref("");
    const canvasElement = ref("");
    const snapSoundElement = ref("");
    const webcamDom = ref("");
    const snapSound = ref(require("@/assets/audio/snap.wav"));

    function cameraStarted() {
      $(".flash").hide();
      $("body").addClass("yh");

      webcamElement.value = document.getElementById("webcam");
      canvasElement.value = document.getElementById("canvas");
      snapSoundElement.value = document.getElementById("snapSound");

      webcamDom.value = new Webcam(
        webcamElement,
        "user",
        canvasElement,
        snapSoundElement
      );

      webcamDom.value
        .start()
        .then((result) => {
          logger("webcam started")
        })
        .catch((err) => {
          if (
            err
              .toString()
              .toLowerCase()
              .indexOf("permission") !== -1 ||
            err
              .toString()
              .toLowerCase()
              .indexOf("denied") !== -1
          ) {
            cameraAccess.value = false;
          } else if (
            err
              .toString()
              .toLowerCase()
              .indexOf("error") !== -1 ||
            err
              .toString()
              .toLowerCase()
              .indexOf("undefined") !== -1
          ) {
            cameraUndefined.value = true
            cameraAccess.value = false;
          }
          logger(err);
        });
    }

    function cameraFilp() {
      webcamDom.value.flip();
    }

    function cameraStoped() {
      webcamDom.value.stop();
      logger("webcam stopped");
    }

    async function handleTakePhoto() {
      beforeTakePhoto();

      // Capture camera
      let pictureSnap = webcamDom.value.snap();
      let picture = await resizePicture(pictureSnap);
      if (picture) {
        // Crop offset for one more bill
        let screenHeight = window.innerHeight; // get height for devices
        let cropHeightUI = 160; //default 152
        let offsetY = Math.round(((screenHeight - cropHeightUI) * 22) / 100); // crop from top 22%

        // console.log('screenHeight', screenHeight);
        // console.log('offsetY', offsetY);

        // Cropped offset by device with top overlay bill
        if (imagePrevUpdate.value) {
          picture = await base64Resize(picture, offsetY);
        }


        afterTakePhoto();

        emit("update:capture", picture);
      
      }
    }

    const resizePicture = async (data) => {
      return new Promise((resolve, reject) => {
        const imgBuffer = new Buffer.from(data.split(",").pop(), "base64");
        var arrayBufferView = new Uint8Array(imgBuffer);
        var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
        const reader = new FileReader();

        reader.onload = async (e) => {
          // console.log(e);
          await loadImage(
            reader.result,
            (img, data) => {
              // console.log(img.toDataURL("image/jpeg"));
              resolve(img.toDataURL("image/jpeg"));
            },
            {
              canvas: true,
              maxWidth: 1200,
              orientation: true,
            }
          );
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    };

    function beforeTakePhoto() {
      $(".flash")
        .show()
        .animate(
          {
            opacity: 0.3,
          },
          500
        )
        .fadeOut(500)
        .css({
          opacity: 0.7,
        });
    }

    function afterTakePhoto() {
      if (!getAndroidDevice() && cameraAccess.value) {
        cameraStoped();
        $("#canvas").removeClass("d-none");
      }
    }

    function base64Resize(sourceBase64, offsetY) {
      return new Promise((resolve, reject) => {
        // We create an image to receive the Data URI
        var img = document.createElement("img");

        // When the event "onload" is triggered we can resize the image.
        img.onload = () => {
          // We create a canvas and get its context.
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");

          // We set the dimensions at the wanted size.
          canvas.width = img.width;
          canvas.height = img.height;

          // We resize the image with the canvas method drawImage();
          ctx.drawImage(this, 0, 0 - offsetY, img.width, img.height);

          var dataURI = canvas.toDataURL();

          // This is the return of the Promise
          resolve(dataURI);
        };
        img.onerror = (error) => reject(error);

        // We put the Data URI in the image's src attribute
        img.src = sourceBase64;
      });
    }

    function detectGalleryFiles(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
        let picture = reader.result;
         emit("update:capture", picture);
      };
      reader.onerror = (error) => {
        e.target.value = "";
      };
      e.target.value = "";
    }

    function pageRefresh() {
      window.location.reload();
    }

    function goback() {
      emit("update:capture", null);
    }

    return {
      settingHeader,
      settingText,
      route,
      router,
      snapSound,
      webcamDom,
      cameraFilp,
      cameraStoped,
      base64Resize,
      webcamElement,
      canvasElement,
      cameraStarted,
      handleTakePhoto,
      imagePrevUpdate,
      snapSoundElement,
      imagePrevIdUpdate,
      detectGalleryFiles,
      getBrowser,
      isBrowser,
      isOs,
      pageRefresh,
      goback,
      cameraAccess,
      hasCameraAccess,
      cameraUndefined
    };
  },
};
