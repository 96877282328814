<style src="./Address.scss" lang="scss" scoped></style>
<script src="./Address.js"></script>

<template>
	<div class="content__address__view">
		<div class="address__header">
			<BaseBackButtonComponent />
			<BaseTitleComponent :title-name="title" />
		</div>
		<div class="address__form">
			<div class="address__input">
				<label for="type">ติดป้ายเป็น</label>
				<span class="input__error" v-if="v$.address.title.$error"
					>*ข้อมูลผิดพลาด</span
				>
			</div>
			<div class="radio__justify">
				<div class="address__radio">
					<label>
						<input
							name="type"
							type="radio"
							value="home"
							v-model="v$.address.title.$model"
						/>
						<span class="radio"></span>
						<span class="label">บ้าน</span>
					</label>
				</div>
				<div class="address__radio">
					<label>
						<input
							name="type"
							type="radio"
							value="office"
							v-model="v$.address.title.$model"
						/>
						<span class="radio"></span>
						<span class="label">ที่ทำงาน</span>
					</label>
				</div>
			</div>
			<div class="address__input">
				<label for="name">ชื่อ - นามสกุล</label>
				<span class="input__error" v-if="v$.address.firstname.$error"
					>*ข้อมูลผิดพลาด</span
				>
				<input
					type="text"
					id="name"
					v-model="v$.address.firstname.$model"
					placeholder="ใส่ชื่อ - นามสกุล"
				/>
			</div>
			<!-- <div class="address__input">
				<label for="name">นามสกุล</label>
				<span class="input__error" v-if="v$.address.lastname.$error">*ข้อมูลผิดพลาด</span>
				<input type="text" id="name" v-model="v$.address.lastname.$model" placeholder="นามสกุล"/>
			</div> -->
			<div class="address__input">
				<label for="phonenumber">เบอร์โทรศัพท์</label>
				<span class="input__error" v-if="v$.address.phonenumber.$error"
					>*ข้อมูลผิดพลาด</span
				>
				<input
					type="text"
					id="phonenumber"
					v-model="v$.address.phonenumber.$model"
					@keypress="isNumber($event)"
					maxlength="10"
					placeholder="ใส่เบอร์โทรศัพท์"
				/>
			</div>
			<div class="address__input">
				<label for="address">ที่อยู่</label>
				<span class="input__error" v-if="v$.address.address.$error"
					>*ข้อมูลผิดพลาด</span
				>
				<input
					type="text"
					id="address"
					v-model="v$.address.address.$model"
					placeholder="ใส่ที่อยู่"
				/>
			</div>
			<div class="address__input">
				<label for="address">ถนน</label>
				<span class="input__error" v-if="v$.address.street.$error"
					>*ข้อมูลผิดพลาด</span
				>
				<input
					type="text"
					id="address"
					v-model="v$.address.street.$model"
					placeholder="ใส่ถนน"
				/>
			</div>
			<div class="address__input">
				<label for="district">แขวง/ตำบล</label>
				<span class="input__error" v-if="v$.address.tumbon.$error"
					>*ข้อมูลผิดพลาด</span
				>
				<BaseAddressInputComponent
					v-model="v$.address.tumbon.$model"
					:value="v$.address.tumbon.$model"
					type="district"
					@select="select"
					placeholder="ใส่แขวง/ตำบล"
				/>
			</div>
			<div class="address__input">
				<label for="amphoe">เขต/อำเภอ</label>
				<span class="input__error" v-if="v$.address.district.$error"
					>*ข้อมูลผิดพลาด</span
				>
				<BaseAddressInputComponent
					v-model="v$.address.district.$model"
					:value="v$.address.district.$model"
					type="amphoe"
					@select="select"
					placeholder="ใส่เขต/อำเภอ"
				/>
			</div>
			<div class="address__input">
				<label for="province">จังหวัด</label>
				<span class="input__error" v-if="v$.address.province.$error"
					>*ข้อมูลผิดพลาด</span
				>
				<BaseAddressInputComponent
					v-model="v$.address.province.$model"
					:value="v$.address.province.$model"
					type="province"
					@select="select"
					placeholder="ใส่จังหวัด"
				/>
			</div>
			<div class="address__input">
				<label for="zipcode">รหัสไปรษณีย์</label>
				<span class="input__error" v-if="v$.address.postal.$error"
					>*ข้อมูลผิดพลาด</span
				>
				<BaseAddressInputComponent
					v-model="v$.address.postal.$model"
					:value="v$.address.postal.$model"
					type="zipcode"
					@select="select"
					placeholder="ใส่รหัสไปรษณีย์"
				/>
			</div>
		</div>
		<BaseButtonComponent
			class="save__btn"
			:button-data="buttonData"
			@HandleButton="SubmitForm"
		/>
		<BaseModalComponent
			:modal-show="ShowModalComplete"
			:modal-type="'updatecomplete'"
			@update:modal="CloseModal"
		/>
	</div>
</template>