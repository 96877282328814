<style src="./SubmissionReceipt.scss" lang="scss" scoped></style>
<script src="./SubmissionReceipt.js"></script>

<template>
	<div class="submission__wrapper">
		<BaseBackButtonCustomComponent
			:go-back="data.goBack"
			@button:action="handleBackward"
		/>
		<div class="submission__content">
			<h1 v-html="data.title"></h1>
			<ShopBillComponent
				:item-brands="data.brands"
				@update:bill-selected="handleSelectedItem"
			/>
			<p v-html="data.exBill"></p>
			<ShopBillExampleComponent
				:item-brands="data.brands"
				:item-bill-id="data.selectedItem"
				@update:bill-selected="handleSelectedItem"
			/>
			<div class="bill__button">
				<SubmissionButtonComponent
					:button-data="data.buttonData"
					@button:click="handleEvent"
				/>
			</div>
		</div>
	</div>
</template>