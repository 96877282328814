<style src="./ResetPass.scss" lang="scss" scoped></style>
<script src="./ResetPass.js"></script>

<template>
	<div class="content__view">
		<div class="content__view__inner">
			<BaseTitleComponent :title-name="titleName" />
			<div class="forget_msg1">
				ใส่รหัสผ่านที่ปลอดภัย โดยมีอักขระ 6 ตัวขึ้นไป
				<br />โดยมีทั้งตัวอักษรภาษาอังกฤษพิมพ์ใหญ่ พิมพ์เล็ก และตัวเลข
			</div>
			<!-- <div class="gaping"></div> -->
			<BaseInputPasswordComponent
				:input-password="inputPassword"
				v-model="inputPassword.value"
			/>
			<div class="gaping2"></div>
			<BaseInputPasswordComponent
				:input-password="inputRePassword"
				v-model="inputRePassword.value"
			/>
			<div class="gaping2"></div>
			<BaseErrorMsgComponent
				:error-msg="errorMsg.Password"
				v-if="errorMsg.Password"
			/>
			<BaseErrorMsgComponent
				:error-msg="errorMsg.RePassword"
				v-if="errorMsg.RePassword"
			/>
			<!-- <div class="password_status" v-if="PasswordCheck">
				{{ PasswordCheck }}
			</div> -->
			<BaseButtonComponent
				:button-data="buttonData"
				@HandleButton="SubmitForm"
			/>
		</div>
		<BaseModalComponent
			:modal-show="modalStatus"
			:modal-type="modalType"
			@update:modal="handleModal"
		/>
	</div>
</template>