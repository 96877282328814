<style src="./Lifestyle.scss" lang="scss" scoped></style>
<script src="./Lifestyle.js"></script>

<template>
	<div class="content__view">
		<BaseTitleComponent :title-name="profile.title" />
		<LifestyleComponent :items-lifestyle="itemsLifestyle" />
		<BaseButtonComponent
			:button-data="buttonData"
			class="button__pos lifestyle"
		/>
	</div>
</template>