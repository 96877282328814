import {
    Icon
} from '@/components';

import { RequestGetInfo } from '@/api/profile.js';

export default {
    name: 'PointBalanceComponent',
    components: {
        Icon
    },
    data() {
        return {
            token :this.$store.getters['profile/token'],
            pointbalance: 0
        }
    },
    created() {
        if (this.token){
          this.GetInfo();
        }
    },
    methods: {
        async GetInfo() {
            let RequestData = {
                "token": this.token,
            }
            let RequestResp = await RequestGetInfo(RequestData)
            if (RequestResp.result == 'complete') {
                this.pointbalance = RequestResp.info.pointbalance

            }
        },
        ReloadBalance () {
            this.GetInfo()
        }
    },
};

