<template>
	<div id="policy__pin" class="pdpa__policy">
		<div class="policy__head">
			<h1>ประกาศว่าด้วยการ<br />คุ้มครองข้อมูลส่วนบุคคล</h1>
			<el-switch
				v-model="value"
				active-color="#6CD064"
				inactive-color="#DBDBDB"
				active-value="1"
				inactive-value="0"
				@change="handleSwitchPolicy"
			>
			</el-switch>
		</div>
		<div class="policy__body">
			<span v-if="!valuePolicy" class="error"
				>* กรุณายอมรับประกาศว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล</span
			>
			<p>
				อะแด็ปเตอร์ ดิจิตอล จำกัด (“บริษัท”) ในฐานะเป็นผู้ควบคุมข้อมูลส่วนบุคคล
				เคารพในสิทธิความเป็นส่วนตัวของท่าน
				และตระหนักถึงความสำคัญในการเก็บรักษาข้อมูลส่วนบุคคลของท่าน
				(“ข้อมูลส่วนบุคคล”)
				บริษัทจึงได้จัดทำประกาศฉบับนี้ขึ้นเพื่อการคุ้มครองข้อมูลส่วนบุคคลของท่านตามกฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล
			</p>
			<ul>
				<li>
					1. บริษัทมีวัตถุประสงค์ในการเก็บรวบรวม ใช้
					หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน ดังต่อไปนี้
					<div>
						(1)
						เพื่อประโยชน์ในการยืนยันหรือระบุตัวตนของท่านในการเข้าร่วมกิจกรรมต่าง
						ๆ ของบริษัท
					</div>
					<div>
						(2)
						เพื่อจัดทำกิจกรรมส่งเสริมการขายและการตลาดเกี่ยวกับสินค้าและการให้บริการต่าง
						ๆ ของบริษัท รวมทั้งการโฆษณาประชาสัมพันธ์กิจกรรม โปรโมชั่น สินค้า
						และบริการ
						ตลอดจนให้คำแนะนำที่เหมาะสมเพื่อประโยชน์สูงสุดและตอบสนองความต้องการของท่าน
					</div>
					<div>
						(3) เพื่อการศึกษา วิจัย จัดทำสถิติ
						พัฒนาและปรับปรุงสินค้าและการให้บริการของบริษัทเพื่อประโยชน์สูงสุดของท่าน
					</div>
					<div>
						(4) เพื่อประโยชน์ในการสื่อสาร และการจัดทำสื่อประชาสัมพันธ์ของบริษัท
						ที่เผยแพร่ผ่านช่องทางการสื่อสารต่าง ๆ
					</div>
				</li>
				<li>
					2. การเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคล
					<div>
						การเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่าน
						จะต้องได้รับความยินยอมจากท่าน และบริษัทจะเก็บรวบรวม ใช้
						และเปิดเผยข้อมูลส่วนบุคคลดังกล่าว
						เท่าที่จำเป็นภายใต้ขอบเขตของวัตถุประสงค์ที่ท่านได้ให้ความยินยอมไว้แก่บริษัท
					</div>
					<div>
						ความยินยอมในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่าน
						ไม่ใช่เงื่อนไขการเข้าถึงการบริการหรือช่องทางการสื่อสารของบริษัท
						อย่างไรก็ตาม กรณีที่การให้บริการ
						การดำเนินการหรือการปฏิบัติตามสัญญาของบริษัทบางประการจำเป็นต้องอาศัยข้อมูลส่วนบุคคลของท่าน
						และท่านไม่ให้ความยินยอมหรือเพิกถอนความยินยอมในภายหลังอาจก่อให้เกิดข้อจำกัดในการให้บริการ
						หรือเข้าถึงช่องทางการสื่อสาร หรือการปฏิบัติตามสัญญาได้อย่างครบถ้วน
					</div>
					<div>
						บริษัทอาจเก็บรวบรวม ใช้
						และเปิดเผยข้อมูลส่วนบุคคลโดยไม่ได้รับความยินยอมจากท่านในกรณีดังต่อไปนี้
					</div>
					<div>
						(1)
						เพื่อให้บรรลุวัตถุประสงค์ที่เกี่ยวกับการจัดทำเอกสารประวัติศาสตร์หรือจดหมายเหตุเพื่อประโยชน์สาธารณะ
						หรือที่เกี่ยวกับการศึกษาวิจัยหรือสถิติซึ่งได้จัดให้มีมาตรการปกป้องที่เหมาะสมเพื่อคุ้มครองสิทธิและเสรีภาพของเจ้าของข้อมูลส่วนบุคคล
						ทั้งนี้ ตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลประกาศกำหนด
					</div>
					<div>
						(2) เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของบุคคล
					</div>
					<div>
						(3)
						เป็นการจำเป็นเพื่อการปฏิบัติตามสัญญาซึ่งท่านเป็นคู่สัญญาหรือเพื่อใช้ในการดำเนินการตามคำขอของท่านก่อนเข้าทำสัญญานั้น
					</div>
					<div>
						(4)
						เป็นการจำเป็นเพื่อการปฏิบัติหน้าที่ในการดำเนินภารกิจเพื่อประโยชน์สาธารณะของบริษัท
						หรือปฏิบัติหน้าที่ในการใช้อำนาจรัฐที่ได้มอบให้แก่ผู้ควบคุมข้อมูลส่วนบุคคล
					</div>
					<div>
						(5)
						เป็นการจำเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมายของบริษัทหรือของบุคคลหรือนิติบุคคลอื่น
						เว้นแต่ประโยชน์ดังกล่าวมีความสำคัญน้อยกว่าสิทธิขั้นพื้นฐานในข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคล
					</div>
					<div>(6) เป็นการปฏิบัติตามกฎหมาย</div>
				</li>
				<li>
					3. นโยบายคุกกี้ “คุกกี้” คือ
					<div>
						ข้อมูลที่มีการส่งไปยังระบบของท่านผ่านโปรแกรมการเข้าถึงเว็บไซต์ต่าง ๆ
						(Web browser) โดยเจ้าของโปรแกรมการเข้าถึงเว็บไซต์นั้น
						และผ่านเว็บไซต์ของบริษัท (“เว็บไซต์ของบริษัท”)
						และเมื่อมีการติดตั้งข้อมูลดังกล่าวไว้ในระบบของท่านแล้ว
						การเปิดใช้งานคุกกี้จะทำให้ท่านได้รับความสะดวกสบายในการใช้งานเว็บไซต์ของบริษัทมากยิ่งขึ้น
						เนื่องจากคุกกี้จะทำหน้าที่เก็บรวบรวมข้อมูลการใช้งานและการตั้งค่าของท่านโดยอัตโนมัติ
						เช่น ข้อมูลการล็อกอิน
						รวมถึงเว็บไซต์ที่เข้าเยี่ยมชมทั้งก่อนและหลังเว็บไซต์ของบริษัท
						เป็นต้น
					</div>
					<div>
						บริษัท ใช้คุกกี้ (cookies)
						หรือไฟล์ข้อมูลขนาดเล็กเพื่อช่วยให้บริษัทสามารถพัฒนา
						หรือปรับปรุงแก้ไขเว็บไซต์ของบริษัทให้ดียิ่งขึ้น
						เพื่อตอบสนองความต้องการในการใช้งานของท่านได้อย่างถูกต้อง แม่นยำ
						และสะดวกมากยิ่งขึ้น
					</div>
					<div>
						เมื่อท่านได้ยอมรับคุกกี้ในการเข้าใช้บริการเว็บไซต์ของบริษัทแล้ว
						การใช้บริการเว็บไซต์ของบริษัทอย่างต่อเนื่องในครั้งต่อไปโดยไม่เปลี่ยนแปลงการตั้งค่าบนอินเตอร์เน็ตเบราว์เซอร์
						อุปกรณ์ของท่านจะยอมรับคุกกี้โดยอัตโนมัติ
						ซึ่งหากท่านไม่ต้องการให้คุกกี้ทำการรวบรวมข้อมูล
						ท่านสามารถเลือกเปลี่ยนแปลงการตั้งค่ายอมรับที่ ส่วน “การตั้งค่า”
						ของแถบเครื่องมือบนอินเตอร์เน็ตเบราว์เซอร์ได้
					</div>
				</li>
				<li>
					4. ระยะเวลาในการเก็บรวบรวมข้อมูลส่วนบุคคล
					<div>
						บริษัทจะเก็บรวบรวมข้อมูลส่วนบุคคลตราบเท่าที่จำเป็นต่อการประมวลผล
					</div>
				</li>
				<li>
					5. การเปิดเผยข้อมูลส่วนบุคคล
					<div>
						บริษัทอาจเปิดเผยข้อมูลส่วนบุคคลให้แก่กลุ่มไทยเบฟ
						และ/หรือบุคคลที่ได้รับมอบหมายให้เป็นผู้ประมวลผลข้อมูลส่วนบุคคลจากบริษัทและ/หรือหน่วยงานของรัฐและ/หรือเอกชนเพื่อปฏิบัติให้เป็นไปตามกฎหมาย
						ตามที่ท่านได้ให้ความยินยอม
					</div>
				</li>
				<li>
					6. สิทธิของเจ้าของข้อมูลส่วนบุคคล
					ท่านในฐานะเจ้าของข้อมูลส่วนบุคคลมีสิทธิในการดำเนินการ ดังนี้
					<div>
						6.1 สิทธิในการเพิกถอนความยินยอมในการเก็บรวบรวม ใช้
						และเปิดเผยข้อมูลส่วนบุคคล
					</div>
					<div>
						6.2 สิทธิในการขอเข้าถึงหรือขอรับสำเนาข้อมูลส่วนบุคคลของท่าน
						หรือขอให้บริษัทเปิดเผยถึงการได้มาซึ่งข้อมูลที่ท่านไม่ได้ให้ความยินยอมไว้
					</div>
					<div>
						6.3 สิทธิในการขอรับ ส่ง
						หรือโอนย้ายข้อมูลส่วนบุคคลของท่านที่ได้ให้ไว้กับบริษัทไปยังผู้ควบคุมข้อมูลส่วนบุคคลรายอื่น
					</div>
					<div>
						6.4 สิทธิในการคัดค้านการเก็บรวบรวม ใช้
						หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
					</div>
					<div>
						6.5 สิทธิในการขอลบ หรือทำลาย
						หรือทำให้ข้อมูลส่วนบุคคลอยู่ในรูปแบบข้อมูลที่ไม่สามารถระบุตัวตนบุคคลที่เป็นเจ้าของข้อมูลได้
					</div>
					<div>6.6 สิทธิในการขอให้บริษัทระงับการใช้ข้อมูลส่วนบุคคล</div>
					<div>
						6.7 สิทธิในการขอให้ดำเนินการให้ข้อมูลส่วนบุคคลนั้นถูกต้อง
						เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด
						หากบริษัทไม่ดำเนินการตามคำร้องขอของท่าน
						บริษัทจะบันทึกคำร้องขอของท่านพร้อมเหตุผลเอาไว้เพื่อการตรวจสอบ
					</div>
					<div>
						6.8
						สิทธิในการร้องเรียนเกี่ยวกับการฝ่าฝืนกฎหมายคุ้มครองข้อมูลส่วนบุคคล
						ทั้งนี้ ภายใต้หลักเกณฑ์ที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลกำหนดไว้
						ในกรณีที่ท่านประสงค์จะใช้สิทธิดังกล่าวข้างต้น
						โปรดติดต่อไปยังบุคคลที่ระบุในข้อ 11.
					</div>
				</li>
				<li>
					7. มาตรการรักษาความปลอดภัย
					<div>
						บริษัทได้จัดให้มีระบบการปกป้องข้อมูลส่วนบุคคลจากการเข้าถึง
						การเปลี่ยนแปลง และการทำลาย โดยไม่ได้รับอนุญาต
						โดยบริษัทตกลงใช้ระบบรักษาความปลอดภัยที่ได้มาตรฐาน
						เพื่อคุ้มครองข้อมูลส่วนบุคคลของท่าน และในการรวบรวม ใช้
						และ/หรือเปิดเผยข้อมูลส่วนบุคคลไม่ว่าทั้งหมดหรือบางส่วน
						บริษัทตกลงดำเนินการตามสิทธิและหน้าที่ที่พึงมีหรือพึงปฏิบัติตามกฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล
					</div>
				</li>
				<li>
					8. บุคคลที่ได้รับอนุญาตให้เข้าถึงข้อมูลส่วนบุคคล
					<div>
						เฉพาะเจ้าหน้าที่ของบริษัทหรือบุคคลที่บริษัทมอบหมายให้มีหน้าที่
						หรือความรับผิดชอบเกี่ยวข้องกับวัตถุประสงค์การใช้ข้อมูลส่วนบุคคลหรือเพื่อปฏิบัติตามกฎหมายเท่านั้นที่มีสิทธิเข้าถึงข้อมูลส่วนบุคคลของท่านได้
						โดยบริษัทจะควบคุมให้เฉพาะเจ้าหน้าที่ของบริษัทหรือบุคคลที่บริษัทมอบหมายดังกล่าวสามารถเข้าถึงข้อมูลส่วนบุคคลของท่านได้
					</div>
				</li>
				<li>
					9. การแก้ไขเปลี่ยนแปลงประกาศฉบับนี้
					<div>
						บริษัทอาจพิจารณาทบทวนประกาศฉบับนี้เพื่อให้สอดคล้องกับแนวปฏิบัติและกฎหมาย
						หรือข้อบังคับที่เกี่ยวข้อง ทั้งนี้ หากมีการเปลี่ยนแปลงประกาศฉบับนี้
						บริษัทจะประกาศผ่านเว็บไซต์ของบริษัท
						หรือช่องทางการสื่อสารอื่นของบริษัท
					</div>
				</li>
				<li>
					10. กฎหมายที่ใช้บังคับ
					<div>
						ประกาศฉบับนี้อยู่ภายใต้บังคับของกฎหมายไทย
						และศาลไทยมีเขตอำนาจในการพิจารณาข้อพิพาทที่เกิดขึ้น
					</div>
				</li>
				<li>
					11. ติดต่อบริษัท
					<div>
						หากท่านมีข้อสอบถามเกี่ยวกับประกาศฉบับนี้
						หรือประสงค์ที่จะใช้สิทธิของเจ้าของข้อมูลส่วนบุคคลในข้อ 6
						(สิทธิของเจ้าของข้อมูลส่วนบุคคล)
						ท่านสามารถติดต่อฝ่ายคุ้มครองข้อมูลส่วนบุคคลของบริษัทผ่านช่องทางดังต่อไปนี้
						roxketeer@adapterdigital.com
					</div>
				</li>
			</ul>
			<!-- <p>
				Various versions have evolved over the years, sometimes by accident,
				sometimes on purpose (injected humour and the like).
				<a href="#" @click="handleModalPolicy">See Details</a>
			</p> -->
			<!-- <PDPADataLinkedPolicyComponent />
			<PDPADataNotLinkedPolicyComponent /> -->
		</div>
	</div>
</template>

<script>
// import PDPADataLinkedPolicyComponent from "./PDPADataLinkedPolicyComponent.vue";
// import PDPADataNotLinkedPolicyComponent from "./PDPADataNotLinkedPolicyComponent.vue";

export default {
	data() {
		return {
			value: 0,
			dialogVisible: false,
			valuePolicy: false,
		};
	},
	props: {
		acceptType: String,
		acceptStatus: Number,
	},
	components: {
		// PDPADataLinkedPolicyComponent,
		// PDPADataNotLinkedPolicyComponent,
	},
	methods: {
		reset() {
			this.value = 0;
			this.dialogVisible = false;
		},
		handleModalPolicy() {
			this.$emit("modal:policy", !this.dialogVisible);
		},
		handleSwitchPolicy() {
			// console.log(this.value);
			if (this.value == 0) {
				this.valuePolicy = false;
				this.$store.commit("profile/setPolicyConsent", null);
			} else {
				this.valuePolicy = true;
				this.$store.commit("profile/setPolicyConsent", this.value);
				this.$emit("switch:policy", {
					type: this.acceptType,
					accept: parseInt(this.value),
					show: Boolean(!this.dialogVisible),
				});
			}
		},
	},
};
</script>

<style lang="scss" >
.pdpa__policy {
	padding: 0 7% 22% 7%;

	.policy__head {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		text-align: left;

		h1 {
			// font-family: $font-family-ibm-semi;
			// @include font-size(30);
			font-size: $font-xl;
			line-height: $font-l;
			// color: $darkGray;
			color: $black;
			padding: 0;
			margin: 0;

			@supports (-webkit-touch-callout: none) {
				/* CSS specific to iOS devices */
				letter-spacing: -1px;
			}
		}

		.el-switch {
			margin-top: 10px;
			&.is-checked {
				span {
					&.el-switch__core {
						width: 56px !important;
						height: 32px;
						border-radius: 20px;
						.el-switch__action {
							top: 3px;
							margin-left: -26px;
							width: 24px;
							height: 24px;
						}
					}
				}
			}

			span {
				&.el-switch__core {
					width: 56px !important;
					height: 32px;
					border-radius: 20px;
					.el-switch__action {
						top: 3px;

						width: 24px;
						height: 24px;
					}
				}
			}
		}
	}

	.error {
		color: $red;
		font-size: $font-s;
		line-height: $font-xl;
		display: block;

		@supports (-webkit-touch-callout: none) {
			/* CSS specific to iOS devices */
			letter-spacing: -1px;
		}
	}

	.policy__body {
		text-align: left;

		p {
			text-indent: 10px;
			text-align: justify;

			margin-top: 5%;
			margin-bottom: 10px;
			// font-family: $font-family-ibm;
			// @include font-size(20);
			@include font-size(30);
			// color: $darkGray;
			color: $black;
			text-align: left;

			a {
				color: $link-bule;

				&:hover,
				&:visited,
				&:active {
					color: $link-bule;
				}
			}
		}
		h1 {
			@include font-size(40);
		}
		ul {
			padding: 0;
			margin: 0;
			list-style-position: inside;
			li {
				list-style: none;
				@include font-size(30);
				div {
					// padding-left: 10px;
					text-indent: 10px;
				}
			}
		}
	}
}
</style>