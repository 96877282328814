// import
import PDPATitleComponent from "./PDPATitleComponent.vue";
import PDPAServiceComponent from "./PDPAServiceComponent.vue";
import PDPAPolicyComponent from "./PDPAPolicyComponent.vue";
import PDPASeparateLineComponent from "./PDPASeparateLineComponent.vue";
import PDPAModalCondComponent from "./PDPAModalCondComponent.vue";
import { BaseModalComponent } from "@/components";

export default {
  name: "PDPAComponent",
  props: {
    titleName: String,
  },
  components: {
    PDPATitleComponent,
    PDPAServiceComponent,
    PDPAPolicyComponent,
    PDPASeparateLineComponent,
    PDPAModalCondComponent,
    BaseModalComponent,
  },
  data() {
    return {
      title: this.titleName,
      modalShow: false,
      modalStatus: false,
      modalValue: 0,
      modalType: null,
      modals: {},
      modalState: [
        {
          title: "Terms of Service",
          detail: `
            <p>
              It is a long established fact that a reader will be distracted by the
              readable content of a page when looking at its layout. The point of
              using Lorem Ipsum.
            </p>
            <p>
              Various versions have evolved over the years, sometimes by accident,
              sometimes on purpose (injected humour and the like).
            </p>
            <p>
              Contrary to popular belief, Lorem Ipsum is not simply random text. It
              has roots in a piece of classical Latin literature from 45 BC, making it
              over 2000 years old. Richard McClintock, a Latin professor at
              Hampden-Sydney College in Virginia, looked up one of the more obscure
              Latin words, consectetur, from a Lorem Ipsum passage, and going through
              the cites of the word in classical literature, discovered the
              undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33
              of “de Finibus Bonorum et Malorum” (The Extremes of Good and Evil) by
              Cicero, written in 45 BC. This book is a treatise on the theory of
              ethics, very popular during the Renaissance. The first line of Lorem
              Ipsum, “Lorem ipsum dolor sit amet..”, comes from a line in section
              1.10.32.
            </p>
            <p>
              The standard chunk of Lorem Ipsum used since the 1500s is reproduced
              below for those interested. Sections 1.10.32 and 1.10.33 from “de
              Finibus Bonorum et Malorum” by Cicero are also reproduced in their exact
              original form, accompanied by English versions from the 1914 translation
              by H. Rackham.
            </p>
      
            <p>
              It is a long established fact that a reader will be distracted by the
              readable content of a page when looking at its layout. The point of
              using Lorem Ipsum.
            </p>
            <p>
              Various versions have evolved over the years, sometimes by accident,
              sometimes on purpose (injected humour and the like).
            </p>
            <p>
              Contrary to popular belief, Lorem Ipsum is not simply random text. It
              has roots in a piece of classical Latin literature from 45 BC, making it
              over 2000 years old. Richard McClintock, a Latin professor at
              Hampden-Sydney College in Virginia, looked up one of the more obscure
              Latin words, consectetur, from a Lorem Ipsum passage, and going through
              the cites of the word in classical literature, discovered the
              undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33
              of “de Finibus Bonorum et Malorum” (The Extremes of Good and Evil) by
              Cicero, written in 45 BC. This book is a treatise on the theory of
              ethics, very popular during the Renaissance. The first line of Lorem
              Ipsum, “Lorem ipsum dolor sit amet..”, comes from a line in section
              1.10.32.
            </p>
            <p>
              The standard chunk of Lorem Ipsum used since the 1500s is reproduced
              below for those interested. Sections 1.10.32 and 1.10.33 from “de
              Finibus Bonorum et Malorum” by Cicero are also reproduced in their exact
              original form, accompanied by English versions from the 1914 translation
              by H. Rackham.
            </p>
          `,
        },
        {
          title: "Privacy Policy",
          detail: `
            <p>
              It is a long established fact that a reader will be distracted by the
              readable content of a page when looking at its layout. The point of
              using Lorem Ipsum.
            </p>
            <p>
              Various versions have evolved over the years, sometimes by accident,
              sometimes on purpose (injected humour and the like).
            </p>
            <p>
              Contrary to popular belief, Lorem Ipsum is not simply random text. It
              has roots in a piece of classical Latin literature from 45 BC, making it
              over 2000 years old. Richard McClintock, a Latin professor at
              Hampden-Sydney College in Virginia, looked up one of the more obscure
              Latin words, consectetur, from a Lorem Ipsum passage, and going through
              the cites of the word in classical literature, discovered the
              undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33
              of “de Finibus Bonorum et Malorum” (The Extremes of Good and Evil) by
              Cicero, written in 45 BC. This book is a treatise on the theory of
              ethics, very popular during the Renaissance. The first line of Lorem
              Ipsum, “Lorem ipsum dolor sit amet..”, comes from a line in section
              1.10.32.
            </p>
            <p>
              The standard chunk of Lorem Ipsum used since the 1500s is reproduced
              below for those interested. Sections 1.10.32 and 1.10.33 from “de
              Finibus Bonorum et Malorum” by Cicero are also reproduced in their exact
              original form, accompanied by English versions from the 1914 translation
              by H. Rackham.
            </p>
  
            <p>
              It is a long established fact that a reader will be distracted by the
              readable content of a page when looking at its layout. The point of
              using Lorem Ipsum.
            </p>
            <p>
              Various versions have evolved over the years, sometimes by accident,
              sometimes on purpose (injected humour and the like).
            </p>
            <p>
              Contrary to popular belief, Lorem Ipsum is not simply random text. It
              has roots in a piece of classical Latin literature from 45 BC, making it
              over 2000 years old. Richard McClintock, a Latin professor at
              Hampden-Sydney College in Virginia, looked up one of the more obscure
              Latin words, consectetur, from a Lorem Ipsum passage, and going through
              the cites of the word in classical literature, discovered the
              undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33
              of “de Finibus Bonorum et Malorum” (The Extremes of Good and Evil) by
              Cicero, written in 45 BC. This book is a treatise on the theory of
              ethics, very popular during the Renaissance. The first line of Lorem
              Ipsum, “Lorem ipsum dolor sit amet..”, comes from a line in section
              1.10.32.
            </p>
            <p>
              The standard chunk of Lorem Ipsum used since the 1500s is reproduced
              below for those interested. Sections 1.10.32 and 1.10.33 from “de
              Finibus Bonorum et Malorum” by Cicero are also reproduced in their exact
              original form, accompanied by English versions from the 1914 translation
              by H. Rackham.
            </p>
          `,
        },
      ],
    };
  },
  inject: ["trackEvent"],
  methods: {
    handleModalCond(value) {
      this.modals = this.modalState[0];
      this.modalShow = value;
    },
    handleModalPolicy(value) {
      this.modals = this.modalState[1];
      this.modalShow = value;
    },
    setAllowBox(value) {
      if (value.type === "accept") {
        var statusTOS = ["not_accept", "accept"];
        var allowbox1 = {
          allowbox1: "terms",
          allowboxstatus1: statusTOS[value.accept],
        };
        if (value.accept === 0) {
          if (this.$refs.pdpaTerms) {
            this.$refs.pdpaTerms.reset();
          }
        } else {
          this.$emit("check:tos", true);
          this.$store.commit("profile/setTosConsent", allowbox1);
          localStorage.setItem("TosConsent", JSON.stringify(allowbox1));
          this.trackEvent("PDPA - T&C", {
            event_category: "PDPA",
            event_label: "Terms and Conditions",
            value: "เงื่อนไขและข้อกำหนด",
          });
        }
      }
      if (value.type === "allow") {
        var statusPolicy = [
          "do_not_allow",
          "allow_once",
          "allow_while_using_app",
        ];
        var allowbox2 = {
          allowbox2: "policy",
          allowboxstatus2: statusPolicy[value.accept],
        };

        if (value.accept === 0) {
          this.$emit("check:policy", false);
          if (this.$refs.pdpaPolicy) {
            this.$refs.pdpaPolicy.reset();
          }
        } else {
          this.$emit("check:policy", true);
          this.$store.commit("profile/setPolicyConsent", allowbox2);
          localStorage.setItem("PolicyConsent", JSON.stringify(allowbox2));
          this.trackEvent("PDPA - Privacy", {
            event_category: "PDPA",
            event_label: "Privacy Policy",
            value: "ประกาศว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล",
          });
        }
      }
    },
    closedModal() {
      if (this.modalStatus === true) {
        this.modalStatus = false;
        this.setAllowBox({
          type: this.modalType,
          accept: 0,
        });
      }
    },
    handleModal(value) {
      this.setAllowBox(value);
      // this.modalType = value.type;
      // this.modalValue = parseInt(value.accept);
      // this.modalStatus = Boolean(value.show);
    },
    handleModalPolicyAccept(value) {
      this.modalType = "completed";
      this.modalValue = parseInt(value.accept);
      this.modalStatus = Boolean(value.show);
    },
    handleModalAllowUsge(value) {
      this.modalType = "allow";
      this.modalValue = parseInt(value.accept);
      this.modalStatus = Boolean(value.show);
    },
  },
};
