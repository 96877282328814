import * as dayjs from "dayjs";
import { Icon } from "@/components";
import $ from "jquery";

export default {
  name: "PointhistoryFilterComponent",
  components: {
    Icon,
  },
  props: {
    FilterMonth: {
      type: String,
      required: true,
    },
  },
  mounted() {
    // console.log(this.FilterMonth)
    if (this.FilterMonth2 == null) {
      this.FilterMonth2 = this.FilterMonth;
    }
  },
  data() {
    return {
      FilterMonth2: null,
      // pickerOptions:{
      //     disabledDate(time) {
      //         return time.getTime() > Date.now() ;
      //     }

      // }
    };
  },
  methods: {
    // LoadBalance(){
    //     let NewBalance =  Math.floor( Math.random() * (1000000 - 1) + 1 );
    //     this.PointBalance = NewBalance;

    // }
    clearCalendar() {
      this.logger("clearCalendar");
      this.FilterMonth2 = new Date();
      let NewElm = dayjs(this.FilterMonth2).format("YYYY-MM");
      this.$emit("UpdateFilter", NewElm);
      if ($(".arrow__icon").hasClass("active"))
        $(".arrow__icon").removeClass("active");
    },
    toggleCalendar() {
      this.logger("toggleCalendar");

      $(".arrow__icon").toggleClass("active");
      if ($(".arrow__icon").hasClass("active")) {
        this.logger("open");
        //   const calendar = $(".el-input__inner");
        document.querySelector(".el-input__inner").focus();
        // calendar.click();
      } else {
        this.logger("close");
        // $(".arrow__icon").addClass("active");
        // document.querySelector(".el-input__inner").focus();
        // document.querySelector(".el-input__inner").blur();
        // this.clearCalendar();
        this.focusCalendar();
      }
    },
    UpdateFilter(elm) {
      this.logger("UpdateFilter");
      let NewElm = dayjs(elm).format("YYYY-MM");
      this.$emit("UpdateFilter", NewElm);
      $(".arrow__icon").toggleClass("active");
    },
    focusCalendar(elm) {
      const iOS = /^(iPhone|iPad|iPod)/.test(navigator.platform);
      if (!iOS) $(".el-input__inner").prop("readonly", true);
      else
        $(".el-input__inner")
          .prop("readonly", true)
          .attr("inputmode", "none");

      // event.preventDefault()
      // this.UpdateFilter(elm)
      // this.toggleCalendar();
      // $(".arrow__icon").toggleClass("active");
    },
  },
};
