import request from '@/utils/request'

export const billService = {
    apiCreateBillData,
    apiBillData,
    apiEBillData,
    handleError
}

async function apiCreateBillData(requestData) {
    const data = requestData;
    return await request({
        url: 'receipt/send',
        method: 'post',
        data
    })
}

async function apiBillData(requestData) {
    const data = requestData;
    return await request({
        url: '/cropping',
        method: 'post',
        data
    })
}

async function apiEBillData(requestData) {
    const data = requestData;
    return await request({
        url: '/cropping',
        method: 'post',
        data
    })
}

function handleError(error) {
    throw error;
}