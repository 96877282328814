import {
  BaseTitleComponent,
  BaseInputPasswordComponent,
  BaseErrorMsgComponent,
  BaseButtonComponent,
  BaseModalComponent,
} from "@/components";

import { ref, reactive, provide, onMounted } from "vue";
import { useRouter } from "vue-router";
import store from "@/store";
import { RequestResetPassword } from "@/api/profile.js";

export default {
  name: "ResetPass",
  components: {
    BaseTitleComponent,
    BaseInputPasswordComponent,
    BaseErrorMsgComponent,
    BaseButtonComponent,
    BaseModalComponent,
  },
  data() {
    return {
      modalShow: false,
      modalStatus: false,
      modalValue: 0,
      modalType: null,
      inputPassword: {
        name: "password",
        value: "",
        type: "password",
        placeholder: "รหัสผ่านใหม่",
        maxlength: 50,
      },
      inputRePassword: {
        name: "password",
        value: "",
        type: "password",
        placeholder: "กรอกรหัสผ่านอีกครั้ง",
        maxlength: 50,
      },
      buttonData: {
        name: "รีเซ็ตรหัสผ่าน",
        type: "submit",
        active: false,
      },
      titleName: "รีเซ็ตรหัสผ่าน",
      // errorMsg: '* คุณกรอกรหัสผ่านผิดพลาดหรือไม่ครบถ้วน \n* รหัสผ่านไม่เหมือนกัน'
      errorMsg: {
        Password: "",
        RePassword: "",
      },
      PasswordCheck: null,
    };
  },
  watch: {
    inputPassword: {
      deep: true,
      handler(Password) {
        if (Password.value != "") {
          this.buttonData.active = true;
        } else {
          this.buttonData.active = false;
        }
        this.errorMsg.Password = "";
        this.StrengthChecker();
      },
    },
    inputRePassword: {
      deep: true,
      handler(Password) {
        if (
          Password.value != "" &&
          Password.value === this.inputPassword.value
        ) {
          this.errorMsg.RePassword = "";
          this.buttonData.active = true;
        } else {
          this.errorMsg.Password = "* คุณกรอกรหัสผ่านผิดพลาดหรือไม่ครบถ้วน";
          this.errorMsg.RePassword = "* รหัสผ่านไม่ตรงกัน";

          this.buttonData.active = false;
        }
      },
    },
  },

  setup() {
    const router = useRouter();
    provide("store", store);
    const state = reactive({
      profile: {
        exchangecode: ref(""),
        validatekey: ref(""),
        phonenumber: ref(""),
      },
    });
    const OTP = store.getters["profile/getRefcode"];
    const ExchangeCode = store.getters["profile/getExchangeCode"];
    const Validatekey = store.getters["profile/getValidatekey"];

    onMounted(() => {
      if (!OTP || !ExchangeCode || !Validatekey) {
        router.push("ForgotPass");
      } else {
        state.profile.phonenumber = OTP.mobile;
        state.profile.exchangecode = ExchangeCode;
        state.profile.validatekey = Validatekey;
      }
    });

    return {
      state,
      OTP,
    };
  },
  methods: {
    StrengthChecker() {
      let password = this.inputPassword.value;
      if (password.length == 0) {
        this.PasswordCheck = "";
      } else if (password.length <= 6) {
        this.PasswordCheck = "รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร";
      } else {
        var rating = [
          0,
          "Weak",
          25,
          "Fair",
          50,
          "Medium",
          75,
          "Strong",
          100,
          "Very Strong",
        ];
        var score = 0;
        var pass = "";

        if (password.length > 8) {
          score += 25;
        }
        if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
          score += 25;
        }
        /* eslint-disable-next-line */
        if (password.match(/[\!\@\#\$\%\^\&\*\?\_\~\-\(\)]+/)) {
          score += 25;
        }
        if (password.match(/[0-9]/)) {
          score += 25;
        }

        for (var i = rating.length - 1; i >= 0; i -= 1) {
          if (score >= rating[i]) {
            pass = rating[i + 1];
            break;
          }
        }
        this.PasswordCheck = `Your Password is ${pass}`;
      }
    },
    FormValid() {
      let FormCheck = true;
      if (this.inputPassword.value !== this.inputRePassword.value) {
        FormCheck = false;
      }
      return FormCheck;
    },
    SubmitForm() {
      let FormValid = this.FormValid();
      if (FormValid === true) {
        this.RequestResetPassword();
      } else {
        this.$store.commit("BUTTON_LOADING", false);
      }
    },
    async RequestResetPassword() {
      this.$store.commit("BUTTON_LOADING", true);
      let RequestData = {
        exchangecode: this.state.profile.exchangecode,
        validatekey: this.state.profile.validatekey,
        mobile: this.state.profile.phonenumber,
        password: this.inputPassword.value,
      };
      // console.log(RequestData)
      let RequestResp = await RequestResetPassword(RequestData);
      this.$store.commit("BUTTON_LOADING", false);
      if (RequestResp.result == "complete") {
        this.ShowModalComplete();
      } else {
        this.$message(RequestResp.resultdetail);
      }
    },
    ShowModalComplete() {
      this.modalType = "reset";
      this.modalStatus = true;
    },
    handleModal(value) {
      this.$router.push("/Login");
    },
  },
};
