<style src="./PointStatusLayout.scss" lang="scss" scoped></style>
<script src="./PointStatusLayout.js"></script>

<template>
	<div class="landing__layout">
		<PointBalanceComponent/>
		<PointExpireComponent   :PointExpires="PointExpires"/>
		<!-- <PointStatusBarComponent /> -->
		<RouterView :key="$route.fullPath" />
		<!-- <TheFooter /> -->
	</div>
</template>