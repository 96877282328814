<style src="./BaseInputPasswordComponent.scss" lang="scss"></style>
<script src="./BaseInputPasswordComponent.js"></script>

<template>
	<div
		class="boxpassword__input"
		v-if="inputPattern"
		:class="[
			{
				weak: PasswordScore >= 25 && PasswordScore < 50,
				fair: PasswordScore >= 50 && PasswordScore < 75,
				medium: PasswordScore >= 75 && PasswordScore < 100,
				strong: PasswordScore >= 100 && PasswordScore < 125,
				very__strong: PasswordScore >= 125,
			},
		]"
	>
		<el-input
			class="password__input"
			:type="inputPassword.type"
			:placeholder="inputPassword.placeholder"
			:maxlength="inputPassword.maxlength"
			v-model.trim="inputPassword.value"
			show-password
			@keyup="StrengthChecker"
			@keypress="StrengthChecker"
			@paste.prevent
		>
		</el-input>
	</div>
	<div class="boxpassword__input" v-else>
		<el-input
			class="password__input"
			:type="inputPassword.type"
			:placeholder="inputPassword.placeholder"
			:maxlength="inputPassword.maxlength"
			v-model="inputPassword.value"
			show-password
			@keypress="isAlphaENNumber($event)"
			@paste.prevent
		>
		</el-input>
	</div>
</template>