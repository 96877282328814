import {
	BaseTitleComponent,
	LifestyleComponent,
	BaseButtonComponent,
} from '@/components';

export default {
	name: 'Lifestyle',
	components: {
		BaseTitleComponent,
		LifestyleComponent,
		BaseButtonComponent
	},
	data() {
		return {
			profile: {
				title: 'เลือกความสนใจ และ<br/>ไลฟ์สไตล์ของคุณ',
			},
			buttonData: {
				name: 'ถัดไป',
				type: 'submit'
			},
			itemsLifestyle: [
				{
					id: 1,
					image: require('@/assets/images/account/interest1.jpg'),
					name: 'interest1'
				},
				{
					id: 2,
					image: require('@/assets/images/account/interest2.jpg'),
					name: 'interest2'
				},
				{
					id: 3,
					image: require('@/assets/images/account/interest3.jpg'),
					name: 'interest3'
				},
				{
					id: 4,
					image: require('@/assets/images/account/interest4.jpg'),
					name: 'interest4'
				},
				{
					id: 5,
					image: require('@/assets/images/account/interest5.jpg'),
					name: 'interest5'
				},
				{
					id: 6,
					image: require('@/assets/images/account/interest6.jpg'),
					name: 'interest6'
				},
				{
					id: 7,
					image: require('@/assets/images/account/interest7.jpg'),
					name: 'interest7'
				},
				{
					id: 8,
					image: require('@/assets/images/account/interest8.jpg'),
					name: 'interest8'
				},
				{
					id: 9,
					image: require('@/assets/images/account/interest9.jpg'),
					name: 'interest9'
				},
				{
					id: 10,
					image: require('@/assets/images/account/interest10.jpg'),
					name: 'interest10'
				},
				{
					id: 11,
					image: require('@/assets/images/account/interest11.jpg'),
					name: 'interest11'
				},
				{
					id: 12,
					image: require('@/assets/images/account/interest12.jpg'),
					name: 'interest12'
				},
				{
					id: 13,
					image: require('@/assets/images/account/interest13.jpg'),
					name: 'interest13'
				},
				{
					id: 14,
					image: require('@/assets/images/account/interest14.jpg'),
					name: 'interest14'
				},
				{
					id: 15,
					image: require('@/assets/images/account/interest15.jpg'),
					name: 'interest15'
				},
				{
					id: 16,
					image: require('@/assets/images/account/interest16.jpg'),
					name: 'interest16'
				},
				{
					id: 17,
					image: require('@/assets/images/account/interest17.jpg'),
					name: 'interest17'
				},
				{
					id: 18,
					image: require('@/assets/images/account/interest18.jpg'),
					name: 'interest18'
				},
				{
					id: 19,
					image: require('@/assets/images/account/interest19.jpg'),
					name: 'interest19'
				},
				{
					id: 20,
					image: require('@/assets/images/account/interest20.jpg'),
					name: 'interest20'
				},
				{
					id: 21,
					image: require('@/assets/images/account/interest21.jpg'),
					name: 'interest21'
				},
				{
					id: 22,
					image: require('@/assets/images/account/interest22.jpg'),
					name: 'interest22'
				},
				{
					id: 23,
					image: require('@/assets/images/account/interest23.jpg'),
					name: 'interest23'
				},
				{
					id: 24,
					image: require('@/assets/images/account/interest24.jpg'),
					name: 'interest24'
				}
			]
		}
	},
	setup() {
		//
	},
	methods: {

	}
};