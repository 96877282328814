<style src="./BaseButtonComponent.scss" lang="scss"></style>
<script src="./BaseButtonComponent.js"></script>

<template>
	<div class="box__button" :class="{ custom__links: buttonPosition }">
		<el-button
			:class="{
				button__next: buttonData.type,
				button__active: buttonData.active,
			}"
			:loading="getButtonLoading"
			:disabled="buttonData.active ? false : true"
			@click="handleButtonNext"
		>
			{{ buttonData.name }}
		</el-button>
	</div>
</template>