import {
  BaseTitleComponent,
  BaseCarouselComponent,
  BaseButtonSimpleComponent,
} from "@/components";

import { onMounted, inject, defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "Home",
  components: {
    BaseTitleComponent,
    BaseCarouselComponent,
    BaseButtonSimpleComponent,
  },
  data() {
    return {
      titleName: "เริ่มต้นใช้งาน",
      //   subtitle: `โดยการเปิดบัญชี ท่านรับทราบและตกลงตาม<br/><a href="#">เงื่อนไขการให้บริการ</a>  & <a href="#">นโยบายความเป็นส่วนตัว</a>`,
      logoBrand: require(`@/assets/images/Roxketeer_X_shop_chana.png`),
      buttonLogin: {
        title: "เข้าสู่ระบบ",
        active: false,
        link: "Login",
      },
      buttonSignup: {
        title: "ลงทะเบียน",
        active: true,
        link: "PDPA",
      },
    };
  },
  mounted() {
    localStorage.removeItem("TosConsent");
    localStorage.removeItem("PolicyConsent");
    localStorage.removeItem("linkingAccount");
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const logger = inject("logger");
    const trackEventButton = inject("trackEvent");

    onMounted(() => {
      if (route.query.linetoken) {
        localStorage.setItem("CrpLinetoken", route.query.linetoken);
        // console.log(route.query);
        router.push({
          name: "Gateway",
          query: {
            utm_source: "adapterroxline",
            utm_medium: "liff-browser-forward",
            utm_campaign: "roxlaunch2021nov",
          },
        });
      }
    });
  },
  inject: ["trackEvent"],
  methods: {
    buttonClick(data) {
      // console.log({ data });
      data.link == "PDPA"
        ? this.trackEvent("Gateway - Register", {
            event_category: "Register",
            event_label: data.link,
            value: "ลงทะเบียน",
          })
        : this.trackEvent("Gateway - Login", {
            event_category: "Login",
            event_label: data.link,
            value: "เข้าสู่ระบบ",
          });
      this.$router.push({ name: data.link });
    },
  },
});
