import { Icon } from "@/components";
import { inject, watch, ref } from "vue";
import $ from "jquery";

export default {
  name: "UserProfileComponent",
  components: {
    Icon,
  },
  props: ["configs", "editableStatus"],
  data() {
    return {
      type: null,
      profile: this.configs,
      updateDob: "",
    };
  },
  created() {
    this.type = parseInt(this.$route.params.type);
    // console.log(this.$route.params);
    if (this.type !== undefined) {
      // this.getRewardDetail()
    }
  },
  computed: {
    typeUpdate: function() {
      return this.$route.params.type;
    },
  },
  setup() {
    const v$ = inject("v$");
    // console.log("inject:", v$);
    const calendar = ref(null);

    const disabledDate = (time) => {
      return time.getTime() > Date.now();
    };

    watch(
      () => v$,
      (newValue) => {
        if (!newValue) return;
      }
    );

    function validateNumber(event) {
      let keyCode = event.keyCode;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    }

    function focusCalendar(event) {
      const iOS = /^(iPhone|iPad|iPod)/.test(navigator.platform);
      if (!iOS) $(".el-input__inner").prop("readonly", true);
      else $(".el-input__inner").attr("inputmode", "none");

      // event.preventDefault()
    }

    return {
      v$,
      calendar,
      focusCalendar,
      validateNumber,
      disabledDate,
    };
  },
  methods: {
    updatePhonenumber() {
      this.$emit("updatePhonenumber");
    },
  },
};
