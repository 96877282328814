<style src="./Apply.scss" lang="scss" scoped></style>
<script src="./Apply.js"></script>

<template>
	<div class="content__view">
		<div class="content__view__inner">
			<section v-if="!modalStatus">
				<BaseTitleComponent :title-name="titleName" />
				<div class="gaping"></div>
				<BaseInputMobileComponent
					:input-mobile="inputMobile"
					v-model.trim="inputMobile.value"
					class="mb__5"
				/>
				<BaseInputPasswordComponent
					:input-pattern="true"
					:input-password="inputPassword"
					v-model.trim="inputPassword.value"
					@updated:password="handlePasswordOk"
				/>

				<div
					class="password_status"
					v-if="PasswordCheck"
					v-html="PasswordCheck"
				/>

				<BaseErrorMsgComponent
					v-if="errorMsg.Mobile"
					:error-msg="errorMsg.Mobile"
				/>
				<BaseErrorMsgComponent
					v-if="errorMsg.Password"
					:error-msg="errorMsg.Password"
				/>
				<BaseButtonComponent
					:button-data="buttonData"
					:button-position="true"
					@HandleButton="SubmitForm"
				/>
				<BaseLinksComponent :button-position="true" />
				<BaseLinksContactComponent />
			</section>
			<BaseModalComponent
				:modal-show="modalStatus"
				:modal-type="modalType"
				@update:modal="GotoLogin"
			/>
		</div>
	</div>
</template>