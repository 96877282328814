<style src="./PointhistoryListComponent.scss" lang="scss" scoped ></style>
<script src="./PointhistoryListComponent.js"></script>

<template>
	<div class="point-history-lists">
		<div
			v-for="PointItem in PointLists"
			class="point-history-date"
			:key="PointItem.date"
		>
			<div class="item-date">
				<span class="item-date-day">{{ GetDate(PointItem.date) }}</span
				><br />
				<span class="item-date-month">{{ GetMonth(PointItem.date) }}</span>
				<div class="vertical-line" v-if="PointItem.data.length >= 2">
					<span class="dot"></span>
				</div>
			</div>
			<div class="item-lists">
				<div
					v-for="BillItem in PointItem.data"
					class="item-bill"
					:key="BillItem.receiptid"
				>
					<div
						class="bill-shop"
						v-if="BillItem.shoptype.toLowerCase() !== 'other'"
					>
						<IconShop :name="BillItem.shoptype.toLowerCase()" />
					</div>
					<div
						class="bill-point"
						v-if="BillItem.shoptype.toLowerCase() !== 'other'"
					>
						{{ BillItem.totalprivilege.toLocaleString() }}<br />
						<span class="bill-point-suffix">พอยท์</span>
					</div>
					<div
						class="bill-icon-circle"
						v-if="BillItem.shoptype.toLowerCase() !== 'other'"
					>
						<router-link to="" @click="handleOpenLink(BillItem.receiptid)">
							<Icon
								:name="`circle-status-${BillItem.contentstatus.toLowerCase()}`"
								class="icon-circle"
							/>
						</router-link>
					</div>
					<div
						class="bill-icon-sheet"
						v-if="BillItem.shoptype.toLowerCase() !== 'other'"
					>
						<router-link to="" @click="handleOpenLink(BillItem.receiptid)">
							<Icon
								:name="`sheet-status-${BillItem.contentstatus.toLowerCase()}`"
								class="icon-sheet"
							/>
						</router-link>
					</div>
					<div
						class="bill-icon-view"
						v-if="BillItem.shoptype.toLowerCase() !== 'other'"
					>
						<router-link
							to=""
							@click="handleOpenLink(BillItem.receiptid)"
							v-if="BillItem.contentstatus.toLowerCase() == 'rejected'"
						>
							<Icon name="view-status-reject" class="view-status-reject" />
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>