<style src="./BaseAddressFormComponent.scss" lang="scss"></style>
<script src="./BaseAddressFormComponent.js"></script>

<template>
	<div class="box__address__form">
		<div class="address__input">
			<span for="type">ติดป้ายเป็น</span>
			<span class="input__error" v-if="address.error.type">*ข้อมูลไม่ถูกต้อง</span>
		</div>
		<div class="radio__justify">
			<div class="address__radio">
				<label>
					<input
						name="type"
						type="radio"
						value="home"
						v-model="address.data.type"
						required
					/>
					<span class="radio"></span>
					<span class="label">บ้าน</span>
				</label>
			</div>
			<div class="address__radio">
				<label>
					<input
						name="type"
						type="radio"
						value="office"
						v-model="address.data.type"
						required
					/>
					<span class="radio"></span>
					<span class="label">ที่ทำงาน</span>
				</label>
			</div>
		</div>
		<div class="address__input">
			<label for="name">ชื่อ - นามสกุล</label>
			<span class="input__error" v-if="address.error.name">*ข้อมูลไม่ถูกต้อง</span>
			<input type="text" id="name" v-model="address.data.name" placeholder="ใส่ชื่อ - นามสกุล"/>
		</div>
		<div class="address__input">
			<label for="phonenumber">เบอร์โทรศัพท์</label>
			<span class="input__error" v-if="address.error.phonenumber">*ข้อมูลไม่ถูกต้อง</span>
			<input type="text" id="phonenumber" v-model="address.data.phonenumber" placeholder="ใส่เบอร์โทรศัพท์"/>
		</div>
		<div class="address__input">
			<label for="address">ที่อยู่</label>
			<span class="input__error" v-if="address.error.address">*ข้อมูลไม่ถูกต้อง</span>
			<input type="text" id="address" v-model="address.data.address" placeholder="ใส่ที่อยู่"/>
		</div>
		<div class="address__input">
			<label for="district">แขวง/ตำบล</label>
			<span class="input__error" v-if="address.error.district">*ข้อมูลไม่ถูกต้อง</span>
			<BaseAddressInputComponent
				v-model="address.data.district"
				:value="address.data.district"
				type="district"
				@select="select"
				placeholder="ใส่แขวง/ตำบล"/>
		</div>
		<div class="address__input">
			<label for="amphoe">เขต/อำเภอ</label>
			<span class="input__error" v-if="address.error.amphoe">*ข้อมูลไม่ถูกต้อง</span>
			<BaseAddressInputComponent
				v-model="address.data.amphoe"
				:value="address.data.amphoe"
				type="amphoe"
				@select="select"
				placeholder="ใส่เขต/อำเภอ"/>
		</div>
		<div class="address__input">
			<label for="province">จังหวัด</label>
			<span class="input__error" v-if="address.error.province">*ข้อมูลไม่ถูกต้อง</span>
			<BaseAddressInputComponent
				v-model="address.data.province"
				:value="address.data.province"
				type="province"
				@select="select"
				placeholder="ใส่จังหวัด"/>
		</div>
		<div class="address__input">
			<label for="zipcode">รหัสไปรษณีย์</label>
			<span class="input__error" v-if="address.error.zipcode">*ข้อมูลไม่ถูกต้อง</span>
			<BaseAddressInputComponent
				v-model="address.data.zipcode"
				:value="address.data.zipcode"
				type="zipcode"
				@select="select"
				placeholder="ใส่รหัสไปรษณีย์"/>
		</div>
	</div>
</template>