import { inject } from "vue";
import { useRouter } from "vue-router";
import { BaseAvatarComponent, Icon } from "@/components";

export default {
  name: "ShortProfile",
  components: {
    BaseAvatarComponent,
    Icon,
  },
  props: ["info"],
  data() {
    return {
      profile: {
        data: {
          welcomeWord: () => {
            const currentTime = new Date().getHours();
            currentTime.toLocaleString("th-TH", { timeZone: "UTC" });
            // console.log(currentTime);
            let greetingText = "";

            if (currentTime < 12) {
              greetingText = `สวัสดีตอนเช้า`;
            } else if (currentTime < 18) {
              greetingText = `สวัสดีตอนกลางวัน`;
            } else {
              greetingText = `สวัสดีตอนเย็น`;
            }
            return greetingText;
          },
          // name: 'สมบูรณ์พร้อม',
          image: `${this.info.profilepic}?v_${Math.random()}`,
        },
        edit: {
          image: "edit_profile",
          name: "แก้ไขข้อมูล",
        },
      },
    };
  },
  setup() {
    const router = useRouter();
    const trackEvent = inject("trackEvent");

    function handleClick() {
      trackEvent(`Home - Edit Profile`, {
        event_category: "Home",
        event_label: "Edit Profile",
        value: "แก้ไขข้อมูล",
      });

      router.push({
        path: "/Account/edit",
      });
    }

    return {
      handleClick,
    };
  },
};
