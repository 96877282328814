<style src="./BaseListTitleComponent.scss" lang="scss"></style>
<script src="./BaseListTitleComponent.js"></script>

<template>
	<div class="box__list__title">
		<div class="list__title">{{title}}</div>
        <div class="list__link">
            <router-link :to="link">ดูทั้งหมด <i class="el-icon-arrow-right"></i></router-link>
        </div>
	</div>
</template>