<style src="./BaseButtonLegendComponent.scss" lang="scss"></style>
<script src="./BaseButtonLegendComponent.js"></script>

<template>
	<div class="box__button__legend">
		<fieldset
			class="action__block"
			:class="{ disabled: buttonData.limit === 0 }"
			@click.prevent="buttonData.limit > 0 && buttonClick(buttonData)"
		>
			<span class="action__block--label">{{ buttonData.title }}</span>
			<legend v-if="buttonData.legend">
				{{ buttonData.limit.toLocaleString() }}
			</legend>
		</fieldset>
	</div>
</template>