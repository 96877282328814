import {
  BaseProfileHeaderComponent,
  BaseBackButtonComponent,
  BaseButtonSimpleComponent,
  BaseShippingAddressComponent,
  BaseModalComponent
} from '@/components';

import _ from 'lodash';
import { RequestProfileAddressList } from '@/api/profile';

export default {
  name: 'PrivilegePoints',
  components: {
    BaseProfileHeaderComponent,
    BaseBackButtonComponent,
    BaseButtonSimpleComponent,
    BaseShippingAddressComponent,
    BaseModalComponent
  },
  data() {
    return {
      token: this.$store.getters['profile/token'],
      type: null,
      id: null,
      profile: null,
      points: 0,
      reward: null,
      maxPrivilege: 0,
      primaryAddress: null,
      buttonData: {
        title: 'ยืนยันการใช้สิทธิ์',
        disabled: true,
        class: 'fixed',
        active: true
      },
      modalStatus: false,
      modalValue: 0,
      modalType: null,
      redeemPointMessage: ''
    }
  },
  created() {

    this.type = this.$route.params.type
    this.id = this.$route.params.id
    this.points = parseInt(this.$store.getters['reward/PRIVILEGE'])

    if (Number.isNaN(this.points)) {
      this.$router.back();
    }

  },
  watch: {
    points: {
      handler(val) {
        this.buttonData.disabled = val > 0 ? false : true;
      },
      deep: true
    },
  },
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 9) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async getRewardItemฺById(itemid) {
      const data = await this.$store.dispatch(
        "reward/UPDATE_REWARD_ITEM_BY_ID",
        itemid
      );
    },
    async getLuckydrawItemById(itemid) {
      await this.$store.dispatch(
        "reward/UPDATE_LUCKYDRAW_ITEM_BY_ID",
        itemid
      )
    },
    async GetProfileAddressList() {
      let RequestData = {
        "token": this.token
      }
      let RequestResp = await RequestProfileAddressList(RequestData)
      if (RequestResp.result == 'complete') {
        var addresses = _.filter(RequestResp.list, address => address.default === true);
        addresses.forEach((address) => {
          this.primaryAddress = address
        })
      }
    },
    buttonClick(data) {
      this.redeemPointMessage = `ยืนยันการใช้สิทธิ์ทั้งหมด<span>${this.points} สิทธิ์</span>`
      this.modalType = 'redeem';
      this.modalStatus = true;
    },
    calculateMaxPrivilege() {
      if (this.type == "redemptions") {
        this.maxPrivilege = parseInt(this.reward.usermaxredeem)
      }
      else {
        this.maxPrivilege = ~~(this.profile.pointbalance / this.reward.points)
      }

      if (this.points > this.maxPrivilege) {
        this.points = this.maxPrivilege
      }
    },
    async getProfileData(data) {
      this.profile = data

      if (this.type == "luckydraws") {
        await this.getLuckydrawItemById(this.id).then(() => {
          this.reward = this.$store.getters['reward/UPDATE_LUCKYDRAW_ITEM_BY_ID']
        });
        this.calculateMaxPrivilege()
      } else if (this.type == "redemptions") {
        await this.getRewardItemฺById(this.id).then(() => {
          this.reward = this.$store.getters['reward/UPDATE_REWARD_ITEM_BY_ID']
        });
        this.calculateMaxPrivilege()
        this.GetProfileAddressList()
      }
    },
    privilegeChange() {
      if (parseInt(this.points) > parseInt(this.maxPrivilege)) {
        this.points = parseInt(this.maxPrivilege)
      }
      else if (parseInt(this.points) < 0) {
        this.points = 1
      }
    },
    buttonDecreaseClick() {
      if (this.points > 0) {
        this.points -= 1
      }
    },
    buttonIncreaseClick() {
      if (this.points < parseInt(this.maxPrivilege)) {
        this.points += 1
      }
    },
    showModalAndReditect(value) {
      this.modalType = value.type;
      this.modalValue = parseInt(value.accept);
      this.modalStatus = Boolean(value.show);
      this.$router.push({
        name: "Rewards"
      });
    },
    async handleModal(value) {
      if (this.type === 'luckydraws' && value.accept == 1) {
        await this.$store.dispatch(
          "reward/REDEEM_LUCKYDRAW_ITEM_BY_ID",
          {
            itemid: this.id,
            privilege: this.points
          }
        ).then((response) => {
          this.showModalAndReditect(value)
        })
      } else if (this.type === 'redemptions' && value.accept == 1) {
        await this.$store.dispatch(
          "reward/REDEEM_REWARD_ITEM_BY_ID",
          {
            itemid: this.id,
            privilege: this.points,
            addressid: this.primaryAddress.id
          }
        ).then((response) => {
          this.showModalAndReditect(value)
        })
      } else {
        this.modalType = value.type;
        this.modalValue = parseInt(value.accept);
        this.modalStatus = Boolean(value.show);
      }

    },
  }
};