export const types = {
    MUTATION: 'mutation',
};

export default {
    namespaced: true,
    state: {
        Mobile: null,
        Password:null,
        NewMobile:null,
        OTP:null,
        ExchangeCode:null,
    },
    getters: {
        // [types.MUTATION]: state => state.value,
        getMobile:state => state.Mobile,
        getPassword: state => state.Password,
        getNewMobile: state => state.NewMobile,
        getRefcode: state => state.OTP,
        getExchangeCode: state => state.ExchangeCode,
    },
    mutations: {
        // [types.MUTATION](state, value) {
        //     state.value = value;
        // },
        OTPinfo(state, playload) {
            state.OTP = playload;
        },
        SetExchangeCode(state, playload) {
            state.ExchangeCode = playload;
        },
        SetVerifyMobile(state, playload){
            state.Mobile = playload;
        },
        SetVerifyPassword(state, playload) {
            state.Password = playload;
        },
        SetNewMobile(state, playload) {
            state.NewMobile = playload;
        }
    },
    actions: {},
};