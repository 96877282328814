import { reactive, watch } from "vue";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/swiper.scss";

export default {
  name: "ShopBillComponent",
  props: {
    itemBrands: Array,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup(props, context) {
    const data = reactive({
      selectedItem: 1,
    });
    // console.log(props.itemBrands)
    watch(
      () => props.itemBrands,
      (newBrands, oldBrands) => {
        /* ... */
        // console.log(newBrands, oldBrands);
      }
    );

    function onSwiper(swiper) {
      // console.log(swiper);
    }

    function onSlideChange() {
      // console.log("slide change");
    }

    function onSlideClicked(id) {
      // console.log(id);
      data.selectedItem = id;
      context.emit("update:bill-selected", id);
    }

    return {
      data,
      onSwiper,
      onSlideChange,
      onSlideClicked,
    };
  },
};
