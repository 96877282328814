<style src="./BaseShippingAddressComponent.scss" lang="scss"></style>
<script src="./BaseShippingAddressComponent.js"></script>

<template>
	<div class="box__shipping__address">
		<div class="shipping__address__title">ที่อยู่ในการจัดส่ง</div>
		<BaseAddressComponent :address-data="address" :primary-address="address" />
		<div class="add__new__address" @click="newAddress" v-if="address === null">
			<!-- <i class="el-icon-circle-plus icon" /> -->
			<Icon name="Add" class="add__icon" />
			เพิ่มที่อยู่ใหม่
		</div>
		<div class="update__button">
			<div
				class="update__shipping__address"
				@click="updateShippingAddress"
				v-if="address !== null"
			>
				แก้ไขที่อยู่ในการจัดส่ง
			</div>
		</div>
	</div>
</template>