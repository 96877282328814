import { nextTick } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { ElLoading } from "element-plus";
import routes from "./routes";
import store from "@/store";
import { logger, isOs, getIOSVersion } from "@/helpers";

// gtag
import { useGtag } from "@/composable/useGtag";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "active",
  base: process.env.BASE_URL,
  // scrollBehavior() {
  //     return {
  //         x: 0,
  //         y: 0,
  //     };
  // },
  routes,
});

// hooks
const { initGtag, trackView, trackEvent } = useGtag();

// init gtag
initGtag();

// Init Loading
let loading = null;
let loadedPath = [];
const showLoading = async () => {
  await store.commit("APP_LOADING", true);

  loading = ElLoading.service({
    lock: true,
    // text: "Loading",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.75)",
  });
};
const hideLoading = () => {
  setTimeout(() => {
    store.commit("APP_LOADING", false);
    loading.close();
  }, 1000);
};

router.beforeEach(async (to, from, next) => {
  // console.log(to.name)
  // if (to.name !== 'SubmissionScan')
  window.scrollTo(0, 0);

  // screen.orientation.addEventListener("change", function (e) {
  //     console.log(screen.orientation.type + " " + screen.orientation.angle);
  // }, false);

  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = (window.innerHeight - 152) * 0.01;
  let vha = (window.innerHeight - 192) * 0.01;
  let vw = window.innerWidth * 0.01;
  let vhpreview = (window.innerHeight - 364) * 0.01;
  let vh_bill = (window.innerHeight - 192) * 0.01;
  let vhdefault = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document

  document.documentElement.style.setProperty("--vw", `${vw}px`);
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  document.documentElement.style.setProperty("--vha", `${vha}px`);
  document.documentElement.style.setProperty("--vh_bill", `${vh_bill}px`);
  document.documentElement.style.setProperty("--vhpreview", `${vhpreview}px`);
  document.documentElement.style.setProperty("--vhdefault", `${vhdefault}px`);
  // console.log('isOs("ios")', isOs("ios"));
  if (isOs("ios")) {
    // alert(getIOSVersion());
    if (getIOSVersion()) {
      // fix iOS v.15 safe-area
      const rootElement = document.querySelector("body");
      const viewPortH = rootElement.getBoundingClientRect().height;
      const windowH = window.innerHeight;
      const browserUiBarsH = viewPortH - windowH;

      rootElement.style.height = `calc(100vh - ${browserUiBarsH}px)`;
    }
  }

  // We listen to the resize event
  window.addEventListener("resize", () => {
    // We execute the same script as before
    vh = (window.innerHeight - 152) * 0.01;
    vha = (window.innerHeight - 192) * 0.01;
    vw = window.innerWidth * 0.01;
    vhpreview = (window.innerHeight - 364) * 0.01;
    vh_bill = (window.innerHeight - 192) * 0.01;
    vhdefault = window.innerHeight * 0.01;

    document.documentElement.style.setProperty("--vw", `${vw}px`);
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    document.documentElement.style.setProperty("--vha", `${vha}px`);
    document.documentElement.style.setProperty("--vh_bill", `${vh_bill}px`);
    document.documentElement.style.setProperty("--vhpreview", `${vhpreview}px`);
    document.documentElement.style.setProperty("--vhdefault", `${vhdefault}px`);
  });

  // change http to https
  let url = window.location.href;
  // console.log(url)
  if (url.indexOf("https") === -1 && !url.includes("localhost")) {
    url = url.replace("http", "https");
    window.location.href = url;
  }
  // window.location.href = url;
  // console.log(to.name)
  await showLoading();

  // const isLoadedPath = loadedPath.includes(to.name)
  // console.log({ loadedPath })
  // if (!isLoadedPath) {
  //     loadedPath.push(to.name)
  //     await showLoading();
  // }

  document.title = `${to.meta.title} - ${process.env.VUE_APP_NAME}`;

  const isLoggedIn = await store.dispatch("app/APP_VALID_TOKEN");
  const isJWTToken = await store.getters["profile/token"];

  logger("isLoggedIn", isLoggedIn);
  logger("isJWTToken", isJWTToken);
  logger("to", to.name);

  nextTick(() => trackView({ path: to.name }));

  // utm tracking
  // const queryString = decodeURIComponent(window.location.search).replace(/\+/g, '%20');
  var queryString = window.location.search
    .slice(1)
    .split("&")
    .reduce(function _reduce(/*Object*/ a, /*String*/ b) {
      b = b.split("=");
      a[b[0]] = decodeURIComponent(b[1]);
      return a;
    }, {});
  if (queryString) {
    const params = new URLSearchParams(queryString);
    sessionStorage.setItem("utm_source", params.get("utm_source"));
    sessionStorage.setItem("utm_medium", params.get("utm_medium"));
    sessionStorage.setItem("utm_campaign", params.get("utm_campaign"));
  }

  if (to.matched.some((m) => m.meta.requiresAuth)) {
    // logger("to", to.name);
    // logger("from", from.name)

    // # AUTHENTICATION CHECK
    if (!isLoggedIn || !isJWTToken) {
      next({
        name: "Gateway",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to, from, failure) => {
  // Hide loading
  hideLoading();
  // const isLoadedPath = loadedPath.includes(to.name)
  // if (isLoadedPath)
  //     nextTick(() => hideLoading())
});

export default router;
