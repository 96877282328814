<style src="./SettingPasswordUpdate.scss" lang="scss" scoped></style>
<script src="./SettingPasswordUpdate.js"></script>

<template>
	<div class="settings__wrapper">
		<BaseBackButtonCustomComponent
			:go-back="goBack"
			@button:action="handleBackward"
		/>
		<div class="settings__content">
			<BaseTitleComponent :title-name="titleData" class="title" />
			<div class="desc new-line" v-html="descData"></div>
			<BaseInputPasswordComponent
				:input-pattern="true"
				:input-password="inputPassword"
				v-model.trim="inputPassword.value"
				@updated:password="handlePasswordOk"
			/>

			<div class="gaping"></div>
			<BaseInputPasswordComponent
				:input-pattern="true"
				:input-password="inputRePassword"
				v-model.trim="inputRePassword.value"
				@updated:password="handleRePasswordOk"
			/>
			<!-- <div class="gaping"></div>
			<BaseErrorMsgComponent
				:error-msg="errorMsg.Password"
				v-if="errorMsg.Password"
			/>
			<BaseErrorMsgComponent
				:error-msg="errorMsg.RePassword"
				v-if="errorMsg.RePassword"
			/>
			<div class="password_status" v-if="PasswordCheck">
				{{ PasswordCheck }}
			</div> -->

			<div
				class="password_status"
				:class="{ error__color: PasswordScore <= 25 || PasswordCheck == '' }"
				v-if="PasswordCheck"
				v-html="PasswordCheck"
			/>

			<BaseButtonComponent
				:button-data="buttonData"
				@HandleButton="SubmitForm"
			/>
			<BaseModalComponent
				:modal-show="modalStatus"
				:modal-type="modalType"
				@update:modal="handleModal"
			/>
		</div>
	</div>
</template>