import InApp from "detect-inapp";
import { ElLoading } from "element-plus";
import loadImage from "blueimp-load-image";
import { isIOS, isAndroid } from "mobile-device-detect";
import DeviceDetector from "device-detector-js";

const isProduction = process.env.NODE_ENV === "production";
let loading = null;

export function uuid(base) {
  var d = new Date().getTime(); //Timestamp
  var d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function abbThaiMonth(number) {
  var month = [
    "",
    "ม.ค.",
    "ก.พ.",
    "มี.ค.",
    "เม.ย.",
    "พ.ค.",
    "มิ.ย.",
    "ก.ค.",
    "ส.ค.",
    "ก.ย.",
    "ต.ค.",
    "พ.ย.",
    "ธ.ค.",
  ];
  return month[number];
}

export function logger(message, ...args) {
  if (!isProduction) console.log(message, args);
}

export function error(err) {
  if (!isProduction) console.error(err);
}

export function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

export function getAndroidDevice() {
  var isAndroid = /(android)/i.test(navigator.userAgent);
  return isAndroid ? true : false;
}

export const showLoading = () => {
  loading = ElLoading.service({
    lock: true,
    // text: "Loading",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.75)",
  });
};

export const hideLoading = () => {
  loading.close();
};

export function getBase64Image({ img, maxwidth, maxheight }) {
  var canvas = document.createElement("canvas");
  var MAX_WIDTH = maxwidth;
  var MAX_HEIGHT = img.height || maxheight;
  // console.log(img, MAX_WIDTH, MAX_HEIGHT);
  if (img.width < 1600) {
    MAX_WIDTH = img.width;
  }
  if (img.height < 1200) {
    MAX_HEIGHT = img.height;
  }

  var width = img.width;
  var height = img.height;

  if (width > height) {
    if (width > MAX_WIDTH) {
      height *= MAX_WIDTH / width;
      width = MAX_WIDTH;
    }
  } else {
    if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height;
      height = MAX_HEIGHT;
    }
  }
  canvas.width = width;
  canvas.height = height;
  // console.log(img, width, height);
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, width, height);
  return canvas.toDataURL("image/png");
}

export const isBrowser = (check) => {
  const inapp = new InApp(
    navigator.userAgent || navigator.vendor || window.opera
  );
  if (inapp.browser === check) {
    return true;
  } else {
    return false;
  }
};

export const getBrowser = (check) => {
  const inapp = new InApp(
    navigator.userAgent || navigator.vendor || window.opera
  );

  if (isAndroid) {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    const agent = ua.toLowerCase();

    if (inapp.browser !== "facebook") {
      return inapp.browser;
    } else {
      if (agent.indexOf("fbav") > -1 && agent.indexOf("fb4a") > -1) {
        return "facebook";
      } else {
        return "messenger";
      }
    }
  }

  return inapp.browser;
};

export const isOs = (check) => {
  if (check == "ios") {
    return isIOS;
  }
  if (check == "android") {
    return isAndroid;
  }
};

export const getIOSVersion = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const deviceDetector = new DeviceDetector();
  const device = deviceDetector.parse(userAgent);
  if (device.os.version >= 15) return true;
  return false;
};

export const hasCameraAccess = () => {
  if (isAndroid) {
    if (isBrowser("line") || isBrowser("facebook") || isBrowser("messenger")) {
      return false;
    } else {
      return true;
    }
  }
  return true;
};

export const validPhoneNumber = (phone) => {
  const regx = /^0[8|9|6][0-9]{8,9}$/i;
  let phoneNumber = `${phone}`;
  if (regx.test(`0${phoneNumber}`)) return true;
  return false;
};

export const imageOptimizer = (file, max, options) => {
  return new Promise((resolve, reject) => {
    // max size: 5mb
    const max_size = max * (1024 * 1024); //MB
    const max_size_bytes = file.size;

    if (max_size_bytes > max_size) {
      resolve({ picture: false });
    } else {
      loadImage(
        file,
        (img, data) => {
          // console.log({ data });
          // const settings = {
          //   img: img,
          //   ...options,
          // };
          // const picture = getBase64Image(settings);
          const picture = img.toDataURL("image/png");
          resolve({
            picture,
            data,
          });
        },
        {
          canvas: true,
          pixelRatio: 1,
          maxWidth: options.maxwidth,
          // maxHeight: options.maxheight,
          // crop: true,
          orientation: true,
          imageSmoothingEnabled: true,
          imageSmoothingQuality: "medium",
        }
      );
    }
  });
};
