import request from '@/utils/requestDev'

export const cropperService = {
    apiBillData,
    apiEBillData,
    handleError
}

async function apiBillData(requestData) {
    const data = requestData;
    return await request({
        url: '/cropping',
        method: 'post',
        data
    })
}

async function apiEBillData(requestData) {
    const data = requestData;
    return await request({
        url: '/cropping',
        method: 'post',
        data
    })
}

function handleError(error) {
    throw error;
}