<style src="./SubmissionButtonComponent.scss" lang="scss" scoped></style>
<script src="./SubmissionButtonComponent.js"></script>

<template>
	<div class="box__button__submission" :class="buttonData.class">
		<el-button
			class="button"
			:class="buttonData.active ? 'active' : ''"
			:disabled="buttonData.disabled"
			@click="buttonClick(buttonData)"
			>{{ buttonData.title }}
		</el-button>
	</div>
</template>