import {
  PDPAComponent,
  BaseBackButtonComponent,
  BaseButtonSimpleComponent,
} from "@/components";
import { gsap } from "gsap";
import $ from "jquery";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";

export default {
  name: "PDPA",
  inject: ["trackEvent"],
  data() {
    return {
      buttonData: {
        title: "ถัดไป",
        disabled: false,
        active: true,
      },
      tos: null,
      policy: null,
    };
  },
  mounted() {
    // if (localStorage.getItem('TosConsent') && localStorage.getItem('PolicyConsent')) {
    //   this.$router.push({ name: 'Linking' })
    // }
  },
  components: {
    PDPAComponent,
    BaseBackButtonComponent,
    BaseButtonSimpleComponent,
  },
  methods: {
    buttonClick(data) {
      if (
        this.$store.getters["profile/getTosConsent"] &&
        this.$store.getters["profile/getPolicyConsent"]
      ) {
        // this.$router.push({ name: 'Apply' })
        if (this.$store.getters["profile/getCrossToken"]) {
          this.$router.push({ name: "Apply" });
          this.trackEvent("PDPA - Next", {
            event_category: "PDPA",
            event_label: "Next",
            value: "ถัดไป",
          });
        } else {
          window.location = process.env.VUE_APP_LoginPlatformURL;
          // console.log(process.env.VUE_APP_LoginPlatformURL)
          this.trackEvent("LinkApps", {
            event_category: "Adapter app",
            event_label: "Single Sign on",
            value: true,
          });
        }
      } else {
        if (
          this.$store.getters["profile/getTosConsent"] == null ||
          this.$store.getters["profile/getTosConsent"] == 0
        ) {
          this.tos = false;
          this.handleScrollToDiv("#pdpa__pin");
        } else if (
          this.$store.getters["profile/getPolicyConsent"] == null ||
          this.$store.getters["profile/getPolicyConsent"] == 0
        ) {
          this.policy = false;
          this.handleScrollToDiv("#policy__pin");
        }

        // this.$message({
        //   message: `Pleae submit Terms of service and Privacy Policy`,
        //   type: "error",
        // });
      }
    },
    handleScrollToDiv(el) {
      // console.log(el);
      const topY = el === "#pdpa__pin" ? 0 : $(el).offset().top - 30;
      gsap.registerPlugin(ScrollToPlugin);
      gsap.to(window, {
        duration: 1,
        ease: "power4",
        scrollTo: topY,
      });
    },
  },
};
