import {
  BaseBackButtonComponent,
  BaseTitleComponent,
  BaseAddressInputComponent,
  BaseButtonComponent,
  BaseModalComponent,
} from "@/components";

import { ref, reactive, provide, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  RequestProfileAddressAdd,
  RequestProfileAddressList,
  RequestProfileAddressUpdate,
} from "@/api/profile.js";

export default {
  name: "Home",
  components: {
    BaseBackButtonComponent,
    BaseTitleComponent,
    BaseAddressInputComponent,
    BaseButtonComponent,
    BaseModalComponent,
  },
  data() {
    return {
      title: "เพิ่มที่อยู่ใหม่",
      token: this.$store.getters["profile/token"],
      primaryAddress: false,
      ShowModalComplete: false,
      buttonData: {
        name: "บันทึกข้อมูล",
        type: "submit",
        active: false,
      },
    };
  },
  created() {
    if (this.token) {
      this.id = this.$route.params.id;
      if (this.id !== "" && this.id !== undefined) {
        this.title = "แก้ไขที่อยู่";
        this.GetProfileAddressList();
      }
    } else {
      this.$message({
        message: "Invalid Token",
        type: "error",
      });
      this.$router.push("/Gateway");
    }
  },
  setup() {
    const state = reactive({
      address: {
        title: ref(""),
        firstname: ref(""),
        phonenumber: ref(""),
        address: ref(""),
        street: ref(""),
        tumbon: ref(""),
        district: ref(""),
        province: ref(""),
        postal: ref(""),
      },
    });
    const validations = {
      address: {
        title: {
          required,
        },
        firstname: {
          required,
        },
        phonenumber: {
          required,
        },
        address: {
          required,
        },
        street: {
          required,
        },
        tumbon: {
          required,
        },
        district: {
          required,
        },
        province: {
          required,
        },
        postal: {
          required,
        },
      },
    };
    const v$ = useVuelidate(validations, state);
    provide("v$", v$);
    return {
      v$,
      state,
    };
  },
  watch: {
    "v$.$invalid": {
      deep: true,
      handler(value) {
        this.logger("v$.$invalid", value);
        if (!value) {
          this.buttonData.active = true;
        } else {
          this.buttonData.active = false;
        }
      },
    },
  },
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 9) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    SubmitForm() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.$store.commit("BUTTON_LOADING", false);
        return;
      } else {
        this.RequestProfileAddressAdd();
      }
    },
    async RequestProfileAddressAdd() {
      this.$store.commit("BUTTON_LOADING", true);
      let RequestData = {
        token: this.token,
        title: this.state.address.title,
        firstname: this.state.address.firstname,
        phonenumber: this.state.address.phonenumber,
        address: this.state.address.address,
        street: this.state.address.street,
        tumbon: this.state.address.tumbon,
        district: this.state.address.district,
        province: this.state.address.province,
        postal: this.state.address.postal.toString(),
      };

      let RequestResp = null;
      if (this.id !== "" && this.id !== undefined) {
        RequestData["id"] = this.id;
        RequestData["default"] = this.primaryAddress;
        RequestResp = await RequestProfileAddressUpdate(RequestData);
      } else {
        RequestResp = await RequestProfileAddressAdd(RequestData);
      }
      this.$store.commit("BUTTON_LOADING", false);
      if (RequestResp.result == "complete") {
        this.ShowModalComplete = true;
      } else {
        this.$message({
          message: RequestResp.resultdetail,
          type: "error",
        });
      }
    },
    async GetProfileAddressList() {
      this.addresses = [];
      let RequestData = {
        token: this.token,
        addressid: this.id,
      };
      let RequestResp = await RequestProfileAddressList(RequestData);
      if (RequestResp.result == "complete") {
        RequestResp.list.forEach((address) => {
          this.state.address.title = address.title;
          this.state.address.firstname = address.firstname;
          this.state.address.phonenumber = address.phonenumber;
          this.state.address.address = address.address;
          this.state.address.street = address.street;
          this.state.address.tumbon = address.tumbon;
          this.state.address.district = address.district;
          this.state.address.province = address.province;
          this.state.address.postal = address.postal;
          this.primaryAddress = address.default;
        });
      }
    },
    select(address) {
      this.state.address.tumbon = address.district;
      this.state.address.district = address.amphoe;
      this.state.address.province = address.province;
      this.state.address.postal = address.zipcode;
    },
    CloseModal() {
      this.ShowModalComplete = false;
      this.$router.push({ name: "AddressLists" });
    },
  },
};
