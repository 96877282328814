import { Icon } from "@/components";
import { logger } from "@/helpers";
export default {
  name: "ScanBarActionComponent",
  props: {
    configButton: {
      type: Object,
      default: [
        {
          icon: "retake",
          name: "ถ่ายใหม่",
          color: "#BEBDC6",
          backgroundColor: "#FFFFFF",
          borderColor: "#BEBDC6",
          width: 80,
          height: 80,
          accept: "image/jpeg,image/gif,image/png",
        },
        {
          icon: "scan_more",
          name: "สแกนเพิ่ม",
          color: "#BEBDC6",
          backgroundColor: "#FFFFFF",
          borderColor: "#BEBDC6",
          width: 80,
          height: 80,
          accept: "image/jpeg,image/gif,image/png",
        },
      ],
    },
    scanProcess: Boolean,
  },
  components: {
    Icon,
  },
  setup(props, { emit }) {
    logger("scanProcess", props.scanProcess);

    function handleScan() {
      emit("update:scaning", true);
    }

    function handleEvent(action) {
      if (action == "retake") emit("update:retake");
      if (action == "scan_more") emit("update:scanmore");
    }

    return {
      handleScan,
      handleEvent,
    };
  },
};
