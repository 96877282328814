import { Icon } from "@/components";
import { bytesToSize } from "@/helpers";
export default {
  name: "BaseFileUploader",
  props: {
    preview: String,
  },
  components: {
    Icon,
  },
  data() {
    return {
      base64_image: this.preview,
      capture: null,
      image: null,
      ExtensionList: ["jpg", "jpeg", "png", "pdf"],
    };
  },
  methods: {
    selectFile() {
      this.$refs.uploadInput.click();
    },
    detectFiles(e) {
      const file = e.target.files[0];
      let filename = file.name;
      let fileExtension = filename
        .split(".")
        .pop()
        .toLowerCase();

      if (this.ExtensionList.includes(fileExtension)) {
        this.upload(file, fileExtension);
      } else {
        this.$message({
          message: "กรุณาเลือกไฟล์ที่รองรับ",
          type: "error",
        });
      }

      e.target.value = "";
      this.capture = null;
    },
    upload(file, type) {
      new Promise((resolve, reject) => {
        var self = this;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          self.base64_image = reader.result;
          self.image = {
            type: type,
            name: file.name,
            size: await bytesToSize(file.size),
            originsize: file.size,
            base64: reader.result,
          };

          self.$emit("loadImage", self.image);
          resolve(self.image);
        };
        reader.onerror = (error) => reject(error);
      });
    },
    remove() {
      this.$emit("removeImage");
    },
    removeFile() {
      this.image = null;
      this.capture = null;
      this.base64_image = this.preview;
    },
  },
};
