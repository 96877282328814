export default {
    name: "BaseImageFeature",
    props: {
      imageSrc: Object
    },
    data() {
      return {
        reward: this.imageSrc
      };
    },
  };
    