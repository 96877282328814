<style src="./BaseInputOTPComponent.scss" lang="scss"></style>
<script src="./BaseInputOTPComponent.js"></script>

<template>
	<div class="box__otp">
		<input
			v-if="inputType === 'password'"
			autocomplete="off"
			name="hidden"
			type="text"
			style="display: none"
		/>
		<SingleOtpInputComponent
			v-for="(item, i) in numInputs"
			:key="i"
			:focus="activeInput === i"
			:value="otp[i]"
			:separator="separator"
			:input-type="inputType"
			:input-classes="inputClasses"
			:is-last-child="i === numInputs - 1"
			:should-auto-focus="shouldAutoFocus"
			@on-change="handleOnChange"
			@on-keydown="handleOnKeyDown"
			@on-paste="handleOnPaste"
			@on-focus="handleOnFocus(i)"
			@on-blur="handleOnBlur"
		/>
	</div>
</template>