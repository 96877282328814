import { ref } from "vue";

export default {
  name: "BaseInputMobile",
  props: {
    inputMobile: Object,
    error: String
  },
  setup() {
    return {
      input: ref(""),
    };
  },
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      const value = evt.target.value
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 9) {
        evt.preventDefault();
      } else {
        if (String(value).length > 8) {
          evt.preventDefault();
        }
        return true;
      }
    },
  },
};
