<style src="./ScanBarComponent.scss" lang="scss"></style>
<script src="./ScanBarComponent.js"></script>

<template>
	<div class="submission__bar">
		<div
			class="submission__button gallery"
			:class="{ fix__w: hasCameraAccess() && !cameraAccess }"
		>
			<label for="inputGallery">คลังภาพ</label>
			<input
				type="file"
				id="inputGallery"
				accept="image/*, .png, .jpeg, .jpg"
				@change.prevent="detectGalleryFiles($event)"
			/>
		</div>
		<button
			v-if="hasCameraAccess() && cameraAccess"
			class="scan__button"
			:style="`background-color: ${configButton.backgroundColor}`"
			@click.prevent="handleScan($event)"
		>
			<Icon name="scan" />
			<span :style="`color: ${configButton.color}`">
				{{ configButton.name }}
			</span>
		</button>
		<div
			class="submission__button file"
			:class="{ fix__w: hasCameraAccess() && !cameraAccess }"
		>
			<label for="inputFile">ไฟล์</label>
			<input
				type="file"
				id="inputFile"
				accept="application/pdf, .pdf"
				@change.prevent="detectPDFFiles($event)"
			/>
		</div>
	</div>
</template>