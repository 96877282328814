<style src="./BillDetail.scss" lang="scss" scoped></style>
<script src="./BillDetail.js"></script>

<template>
	<div class="box__Bill__detail">
		<BaseBackButtonComponent />
		<div class="box__Bill__detail_inner">
			<div class="detail_title">{{ BillStatusMsg }}</div>

			<div class="bill-title">
				<IconShop
					:name="BillItem.shoptype.toLowerCase()"
					class="icon-shop"
					v-if="BillItem.shoptype"
				/>
				<div class="bill-date">
					<div class="date-submited">
						วันที่ส่งเข้ามา: {{ FormatDate(BillItem.created) }}
					</div>
					<div class="date-rejected" v-if="BillUpdateStatusDate">
						วันที่ถูกตรวจสอบ: {{ FormatDate(BillUpdateStatusDate) }}
					</div>
				</div>
			</div>

			<section v-if="BillItem.remark">
				<div class="Bill_detail_msg">รายละเอียด</div>
				<div class="Bill_detail_remark">{{ BillItem.remark }}</div>
			</section>

			<div class="Bill_Image_list" :class="BillItem.contentstatus">
				<div class="Bill_Image_list_title">ใบเสร็จของคุณ</div>

				<div class="Bill_Image_list_icons" v-if="BillItem.mediatype == 'pdf'">
					<a :href="BillItem.receiptimage" target="_blank"
						><div class="bill-icon">1</div></a
					>
				</div>
				<div class="Bill_Image_list_icons" v-else>
					<div
						class="bill-icon"
						v-for="(item, index) in BillPhoto"
						:key="index"
						@click="ShowBill(index)"
					>
						{{ index + 1 }}
					</div>
				</div>
			</div>
		</div>
		<router-link to="" @click="handleClick">
			<div class="button_contact">ติดต่อเรา</div>
		</router-link>

		<BillImageViewer
			:ImageList="BillPhoto"
			:Showbill="Showbill"
			:BillStatus="BillItem.contentstatus"
			v-if="Showbill != null"
			@hideModal="HideBill"
		/>
	</div>
</template>