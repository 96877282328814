import {
	BaseTitleComponent,
	BaseCarouselComponent,
	BaseButtonSimpleComponent
} from '@/components';

export default {
	name: 'Home',
	components: {
		BaseTitleComponent,
		BaseCarouselComponent,
		BaseButtonSimpleComponent
	},
	data() {
		return {
			titleName: 'เริ่มต้นใช้งานกับเรา',
			buttonData: {
				title: 'เริ่มต้น',
				active: true
			},
		}
	},
	mounted() {
		localStorage.removeItem('TosConsent')
		localStorage.removeItem('PolicyConsent')
		localStorage.removeItem('linkingAccount')
	},
	methods: {
		buttonClick() {
			this.$router.push({ name: 'Gateway' })
		},
	}
};