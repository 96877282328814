import { reactive, computed, watchEffect } from "vue";
export default {
  name: "BaseModal",
  props: {
    modalType: String,
    modalShow: Boolean,
    dayAmount: {
      type: Number,
      default: 1,
    },
    message: String,
  },
  emits: ["update:message", "update:modal", "closed:modal"],
  setup(props, context) {
    let state = reactive({
      modals: [
        // #1 Accept Terms [type:accept]
        {
          title: "Accept Terms",
          message:
            "By creating an account, you agree<br/> to the adapter CRP Terms of <br/>Service and Privacy Policy.",
          button: [
            {
              name: "Cancel",
              action: 0,
            },
            {
              name: "I agree",
              action: 1,
            },
          ],
          type: "accept",
        },
        // #2 Completed [type:completed]
        {
          title: "Completed!",
          message: "ยินดีด้วย!<br/>คุณลงทะเบียนสำเร็จ",
          button: [
            {
              name: "ตกลง",
              action: true,
            },
          ],
          type: "completed",
        },
        // #3 Success [type:success]
        {
          title: "บันทึกใบเสร็จเข้าระบบเรียบร้อย!",
          message: `
          <span>กรุณาเก็บใบเสร็จไว้
          <br/>จนเสร็จสิ้นกระบวนการตรวจสอบ
          <br/><br/>แอดมินใช้เวลาตรวจสอบความถูกต้อง ${props.dayAmount} วัน<br/>สามารถเช็กพอทย์ที่ได้รับ<br/>ได้ที่เมนู “ประวัติสะสมพอยท์”</span>
          `,
          button: [
            {
              name: "ตกลง",
              action: true,
            },
          ],
          type: "success",
        },
        // #4 Allow [type:allow]
        {
          title: `Allow adapter CRP to use<br/> your location?`,
          message: "Location When in Use Usage<br/> Description",
          button: [
            {
              name: "Allow While Using App",
              action: 2,
            },
            {
              name: "Allow Once",
              action: 1,
            },
            {
              name: "Don't Allow",
              action: 0,
            },
          ],
          type: "allow",
        },
        // #5 Redeem [type:redeem]
        {
          title: "Confirm to redeem",
          message: props.message,
          button: [
            {
              name: "ย้อนกลับ",
              action: false,
            },
            {
              name: "ตกลง",
              action: true,
            },
          ],
          type: "redeem",
        },
        // #6 Password Reset [type:reset]
        {
          title: "Completed!",
          message: "Your password has been reset",
          button: [
            {
              name: "ตกลง",
              action: true,
            },
          ],
          type: "reset",
        },
        // #7 Password Update [type:completed]
        {
          title: "เปลี่ยนเบอร์โทรศัพท์เรียบร้อย",
          message: "", //ต้องการสอบถามข้อมูลเพิ่มเติม\nติดต่อแอดมินได้ที่เบอร์ xx-xxx-xxxx
          button: [
            {
              name: "ตกลง",
              action: true,
            },
          ],
          type: "updated",
        },
        // #8 Remove File [type:remove]
        {
          title: "ท่านต้องการลบไฟล์ที่เลือกไว้",
          message: "ใช่หรือไม่",
          button: [
            {
              name: "ย้อนกลับ",
              action: false,
            },
            {
              name: "ตกลง",
              action: true,
            },
          ],
          type: "remove",
        },
        // #9 Saved [type:saved]
        {
          title: "บันทึกข้อมูลเรียบร้อย",
          message: `ทีมงานจะตรวจสอบข้อมูลโดยเร็วที่สุด<br/>หลังจากได้รับการแจ้งเรื่องเข้ามา`,
          button: [
            {
              name: "ตกลง",
              action: true,
            },
          ],
          type: "saved",
        },
        {
          title: "บันทึกข้อมูลเรียบร้อย",
          message: ``,
          button: [
            {
              name: "ตกลง",
              action: true,
            },
          ],
          type: "updatecomplete",
        },
        // #9 Saved [type:verifycomplete]
        {
          title: "Completed!",
          message: `ยืนยันตัวตนเรียบร้อย`,
          button: [
            {
              name: "ตกลง",
              action: true,
            },
          ],
          type: "verifycomplete",
        },
        // #10 Saved [type:duplicate_emaill]
        {
          title: "อีเมลนี้มีอยู่ในระบบแล้ว",
          message: ``,
          button: [
            {
              name: "เข้าสู่ระบบ",
              action: true,
            },
          ],
          type: "duplicate_email",
        },
      ],
    });

    watchEffect(() => {
      // console.log('watchEffect points', props.message)
    });

    let dataModals = computed(() => {
      return state.modals.filter((modal) => modal.type === props.modalType);
    });

    function handleOk() {
      context.emit("update:modal", {
        type: this.modalType,
        accept: 1,
        show: !this.modalShow,
      });
    }

    function handleCancel() {
      context.emit("update:modal", {
        type: this.modalType,
        accept: 0,
        show: !this.modalShow,
      });
    }

    function handleAllow(allow) {
      context.emit("update:modal", {
        type: this.modalType,
        accept: allow,
        show: !this.modalShow,
      });
    }

    function handleClosed() {
      context.emit("closed:modal");
    }

    return {
      state,
      dataModals,
      handleOk,
      handleCancel,
      handleAllow,
      handleClosed,
    };
  },
};
