<style src="./SubmissionEdit.scss" lang="scss" scoped></style>
<script src="./SubmissionEdit.js"></script>

<template>
	<div class="submission__wrapper">
		<ScanProcessingComponent v-show="data.scanProcess" />
		<BaseBackButtonCustomComponent
			:config-button="data.buttonData"
			:scan-process="data.scanDone"
		/>
		<BaseBillEditComponent />
		<ScanBarActionComponent
			:config-button="data.buttonAction"
			:scan-process="data.scanProcess"
			@update:scaning="handleScanProcess"
		/>
	</div>
</template>