import { reactive, watchEffect, watch, onMounted } from "vue";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/swiper.scss";

export default {
  name: "ShopBillConfirmComponent",
  props: {
    itemBrands: Array,
    itemSelected: String || Number,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  emits: ["update:bill-selected"],
  setup(props, context) {
    const data = reactive({
      selectedItem: null,
    });

    // console.log(props.itemBrands);

    watch(
      () => props.itemBrands,
      (newBrands, oldBrands) => {
        /* ... */
        // console.log(newBrands, oldBrands);
      }
    );

    onMounted(() => {
      // data.selectedItem = props.itemSelected
      // onSlideClicked(props.itemSelected)
      // context.emit('update:bill-selected', data.selectedItem)
    });
    // computed(() => onSlideClicked(props.itemSelected))

    function onSwiper(swiper) {
      // console.log(swiper);
    }

    function onSlideChange() {
      // console.log("slide change");
    }

    function onSlideClicked(selected) {
      // console.log(id);
      data.selectedItem = selected.name;
      context.emit("update:bill-selected", selected.id);
    }

    return {
      data,
      onSwiper,
      onSlideChange,
      onSlideClicked,
      // itemSelectedUpdate
    };
  },
};
