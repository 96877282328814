<style src="./AddressLists.scss" lang="scss" scoped></style>
<script src="./AddressLists.js"></script>

<template>
	<div class="content__address__list__view">
		<div class="address__header">
			<BaseBackButtonComponent />
			<BaseTitleComponent :title-name="title" />
		</div>
		<div class="address__lists">
			<BaseAddressComponent
				v-for="item in addresses"
				:key="item.id"
				:address-data="item"
				:primary-address="primary"
				v-on:primarySelect="primarySelect"
			/>

			<div class="add__new__address" @click="newAddress">
				<!-- <i class="el-icon-circle-plus icon" /> -->
				<Icon name="Add" class="address__add" />
				เพิ่มที่อยู่ใหม่
			</div>
		</div>
		<BaseButtonSimpleComponent
			class="confirm__address"
			:button-data="buttonData"
			v-on:buttonClick="buttonClick"
		/>
		<BaseModalComponent
			:modal-show="ShowModalComplete"
			:modal-type="'updatecomplete'"
			@update:modal="CloseModal"
		/>
	</div>
</template>