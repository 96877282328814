import {
    BaseBackButtonComponent,
    BaseImageFeatureComponent,
    BaseDetailComponent
  } from '@/components';
import * as dayjs from 'dayjs'

import { RequestGetLuckyHistory, RequestGetRewardHistory } from '@/api/profile.js';
import {  rewardService } from '@/api/reward.js';
  
  export default {
    name: 'RedeemDetails',
    components: {
      BaseBackButtonComponent,
      BaseImageFeatureComponent,
      BaseDetailComponent,
    },
    data() {
      return {
        token: this.$store.getters['profile/token'],
        type: null,
        id: null,
        redeem: {
          id: null,
          image: null,
          title: '',
          price: '',
          claimed_datetime: '',
          points_spended: '',
          description: '',
          terms: '',
        },
        
      }	
    },
    created () {
      this.type = this.$route.params.type
      this.id = this.$route.params.id


      if (this.type !== undefined && this.id !== undefined){
        this.getRedeemDetail()
      }
    },
    methods: {
      getRedeemDetail () {
        this.GetHistory();
      },
      async GetHistory() {
        let RequestData = {
          "token": this.token,
          "redeemid": this.id
        }

        let RequestResp;

        if (this.type == 'redemptions'){
           RequestResp = await RequestGetRewardHistory(RequestData)
        } else if (this.type == 'luckydraws'){
           RequestResp = await RequestGetLuckyHistory(RequestData)
        }
        
        if (RequestResp.result == 'complete') {

          let list = RequestResp.list
          let redeemDetail =list[0]

          
          let RequestDataReward = {
            "token": this.token,
            "itemid": redeemDetail.itemid
          }

          let RewardResp = null;
          if (this.type == 'redemptions') {
            RewardResp = await rewardService.getRewardItems(RequestDataReward)
          } else if (this.type == 'luckydraws') {
            RewardResp = await rewardService.getLuckydrawItems(RequestDataReward)
          }

          let RewardDetail = RewardResp.list[0];
       

          this.redeem = {
            id: redeemDetail.redeemid,
            image: RewardDetail.assetthumb,
            title: redeemDetail.titleth,
            price: redeemDetail.descth,
            claimed_datetime: dayjs(RewardDetail.created).locale('th').format('DD MMM YYYY เวลา HH:mm น.'),
            points_spended: `ใช้ ${redeemDetail.point} พอยท์ แลก ${redeemDetail.privilege} สิทธิ์`,
            description: RewardDetail.detailth,
            terms: RewardDetail.termth,
          }

          this.$forceUpdate();


        }
      },
      
    }
  };