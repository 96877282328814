import * as dayjs from 'dayjs'

export default {
    name: 'PointExpireComponent',
    components: {
        
    },
    props: ["PointExpires"],
   
    methods: {
        FormatDate(Date){
            let FormatDate = dayjs(Date).locale('th').format('DD MMM YYYY')
            return FormatDate;
        }
    },
};