<style src="./SettingPhone.scss" lang="scss" scoped></style>
<script src="./SettingPhone.js"></script>

<template>
	<div class="settings__wrapper">
		<BaseBackButtonCustomComponent
			:go-back="goBack"
			@button:action="handleBackward"
		/>
		<div class="settings__content">
			<BaseTitleComponent :title-name="titleData" class="title" />
			<BaseInputTextComponent
				class="text__input"
				:input-text="inputText"
				v-model="inputText.value"
			/>
			<div class="gaping"></div>
			<BaseInputMobileComponent
				:input-mobile="inputMobile"
				v-model="inputMobile.value"
				:error="errorMsg.Mobile"
			/>

			<div class="gaping"></div>

			<BaseErrorMsgComponent
				v-if="errorMsg.Mobile && !buttonData.active"
				:error-msg="errorMsg.Mobile"
			/>
			<BaseTextLineComponent
				v-else-if="buttonData.active"
				:text-data="textLine"
			/>
			<BaseButtonComponent
				:button-data="buttonData"
				@HandleButton="SubmitForm"
			/>
		</div>
	</div>
</template>