<style src="./BaseAddressInputComponent.scss" lang="scss"></style>
<script src="./BaseAddressInputComponent.js"></script>

<template>
  <div class="address__container" v-click-outside="onClickOutside">
    <div class="address__input__container">
      <input type="text"
      v-model="currentValue"
      :placeholder="placeholder"
      autocomplete="disabled"
      ref="input"
      v-on:focus="hasFocus = true"
      v-on:blur="hasFocus = false"
      @keydown.up="pressArrow('up')"
      @keydown.down="pressArrow('down')"
      @keydown.enter="pressEnter()">
      <div v-if="resultsFromSearch.length && isOpenListContainer"
      ref="dropdown"
      class="address__list__container">
        <div class="address__list"
        :class="{ 'address__list__on-focused': itemOnFocus === index }"
        :style="{
          'background-color': itemOnFocus === index ? currentColor : '#fff'
        }"
        v-for="(item, index) in resultsFromSearch"
        :key="index"
        @mouseover="itemOnFocus = index"
        @mouseout="itemOnFocus = -1"
        @click="clickSelectItem(item)">
          <div class="address__box__item">
            <span :class="{ 'address__box__item--focused': itemOnFocus === index && currentColor !== '#f5f5f5' }">
              {{ displayItem(item) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>