<style src="./FooterSignupComponent.scss" lang="scss" scoped></style>
<script src="./FooterSignupComponent.js"></script>

<template>
	<div class="box__link" :class="{ custom__links: buttonPosition }">
		<ul class="list__items">
			<li>
				<router-link to="" @click="handleForgotPassword">
					<span class="appbar__text">ลืมรหัสผ่าน</span>
				</router-link>
			</li>
			<li>
				<router-link to="" @click="handleOpenLink">
					<span class="appbar__text">สมัครบัญชีใหม่</span>
				</router-link>
			</li>
		</ul>
	</div>
</template>