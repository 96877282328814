export default {
  name: "BackButton",
  props: {
    classButton: String,
    backAction: Boolean,
    backwardEvent: Boolean,
  },
  methods: {
    goback() {
      if (this.backwardEvent) {
        this.$router.push("/Gateway");
      } else {
        this.backAction ? this.$router.push("/Home") : this.$router.back();
      }
    },
  },
};
