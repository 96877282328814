<style src="./ForgotCheckname.scss" lang="scss" ></style>
<script src="./ForgotCheckname.js"></script>

<template>
	<div class="content__forgotcheckname__view">
		<div class="content__view__inner">
		<BaseTitleComponent :title-name="titleName" />
		<div class="forget_msg1">กรุณากรอกชื่อ-นามสกุลเพื่อยืนยัน</div>
		<BaseInputTextComponent class="text__input"
				:input-text="inputFirstnameText"
				v-model="inputFirstnameText.value"/>
		<div class="gaping"></div>
		<BaseInputTextComponent class="text__input"
				:input-text="inputLastnameText"
				v-model="inputLastnameText.value"/>
		<BaseErrorMsgComponent v-if="errorMsg" :error-msg="errorMsg" />
		<BaseButtonComponent :button-data="buttonData"  @HandleButton="SubmitForm" />
		</div>
		
	</div>
</template>