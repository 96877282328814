// import * as dayjs from 'dayjs'
import { inject } from "vue";
import { useRouter } from "vue-router";
import { PointhistoryListComponent } from "@/components";
import { RequestGetPointHistory } from "@/api/profile.js";
import * as dayjs from "dayjs";

export default {
  name: "PointLastestComponent",
  components: {
    PointhistoryListComponent,
  },
  mounted() {
    if (this.token) {
      this.GetPointHistory();
    }
  },
  data() {
    return {
      token: this.$store.getters["profile/token"],
      PointLists: [],
    };
  },
  setup() {
    const router = useRouter();
    const trackEvent = inject("trackEvent");

    function handleClick() {
      trackEvent(`Home - Point status more`, {
        event_category: "Home",
        event_label: "Point status more",
        value: "ดูรายละเอียดเพิ่มเติม",
      });

      router.push({
        path: "/point-status",
      });
    }

    return {
      handleClick,
    };
  },
  methods: {
    async GetPointHistory() {
      let RequestData = {
        token: this.token,
        // "month":"6",
        // "year":"2021"
      };
      let RequestResp = await RequestGetPointHistory(RequestData);
      if (RequestResp.result == "complete") {
        let list = RequestResp.list;
        var size = 3;
        list = list.slice(0, size);

        let Historylist = this.SetUpPointArray(list);
        this.PointLists = Historylist;
      }
    },
    SetUpPointArray(Historylist) {
      // this gives an object with dates as keys
      const groups = Historylist.reduce((groups, bill) => {
        const date = dayjs(bill.created).format("YYYY-MM-DD");
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(bill);
        return groups;
      }, {});

      // Edit: to add it in the array format instead
      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          data: groups[date],
        };
      });

      return groupArrays;
    },
  },
};
