import {
  ShortProfileComponent,
  BaseBannerComponent,
  PointBalanceComponent,
  PointExpireComponent,
  PointLastestComponent,
} from "@/components";
import { RequestGetInfo } from "@/api/profile.js";

export default {
  name: "Home",
  components: {
    ShortProfileComponent,
    BaseBannerComponent,
    PointBalanceComponent,
    PointExpireComponent,
    PointLastestComponent,
  },
  data() {
    return {
      token: this.$store.getters["profile/token"],
      Myinfo: null,
      PointExpire: null,
      Banners: [],
    };
  },
  created() {
    if (this.token) {
      this.GetInfo();
    } else {
      this.$message({
        message: "Invalid Token",
        type: "error",
      });

      this.$router.push("/Gateway");
    }
  },
  methods: {
    async GetInfo() {
      let RequestData = {
        token: this.token,
      };
      let RequestResp = await RequestGetInfo(RequestData);
      if (RequestResp.result == "complete") {
        this.Myinfo = RequestResp.info;
        this.PointExpire = RequestResp.pointexpirelist;
        this.$store.commit("profile/setUserInfo", this.Myinfo);
      } else {
        this.$message({
          message: RequestResp.resultdetail,
          type: "error",
        });
      }
    },
  },
};
