<style src="./SubmissionScan.scss" lang="scss"></style>
<script src="./SubmissionScan.js"></script>

<template>
	<div class="submission__wrapper">
		<ScanProcessingComponent v-show="data.scanProcess" />
		<BaseModalComponent
			:modal-type="data.modal.type"
			:modal-show="data.modal.show"
			:modal-status="data.modal.status"
			@update:modal="handleSuccess"
		/>
		<BaseBackButtonCustomComponent
			:config-button="data.buttonData"
			:scan-process="data.scanProcess"
			:capture-images="data.captureImages"
			:capture-action="data.page"
			@update:recheckdone="handleRecheckDone"
			@button:action="handleButtonAction"
		/>
		<!-- camera scan -->
		<BaseCameraComponent
			v-if="data.page === 'capture'"
			:image-prev-id="data.captureImages.BillsIndex"
			:image-prev="data.captureImages.BillsPrev"
			:scan-capture="data.scanCapture"
			@update:capture="handleCapture"
			@process:scaning="handleBeforeCapture"
			@camera:access="handleCameraAccess"
			@update:backbar="handleBackBar"
			@update:backbarupload="handleBackBarUpload"
		/>
		<!-- bill edit -->
		<BaseBillEditComponent
			v-if="data.page === 'recheck'"
			:capture-images="data.captureImages"
			:coordinates="data.coordinates"
			@update:cropper="handleCropper"
		/>
		<ScanBarComponent
			v-if="data.page === 'capture'"
			:config-button="data.buttonScan"
			:scan-process="data.scanProcess"
			:camera-access="cameraAccess"
			@update:scaning="handleScanProcess"
			@update:process="handleBeforeUploadFile"
			@update:uploaded="handleUploadFile"
		/>
		<ScanBarActionComponent
			v-if="data.page === 'recheck'"
			:config-button="data.buttonAction"
			:scan-process="data.scanProcess"
			@update:scaning="handleScanProcess"
			@update:retake="handleRetakeProcess"
			@update:scanmore="handleScanMoreProcess"
		/>

		<!-- Done -->
		<div class="submission__content" v-if="data.page === 'done'">
			<div class="submission__bill__view">
				<BillImageConfirmViewer
					:bill-ready="billImageConfirmViewerHeight"
					:bill-view="data.billView"
					:bill-show="data.billShow"
				/>
			</div>
			<div class="submission__bill__action">
				<h1>
					{{ data.title }}
					<!-- <span>{{ data.billName }} </span> -->
				</h1>
				<p
					v-if="!data.billName"
					class="error__select"
					v-html="data.subtitle"
				></p>
				<ShopBillConfirmComponent
					:item-brands="data.brands"
					:item-selected="data.billName"
					@update:bill-selected="handleSelectedItem"
				/>
				<!-- {{ data.billName }} -->
				<SubmissionButtonComponent
					:button-data="data.buttonSetting"
					@button:click="
						data.billName ? handleConfirm() : (data.billName = null)
					"
				/>
			</div>
		</div>
	</div>
</template>