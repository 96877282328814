import SingleOtpInputComponent from "./SingleOtpInputComponent.vue";

// keyCode constants
const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;

export default {
  name: "BaseInputOTP",
  props: {
    numInputs: {
      default: 4,
    },
    separator: {
      type: String,
      default: "**",
    },
    inputClasses: {
      type: String,
    },
    inputType: {
      type: String,
      // validator(value) {
      //   return ['number', 'tel', 'password'].includes(value);
      // },
    },
    shouldAutoFocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeInput: 0,
      otp: [],
      oldOtp: [],
    };
  },
  components: {
    SingleOtpInputComponent,
  },
  methods: {
    handleOnFocus(index) {
      this.activeInput = index;
    },
    handleOnBlur() {
      this.activeInput = -1;
    },
    // Helper to return OTP from input
    checkFilledAllInputs() {
      // console.log('activeInput:', this.activeInput)
      // console.log('numInputs:', this.numInputs)
      // if (this.otp.join('').length === this.numInputs) {
      return this.$emit("on-complete", this.otp.join(""));
      // }
      // return 'Wait until the user enters the required number of characters';
    },
    // Focus on input by index
    focusInput(input) {
      this.activeInput = Math.max(Math.min(this.numInputs - 1, input), 0);
      // this.activeInput = Math.max(Math.min(this.numInputs, input), 0);
    },
    // Focus on next input
    focusNextInput() {
      this.focusInput(this.activeInput + 1);
    },
    // Focus on previous input
    focusPrevInput() {
      this.focusInput(this.activeInput - 1);
    },
    handleOnChange(value) {
      // console.log('handleOnChange:', value)

      this.otp[this.activeInput] = value;
      if (value) {
        let vm = this;
        // let promise = new Promise((resolve, reject) => {
        //   resolve(value)
        //   reject(false)
        // });

        let promise = Promise.resolve(value);
        promise.then((value) => vm.changeCodeAtFocus(value));
        this.focusNextInput();
      } else {
        let vm = this;
        let promise = Promise.resolve(value);
        promise.then((value) => vm.changeCodeAtFocus(value));
      }
    },
    // Change OTP value at focused input
    changeCodeAtFocus(value) {
      if (!value) {
        this.focusPrevInput();
      }
      // console.log("value:", value);
      // console.log('activeInput:', this.activeInput)

      // this.oldOtp = Object.assign([], this.otp);
      // this.$set(this.otp, this.activeInput, value);
      // this.otp[this.activeInput] = value;

      // if (this.oldOtp.join('') !== this.otp.join('')) {
      //   this.$emit('on-change', this.otp.join(''));
      this.checkFilledAllInputs();
      // }
    },
    // Handle pasted OTP
    handleOnPaste(event) {
      event.preventDefault();
      const pastedData = event.clipboardData
        .getData("text/plain")
        .slice(0, this.numInputs - this.activeInput)
        .split("");
      if (this.inputType === "number" && !pastedData.join("").match(/^\d+$/)) {
        return "Invalid pasted data";
      }
      // Paste data from focused input onwards
      const currentCharsInOtp = this.otp.slice(0, this.activeInput);
      const combinedWithPastedData = currentCharsInOtp.concat(pastedData);
      this.$set(this, "otp", combinedWithPastedData.slice(0, this.numInputs));
      this.focusInput(combinedWithPastedData.slice(0, this.numInputs).length);
      return this.checkFilledAllInputs();
    },
    clearInput() {
      if (this.otp.length > 0) {
        this.$emit("on-change", "");
      }
      this.otp = [];
      this.activeInput = 0;
    },
    // Handle cases of backspace, delete, left arrow, right arrow
    handleOnKeyDown(event) {
      // console.log('handleOnKeyDown:', event.keyCode)
      switch (event.keyCode) {
        case BACKSPACE:
          // console.log("BACKSPACE");
          // event.preventDefault();
          // this.changeCodeAtFocus('');
          // this.focusPrevInput();
          break;
        case DELETE:
          // event.preventDefault();
          this.changeCodeAtFocus("");
          this.focusPrevInput();
          break;
        case LEFT_ARROW:
          event.preventDefault();
          this.focusPrevInput();
          break;
        case RIGHT_ARROW:
          event.preventDefault();
          this.focusNextInput();
          break;
        default:
          break;
      }
    },
  },
};
