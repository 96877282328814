<style src="./PointBalanceComponent.scss" lang="scss" scoped></style>
<script src="./PointBalanceComponent.js"></script>

<template>
  <div class="point-balance">
    <div class="title">คุณมีพอยท์ทั้งหมด</div>
    <div class="detail">
      <div class="detail_msg">
        <span class="pointscore">{{ pointbalance.toLocaleString() }}</span>
        <span>พอยท์</span>
      </div>
      <div class="icon-refresh" @click="ReloadBalance">
        <Icon name="point-reload" />
      </div>
    </div>
  </div>
</template>