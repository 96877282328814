<style src="./Account.scss" lang="scss"></style>
<script src="./Account.js"></script>

<template>
	<section>
		<BaseBackButtonComponent
			v-if="page !== 'capture'"
			class-button="profile__back"
			:back-action="true"
		/>
		<BaseAvatarEditorComponent
			ref="avatarEditor"
			v-on:loadImage="getProfileImage"
			v-on:retakeImage="retakeImage"
		/>
		<BaseAvatarCameraComponent
			v-if="page === 'capture'"
			@update:capture="handleCapture"
		/>
		<div class="content__account__view" v-if="page === 'account'">
			<div
				class="profile__image"
				:class="{ profile__pd: profile.button.type === 'upload' }"
			>
				<BaseTitleComponent :title-name="changeTitle" />
				<div>
					<BaseAvatarComponent
						:image-src="profile.data.image"
						image-size="large"
						:key="profile.data.image"
					/>
				</div>
				<button type="button" @click="imageAction()">
					{{ getButtonLabel() }}
				</button>
			</div>
			<UserProfileComponent
				:configs="profile"
				v-on:updatePhonenumber="updatePhonenumber"
				:editable-status="profile.button.type"
			/>
			<BaseShippingAddressComponent
				:address="primaryAddress"
				v-show="type"
				class="profile__address"
			/>
			<div class="button" v-if="profile.button.type === 'upload'">
				<button type="button" @click="save()">บันทึก</button>
			</div>

			<BaseModalComponent
				:modal-show="ShowModalComplete"
				:modal-type="'updatecomplete'"
				@update:modal="CloseModal"
			/>
		</div>
	</section>
</template>