<style src="./BaseBannerComponent.scss" lang="scss"></style>
<script src="./BaseBannerComponent.js"></script>

<template>
	<div class="box__banner block">
		<div v-if="!Banners.length" class="banner__loading">
			<div>Loading...</div>
		</div>
		<el-carousel
			v-else
			trigger="click"
			height="100%"
			:interval="3000"
			arrow="hover"
		>
			<el-carousel-item v-for="(banner, index) in Banners" :key="index">
				<!-- # BANNER IMAGE -->

				<el-image
					v-if="banner.type == 'image'"
					:src="banner.assetlarge"
					:alt="banner.titleen"
					@click="handleBannerClick(banner.bannerid, banner.type)"
					fit="fill"
				>
					<template #placeholder>
						<div class="image-slot">Loading<span class="dot">...</span></div>
					</template>
				</el-image>

				<!-- # BANNER YOUTUBE -->
				<el-image
					v-else
					:src="banner.cover"
					:alt="banner.titleen"
					@click="handleBannerClick(banner.bannerid, banner.type)"
					fit="fill"
				>
					<template #placeholder>
						<div class="image-slot">Loading<span class="dot">...</span></div>
					</template>
				</el-image>
			</el-carousel-item>
		</el-carousel>
	</div>
</template>