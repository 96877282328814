import request from '@/utils/request'

export const ContactService = {
    SubmitContactUs,
    handleError
}


async function SubmitContactUs(requestData) {
    const data = requestData;
    return await request({
        url: 'contactus',
        method: 'post',
        data
    })
}


function handleError(error) {
    throw error;
}