// # Component Pages
import {
  Home,
  PDPA,
  Login,
  Apply,
  Verify,
  Account,
  Rewards,
  Linking,
  Address,
  Gateway,
  LinkApps,
  LinkLine,
  Register,
  ApplyOTP,
  NotFound,
  Greeting,
  Settings,
  ContactUs,
  VerifyOTP,
  Lifestyle,
  ForgotOTP,
  ResetPass,
  BillDetail,
  Submission,
  ForgotPass,
  RewardLists,
  LoginGoogle,
  BannerDetail,
  AddressLists,
  PointHistory,
  RewardDetails,
  RedeemDetails,
  SubmissionScan,
  SubmissionEdit,
  ForgotCheckname,
  PrivilegePoints,
  PointHistoryUsed,
  SubmissionReceipt,
  SubmissionConfirm,
  PointHistoryRedeem,
  PointHistoryLuckydraws,
} from "@/views/Pages";

// # Layouts
import BlankLayout from "@/views/Layout/BlankLayout";
import MainLayout from "@/views/Layout/MainLayout";
import PointStatusLayout from "@/views/Layout/PointStatusLayout";

export default [
  {
    name: "MainLayout",
    path: "/",
    component: MainLayout,
    meta: {
      title: "Home",
      requiresAuth: true,
    },
    redirect: "/Home",
    children: [
      {
        name: "Home",
        path: "/Home",
        component: Home,
        props: true,
      },
      {
        path: "/Lifestyle",
        name: "Lifestyle",
        component: Lifestyle,
      },
      {
        path: "/Account/:type?",
        name: "Account",
        component: Account,
      },
      {
        path: "/Rewards",
        name: "Rewards",
        redirect: "/BannerDetail/91362EE7-0B7C-48C7-AB92-CD3AE7743543",
      },
      {
        path: "/RewardLists/:type",
        name: "RewardLists",
        component: RewardLists,
      },
    ],
  },
  {
    name: "PointStatus",
    path: "/point-status",
    component: PointStatusLayout,
    meta: {
      title: "Point status",
      requiresAuth: true,
    },
    redirect: "/point-status/earned",
    children: [
      {
        name: "point-earned",
        path: "/point-status/earned",
        component: PointHistory,
      },
      {
        name: "point-used",
        path: "/point-status/used",
        component: PointHistoryUsed,
      },
      // {
      //   name: 'point-history-redeem',
      //   path: '/point-status/redeem',
      //   component: PointHistoryRedeem
      // },
      {
        name: "BillDetail",
        path: "BillDetail/:BillId",
        component: BillDetail,
      },
    ],
  },
  {
    name: "BlankLayout",
    path: "/Apply",
    component: BlankLayout,
    meta: {
      title: "Apply",
    },
    redirect: "/Apply",
    children: [
      {
        name: "Greeting",
        path: "/Greeting",
        component: Greeting,
        meta: {
          title: "Greeting",
        },
      },
      {
        name: "Gateway",
        path: "/Gateway",
        component: Gateway,
        meta: {
          title: "Gateway",
        },
      },
      {
        name: "BannerDetail",
        path: "/BannerDetail/:id",
        component: BannerDetail,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "Apply",
        path: "/Apply",
        component: Apply,
        meta: {
          title: "Apply with mobile",
        },
      },
      {
        name: "Linking",
        path: "/Linking",
        component: Linking,
        meta: {
          title: "Linking with LINE",
        },
      },
      {
        name: "LinkApps",
        path: "/LinkApps",
        component: LinkApps,
        meta: {
          title: "Linking Accounts",
        },
      },
      {
        name: "LinkLine",
        path: "/LinkLine",
        component: LinkLine,
        meta: {
          title: "Line Logining",
        },
      },

      {
        name: "ContactUs",
        path: "/Contact-Us",
        component: ContactUs,
        meta: {
          title: "Contact Us",
          // requiresAuth: true
        },
      },
      {
        path: "/Register",
        name: "Register",
        component: Register,
        meta: {
          title: "Register",
        },
      },
      {
        name: "Login",
        path: "/Login",
        component: Login,
        meta: {
          title: "Log in",
        },
      },
      {
        name: "Verify",
        path: "/Verify",
        component: Verify,
        meta: {
          title: "โปรดยืนยันตัวตน",
        },
      },
      {
        name: "VerifyOTP",
        path: "/VerifyOTP",
        component: VerifyOTP,
        meta: {
          title: "Verify OTP",
        },
      },
      {
        name: "ForgotPass",
        path: "/ForgotPass",
        component: ForgotPass,
        meta: {
          title: "Forgot password",
        },
      },
      {
        name: "ResetPass",
        path: "/ResetPass",
        component: ResetPass,
        meta: {
          title: "Reset password",
        },
      },
      {
        name: "ForgotOTP",
        path: "/ForgotOTP",
        component: ForgotOTP,
        meta: {
          title: "Forgot password",
        },
      },
      {
        name: "ForgotCheckname",
        path: "/ForgotCheckname",
        component: ForgotCheckname,
        meta: {
          title: "Forgot Check Name",
        },
      },
      {
        name: "ApplyOTP",
        path: "/OTP",
        component: ApplyOTP,
        meta: {
          title: "Apply OTP",
        },
      },
      {
        name: "PDPA",
        path: "/PDPA",
        component: PDPA,
        meta: {
          title: "PDPA",
        },
        props: true,
      },
      {
        path: "/RewardDetails/:type/:id",
        name: "RewardDetails",
        component: RewardDetails,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/RedeemDetails/:type/:id",
        name: "RedeemDetails",
        component: RedeemDetails,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/PrivilegePoints/:type/:id",
        name: "PrivilegePoints",
        component: PrivilegePoints,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/Address/:id?",
        name: "Address",
        component: Address,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/AddressLists",
        name: "AddressLists",
        component: AddressLists,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/Submission",
        name: "Submission",
        component: Submission,
        redirect: "/Submission/scan/1",
        meta: {
          title: "Submission",
          requiresAuth: true,
        },
        children: [
          {
            path: "receipt",
            name: "SubmissionReceipt",
            component: () =>
              import(
                /* webpackChunkName: "SubmissionReceipt" */ "@/views/Pages/SubmissionReceipt"
              ),
          },
          {
            path: "scan/:id",
            name: "SubmissionScan",
            component: () =>
              import(
                /* webpackChunkName: "SubmissionScan" */ "@/views/Pages/SubmissionScan"
              ),
          },
        ],
      },
      {
        name: "BillDetail",
        path: "/BillDetail/:BillId",
        component: BillDetail,
        meta: {
          title: "Bill Detail",
          requiresAuth: true,
        },
      },
      {
        path: "/Settings",
        name: "Settings",
        component: Settings,
        redirect: "/Settings/phone",
        meta: {
          title: "Settings",
          requiresAuth: true,
        },
        children: [
          {
            path: "phone",
            name: "SettingPhone",
            component: () =>
              import(
                /* webpackChunkName: "SettingPhone" */ "@/views/Pages/SettingPhone"
              ),
          },
          {
            path: "phone/otp",
            name: "SettingPhoneOTP",
            component: () =>
              import(
                /* webpackChunkName: "SettingPhoneOTP" */ "@/views/Pages/SettingPhoneOTP"
              ),
          },
          {
            path: "password/options",
            name: "SettingPasswordOptions",
            component: () =>
              import(
                /* webpackChunkName: "SettingPasswordOptions" */ "@/views/Pages/SettingPasswordOptions"
              ),
          },
          {
            path: "password/update",
            name: "SettingPasswordUpdate",
            component: () =>
              import(
                /* webpackChunkName: "SettingPasswordUpdate" */ "@/views/Pages/SettingPasswordUpdate"
              ),
          },
        ],
      },
    ],
  },
  {
    name: "Error 404",
    path: "/:pathMatch(.*)*",
    redirect: "/",
    component: NotFound,
    meta: {
      title: "Page not Found",
    },
  },
];
