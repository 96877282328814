import { reactive } from "vue";
import {
  Icon,
  BillImageConfirmViewer,
  BaseBackButtonCustomComponent,
  ShopBillConfirmComponent,
  ShopBillExampleComponent,
  SubmissionButtonComponent,
  BaseModalComponent,
} from "@/components";

export default {
  name: "SubmissionConfirm",
  components: {
    Icon,
    BillImageConfirmViewer,
    BaseBackButtonCustomComponent,
    ShopBillConfirmComponent,
    ShopBillExampleComponent,
    SubmissionButtonComponent,
    BaseModalComponent,
  },
  setup() {
    const data = reactive({
      billShow: 0,
      selectedItem: 1,
      title: `ยืนยันบันทึกใบเสร็จจาก`,
      billName: "7-11",
      subtitle: `หากไม่ใช่ กรุณาเลือกใหม่`,
      exBill: `ตัวอย่างใบเสร็จ`,
      buttonBack: {
        name: "Back",
        color: "#FFFFFF",
        colorIcon: "#FFFFFF",
        backgroundColor: "#000000",
      },
      buttonData: {
        title: "ยืนยัน",
        disabled: false,
        class: "fixed",
        active: true,
      },
      billView: {
        image: require("@/assets/images/submission/bill_confirm.jpg"),
      },
      brands: [
        {
          id: 1,
          name: "7-11",
          image: require("@/assets/images/submission/7-11_brand.jpg"),
          bill: require("@/assets/images/submission/7-11_bill.jpg"),
        },
        {
          id: 2,
          name: "Tops",
          image: require("@/assets/images/submission/tops_brand.jpg"),
          bill: require("@/assets/images/submission/7-11_bill.jpg"),
        },
        {
          id: 3,
          name: "Lotus",
          image: require("@/assets/images/submission/lotus_brand.jpg"),
          bill: require("@/assets/images/submission/lotus_bill.jpg"),
        },
        {
          id: 4,
          name: "BigC",
          image: require("@/assets/images/submission/bigc_brand.jpg"),
          bill: require("@/assets/images/submission/7-11_bill.jpg"),
        },
      ],
      modal: {
        type: "success",
        show: false,
        status: 0,
      },
    });

    function handleSelectedItem(value) {
      data.selectedItem = parseInt(value);
      data.billName = data.brands
        .filter((brand) => brand.id == value)
        .map((bill) => bill.name);
      data.billName = data.billName[0];
      // console.log(data.billName)
    }

    function handleConfirm(value) {
      // console.log(value)
      data.modal.show = true;
    }

    function handleSuccess(value) {
      // console.log("handleSuccess:", value)
      data.modal.show = false;
    }

    return {
      data,
      handleSelectedItem,
      handleConfirm,
      handleSuccess,
    };
  },
};
