<style src="./SettingPasswordOptions.scss" lang="scss" scoped></style>
<script src="./SettingPasswordOptions.js"></script>

<template>
	<div class="settings__wrapper">
		<BaseBackButtonCustomComponent
			:go-back="goBack"
			@button:action="handleBackward"
		/>
		<div class="settings__content">
			<BaseTitleComponent :title-name="titleData" class="title" />
			<div class="desc">คุณต้องการเปลี่ยนรหัสผ่านใหม่หรือไม่</div>

			<div class="button__options">
				<BaseButtonSimpleComponent
					class="password__setting"
					:button-data="buttonOldPassword"
					v-on:buttonClick="buttonClick"
				/>

				<BaseButtonSimpleComponent
					class="password__setting"
					:button-data="buttonUpdatePassword"
					v-on:buttonClick="buttonClick"
				/>
			</div>

			<BaseModalComponent
				:modal-show="modalStatus"
				:modal-type="modalType"
				@update:modal="handleModal"
			/>
		</div>
	</div>
</template>