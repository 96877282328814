<style src="./BaseInputMobileComponent.scss" lang="scss"></style>
<script src="./BaseInputMobileComponent.js"></script>

<template>
	<div class="box__input">
		<span>+66</span>
		<el-input
			class="mobile__input"
			:class="{ error: error }"
			:type="inputMobile.type"
			:placeholder="inputMobile.placeholder"
			:maxlength="inputMobile.maxlength"
			v-model="inputMobile.value"
			inputmode="tel"
			@keypress="isNumber($event)"
			@paste.prevent
		>
		</el-input>
	</div>
</template>