import {
  reactive,
} from 'vue'
import {
  ScanProcessingComponent,
  BaseBackButtonCustomComponent,
  BaseBillEditComponent,
  ScanBarActionComponent
} from '@/components';

export default {
  name: 'SubmissionEdit',
  components: {
    ScanProcessingComponent,
    BaseBackButtonCustomComponent,
    BaseBillEditComponent,
    ScanBarActionComponent
  },
  setup() {
    const data = reactive({
      scanProcess: false,
      scanDone: true,
      buttonData: {
        name: 'Back',
        color: '#FFFFFF',
        colorIcon: '#FFFFFF',
        backgroundColor: '#000000',
      },
      buttonAction: [
        {
            icon: 'retake',
            name: 'Retake',
            color: '#BEBDC6',
            backgroundColor: '#FFFFFF',
            borderColor: '#BEBDC6',
            width: 80,
            height: 80,
            accept: 'image/jpeg,image/gif,image/png'
        },
        {
            icon: 'scan_more',
            name: 'Scan more',
            color: '#BEBDC6',
            backgroundColor: '#FFFFFF',
            borderColor: '#BEBDC6',
            width: 80,
            height: 80,
            accept: 'image/jpeg,image/gif,image/png'
        }
      ]
    })

    function handleScanProcess(value) {
      data.scanProcess = value;
      setTimeout(() => {
        // success go to...
        data.scanProcess = false;
        data.scanDone = true
      }, 3000)
    }

    return {
      data,
      handleScanProcess
    }
  }
};