import {
    BaseAddressInputComponent
} from '@/components';

export default {
    name: 'BaseAddressForm',
    components: {
        BaseAddressInputComponent
    },
    props: {
        addressForm: Object
    },
    methods: {
        select (address) {
            this.$emit('select', address)
        }
    },
}