<style src="./BaseBillEditComponent.scss" lang="scss"></style>
<script src="./BaseBillEditComponent.js"></script>

<template>
	<div class="submission__bill__edit">
		<Cropper
			ref="cropper"
			:src="captureImages.Image_Recheck"
			:auto-zoom="false"
			:resize-image="{
				touch: false,
				mouse: false,
			}"
			image-restriction="fit-area"
			:coordinates="coordinates"
			:default-position="defaultPosition"
			:default-size="defaultSize"
			:debounce="500"
			@change="onChange"
		/>
		<!-- 
		:default-position="defaultPosition" 
		:default-size="defaultSize" 
		:default-visible-area="defaultVisibleArea" 
		--></div>
</template>