import { ref, computed, onMounted, watchEffect, onBeforeUnmount } from "vue";
import $ from "jquery";
import { useStore } from "vuex";
import { Icon, BaseTitleComponent } from "@/components";
import Webcam from "webcam-easy-v2";
import { ElMessage } from "element-plus";
import loadImage from "blueimp-load-image";
import { useRouter, useRoute } from "vue-router";
import { cameraSetting } from "@/api/camera";

import {
  uuid,
  isOs,
  logger,
  isBrowser,
  getBrowser,
  getBase64Image,
  hasCameraAccess,
  getAndroidDevice,
  getIOSVersion,
} from "@/helpers";
import Jimp from "jimp";

export default {
  name: "BaseCameraComponent",
  props: {
    imageArray: {
      type: Array,
      default: [],
    },
    imagePrevId: Number,
    imagePrev: {
      type: String,
      default: "",
    },
    scanCapture: Boolean,
    currentAction: {
      type: String,
      default: "capture",
    },
  },
  components: {
    Icon,
    BaseTitleComponent,
  },
  emits: [
    "process:scaning",
    "update:capture",
    "camera:access",
    "update:backbar",
    "update:backbarupload",
  ],
  setup(props, { emit }) {
    let settingHeader = ref(null);
    let settingText = ref(null);
    let cameraAccess = ref(true);
    let cameraUndefined = ref(false);
    emit("camera:access", cameraAccess.value);

    async function loadCameraInfo() {
      let cameraInfo = await cameraSetting();
      if (cameraInfo) {
        settingHeader.value = cameraInfo.access;
        let deviceOs = null;
        const browser = getBrowser();
        if (isOs("android")) {
          deviceOs = `android_${browser}`;
        }
        if (isOs("ios")) {
          deviceOs = `ios_${browser}`;
        }
        const warningText = cameraInfo.steps.filter((val) => {
          return val.id == deviceOs;
        });
        if (warningText.length > 0) {
          settingText.value = warningText[0].text;
          // handle backward bar camera undefined
          emit("update:backbarupload", true);
        }
      }
    }
    loadCameraInfo();

    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    onMounted(() => {
      if (hasCameraAccess() && cameraAccess.value) {
        cameraStarted();
      } else {
        // handle android camera undefined
        cameraUndefined.value = true;
        cameraAccess.value = false;
        emit("camera:access", cameraAccess.value);
      }
    });

    onBeforeUnmount(() => {
      $("body").removeClass("yh");
      if (hasCameraAccess() && cameraAccess.value) {
        cameraStoped();
      }
    });

    watchEffect(async () => {
      if (props.scanCapture && props.currentAction === "capture") {
        await handleTakePhoto(props.scanCapture);
      }
    });

    const imagePrevIdUpdate = computed(() => props.imagePrevId + 1);
    const imagePrevUpdate = computed(() => props.imagePrev);
    const webcamElement = ref(null);
    const canvasElement = ref(null);
    const snapSoundElement = ref(null);
    const webcamDom = ref(null);
    const snapSound = ref(require("@/assets/audio/snap.wav"));

    // logger(imagePrevUpdate.value)

    function cameraStarted() {
      $(".flash").hide();
      // $("body").css("overflow-y", "hidden");
      $("body").addClass("yh");
      // window.scrollTo(0, 0);

      webcamElement.value = document.getElementById("webcam");
      canvasElement.value = document.getElementById("canvas");
      snapSoundElement.value = document.getElementById("snapSound");

      webcamDom.value = new Webcam(
        webcamElement,
        "enviroment",
        canvasElement,
        snapSoundElement
      );

      webcamDom.value
        .start()
        .then((result) => {
          logger("webcam started");
        })
        .catch((err) => {
          if (
            err
              .toString()
              .toLowerCase()
              .indexOf("permission") !== -1 ||
            err
              .toString()
              .toLowerCase()
              .indexOf("denied") !== -1
          ) {
            cameraAccess.value = false;
            emit("camera:access", cameraAccess.value);
            // handle bar color camera access
            emit("update:backbar", true);
          } else if (
            err
              .toString()
              .toLowerCase()
              .indexOf("error") !== -1 ||
            err
              .toString()
              .toLowerCase()
              .indexOf("undefined") !== -1
          ) {
            // handle ios 14 and below camera undefined
            cameraUndefined.value = true;
            cameraAccess.value = false;
            emit("camera:access", cameraAccess.value);
          }
          logger(err);
        });
    }

    function cameraFilp() {
      webcamDom.value.flip();
    }

    function cameraStoped() {
      webcamDom.value.stop();
      logger("webcam stopped");
    }

    async function handleTakePhoto(status) {
      // Call APIs check receipt ...
      const isLoggedIn = await store.dispatch("app/APP_VALID_TOKEN");
      const isJWTToken = await store.getters["profile/token"];

      if (status && isLoggedIn && isJWTToken) {
        beforeTakePhoto();

        // Capture camera
        let pictureSnap = await webcamDom.value.snap();
        let picture = pictureSnap;
        logger("picture", picture);

        if (picture) {
          // Crop offset for one more bill
          let screenHeight = window.innerHeight; // get height for devices
          let cropHeightUI = 152; //default 160

          // fix safe area ios 15 padding
          if (isOs("ios")) {
            if (getIOSVersion()) {
              const rootElement = document.querySelector("body");
              const viewPortH = rootElement.getBoundingClientRect().height;
              const windowH = window.innerHeight;
              const browserUiBarsH = viewPortH - windowH;

              screenHeight = windowH + browserUiBarsH;
            }
          }

          let offsetY = 0;

          offsetY = Math.round(((screenHeight - cropHeightUI) * 22) / 100); // crop from top 22%

          logger("screenHeight", screenHeight);
          logger("offsetY", offsetY);

          // Cropped offset by device with top overlay bill
          if (imagePrevUpdate.value) {
            logger("imagePrevUpdate", imagePrevUpdate.value);
            picture = await base64Cropped(picture, offsetY);
          }

          picture = await reducePicture(picture);

          // var img = document.createElement("img");
          // img.onload = () => {
          //   let canvas = document.createElement("canvas");
          //   let ctx = canvas.getContext("2d");

          //   // We set the dimensions at the wanted size.
          //   canvas.width = img.width;
          //   canvas.height = img.height;

          //   logger("image w", img.width);
          //   logger("image h", img.height);

          //   logger(Math.round(((img.height - cropHeightUI) * 22) / 100));
          //   offsetY = Math.round(
          //     ((screenHeight / img.height - cropHeightUI) * 22) / 100
          //   );
          // };
          // img.src = picture;

          const billContent = {
            BillData: {
              Original: picture,
              coordinates: null,
              Final: null,
            },
            status: status,
            picture: picture,
            cropped: null,
          };

          // const sendData = JSON.stringify({
          //     "id": uuid(),
          //     "image": picture.split(',').pop(),
          //     "token": isJWTToken
          // })

          afterTakePhoto();

          const sendData = {
            id: uuid(),
            image: picture.split(",").pop(),
            token: isJWTToken,
          };

          const promise = new Promise((resolve, reject) => {
            const cropped = store.dispatch("app/APP_CROPPING_BILL", sendData);
            if (cropped) {
              resolve(cropped);
            } else {
              reject(Error("it broke"));
            }
          });

          promise.then(
            (result) => {
              billContent.cropped = result;
              emit("update:capture", billContent);
            },
            (err) => {
              logger(err);
            }
          );
        }

        logger("capture done!!!");
        // router.push({path: 'edit'})
      } else {
        // token invalid
        router.push({
          name: "Login",
        });
      }
    }

    const reducePicture = (data) => {
      return new Promise((resolve, reject) => {
        // alert(data);
        // const maxSize = 5;
        // const options = { maxwidth: 1000, maxheight: 1000 };
        // const { picture } = imageOptimizer(data, maxSize, options);
        // resolve(picture);

        let imgBuffer = new Buffer.from(data.split(",").pop(), "base64");
        var arrayBufferView = new Uint8Array(imgBuffer);
        var blob = new Blob([arrayBufferView], {
          type: "image/jpeg",
        });
        // logger(data);
        const reader = new FileReader();

        reader.onload = async (e) => {
          // logger(e);
          await Jimp.read(e.target.result)
            .then((image) => {
              // trim surrounding fully transparent pixels
              image
                .autocrop({
                  cropOnlyFrames: false,
                  cropSymmetric: false,
                  leaveBorder: false,
                })
                .resize(1000, Jimp.AUTO, Jimp.RESIZE_BEZIER) // resize
                .quality(90)
                .getBase64Async(Jimp.MIME_PNG)
                .then(async (data) => {
                  return resolve(data);
                });
            })
            .catch((err) => {
              if (err) throw err;
              // Handle an exception.
            });

          // await loadImage(
          //   e.target.result,
          //   async (img, data) => {
          //     // logger(img.toDataURL("image/jpeg"));
          //     logger("Original image width: ", data.originalWidth);
          //     logger("Original image height: ", data.originalHeight);
          //     let dataURL = img.toDataURL("image/jpeg");
          //     resolve(dataURL);
          //   },
          //   {
          //     canvas: true,
          //     pixelRatio: 1,
          //     maxWidth: 1000,
          //     // maxHeight: 1000,
          //     // crop: true,
          //     orientation: true,
          //     imageSmoothingEnabled: true,
          //     imageSmoothingQuality: "low",
          //     // crossOrigin: true,
          //   }
          // );
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    };

    function beforeTakePhoto() {
      $(".flash")
        .show()
        .animate(
          {
            opacity: 0.3,
          },
          500
        )
        .fadeOut(500)
        .css({
          opacity: 0.7,
        });
      // window.scrollTo(0, 0);
    }

    function afterTakePhoto() {
      // logger('getAndroidDevice', getAndroidDevice())
      if (!getAndroidDevice() && cameraAccess.value) {
        cameraStoped();
        $("#canvas").removeClass("d-none");
      }
    }

    function base64Cropped(sourceBase64, offsetY) {
      return new Promise((resolve, reject) => {
        // We create an image to receive the Data URI
        var img = document.createElement("img");

        // When the event "onload" is triggered we can resize the image.
        img.onload = () => {
          // We create a canvas and get its context.
          let canvas = document.createElement("canvas");
          let ctx = canvas.getContext("2d");

          // We set the dimensions at the wanted size.
          canvas.width = img.width;
          canvas.height = img.height;

          // We resize the image with the canvas method drawImage();
          // ctx.clearRect(0, 0, img.width, img.height);
          ctx.drawImage(img, 0, 0 - offsetY, img.width, img.height);

          let dataURI = canvas.toDataURL();

          // This is the return of the Promise
          resolve(dataURI);
        };
        img.onerror = (error) => reject(error);

        // We put the Data URI in the image's src attribute
        img.src = sourceBase64;
      });
    }

    function pageRefresh() {
      window.location.reload();
    }

    return {
      settingHeader,
      settingText,
      route,
      router,
      snapSound,
      webcamDom,
      cameraFilp,
      cameraStoped,
      base64Cropped,
      webcamElement,
      canvasElement,
      cameraStarted,
      handleTakePhoto,
      imagePrevUpdate,
      snapSoundElement,
      imagePrevIdUpdate,
      getBrowser,
      isBrowser,
      isOs,
      pageRefresh,
      cameraAccess,
      hasCameraAccess,
      cameraUndefined,
    };
  },
};
