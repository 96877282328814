import { onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import liff from "@line/liff";
import { logger } from "@/helpers";

export default {
  name: "LinkApps",

  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    let LineToken;

    onMounted(() => {
      // console.log(process.env.VUE_APP_LiffID)

      liff.init(
        {
          liffId: process.env.VUE_APP_LiffID,
        },
        (resp) => {
          if (liff.isLoggedIn()) {
            LiffGetProfile();
          } else {
            liff.login();
          }
        }
      );
      // store.commit("BUTTON_LOADING", false)

      // if(!route.query.crossapptoken){
      //   router.push({
      //     // name: 'SubmissionReceipt'
      //     name: "Home",
      //   });
      // }else{
      //   CheckCrosstoken()
      // }
    });

    const LiffGetProfile = () => {
      liff
        .getProfile()
        .then((profile) => {
          // console.log(profile);
          CheckIsFan();
          // this.LineProfile = profile
          // let LineToken = liff.getAccessToken();
          // console.log(LineToken)
          // CheckLinetoken(LineToken)
        })
        .catch((err) => console.error(err));
    };

    const CheckIsFan = () => {
      liff.getFriendship().then((data) => {
        if (data.friendFlag) {
          LineToken = liff.getAccessToken();
          // CheckLinetoken(LineToken);
          CheckGaIsinit();
        } else {
          setTimeout(() => {
            let OA_LINK = process.env.VUE_APP_Line_OAURL;
            window.location = OA_LINK;
            CheckIsFan();
          }, 500);
        }
      });
    };

    const CheckGaIsinit = () => {
      if (!window.gtag) {
        setTimeout(() => {
          CheckGaIsinit();
        }, 500);
      } else {
        CheckLinetoken();
      }
    };

    const CheckLinetoken = async () => {
      const sendData = {
        linetoken: LineToken,
      };

      const response = await store.dispatch("profile/CheckLinetoken", sendData);

      logger("CheckLinetoken", response);

      if (response) {
        if (response.result === "complete") {
          router.push({
            name: "Home",
            query: {
              utm_source: "adapterroxline",
              utm_medium: "liff",
              utm_campaign: "roxlaunch2021nov",
            },
          });
        } else {
          if (response.resultdetail == "no_account_linked") {
            // commit("SetLineToken", LineToken);
            let URL = `${process.env.VUE_APP_BASEAPPURL}Gateway?linetoken=${LineToken}&utm_source=adapterroxline&utm_medium=liff-browser-forward&utm_campaign=roxlaunch2021nov`;
            if (liff.isInClient()) {
              liff.openWindow({
                url: URL,
                external: true,
              });
            } else {
              window.location = URL;
            }

            // liff.closeWindow()
          } else {
            // console.log('line token invallid')s

            let URL = `${process.env.VUE_APP_BASEAPPURL}Gateway?utm_source=adapterroxline&utm_medium=liff-browser-forward&utm_campaign=roxlaunch2021nov`;
            if (liff.isInClient()) {
              liff.openWindow({
                url: URL,
                external: true,
              });
            } else {
              window.location = URL;
            }
            // liff.closeWindow()
          }
        }
      } else {
        logger("api error");
      }
    };
  },
};
