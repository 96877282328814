<style src="./BillImageConfirmViewer.scss" lang="scss" scoped></style>
<script src="./BillImageConfirmViewer.js"></script>

<template>
	<div
		class="bill__viewer"
		:style="(billView.type === 'image')?`height:${billHeight}px`:`height:400px`"
		:data-load-bill="totalBillHeight"
	>
		<v-zoomer
			class="image_preview"
			ref="Zoommer"
			:pivot="`image-center`"
			v-if="billView.type !== 'pdf'"
		>
			<div class="merge__images__contain">
				<img
					:id="`bill-id-${index + 1}`"
					v-for="(image, index) in setBillView"
					:key="index"
					:src="image"
				/>
			</div>
		</v-zoomer>
		<canvas id="pdfViewer" v-else></canvas>

		<div class="button__action__group">
			<div class="button__zoom">
				<Icon name="zoom-in-44x44" @click="$refs.Zoommer.zoomIn()" />
			</div>
			<div class="button__zoom">
				<Icon name="zoom-reset-44x44" @click="$refs.Zoommer.reset()" />
			</div>
			<div class="button__zoom">
				<Icon name="zoom-out-44x44" @click="$refs.Zoommer.zoomOut()" />
			</div>
		</div>
	</div>
</template>