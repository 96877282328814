// import VueEasyLightbox from 'vue-easy-lightbox'
import VueZoomer from "vue-zoomer";
import { Icon } from "@/components";
import { hideLoading } from "@/helpers";

export default {
  name: "BillImageConfirmViewer",
  components: {
    VueZoomer,
    Icon,
  },
  props: {
    billView: {
      type: Object,
      required: true,
    },
    billShow: {
      type: Number,
      required: true,
    },
    billReady: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    setBillView: function() {
      this.logger("setBillView", this.billView.image);
      return this.billView.image;
    },
    totalBillHeight: function() {
      if (this.billReady && this.billView.type === "image") {
        // let billHeight = 0
        setTimeout(() => {
          for (let i = 1; i <= this.billView.image.length; i++) {
            var img = document.getElementById(`bill-id-${i}`);

            this.billHeight += img.height;
          }
          hideLoading();
        }, 2000);
      }
    },
  },
  mounted() {
    this.index = this.billShow;
  },
  data() {
    return {
      visible: true,
      index: 0,
      billHeight: 0,
    };
  },
  methods: {
    NextBill() {
      this.index += 1;
    },
    PrevBill() {
      this.index -= 1;
    },
    handleHide() {
      this.$emit("hideModal");
    },
  },
};
