import {
  reactive,
  computed,
  watchEffect,
  onMounted,
  nextTick,
  observable,
  ref,
  inject,
  watch,
} from "vue";
import Jimp from "jimp";
import dayjs from "dayjs";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import mergeImages from "merge-base64-v2";
import * as htmlToImage from "html-to-image";
import { useRouter, useRoute } from "vue-router";
import { logger, showLoading, hideLoading } from "@/helpers";

import {
  ScanBarComponent,
  BaseModalComponent,
  BaseCameraComponent,
  BaseBillEditComponent,
  ScanBarActionComponent,
  BillImageConfirmViewer,
  ScanProcessingComponent,
  ShopBillConfirmComponent,
  SubmissionButtonComponent,
  BaseBackButtonCustomComponent,
} from "@/components";

export default {
  name: "SubmissionScan",
  components: {
    ScanBarComponent,
    BaseModalComponent,
    BaseCameraComponent,
    BaseBillEditComponent,
    ScanBarActionComponent,
    BillImageConfirmViewer,
    ScanProcessingComponent,
    ShopBillConfirmComponent,
    SubmissionButtonComponent,
    BaseBackButtonCustomComponent,
  },
  setup(props, { emit }) {
    const billImageConfirmViewerHeight = ref(false);
    let cameraAccess = ref(null);
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const trackEvent = inject("trackEvent");

    logger("route", route.params.id);

    const data = reactive({
      billShow: 0,
      selectedItem: null,
      title: `เลือกห้างร้าน/ร้านค้าของใบเสร็จ`,
      billName: null,
      subtitle: `* กรุณาระบุร้านค้าของใบเสร็จ `,
      exBill: `ตัวอย่างใบเสร็จ`,
      scanCapture: false,
      scanProcess: false,
      scanDone: false,
      coordinates: {},
      page: "capture",
      captureImages: {
        BillsList: [],
        BillsIndex: 0,
        BillsPrev: null,
        Image_Recheck: null,
        DeviceList: null,
      },
      buttonData: {
        name: "Back",
        color: "#FFFFFF",
        colorIcon: "#FFFFFF",
        backgroundColor: "#000000",
      },
      buttonScan: {
        name: "สแกน",
        color: "#FFFFFF",
        backgroundColor: "#000000",
      },
      buttonAction: [
        {
          icon: "retake",
          name: "ถ่ายใหม่",
          color: "#BEBDC6",
          backgroundColor: "#FFFFFF",
          borderColor: "#BEBDC6",
          width: 80,
          height: 80,
          accept: "image/jpeg,image/gif,image/png",
          show: true,
        },
        {
          icon: "scan_more",
          name: "สแกนเพิ่ม",
          color: "#BEBDC6",
          backgroundColor: "#FFFFFF",
          borderColor: "#BEBDC6",
          width: 80,
          height: 80,
          accept: "image/jpeg,image/gif,image/png",
          show: true,
        },
      ],
      imageArray: [],
      buttonSetting: {
        title: "ยืนยัน",
        disabled: true,
        class: "",
        active: false,
      },
      billView: {
        image: require("@/assets/images/submission/bill_confirm.jpg"),
        type: "image",
      },
      brands: [
        {
          id: 1,
          name: "7-11",
          image: require("@/assets/images/submission/7-11_brand.jpg"),
          bill: require("@/assets/images/submission/7-11_bill.jpg"),
        },
        {
          id: 2,
          name: "Tops",
          image: require("@/assets/images/submission/tops_brand.jpg"),
          bill: require("@/assets/images/submission/7-11_bill.jpg"),
        },
        {
          id: 3,
          name: "Lotus",
          image: require("@/assets/images/submission/lotus_brand.jpg"),
          bill: require("@/assets/images/submission/lotus_bill.jpg"),
        },
        {
          id: 4,
          name: "BigC",
          image: require("@/assets/images/submission/bigc_brand.jpg"),
          bill: require("@/assets/images/submission/7-11_bill.jpg"),
        },
      ],
      modal: {
        type: "success",
        show: false,
        status: 0,
      },
      base64Arr: [],
      imagesMargin: [],
    });

    watch(
      () => "data.billName",
      (oldval, newval) => {
        if (newval) data.billName = newval;
      }
    );

    watchEffect(() =>
      store.commit("app/SET_SHOPBILL_SELECTED", route.params.id)
    );

    logger(`shop_selected`, store.getters["app/SET_SHOPBILL_SELECTED"]);

    function handleScanProcess(value) {
      logger(`handleScanProcess`, value);

      trackEvent("SubmissionScan - Scan", {
        event_category: "SubmissionScan",
        event_label: "Scan",
        value: true,
      });

      if (window.innerHeight < window.innerWidth) {
        return ElMessage({
          message: "Please use Portrait!",
          type: "error",
          duration: 5 * 1000,
        });
      }

      data.scanCapture = value;

      // wait process after capture
      setTimeout(() => {
        data.scanProcess = true;
      }, 1000);

      logger(`scanProcess`, data.scanProcess);
    }

    function handleCapture({ BillData, picture, cropped }) {
      logger("handleCapture", cropped);

      data.scanProcess = false;
      data.page = "recheck";
      data.captureImages.BillsList.push(BillData);
      data.captureImages.BillsIndex = data.captureImages.BillsList.length - 1;
      data.captureImages.Image_Recheck = picture;

      logger("scanProcess", data.scanProcess);

      // enable button scan
      const btn = document.querySelector(".scan__button");
      btn.removeAttribute("disabled");
      btn.removeAttribute("data-prevent-double-click");

      convertContours(cropped);
    }

    function handleBeforeCapture(value) {
      // call api check validate reciept
      logger("handleBeforeCapture", value);
      data.scanProcess = value;
      logger("scanProcess", data.scanProcess);
    }

    function handleCropper({ coordinates, canvas }) {
      logger(coordinates, canvas);
      data.captureImages.BillsList[
        data.captureImages.BillsIndex
      ].coordinates = coordinates;
      data.captureImages.BillsList[
        data.captureImages.BillsIndex
      ].Final = canvas.toDataURL();
      // logger(data.captureImages);
    }

    async function handleRecheckDone(value) {
      trackEvent("SubmissionScan - Scan", {
        event_category: "SubmissionScan",
        event_label: "Done",
        value: true,
      });
      logger(`handleRecheckDone`, data.captureImages);
      // PROCESS MERGE IMAGE HERE...
      showLoading();
      // data.scanProcess = value;

      setTimeout(async () => {
        const index = data.captureImages.BillsList.length - 1;
        const lastBill = data.captureImages.BillsList[index];
        data.captureImages.BillsPrev = lastBill.Final;

        const resultMerge = await mergeImageAll();

        logger("resultMerge", resultMerge);

        if (resultMerge) {
          // data.scanProcess = false;
          data.page = "done";

          billImageConfirmViewerHeight.value = true;
        }
      }, 500);
    }

    async function mergeImageAll() {
      // const images = data.captureImages.BillsList;
      // const imagesMargin = [];
      // const base64Arr = [];

      // images.map((imageObj) => {
      //   // trim : data:image/png;base64
      //   const base64String = imageObj.Final.split(",").pop();
      //   base64Arr.push(base64String);

      //   imagesMargin.push({
      //     left: imageObj.coordinates.left,
      //     top: imageObj.coordinates.top,
      //   });
      // });

      data.base64Arr = [];
      data.imagesMargin = [];
      const images = data.captureImages.BillsList;
      images.map((imageObj) => {
        // const base64String = imageObj.Final.split(",").pop();
        const base64String = imageObj.Final;
        data.base64Arr.push(base64String);
        data.imagesMargin.push({
          left: imageObj.coordinates.left,
          top: imageObj.coordinates.top,
        });
      });

      // logger(
      //   "BeforeMergeTime",
      //   dayjs().format("[YYYYescape] YYYY-MM-DDTHH:mm:ssZ[Z]")
      // );
      logger("base64Arr", data.base64Arr);
      logger("imagesMargin", data.imagesMargin);

      const options = {
        direction: true,
        align: "center",
        color: "#FFFFFF",
        margin: data.imagesMargin,
        // offset: 0
      };

      // get final for 1 image only
      // const index = data.captureImages.BillsList.length - 1;
      // const lastBill = data.captureImages.BillsList[index];

      // merge
      // mergedImage = await mergeImages(data.base64Arr, options);
      let mergedImage = null;
      // alert(data.base64Arr.length);
      mergedImage = data.base64Arr;

      logger("mergedImage", mergedImage);

      data.billView.image = mergedImage;

      // PROCESS MERGE IMAGE HERE...
      return true;
    }

    function handleRetakeProcess() {
      trackEvent("SubmissionScan - Scan", {
        event_category: "SubmissionScan",
        event_label: "Retake",
        value: true,
      });

      data.captureImages.BillsList.pop();

      if (data.captureImages.BillsList.length > 0) {
        data.captureImages.BillsIndex = data.captureImages.BillsList.length - 1;
        const lastBill =
          data.captureImages.BillsList[data.captureImages.BillsIndex];
        data.captureImages.BillsPrev = lastBill.Final;

        data.scanCapture = false;

        nextTick(() => {
          data.page = "capture";
        });
      } else {
        router.push({ path: `/Submission/scan/${Math.random()}` });
      }

      logger("handleRetakeProcess BillsPrev", data.captureImages.BillsPrev);
      logger(`handleRetakeProcess`, data.captureImages);
    }

    function handleScanMoreProcess() {
      trackEvent("SubmissionScan - Scan", {
        event_category: "SubmissionScan",
        event_label: "Scan more",
        value: true,
      });

      const index = data.captureImages.BillsList.length - 1;
      const lastBill = data.captureImages.BillsList[index];

      data.captureImages.BillsPrev = lastBill.Final;
      data.scanCapture = false;

      logger(`handleScanMoreProcess`, data.captureImages.BillsPrev);

      nextTick(() => {
        data.page = "capture";
      });
    }

    function handleSelectedItem(value) {
      data.selectedItem = parseInt(value);

      if (value) {
        data.billName = data.brands
          .filter((brand) => brand.id == value)
          .map((bill) => bill.name);

        data.billName = data.billName[0];
        data.buttonSetting.disabled = data.billName ? false : true;
        data.buttonSetting.active = data.billName ? true : false;

        trackEvent("SubmissionScan - Scan", {
          event_category: "SubmissionScan",
          event_label: "Shop",
          value: data.billName,
        });
      } else {
        data.billName = null;
      }

      logger("handleSelectedItem", data.billName);
    }

    async function handleConfirm(value) {
      showLoading();

      store.commit("APP_LOADING", true);

      logger("handleConfirm", value);

      // Call APIs check receipt ...
      const isJWTToken = store.getters["profile/token"];
      data.captureImages.BillsList.filter(
        (data) => data.Original && data.Final
      ).map((bill) => {
        const setOriginalBase64 =
          data.billView.type === "image"
            ? bill.Original.split(",").pop()
            : bill.Original;
        const setFinalBase64 =
          data.billView.type === "image"
            ? bill.Final.split(",").pop()
            : bill.Final;

        logger("setOriginalBase64", setOriginalBase64);
        logger("setFinalBase64", setFinalBase64);

        bill.Original = setOriginalBase64;
        bill.Final = setFinalBase64;
      });

      logger("billRawData", data.captureImages.BillsList);

      // html to image
      let imagePreview = "";
      let receiptPicBase64 = null;

      // 7-11 = seven, Tops = tops, Lotus = lotus, BigC = bigc
      const retailShopName =
        data.billName == "7-11"
          ? "seven"
          : data.billName == "Tops"
          ? "tops"
          : data.billName == "Lotus"
          ? "lotus"
          : data.billName == "BigC"
          ? "bigc"
          : "other";

      if (data.billView.type === "image") {
        imagePreview = await combineReceipt();
        logger("imagePreview", { imagePreview });
        receiptPicBase64 = imagePreview.split(",").pop();
      } else {
        receiptPicBase64 = data.billView.image;
      }

      // submit bill by type: image, pdf
      let sendData = {
        token: isJWTToken,
        receiptpic: receiptPicBase64,
        receipttype: data.billView.type,
        receiptrawdata:
          data.billView.type === "image" ? data.captureImages.BillsList : "",
        retail: retailShopName,
      };

      logger("sendData", sendData);

      const response = await store.dispatch(
        "app/APP_CREATE_BILL_DATA",
        sendData
      );
      if ((await response) && data.billName !== "other") {
        logger("result", response);

        data.modal.show = true;
        data.billName = null;
        data.buttonSetting.disabled = true;
        data.buttonSetting.active = false;

        trackEvent("SubmissionScan - Scan", {
          event_category: "SubmissionScan",
          event_label: "Preview confirm",
          value: true,
        });

        hideLoading();
      } else {
        logger("sendData", "can not send data");
      }

      // const promise = new Promise((resolve, reject) => {
      //   const response = store.dispatch("app/APP_CREATE_BILL_DATA", sendData);
      //   if (response) {
      //     resolve(response);
      //   } else {
      //     reject(Error("it broke"));
      //   }
      // });

      // promise.then(
      //   (result) => {
      //     logger("result", result);
      //     hideLoading();
      //     data.modal.show = true;
      //     data.billName = null;
      //   },
      //   (err) => {
      //     logger("sendData", err);
      //   }
      // );
    }

    async function combineReceipt() {
      const node = document.querySelector(".merge__images__contain");

      return await htmlToImage
        .toJpeg(node, {
          quality: 0.8,
          pixelRatio: 0,
          backgroundColor: "rgba(0,0,0)",
        })
        .then((dataUrl) => {
          return dataUrl;
        })
        .catch((error) => {
          console.error("oops, something went wrong!", error);
        });
    }

    function handleSuccess(value) {
      logger("handleSuccess:", value);

      logger("type:", value.type);

      router.push({
        name: "Home",
      });
      // data.modal.show = false;
    }

    function handleButtonAction(value) {
      logger(`handleButtonAction`, value);
      logger(`BillsIndex`, data.captureImages.BillsIndex);
      logger(`BillsPrev`, data.captureImages.BillsPrev);

      if (value === "capture") {
        data.scanCapture = false;
        onResetCaptureImages();
      }
      data.page = value;
    }

    function handleBeforeUploadFile(value) {
      if (!value.status) {
        return ElMessage({
          message: value.message,
          type: "error",
          duration: 5 * 1000,
        });
      }
      data.scanProcess = true;
    }

    function handleUploadFile(value) {
      logger("handleUploadFile", value);
      data.billView.type = value.type;
      if (value.type === "image") {
        onUploadGallery(value);
      } else {
        onUploadFile(value);
      }
    }

    function onUploadGallery({
      BillData,
      picture,
      cropped,
      imageWidth,
      imageHeight,
    }) {
      trackEvent("SubmissionScan - Gallery", {
        event_category: "SubmissionScan",
        event_label: "Gallery",
        value: true,
      });
      // logger(`onUploadGallery`)
      // logger(cropped)

      // Set show button for type image, pdf upload
      // data.buttonAction
      //   .filter((button) => button.icon === "scan_more")
      //   .map((status) => (status.show = false));

      data.scanProcess = false;
      data.page = "recheck";
      data.captureImages.BillsList.push(BillData);
      data.captureImages.BillsIndex = data.captureImages.BillsList.length - 1;
      data.captureImages.Image_Recheck = picture;

      convertContours(cropped);
    }

    function onUploadFile({ type, BillData, picture }) {
      logger(`onUploadFile`);

      data.scanProcess = false;
      data.page = "done";
      data.captureImages.BillsList.push(BillData);
      data.captureImages.BillsIndex = data.captureImages.BillsList.length - 1;
      data.captureImages.Image_Recheck = picture;
      data.billView.image = picture;

      logger(`type`, type);
      logger(`BillData`, BillData);
      logger(`picture`, picture);

      trackEvent("SubmissionScan - File", {
        event_category: "SubmissionScan",
        event_label: "File",
        value: true,
      });
    }

    function convertContours(cropped) {
      const { contours } = cropped;

      let left = 0;
      let top = 0;
      let width = 0;
      let height = 0;
      // let screenHeight = window.innerHeight
      // let offsetY = Math.ceil(((screenHeight - 152) * 22) / 100)

      if (contours.length !== 0) {
        const leftX = contours[0][0];
        const leftY = contours[0][1];
        const rightX = contours[1][0];
        const rightY = contours[1][1];
        const topX = contours[2][0];
        const topY = contours[2][1];
        const bottomX = contours[3][0];
        const bottomY = contours[3][1];

        const minX = Math.min(leftX, rightX, topX, bottomX);
        const minY = Math.min(leftY, rightY, topY, bottomY);
        const maxX = Math.max(leftX, rightX, topX, bottomX);
        const maxY = Math.max(leftY, rightY, topY, bottomY);

        left = minX;
        top = minY;
        width = maxX - minX;
        height = maxY - minY;
      }

      // check take more bill optimize new offset images overlap
      // if (data.captureImages.BillsIndex > 0) {

      //   const y = top
      //   const diffY = offsetY - y

      //   top = offsetY
      //   height = height - diffY

      //   logger('BillsIndex', data.captureImages.BillsIndex)
      //   logger('offsetY', offsetY)
      //   logger('diffY', diffY)
      //   logger('top', top)
      //   logger('height', height)

      // }
      // end

      // coordinates
      data.coordinates = {
        left,
        top,
        width,
        height,
      };

      logger("coordinates", data.coordinates);
    }

    function onResetCaptureImages() {
      data.captureImages.BillsList = [];
      data.captureImages.BillsIndex = 0;
      data.captureImages.BillsPrev = null;
      data.captureImages.Image_Recheck = null;
      data.captureImages.DeviceList = null;
      logger(`onResetCaptureImages`, data.captureImages);
    }

    function handleCameraAccess(status) {
      cameraAccess.value = status;
    }

    function handleRecapture() {
      data.scanCapture = false;
    }

    function handleBackBar(val) {
      if (val) {
        Object.assign(data.buttonData, {
          color: "#000000",
          colorIcon: "#5653FF",
          backgroundColor: "#FFFFFF",
        });
      }
    }

    function handleBackBarUpload(val) {
      if (val) {
        Object.assign(data.buttonData, {
          color: "#FFFFFF",
          colorIcon: "#FFFFFF",
          backgroundColor: "#000000",
        });
      }
    }

    return {
      data,
      route,
      onUploadFile,
      handleCapture,
      handleCropper,
      handleConfirm,
      handleSuccess,
      convertContours,
      onUploadGallery,
      handleUploadFile,
      handleRecheckDone,
      handleScanProcess,
      handleSelectedItem,
      handleButtonAction,
      handleBeforeCapture,
      handleRetakeProcess,
      onResetCaptureImages,
      handleScanMoreProcess,
      handleBeforeUploadFile,
      handleCameraAccess,
      cameraAccess,
      billImageConfirmViewerHeight,
      handleBackBar,
      handleBackBarUpload,
    };
  },
};
