import { searchAddressByDistrict, searchAddressByAmphoe, searchAddressByProvince, searchAddressByZipcode } from 'thai-address-database'
import vClickOutside from 'click-outside-vue3'

export default {
    name: 'BaseAddressInput',
    props: {
        value: {
            type: [String, Number]
        },
        type: {
            type: String
        },
        label: {
            type: String
        },
        placeholder: {
            type: String
        },
        color: {
            type: String
        },
        size: {
            type: String,
            default: 'default'
        }
    },
    data() {
        return {
            currentValue: this.value,
            currentColor: this.color || '#f5f5f5',
            itemOnFocus: 0,
            isOpenListContainer: false,
            hasFocus: false
        }
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    computed: {
        resultsFromSearch() {
            if (this.type) {
                if (this.type === 'district') return this.resultsFromSearchByDistrict
                else if (this.type === 'amphoe') return this.resultsFromSearchByAmphoe
                else if (this.type === 'province') return this.resultsFromSearchByProvince
                else if (this.type === 'zipcode') return this.resultsFromSearchByZipcode
            } else {
                this._errorLog('type is undefined.')
                return []
            }
        },
        resultsFromSearchByDistrict() {
            if (this.currentValue !== undefined) {
                return searchAddressByDistrict(this.currentValue)
            }
            else{
                return []
            }
        },
        resultsFromSearchByAmphoe() {
            if (this.currentValue !== undefined) {
                return searchAddressByAmphoe(this.currentValue)
            }
            else{
                return []
            }
        },
        resultsFromSearchByProvince() {
            if (this.currentValue !== undefined) {
                return searchAddressByProvince(this.currentValue)
            }
            else{
                return []
            }
        },
        resultsFromSearchByZipcode() {
            if (this.currentValue !== undefined) {
                return searchAddressByZipcode(this.currentValue)
            }
            else{
                return []
            }
        }
    },
    watch: {
        currentValue(value) {
            this.$emit('input', this.currentValue)
            if (this.hasFocus) {
                this.isOpenListContainer = true
            }
            this.itemOnFocus = 0
        },
        value(value) {
            if (value !== this.currentValue) {
                this.currentValue = value
                this.$nextTick(() => this.isOpenListContainer = false)
            }
        },
        color(val) {
            this.currentColor = val
        }
    },
    methods: {
        onClickOutside(event) {
            this.isOpenListContainer = false
        },
        pressArrow(direction) {
            if (direction === 'up') {
                this.setInputCursorToLastChar()
                this.itemOnFocus = this.itemOnFocus > 0 ? this.itemOnFocus - 1 : 0
            } else {
                this.itemOnFocus = this.itemOnFocus < this.resultsFromSearch.length - 1 ? this.itemOnFocus + 1 : this.resultsFromSearch.length - 1
            }
            this.moveScrollOfListContainer()
        },
        pressEnter() {
            this.setSelectedValue(this.resultsFromSearch[this.itemOnFocus])
        },
        moveScrollOfListContainer() {
            const list = this.$refs.dropdown
            const element = list.querySelectorAll('.list')[this.itemOnFocus]
            if (!element) return
            const visMin = list.scrollTop
            const visMax = list.scrollTop + list.clientHeight - element.clientHeight
            if (element.offsetTop < visMin) {
                list.scrollTop = element.offsetTop
            } else if (element.offsetTop >= visMax) {
                list.scrollTop = (
                    element.offsetTop -
                    list.clientHeight +
                    element.clientHeight
                )
            }
        },
        clickSelectItem(address) {
            this.setSelectedValue(address)
        },
        setSelectedValue(address) {
            this.$emit('select', address)
            this.type ? this.currentValue = address[this.type] : this._errorLog('type is undefined.')
            this.$nextTick(() => this.isOpenListContainer = false)
        },
        setInputCursorToLastChar() {
            const len = this.currentValue.length * 2
            setTimeout(() => { this.$refs.input.setSelectionRange(len, len) }, 1)
        },
        displayItem(address) {
            return address['district']+'>>'+address['amphoe']+'>>'+address['province']+'>>'+address['zipcode'] 
        },
        _errorLog(text) {
            console.error(`[ERROR] thailand-address-database : ${text}`)
        }
    }
}