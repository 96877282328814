<style src="./PointLastestComponent.scss" lang="scss" scoped ></style>
<script src="./PointLastestComponent.js"></script>

<template>
	<div class="point-lastest">
		<div class="point-lastest-title">รายการล่าสุด</div>
		<PointhistoryListComponent :PointLists="PointLists" />

		<router-link to="" @click="handleClick" class="bt_more">
			ดูรายละเอียดเพิ่มเติม
		</router-link>
	</div>
</template>