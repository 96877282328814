<style src="./AppbarComponent.scss" lang="scss"></style>
<script src="./AppbarComponent.js"></script>

<template>
	<section>
		<nav class="appbar">
			<router-link to="/Home" class="appbar__link">
				<Icon
					name="home"
					class="appbar__icon home"
					:class="{ active: $route.name == 'Home' }"
				/>
				<span class="appbar__text" :class="{ active: $route.name == 'Home' }"
					>หน้าหลัก</span
				>
			</router-link>
			<router-link to="/point-status" class="appbar__link">
				<Icon
					name="pointstatus"
					class="appbar__icon points"
					:class="{ active: $route.name == 'point-earned' }"
				/>
				<span
					class="appbar__text"
					:class="{ active: $route.name == 'point-earned' }"
					>ประวัติสะสมพอยท์</span
				>
			</router-link>
			<router-link to="/Submission" class="appbar__link appbar__link--circle">
				<Icon name="scan" class="appbar__icon scan" />
				<span class="appbar__text">สแกน</span>
			</router-link>
			<router-link to="/Rewards" class="appbar__link">
				<Icon
					name="rewards"
					class="appbar__icon rewards"
					:class="{ active: $route.name == 'BannerDetail' }"
				/>
				<span
					class="appbar__text"
					:class="{ active: $route.name == 'BannerDetail' }"
					>ของรางวัล</span
				>
			</router-link>
			<router-link to="" class="appbar__link" @click="showMore">
				<Icon name="menu" class="appbar__icon menu" />
				<span class="appbar__text">รายการอื่นๆ</span>
			</router-link>
		</nav>
		<div class="more__menu">
			<div class="backdrop">
				<nav class="sheet">
					<ul class="sheet__menu">
						<li>
							<router-link to="" @click="handleClick">
								<div class="link__contain">
									<div class="icon-sheet">
										<Icon name="user" class="sheet__icon user" />
									</div>
									<div class="menu__name">ข้อมูลส่วนตัว</div>
								</div>
							</router-link>
						</li>
						<li>
							<router-link to="" @click="handleHowtoSend">
								<div class="link__contain">
									<div class="icon-sheet">
										<Icon name="ScanIcon" class="sheet__icon howto" />
									</div>
									<div class="menu__name">วิธีการส่งใบเสร็จ</div>
								</div>
							</router-link>
						</li>
						<!-- <li>
							<router-link to="/Policy">
								<Icon name="padlock" class="sheet__icon" />
								<span class="menu__name">นโยบายความเป็นส่วนตัว</span>
							</router-link>
						</li> -->
						<li>
							<router-link to="/Contact-Us">
								<div class="link__contain">
									<div class="icon-sheet">
										<Icon name="card" class="sheet__icon contact" />
									</div>
									<div class="menu__name">ติดต่อเรา</div>
								</div>
							</router-link>
						</li>
						<li @click="SignOut">
							<router-link to="">
								<div class="link__contain">
									<div class="icon-sheet">
										<Icon name="exit" class="sheet__icon signout" />
									</div>
									<div class="menu__name">ออกจากระบบ</div>
								</div>
							</router-link>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	</section>
</template>