<style src="./BaseSendOTPComponent.scss" lang="scss"></style>
<script src="./BaseSendOTPComponent.js"></script>

<template>
	<div class="box__send__otp">
		<ul class="list__items">
			<li v-if="sendData.error" class="error error__alert">
				{{ sendData.error }}
			</li>
			<li v-else>
				{{ sendData.msg }}
			</li>
			<li>
				<a @click="RequestApply" v-if="ShowResend">{{ sendData.link }}</a>
			</li>
		</ul>
	</div>
</template>