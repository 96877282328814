<style src="./RewardLists.scss" lang="scss" scoped></style>
<script src="./RewardLists.js"></script>

<template>
	<div class="content__view">
		<BaseProfileHeaderComponent />
		<BaseBackButtonComponent />
		<div class="rewards__tab">
			<div class="tab__button">
				<el-button
					class="button margin-right"
					:class="type === 'luckydraws' ? 'tab__button--active' : ''"
					@click="getRewardList('luckydraws')"
					>ลุ้นรางวัล</el-button
				>
				<el-button
					class="button margin-left"
					:class="type === 'redemptions' ? 'tab__button--active' : ''"
					@click="getRewardList('redemptions')"
					>แลกรางวัล</el-button
				>
			</div>
		</div>
		<div class="rewards__list">
			<BaseListItemComponent
				v-if="dataLists && dataLists.length"
				:list-data="dataLists"
				v-on:viewDetail="viewDetail"
				:key="type"
			/>
		</div>
	</div>
</template>