export default {
  name: "BaseButtonSimple",
  props: {
    class: String,
    buttonData: Object,
    buttonClass: Boolean,
    buttonClassChange: Boolean,
  },
  methods: {
    buttonClick(item) {
      this.$emit("buttonClick", item);
    },
  },
};
