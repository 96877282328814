<template>
	<div id="pdpa__pin" class="pdpa__term__service">
		<div class="service__head">
			<h1>เงื่อนไขและข้อกำหนด</h1>
			<el-switch
				v-model="value"
				active-color="#6CD064"
				inactive-color="#DBDBDB"
				active-value="1"
				inactive-value="0"
				@change="handleSwitch"
			>
			</el-switch>
		</div>
		<div class="service__body">
			<span v-if="!valueTos" class="error"
				>* กรุณายอมรับเงื่อนไขและข้อกำหนด</span
			>
			<p>
				อะแด็ปเตอร์ ดิจิตอล จำกัด (“บริษัท”) ได้จัดทำกิจกรรม "Roxketeer ช้อปชนะ”
				ขึ้น เพื่อการสื่อสารระหว่างบริษัทฯ กับผู้ลงทะเบียนสมัครเป็นสมาชิก
				("สมาชิก") และผู้เยี่ยมชมทั่วไป ("ผู้ชม") (ซึ่งต่อไปนี้จะเรียกรวมกันว่า
				“ท่าน”) หากท่านได้กดตกลงใช้ และเข้าชมผ่านโปรแกรมการเข้าถึงเว็บไซต์ต่าง ๆ
				นี้ ถือว่าท่านให้ความยินยอมและยอมรับโดยสมัครใจในการปฏิบัติตามข้อกำหนด
				รวมตลอดถึงยินยอมให้บริษัทฯ จัดเก็บรวบรวม ใช้
				หรือเปิดเผยข้อมูลส่วนบุคคลของท่านตามวัตถุประสงค์ที่ระบุไว้ในข้อกำหนดและเงื่อนไขนี้ทุกประการ
				หากว่าท่านไม่ยินยอม ไม่ยอมรับหรือไม่ต้องการผูกพันตนเองตามเงื่อนไขนี้
				โปรดยุติการเข้าชมและออกจากกิจกรรมนี้โดยทันที
			</p>
			<p class="paragraph">
				บริษัทฯ ขอสงวนสิทธิในการปรับเปลี่ยน เปลี่ยนแปลง แก้ไข
				เพิ่มเติมหรือยกเลิกข้อกำหนดและเงื่อนไข ข้อหนึ่งข้อใดหรือทั้งหมด
				ตลอดจนข้อตกลงอื่นใดที่อาจมีหรืออ้างถึงบนกิจกรรมนี้โดยไม่จำเป็นต้องแจ้งให้ท่านทราบล่วงหน้า
			</p>
			<h1>กติกาการร่วมกิจกรรม</h1>
			<ul>
				<li>
					1.ท่านจะต้องลงทะเบียนสมัครเป็นสมาชิก และเข้าสู่ระบบก่อนร่วมกิจกรรม
				</li>
				<li>
					2. ท่านจะต้องลงทะเบียนด้วยตัวเอง ด้วยข้อมูลส่วนตัวที่เป็นจริง
					และข้อมูลการติดต่อที่ยังใช้งานอยู่ปัจจุบันเท่านั้น เพื่อให้ทางบริษัทฯ
					สามารถตรวจสอบยืนยันตัวบุคคลท่านในกรณีแลกรับของรางวัลต่าง ๆ ได้
				</li>
				<li>
					3. ท่านสามารถมีบัญชี “Roxketeer ช้อปชนะ” ได้ 1 บัญชีเท่านั้น
					หากตรวจสอบพบว่า มีการสร้างบัญชีที่ตัวตนผู้ใช้งานซ้ำกันกับที่มีอยู่เดิม
					หรือสร้างอีเมลที่ไม่สามารถระบุตัวตนได้ (สแปมเมล)
					บัญชีดังกล่าวจะถือเป็นโมฆะ ไม่สามารถแลกรับของรางวัลได้
					รวมถึงพอยท์ที่ถูกใช้งานไปแล้วจะไม่สามารถโอนกลับคืนสู่บัญชีได้
				</li>
				<li>
					4. วิธีร่วมสนุก
					<div>4.1 ช้อป : ช้อปสินค้าตามรายการสินค้าที่กำหนด</div>
					<div>
						4.2 ถ่ายรูปใบเสร็จ :
						ถ่ายรูปหรืออัปโหลดใบเสร็จที่เห็นรายละเอียดชื่อสินค้า
						ร้านค้าอย่างชัดเจน
					</div>
					<div>
						4.3 ส่ง : กดส่งใบเสร็จและรออนุมัติจากผู้ดูแลระบบ
						คะแนนสะสมจะขึ้นในวันถัดไป
					</div>
					<div>4.4 ลุ้น : รอลุ้นรางวัลใหญ่กว่า 20 รายการ หลังจบกิจกรรม</div>
				</li>
				<li>
					5. รอบการซื้อและส่งใบเสร็จตั้งแต่วันที่ 15 พ.ย.-12 ธ.ค. 2564
					(ประกาศผลกิจกรรมในวันที่ 24 ธ.ค. 2564)
				</li>
				<li>
					6. สินค้าที่เข้าร่วมรายการ ได้แก่ est Cola / est Play
					ทุกขนาดทุกรสชาติ, โออิชิ ทุกขนาด ทุกรสชาติ, Meiji เฉพาะโยเกิร์ต
					ทุกขนาด ทุกรสชาติ, สแน็คแจ๊ค ทุกขนาด ทุกรสชาติ, Sunsu ทุกขนาด
					ทุกรสชาติ
				</li>
				<li>7. ช่องทางในการซื้อสินค้า 7-Eleven, Lotus’s, Big C, Tops</li>
				<li>
					8. วิธีการส่งใบเสร็จเข้าร่วมกิจกรรมช้อปชนะ
					<div>8.1 ซื้อสินค้าดังต่อไปนี้จาก 7-Eleven, Lotus’s, Big C, Tops</div>
					<div>8.1.1 est Cola / est Play ทุกขนาดทุกรสชาติ</div>
					<div>8.1.2 โออิชิ ทุกขนาด ทุกรสชาติ</div>
					<div>8.1.3 Meiji เฉพาะโยเกิร์ต ทุกขนาด ทุกรสชาติ</div>
					<div>8.1.4 สแน็คแจ๊ค ทุกขนาด ทุกรสชาติ</div>
					<div>8.1.5 Sunsu ทุกขนาด ทุกรสชาติ</div>
					<div>
						8.2 ถ่ายรูปหรืออัปโหลดรูปใบเสร็จเข้าระบบ “Roxketeer ช้อปชนะ” ได้ 2
						ช่องทางคือ
					</div>
					<div>8.2.1 LINE OA “Roxketeer ช้อปชนะ”</div>
					<div>8.2.2 Adapter application > เลือกเมนู “Roxketeer ช้อปชนะ”</div>
					<div>
						8.3
						ระบุห้างร้านของใบเสร็จที่ส่งเข้ามาร่วมสนุกว่าเป็นใบเสร็จจากห้างร้านใด
						(7-Eleven, Lotus’s, Big C, Tops)
					</div>
					<div>8.4 กดส่งใบเสร็จ และรอการอนุมัติจากผู้ดูแลระบบ</div>
					<div>
						8.5 หากใบเสร็จได้รับการอนุมัติแล้ว คะแนนสะสมจะปรากฏในวันถัดไป
					</div>
					<div>
						8.6 เก็บใบเสร็จตัวจริงเพื่อใช้ยืนยันตอนรับรางวัลตอบจบกิจกรรม
						“Roxketeer ช้อปชนะ”
					</div>
				</li>
				<li>
					9. เงื่อนไขในการได้พอยท์
					<div>
						9.1 นับคะแนนตามจำนวนแบรนด์สินค้าในใบเสร็จ
						โดยไม่อ้างอิงจำนวนชิ้นสินค้า
					</div>
					<div>
						9.2 สินค้า 1 แบรนด์/ใบเสร็จ จะได้รับ 1 คะแนน (เช่น ในใบเสร็จมีโออิชิ
						4 ขวด และ สแน็คแจ๊ค 12 ซอง จะได้รับ 2 คะแนน)
					</div>
					<div>9.3 หากเป็นใบเสร็จจาก Lotus’s จะได้รับคะแนน x2</div>
					<div>9.4 ใบเสร็จ 1 ใบ สามารถร่วมกิจกรรมได้ 1 ครั้งเท่านั้น</div>
					<div>9.5 ส่งใบเสร็จได้ไม่จำกัดจำนวนครั้ง</div>
					<div>
						9.6
						ใบเสร็จที่ร่วมกิจกรรมต้องเป็นใบเสร็จที่ซื้อสินค้าจากร้านค้าที่กำหนดตั้งแต่วันที่
						15 พ.ย.-12 ธ.ค. 2564 และต้องส่งใบเสร็จเข้าระบบ “Roxketeer ช้อปชนะ”
						ตั้งแต่วันที่ 15 พ.ย.-12 ธ.ค. 2564 เท่านั้น
					</div>
					<div>9.7 ประกาศรางวัลและรายชื่อผู้โชคดีในวันที่ 24 ธ.ค. 2564</div>
				</li>
				<li>
					10. รางวัลในกิจกรรม "Roxketeer ช้อปชนะ”
					<div>10.1 ยอดคะแนนสะสมสูงสุด 3 อันดับแรก</div>
					<div>รางวัลที่ 1 : Bose Soundlink Revolve II</div>
					<div>รางวัลที่ 2 : Chaarispa Voucher นวดเท้า/นวดไทย 10 ชม.</div>
					<div>รางวัลที่ 3 : บัตรกำนัล Shinsei Omakase มูลค่า 1,500 บาท</div>
					<div>10.2 จับฉลากลุ้นรางวัลสุดพิเศษ 3 รางวัล</div>
					<div>รางวัลที่ 1 : ทองคำ 1 สลึง</div>
					<div>รางวัลที่ 2 : ​Panpuri set (Voyage of diffuser)</div>
					<div>รางวัลที่ 3 : บัตรกำนัลบาบีคิวพลาซ่ามูลค่า 1,000 บาท</div>
					<div>10.3 ยอดคะแนนสะสมสูงสุดจากใบเสร็จ Lotus’s 2 รางวัล</div>
					<div>รางวัลพิเศษ : บัตรของขวัญโลตัสมูลค่า 500 บาท</div>
					<div>
						10.4 ในวันประกาศผลกิจกรรม มีรางวัลพิเศษ แจกบัตร Starbucks มูลค่า 100
						บาท จำนวน 15 รางวัล
					</div>
				</li>
				<li>
					11. ของรางวัลที่ได้รับไม่สามารถจำหน่าย แลก ทอน
					เปลี่ยนเป็นเงินสดหรือของรางวัลอื่นได้
					และไม่สามารถโอนสิทธิให้กับบุคคลภายนอกหรือนิติบุคคลอื่นได้ไม่ว่ากรณีใด
					รวมทั้งไม่มีการจ่ายเงินเป็นส่วนประกอบเเต่อย่างใด
				</li>
				<li>
					12. บริษัทฯ
					จะเป็นผู้สร้างและประกาศกิจกรรมลงบนผ่านโปรแกรมการเข้าถึงเว็บไซต์ต่าง ๆ
					เพื่อให้ท่าน แลกรับรางวัลตามที่บริษัทฯ กำหนด
					ทั้งนี้แต่ละกิจกรรมอาจมีข้อกำหนดหรือเงื่อนไขเฉพาะ
					ดังนั้นจึงเป็นหน้าที่ของท่านในการศึกษาและทำความเข้าใจข้อกำหนดและเงื่อนไขเฉพาะดังกล่าว
					โดยบริษัทฯ
					จะไม่รับผิดชอบหากท่านเข้าใจผิดเกี่ยวกับเนื้อหาหรือข้อกำหนดหรือเงื่อนไขของกิจกรรม
				</li>
				<li>
					13. สงวนสิทธิ์ผู้ร่วมเล่นกิจกรรมเฉพาะพนักงาน Adapter Digital เท่านั้น
				</li>
			</ul>
			<!-- <p>
				It is a long established fact that a reader will be distracted by the
				readable content of a page when looking at its layout. The point of
				using Lorem Ipsum. <a href="#" @click="handleModalCond">See Details</a>
			</p> -->
			<!-- <PDPADataLinkedComponent /> -->
		</div>
	</div>
</template>

<script>
// import { reactive, toRefs } from "vue";
// import PDPADataLinkedComponent from "./PDPADataLinkedComponent.vue";

export default {
	data() {
		return {
			value: 0,
			dialogVisible: false,
			valueTos: false,
		};
	},
	props: {
		acceptType: String,
		acceptStatus: Number,
	},
	components: {
		// PDPADataLinkedComponent,
	},
	methods: {
		reset() {
			this.value = 0;
			this.dialogVisible = false;
		},
		handleModalCond() {
			this.$emit("modal:cond", !this.dialogVisible);
		},
		handleSwitch() {
			// console.log(this.value);
			if (this.value == 0) {
				this.valueTos = false;
				this.$store.commit("profile/setTosConsent", null);
			} else {
				this.valueTos = true;
				this.$store.commit("profile/setTosConsent", this.value);
				this.$emit("switch:cond", {
					type: this.acceptType,
					accept: parseInt(this.value),
					show: Boolean(!this.dialogVisible),
				});
			}
		},
	},
};
</script>

<style lang="scss" >
.pdpa__term__service {
	padding: 0 7%;

	.service__head {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		text-align: left;

		h1 {
			// font-family: $font-family-ibm-semi;
			// @include font-size(30);
			font-size: $font-xl;
			line-height: $font-xl;
			// color: $darkGray;
			color: $black;
			padding: 0;
			margin: 0;

			@supports (-webkit-touch-callout: none) {
				/* CSS specific to iOS devices */
				letter-spacing: -1px;
			}
		}

		.el-switch {
			margin-top: 13px;
			&.is-checked {
				span {
					&.el-switch__core {
						width: 56px !important;
						height: 32px;
						border-radius: 20px;
						.el-switch__action {
							top: 3px;
							margin-left: -26px;
							width: 24px;
							height: 24px;
						}
					}
				}
			}

			span {
				&.el-switch__core {
					width: 56px !important;
					height: 32px;
					border-radius: 20px;
					.el-switch__action {
						top: 3px;

						width: 24px;
						height: 24px;
					}
				}
			}
		}
	}

	.error {
		color: $red;
		font-size: $font-s;
		line-height: $font-s;
		display: block;

		@supports (-webkit-touch-callout: none) {
			/* CSS specific to iOS devices */
			letter-spacing: -1px;
		}
	}

	.service__body {
		text-align: left;
		p {
			margin-top: 8%;

			text-indent: 10px;
			text-align: justify;

			margin-bottom: 10px;
			// font-family: $font-family-ibm;
			// @include font-size(30);
			@include font-size(30);
			// color: $darkGray;
			color: $black;
			text-align: left;

			a {
				color: $link-bule;

				&:hover,
				&:visited,
				&:active {
					color: $link-bule;
				}
			}
		}
		.paragraph {
			margin-top: 0;
		}
		h1 {
			@include font-size(40);
		}
		ul {
			padding: 0;
			margin: 0;
			list-style-position: inside;
			li {
				@include font-size(30);
				list-style: none;
				margin-bottom: 2px;
				div {
					// padding-left: 10px;
					text-indent: 10px;
				}
			}
		}
	}
}
</style>