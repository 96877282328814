<style src="./ContactUs.scss" lang="scss"></style>
<script src="./ContactUs.js"></script>

<template>
	<div class="contact__view">
		<div class="contact__header">
			<BaseBackButtonComponent />
			<BaseTitleComponent :title-name="title" />
		</div>
		<div class="content__box">
			<div class="step__topic">
				<div class="form-select">
					<div class="box__input_text">
						<div class="text__input">
							<input
								type="text"
								id="device"
								v-model="v$.contactus.device.$model"
								placeholder="ระบุอุปกรณ์ที่ใช้งาน เช่น iPhone 12"
								:class="{ error: v$.contactus.device.$error }"
							/>
						</div>
					</div>
				</div>
				<div class="gaping"></div>
				<div class="form-select">
					<Icon name="arrow-down" class="arrow-down" />
					<select id="topic" v-model="v$.contactus.topic.$model">
						<option value="" selected disabled>ระบุหัวข้อในการติดต่อ</option>
						<option :value="item.id" v-for="item in topics" :key="item.id">
							{{ item.name }}
						</option>
					</select>
				</div>
				<div class="gaping"></div>
				<div class="error__alert" v-if="v$.contactus.device.$error">
					<div class="error">* กรุณาระบุอุปกรณ์ที่ใช้งาน</div>
				</div>
				<div class="error__alert" v-if="v$.contactus.topic.$error">
					<div class="error">* กรุณาระบุหัวข้อในการติดต่อ</div>
				</div>
				<BaseButtonSimpleComponent
					:button-data="buttonData"
					v-if="!isSelectProblem"
					@click.prevent="
						v$.contactus.device.$model || v$.contactus.topic.$model
							? buttonNext()
							: ''
					"
				/>
				<!-- <Icon name="google_recaptcha_banner" class="google_recaptcha" /> -->
				<!-- <img src="@/assets/icons/google_recaptcha_banner.png" /> -->
			</div>
			<section v-if="isSelectProblem">
				<div class="step__desc" v-if="v$.contactus.topic.$model === 2">
					<div class="desc__header">
						<div class="headline">
							ปัญหาที่พบ <span class="headline--red">*</span>
						</div>
						<div class="input__error" v-if="v$.contactus.Problemdetail.$error">
							*กรุณากรอกข้อมูล
						</div>
					</div>
					<textarea
						class="input__textarea"
						rows="10"
						v-model="v$.contactus.Problemdetail.$model"
						placeholder="กรอกปัญหาที่พบ"
					/>
				</div>
				<div class="step__attachment" v-if="v$.contactus.topic.$model === 2">
					<span class="headline"
						>{{ attachmentTitle }} <span class="headline--red">*</span>
						<div class="input__error" v-if="v$.contactus.attachment.$error">
							{{ attachmentErrorMsg }}
						</div></span
					>
					<BaseFileUploadComponent
						ref="fileUpload"
						:preview="preview"
						v-on:loadImage="getImageFile"
						v-on:removeImage="deleteImageFile"
						:key="preview"
					/>
					<button type="button" class="upload__button" @click="uploadImage()">
						ส่งภาพ
					</button>
					<div class="upload__button__note">
						▪ สามารถแนบไฟล์ได้สูงสุด 1 ไฟล์ (สกุลไฟล์ที่รองรับ: JPEG, PNG, PDF)
					</div>
					<div class="upload__button__note">
						▪ ขนาดไฟล์ที่แนบได้สูงสุดคือ 10 MB ต่อ 1 ไฟล์
					</div>
				</div>

				<div class="step__info">
					<span class="headline"
						>กรุณากรอกข้อมูลเพิ่มเติม <span class="headline--red">*</span></span
					>

					<div class="box__input_text">
						<div class="text__input">
							<div class="title__input">
								<div class="input__title">หมายเลขโทรศัพท์ที่ใช้ลงทะเบียน</div>
								<div class="input__error" v-if="v$.contactus.OldPhone.$error">
									*ข้อมูลไม่ถูกต้อง
								</div>
							</div>

							<input
								type="text"
								id="OldPhone"
								ref="OldPhone"
								v-model="v$.contactus.OldPhone.$model"
								@input="v$.contactus.OldPhone.$touch"
								placeholder="ใส่เบอร์โทรศัพท์ที่ใช้ลงทะเบียน"
								maxlength="10"
								:disabled="v$.contactus.OldPhone.$model ? true : false"
								:class="{ error: v$.contactus.OldPhone.$error }"
								@keypress="isNumber($event)"
							/>
						</div>
					</div>

					<div class="box__input_text">
						<div class="text__input">
							<div class="title__input">
								<div class="input__title">หมายเลขโทรศัพท์ที่ใช้ปัจจุบัน</div>
								<div
									class="input__error"
									v-if="v$.contactus.CurrentPhone.$error"
								>
									*ข้อมูลไม่ถูกต้อง
								</div>
							</div>

							<input
								type="text"
								id="CurrentPhone"
								v-model="v$.contactus.CurrentPhone.$model"
								@input="v$.contactus.CurrentPhone.$touch"
								placeholder="ใส่เบอร์โทรศัพท์ปัจจุบัน"
								maxlength="10"
								:class="{ error: v$.contactus.CurrentPhone.$error }"
								@keypress="isNumber($event)"
							/>
						</div>
					</div>

					<div class="box__input_text">
						<div class="text__input">
							<div class="title__input">
								<div class="input__title">ชื่อ (ที่ใช้ลงทะเบียน)</div>
								<div class="input__error" v-if="v$.contactus.firstname.$error">
									*ข้อมูลไม่ถูกต้อง
								</div>
							</div>

							<input
								type="text"
								id="firstname"
								v-model="v$.contactus.firstname.$model"
								@input="v$.contactus.firstname.$touch"
								placeholder="ใส่ชื่อ"
								maxlength="90"
								:disabled="v$.contactus.firstname.$model ? true : false"
								:class="{ error: v$.contactus.firstname.$error }"
							/>
						</div>
					</div>

					<div class="box__input_text">
						<div class="text__input">
							<div class="title__input">
								<div class="input__title">นามสกุล (ที่ใช้ลงทะเบียน)</div>
								<div class="input__error" v-if="v$.contactus.lastname.$error">
									*ข้อมูลไม่ถูกต้อง
								</div>
							</div>

							<input
								type="text"
								id="lastname"
								v-model="v$.contactus.lastname.$model"
								@input="v$.contactus.lastname.$touch"
								placeholder="ใส่นามสกุล"
								maxlength="90"
								:disabled="v$.contactus.lastname.$model ? true : false"
								:class="{ error: v$.contactus.lastname.$error }"
							/>
						</div>
					</div>
				</div>
				<div class="step__attachment" v-if="v$.contactus.topic.$model === 1">
					<span class="headline"
						>{{ attachmentTitle }} <span class="headline--red">*</span>
						<div class="input__error" v-if="v$.contactus.attachment.$error">
							{{ attachmentErrorMsg }}
						</div></span
					>
					<BaseFileUploadComponent
						ref="fileUpload"
						:preview="preview"
						v-on:loadImage="getImageFile"
						v-on:removeImage="deleteImageFile"
						:key="preview"
					/>
					<button type="button" class="upload__button" @click="uploadImage()">
						ส่งภาพ
					</button>
					<div class="upload__button__note">
						▪ สามารถแนบไฟล์ได้สูงสุด 1 ไฟล์ (สกุลไฟล์ที่รองรับ: JPEG, PNG, PDF)
					</div>
					<div class="upload__button__note">
						▪ ขนาดไฟล์ที่แนบได้สูงสุดคือ 10 MB ต่อ 1 ไฟล์
					</div>
				</div>
				<BaseButtonSimpleComponent
					:button-data="buttonData2"
					:button-class-change="true"
					v-on:buttonClick="buttonClick"
				/>
			</section>
		</div>
		<BaseModalComponent
			:modal-show="modalStatus"
			:modal-type="modalType"
			@update:modal="handleModal"
		/>
		<!-- <div class="recaptcha">
			This site is protected by reCAPTCHA and the Google<br />
			<a href="https://policies.google.com/privacy" target="_blank"
				>Privacy Policy</a
			>
			and
			<a href="https://policies.google.com/terms" target="_blank"
				>Terms of Service</a
			>
			apply.
		</div> -->
	</div>
</template>