<style src="./LifestyleComponent.scss" lang="scss"></style>
<script src="./LifestyleComponent.js"></script>

<template>
	<div class="box__lifestyle">
		<ul class="lifestyle__lists">
			<li v-for="(item, index) in itemsLifestyle" :key="index">
				<!-- <img :src="item.image" alt="item.name" /> -->
				<a href="#">
					<el-avatar
						shape="square"
						:src="item.image"
						:class="{ is__selected: item.id == 1 }"
					></el-avatar>
				</a>
			</li>
		</ul>
	</div>
</template>