import {
  onUpdated,
  onBeforeUnmount
} from 'vue'
import * as dayjs from 'dayjs'
import _ from 'lodash';
import TheFooter from '@/views/Layout/Footer';
// import $ from 'jquery'
import { RequestGetLuckyHistory, RequestGetRewardHistory } from '@/api/profile.js';
import {
  PointhistoryFilterComponent,
  PointhistoryListLuckydrawComponent
} from '@/components';


export default {
  name: 'PointHistory',
  data() {
    return {
      token: this.$store.getters['profile/token'],
      FilterMonth: null,
      RewardType:'luckydraws',
      HistoryList: []

    }
  },
  components: {
    PointhistoryFilterComponent,
    PointhistoryListLuckydrawComponent,
    TheFooter
  },
  mounted() {
    if (this.token) {
      if (this.FilterMonth == null) {
        this.FilterMonth = dayjs().format('YYYY-MM')
      }
      this.GetHistory();
    }
  },

  setup() {

    onUpdated(() => {
      // let vm = this;
      // console.log('Component is mounted!')
    }),

      onBeforeUnmount(() => {
        // console.log('off emitter')
        // this.emitter.off(`button:${this.buttonType}`);
      })

  },
  created() {
    // this.emitter.on(`button:${this.buttonData.type}`, () => {
    //   setTimeout(() => {
    //     this.$store.commit("BUTTON_LOADING", false);
    //     $('.box__alert').fadeIn(500).delay(1000).fadeOut();
    //   }, 1000);
    // })
  },
  methods: {
    UpdateFilter(NewFilter) {
      this.FilterMonth = NewFilter;
      this.GetHistory();
      // console.log(NewFilter)

    },
    async GetHistory() {
      let FilterMonthArray = this.FilterMonth.split('-')
      let RequestData = {
        "token": this.token,
        "month": FilterMonthArray[1],
        "year": FilterMonthArray[0]
      }
      let RequestRespLucky = await RequestGetLuckyHistory(RequestData)
      let RequestRespRedeem = await RequestGetRewardHistory(RequestData)
      if (RequestRespLucky.result == 'complete' && RequestRespRedeem.result =='complete') {

        let Luckylist = RequestRespLucky.list
        let Redeemlist = RequestRespRedeem.list
        
        Luckylist.forEach(list => {list.type ="luckydraws"});
        Redeemlist.forEach(list => { list.type = "redemptions" });
        let UsedList = Luckylist.concat(Redeemlist)

        let HistoryList = this.SetUpPointArray(UsedList)
        this.HistoryList = HistoryList;


      }
    },
    SetUpPointArray(Historylist) {


      // this gives an object with dates as keys
      const groups = Historylist.reduce((groups, bill) => {

        const date = dayjs(bill.created).format('YYYY-MM-DD')
        if (!groups[date]) {
          groups[date] = [];
        }

        const BillMap =
        {
          id: bill.redeemid,
          image: bill.assetthumb,
          title: bill.titleth,
          price: bill.descth,
          type: bill.type,
          usage: {
            'point': bill.point,
            'item': bill.privilege
          },

        }


        groups[date].push(BillMap);
        return groups;
      }, {});

      // Edit: to add it in the array format instead
      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          data: groups[date]
        };
      });



      return groupArrays
    },
  },

};