import request from "@/utils/request";

export const rewardService = {
    getLuckydrawItems,
    getLuckydrawItemById,
    redeemLuckydrawItemById,
    getRewardItems,
    getRewardItemById,
    redeemRewardItemById,
    handleError,
};

async function getLuckydrawItems(requestData) {
    const data = requestData;
    return await request({
        url: "lucky/itemlist",
        method: "POST",
        data,
    });
}

async function getLuckydrawItemById(requestData) {
    const data = requestData;
    return await request({
        url: "lucky/itemlist",
        method: "POST",
        data,
    });
}

async function redeemLuckydrawItemById(requestData) {
    const data = requestData;
    return await request({
        url: "lucky/redeem",
        method: "POST",
        data,
    });
}

async function getRewardItems(requestData) {
    const data = requestData;
    return await request({
        url: "reward/itemlist",
        method: "POST",
        data,
    });
}

async function getRewardItemById(requestData) {
    const data = requestData;
    return await request({
        url: "reward/itemlist",
        method: "POST",
        data,
    });
}

async function redeemRewardItemById(requestData) {
    const data = requestData;
    return await request({
        url: "reward/redeem",
        method: "POST",
        data,
    });
}

function handleError(error) {
    throw error;
}
