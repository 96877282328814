import {
  BaseBackButtonComponent,
  BaseTitleComponent,
  BaseInputTextComponent,
  BaseFileUploadComponent,
  BaseButtonSimpleComponent,
  BaseModalComponent,
  Icon,
} from "@/components";
import { useReCaptcha } from "vue-recaptcha-v3";
import { ref, reactive, provide } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, numeric, email, requiredIf } from "@vuelidate/validators";
import { ElLoading } from "element-plus";
import { showLoading, hideLoading } from "@/helpers";
import { ContactService } from "@/api/contact";
import { RequestGetInfo } from "@/api/profile";

export default {
  name: "ContactUs",
  components: {
    BaseBackButtonComponent,
    BaseTitleComponent,
    BaseInputTextComponent,
    BaseFileUploadComponent,
    BaseButtonSimpleComponent,
    BaseModalComponent,
    Icon,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      contactus: {
        device: { required },
        topic: { required },
        firstname: { required },
        lastname: { required },
        OldPhone: { required, numeric },
        CurrentPhone: { required, numeric },
        Problemdetail: {
          required: requiredIf(function() {
            return this.contactus.topic === 2;
          }),
        },
        attachment: { required },
      },
    };
  },
  data() {
    return {
      token: this.$store.getters["profile/token"] || "",
      reCaptchaToken: null,
      title: "ติดต่อเรา",
      attachmentTitle: "ส่งภาพถ่ายขณะถือบัตรประชาชน",
      attachmentErrorMsg: "กรุณาส่งภาพถ่ายของท่านขณะถือบัตรประชาชน",
      modalShow: false,
      modalStatus: false,
      modalValue: 0,
      modalType: null,
      devices: [
        {
          id: 1,
          name: "iPhone 10",
        },
        {
          id: 2,
          name: "iPhone 12",
        },
      ],
      topics: [
        {
          id: 1,
          name: "ล็อกอินไม่ได้ บัญชีถูกล็อก",
        },
        {
          id: 2,
          name: "ปัญหาอื่นๆ",
        },
      ],
      topic: null,
      inputTextArea: {
        value: "",
        type: "text",
        placeholder: "กรอกปัญหาที่พบ",
        row: 10,
        error: true,
      },
      // preview: require("@/assets/images/account/default_user_id.png"),
      contactus: {
        device: "",
        topic: "",
        firstname: "",
        lastname: "",
        OldPhone: "",
        CurrentPhone: "",
        Problemdetail: "",
        attachment: "",
      },
      loading: null,
      isSelectProblem: false,
    };
  },
  inject: ["trackEvent"],
  computed: {
    buttonData: function() {
      let resp = {
        title: "ถัดไป",
        active: false,
        disabled: true,
        class: "contact__button",
      };

      if (this.contactus.topic == 1 && this.contactus.device !== "") {
        resp.title = "ถัดไป";
        this.isSelectProblem = false;
        resp.disabled = false;
      } else if (this.contactus.topic == 2 && this.contactus.device !== "") {
        resp.title = "ถัดไป";
        this.isSelectProblem = false;
        resp.disabled = false;
      }

      return resp;
    },
    buttonData2: function() {
      let resp = {
        title: "ส่งข้อมูลถึงแอดมิน",
        active: false,
        disabled: true,
        class: "contact__button",
      };

      if (this.contactus.topic == 1 && this.contactus.device !== "") {
        if (!this.v$.$invalid && this.contactus.attachment) {
          resp.disabled = false;
        }
      } else if (this.contactus.topic == 2 && this.contactus.device !== "") {
        if (!this.v$.$invalid && this.contactus.attachment) {
          resp.disabled = false;
        }
      }

      return resp;
    },
    preview: function() {
      let preview = require("@/assets/images/default_image_preview.png");
      if (this.contactus.topic == 1) {
        preview = require("@/assets/images/account/default_user_id.png");
      }
      return preview;
    },
  },
  watch: {
    "contactus.topic": function(val) {
      if (val == 1) {
        this.attachmentTitle = "ส่งภาพถ่ายขณะถือบัตรประชาชน";
        this.attachmentErrorMsg = "กรุณาส่งภาพถ่ายของท่านขณะถือบัตรประชาชน";
      } else {
        this.attachmentTitle = "แนบรูปภาพปัญหา";
        this.attachmentErrorMsg = "กรุณาแนบรูปภาพปัญหา";
      }
    },
  },
  mounted() {
    this.GetInfo();
  },
  async created() {
    await this.loadRecaptcha();
    this.logger(this.$store.getters["profile/getUserInfo"]);
    if (this.$store.getters["profile/getUserInfo"]) {
      let { firstname, lastname, mobile } = this.$store.getters[
        "profile/getUserInfo"
      ];
      this.contactus.firstname = firstname;
      this.contactus.lastname = lastname;
      this.contactus.OldPhone = mobile;
    }
  },
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 9) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async loadRecaptcha() {
      const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
      await recaptchaLoaded();
      const token = await executeRecaptcha("login");
      this.reCaptchaToken = token;
    },
    uploadImage() {
      this.$refs.fileUpload.selectFile();
    },
    getImageFile(data) {
      //Manage oversize file here before submit
      const Maxsize = 10 * (1024 * 1024);
      let base64Array = data.base64.split(",");
      if (data.originsize <= Maxsize) {
        this.contactus.attachment = base64Array[1];
        this.contactus.attachmentType = data.type;
      } else {
        this.$refs.fileUpload.removeFile();
        this.contactus.attachment = null;
      }
    },
    deleteImageFile() {
      this.ShowModalComplete();
    },
    async buttonClick() {
      this.logger(this.v$.contactus);
      this.v$.$touch();
      if (this.v$.$error) return;

      this.buttonData.disabled = true;

      let SelectedTopic = this.topics.find(
        (topic) => topic.id == this.contactus.topic
      );
      // let SelectDevice = this.devices.find(device => device.id == this.device)
      showLoading();

      const ContactUsData = {
        token: this.token,
        firstname: this.contactus.firstname,
        lastname: this.contactus.lastname,
        mobileprefix: "66",
        mobile: this.contactus.OldPhone,
        mobilenewprefix: "66",
        mobilenew: this.contactus.CurrentPhone,
        email: "",
        contactdevice: this.contactus.device,
        subject: SelectedTopic.name,
        message: this.contactus.Problemdetail,
        evidence: this.contactus.attachment,
        type: this.contactus.attachmentType,
      };

      let RequestContactUS = await ContactService.SubmitContactUs(
        ContactUsData
      );
      this.$store.commit("APP_LOADING", false);

      // console.log(RequestContactUS)

      if (RequestContactUS.result == "complete") {
        hideLoading();
        this.modalType = "saved";
        this.modalStatus = true;

        this.trackEvent(`ContactUs - Send`, {
          event_category: "ContactUs",
          event_label: "saved",
          value: true,
        });
      } else {
        hideLoading();
        // this.$message({
        //   message: RequestContactUS.resultdetail,
        //   type: "error",
        // });
        this.trackEvent(`ContactUs - Send`, {
          event_category: "ContactUs",
          event_label: "error",
          value: false,
        });
      }
    },
    ShowModalComplete() {
      this.modalType = "remove";
      this.modalStatus = true;
    },
    handleModal(value) {
      // this.$router.push({ name: 'Home' });
      if (value.type === "remove" && value.accept === 1) {
        this.$refs.fileUpload.removeFile();
        this.contactus.attachment = null;
      } else if (value.type === "saved" && value.accept === 1) {
        // console.log('go to home')
        this.modalStatus = false;
        this.$router.push({ name: "Home" });
      }
      this.modalStatus = false;
      this.buttonData.disabled = false;
    },
    buttonNext() {
      this.v$.$touch();
      if (!this.v$.contactus.device.$model || !this.v$.contactus.topic.$model)
        return;
      this.v$.$reset();

      if (this.$store.getters["profile/getUserInfo"]) {
        this.contactus.CurrentPhone = "";
      } else {
        this.contactus.firstname = "";
        this.contactus.lastname = "";
        this.contactus.OldPhone = "";
        this.contactus.CurrentPhone = "";
        this.contactus.Problemdetail = "";
      }

      this.buttonData.title = "ส่งข้อมูลถึงแอดมิน";
      this.isSelectProblem = !this.isSelectProblem;
      this.buttonData.disabled = true;
    },
    async GetInfo() {
      let RequestData = {
        token: this.token,
      };
      let RequestResp = await RequestGetInfo(RequestData);
      if (RequestResp.result == "complete") {
        this.$store.commit("profile/setUserInfo", RequestResp.info);
      } else {
        this.logger(RequestResp.resultdetail);
      }
    },
  },
};
