import { authService } from "@/api/auth";
import { cropperService } from "@/api/cropping";
import { billService } from "@/api/bill";
import store from "@/store";

export const types = {
  APP_VALID_TOKEN: "APP_VALID_TOKEN",
  APP_CROPPING_BILL: "APP_CROPPING_BILL",
  APP_SCANNING_EBILL: "APP_SCANNING_EBILL",
  APP_CREATE_BILL_DATA: "APP_CREATE_BILL_DATA",
  SET_SHOPBILL_SELECTED: "SET_SHOPBILL_SELECTED",
  SET_LINE_ACCESS_TOKEN: "SET_LINE_ACCESS_TOKEN",
  SET_PROFILE_LINE_LOGIN: "SET_PROFILE_LINE_LOGIN",
};

export default {
  namespaced: true,
  state: {
    appValidToken: false,
    shopBillSelected: "",
    shopBillCropData: "",
    shopEBillCropData: "",
    billPostData: "",
    lineAccessToken: ""
  },
  getters: {
    [types.APP_VALID_TOKEN]: (state) => state.appValidToken,
    [types.SET_SHOPBILL_SELECTED]: (state) => state.shopBillSelected,
    [types.SET_LINE_ACCESS_TOKEN]: (state) => state.lineAccessToken,
  },
  mutations: {
    [types.APP_VALID_TOKEN](state, appValidToken) {
      Object.assign(state, {
        appValidToken,
      });
    },
    [types.APP_CROPPING_BILL](state, shopBillCropData) {
      Object.assign(state, {
        shopBillCropData,
      });
    },
    [types.APP_SCANNING_EBILL](state, shopEBillCropData) {
      Object.assign(state, {
        shopEBillCropData,
      });
    },
    [types.SET_SHOPBILL_SELECTED](state, shopBillSelected) {
      Object.assign(state, {
        shopBillSelected,
      });
    },
    [types.APP_CREATE_BILL_DATA](state, billPostData) {
      Object.assign(state, {
        billPostData,
      });
    },
    [types.SET_LINE_ACCESS_TOKEN](state, lineAccessToken) {
      Object.assign(state, {
        lineAccessToken,
      });
    },
  },
  actions: {
    async [types.APP_VALID_TOKEN]({ commit }) {
      if (store.getters["profile/token"]) {
        const loginData = {
          token: store.getters["profile/token"],
        };
        const appValidToken = await authService.check(loginData);
        commit("APP_VALID_TOKEN", appValidToken.return);
        return appValidToken.return;
      }
      return false;
    },
    async [types.APP_CROPPING_BILL]({ commit }, billData) {
      try {
        const shopBillCropData = await cropperService.apiBillData(billData);
        commit("APP_CROPPING_BILL", shopBillCropData);
        return shopBillCropData;
      } catch (e) {
        cropperService.handleError(e);
      }
    },
    async [types.APP_SCANNING_EBILL]({ commit }, ebillData) {
      try {
        const shopEBillCropData = await cropperService.apiEBillData(ebillData);
        commit("APP_SCANNING_EBILL", shopEBillCropData);
        return shopEBillCropData;
      } catch (e) {
        cropperService.handleError(e);
      }
    },
    async [types.APP_CREATE_BILL_DATA]({ commit }, postData) {
      try {
        const billPostData = await billService.apiCreateBillData(postData);
        commit("APP_CREATE_BILL_DATA", billPostData);
        return billPostData;
      } catch (e) {
        billService.handleError(e);
      }
    },
    async [types.SET_LINE_ACCESS_TOKEN]({ commit }, LineToken) {
      
        commit("SET_LINE_ACCESS_TOKEN", LineToken);
        return true;
    },
    async [types.SET_PROFILE_LINE_LOGIN]({ commit }, postData) {
      try {
        const reponseData = await authService.profileLineLogin(postData);
        return reponseData;
      } catch (e) {
        billService.handleError(e);
      }
    },
  },
};
