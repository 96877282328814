import { BaseBackButtonComponent } from "@/components"
import { RequestGetBanner } from '@/api/general.js';
import TheFooter from '@/views/Layout/Footer';

export default {
  name: "BaseBannerDetail",
  data() {
    return {
      id:null,
      bannerData: null,
      banners: [],
      token: this.$store.getters['profile/token'],
    };
  },
  components: {
    BaseBackButtonComponent,
    TheFooter
  },
  watch: {
    '$route.params.id': {
      handler: function (id) {
        this.id = this.$route.params.id;
        if (this.id !== undefined) {
          this.$nextTick(() => this.getBannerDetail())
        }
      },
      deep: true,
      immediate: true
    }
  },
  // created() {
  //   if (this.token) {
  //     this.GetBanner()
  //   }
  // },
  methods: {
    async getBannerDetail() {
      await this.GetBanner()
      return this.bannerData = this.banners.filter((banner) => banner.bannerid === this.id)
    },
    async GetBanner() {
      let RequestData = {
        "token": this.token,
      }
      let RequestResp = await RequestGetBanner(RequestData)
      if (RequestResp.result == 'complete') {
        RequestResp.list.forEach((banner)=>{
          if(banner.assetlarge.includes("www.youtube.com")){
            banner.source = banner.assetlarge.replace('watch?v=', 'embed/')
          }
          else if(banner.assetlarge.includes(".mp4")){
            banner.source = banner.assetlarge
          }
          this.banners.push(banner)
        })
      } else {
        this.$message({
          message: RequestResp.resultdetail,
          type: 'error'
        })
      }

    },
  },
};
