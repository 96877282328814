<style src="./BaseBackButtonCustomComponent.scss" lang="scss" scoped></style>
<script src="./BaseBackButtonCustomComponent.js"></script>

<template>
	<div
		class="button__back__contain"
		:style="
			configButton.backgroundColor
				? `background-color: ${configButton.backgroundColor}`
				: ''
		"
	>
		<div
			class="back__button"
			:style="configButton.color ? `color: ${configButton.color}` : ''"
		>
			<span
				@click.prevent="
					captureImages.BillsList.length ? handleCancel() : goback()
				"
			>
				<i
					class="el-icon-back"
					:style="
						configButton.colorIcon ? `color: ${configButton.colorIcon}` : ''
					"
				></i>
				{{ captureImages.BillsList.length ? "Cancel" : configButton.name }}
			</span>
		</div>
		<div class="retake__amount" v-if="captureAction === 'recheck'">
			<span> {{ currentImage }}/{{ captureImages.BillsList.length }} </span>
		</div>
		<button
			class="done__button"
			v-if="captureAction === 'recheck'"
			:disabled="captureImages.BillsList.length ? false : true"
			@click.prevent="handleRecheckDone($event)"
		>
			<span>
				ยืนยัน
				<i class="el-icon-right"></i>
			</span>
		</button>
	</div>
</template>