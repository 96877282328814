<style src="./SubmissionConfirm.scss" lang="scss" scoped></style>
<script src="./SubmissionConfirm.js"></script>

<template>
	<div class="submission__wrapper">
		<BaseBackButtonCustomComponent :config-button="data.buttonBack" />
		<div class="submission__content">
			<div class="submission__bill__view">
				<BillImageConfirmViewer
					:bill-view="data.billView"
					:bill-show="data.billShow"
				/>
			</div>
			<div class="submission__bill__action">
				<h1>
					{{ data.title }}
					<span>{{ data.billName }} </span>
				</h1>
				<p v-html="data.subtitle"></p>
				<ShopBillConfirmComponent
					:item-brands="data.brands"
					@update:bill-selected="handleSelectedItem"
				/>
				<SubmissionButtonComponent
					:button-data="data.buttonData"
					@button:click="handleConfirm"
				/>
			</div>
		</div>
		<BaseModalComponent
			:modal-type="data.modal.type"
			:modal-show="data.modal.show"
			:modal-status="data.modal.status"
			@update:modal="handleSuccess"
		/>
	</div>
</template>