// import VueEasyLightbox from 'vue-easy-lightbox'
import VueZoomer from 'vue-zoomer'
import { Icon } from '@/components';

export default {
  name: 'BillImageViewer',
  components: {
    VueZoomer,
    Icon
  },
  props: {
    ImageList: {
      type: Array,
      required: true,
    },
    Showbill:{
      type: Number,
      required: true,
    },
    BillStatus:{
      type: String,
      required: true,
    }
  },
  computed:{
    ArrayImage: function(){
      return this.ImageList.map(image => image.bill_photo_image)
    }
  },
  mounted() {
    this.index = this.Showbill
  },
  data() {
    return {
      
      visible: true,
      index: 0 // default: 0
    }
  },
  methods: {
    NextBill(){
      this.index +=1;
    },
    PrevBill(){
      this.index -= 1;
    },
    handleHide() {
      this.$emit("hideModal");
    }
  }

}; 