<style src="./BaseTextLineComponent.scss" lang="scss"></style>
<script src="./BaseTextLineComponent.js"></script>

<template>
	<div class="box__text">
		<ul class="list__items">
			<li>
				{{ textData.label }}
			</li>
			<li>
				{{ textData.value }}
			</li>
		</ul>
	</div>
</template>