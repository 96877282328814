import { ref, reactive, toRefs } from "vue";
import { logger } from "@/helpers";

export default {
  name: "BaseInputPassword",
  props: {
    inputPassword: Object,
    inputScore: Number,
    inputPattern: Boolean,
    modelValue: String,
  },
  setup(props, { emit }) {
    let dataPass = reactive({
      PasswordCheck: "",
      PasswordScore: 0,
      password: "",
    });

    function isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 9) {
        evt.preventDefault();
      } else {
        return true;
      }
    }

    function isAlphaENNumber(evt) {
      let charCode = evt.which ? evt.which : evt.keyCode;
      let char = String.fromCharCode(charCode); // Get the character
      if (/^[A-Za-z0-9/ \-_.@+]/.test(char) || charCode === 8) return true;
      // Match with regex
      else evt.preventDefault(); // If not match, don't add to input text
    }

    function StrengthChecker() {
      // We then change the badge's color and text based on the password strength
      // console.log(password);
      let password = props.inputPassword.value;
      dataPass.password = password;
      // console.log("length", password.length);
      if (password.length == 0) {
        dataPass.PasswordCheck = "";
        dataPass.PasswordScore = 0;
      } else if (password.length > 0 && password.length < 6) {
        // console.log("length");
        dataPass.PasswordCheck = `
        <div style="color: #FA5A5A;">
        รหัสผ่านต้องมีขั้นต่ำ 6 ตัวอักษร<br/>
        โดยมีทั้งตัวอักษรภาษาอังกฤษพิมพ์ใหญ่ <br/>
        ตัวอักษรภาษาอังกฤษพิมพ์เล็ก<br/>
        และตัวเลข</div>`;
        dataPass.PasswordScore = 25;
      } else {
        // console.log("else");
        // console.log(password);
        var score = 0;
        var pass = "";
        var rating = [
          { score: 25, status: "รหัสผ่านของคุณยังไม่ปลอดภัย" },
          { score: 50, status: "รหัสผ่านของคุณยังไม่ปลอดภัย" },
          { score: 75, status: "รหัสผ่านของคุณยังไม่ปลอดภัย" },
          { score: 100, status: "รหัสผ่านของคุณปลอดภัย" },
          { score: 125, status: "รหัสผ่านของคุณปลอดภัย" },
        ];

        if (password.length > 8) {
          score += 25;
        }

        if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
          score += 25;
        }

        /* eslint-disable-next-line */
        if (password.match(/[\!\@\#\$\%\^\&\*\?\_\~\-\(\)]+/)) {
          score += 25;
        }

        if (password.match(/[0-9]/)) {
          score += 25;
        }

        rating.filter((o) => score >= o.score).filter((a) => (pass = a.status));
        dataPass.PasswordCheck = pass;
        dataPass.PasswordScore = score;
      }
      logger(dataPass);
      emit("updated:password", dataPass);
    }

    return {
      input: ref(""),
      ...toRefs(dataPass),
      isNumber,
      isAlphaENNumber,
      StrengthChecker,
    };
  },
};
