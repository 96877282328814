<style src="./ScanProcessingComponent.scss" lang="scss" scoped></style>
<script src="./ScanProcessingComponent.js"></script>

<template>
	<div class="processing__contain">
		<div class="processing__inner">
			<div class="processing__icon">
				<img
					src="../../assets/images/submission/finding_frame.png"
					class="icon__frame"
				/>
				<img
					src="../../assets/images/submission/finding_icon.png"
					class="icon__finding"
				/>
			</div>
			<div class="processing__text">{{ config.name }}</div>
		</div>
	</div>
</template>