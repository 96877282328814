<style src="./BaseCampaignInfoComponent.scss" lang="scss"></style>
<script src="./BaseCampaignInfoComponent.js"></script>

<template>
	<div class="box__campaign__info">
		<div class="campaign__info">
			<div class="info__title">{{campaignTitle}}</div>
			<div class="info__duration">{{campaignInfo}}</div>
		</div>
	</div>
</template>