<style src="./BaseInputTextComponent.scss" lang="scss"></style>
<script src="./BaseInputTextComponent.js"></script>

<template>
	<div class="box__input_text">
		<div class="text__input">
			<div class="title__input">
				<div class="input__title">{{inputText.title}}</div>
				<div class="input__error" v-if="inputText.error">*ข้อมูลผิดพลาด</div>
			</div>
			<input
				:type="inputText.type"
				:placeholder="inputText.placeholder"
				v-model="inputText.value"
				:maxlength="inputText.maxlength"
				:disabled="inputText.disabled"
			/>
		</div>
	</div>
</template>