import {
  BaseTitleComponent,
  BaseTextLineComponent,
  BaseInputOTPComponent,
  BaseSendOTPComponent,
  BaseButtonComponent,
} from "@/components";
import * as dayjs from "dayjs";
import { RequestForgotPassword, ForgotCheckOTP } from "@/api/profile.js";

export default {
  name: "ForgotOTP",
  data() {
    return {
      textLine: {
        label: "OTP ถูกส่งไปที่หมายเลข",
        value: "",
      },
      sendOTPData: {
        msg: "ไม่ได้รับรหัส OTP?",
        error: "",
        link: "ส่งใหม่อีกครั้ง",
      },
      buttonData: {
        name: "รีเซ็ตรหัสผ่าน",
        type: "submit_otp",
        active: false,
      },
      titleName: "กรุณากรอกรหัส OTP",
      errorMsg: "คุณกรอกเบอร์โทรศัพท์ผิดพลาดหรือไม่ครบถ้วนกรุณาลองใหม่อีกครั้ง",
      OTP: this.$store.getters["profile/getRefcode"],
      CountDown: {
        minute: 0,
        second: 0,
      },
      OTPisValid: false,
      InputOTP: "",
      timer: null,
    };
  },
  components: {
    BaseTitleComponent,
    BaseTextLineComponent,
    BaseInputOTPComponent,
    BaseSendOTPComponent,
    BaseButtonComponent,
  },
  created() {
    if (!this.OTP) {
      this.$router.push("ForgotPass");
    } else {
      this.handleCountDown();
      this.textLine.value = this.OTP.mobile.slice(0, 6) + "XXXX";
      if (this.OTP.otp && this.OTP.otp !== "") {
        this.$message(`OTP ของคุณคือ ${this.OTP.otp}`);
      }
    }
  },
  methods: {
    SubmitForm() {
      this.ForgotCheckOTP();
    },
    handleOnComplete(value) {
      this.sendOTPData.error = "";
      this.InputOTP = value;
      if (this.InputOTP.length == 5) {
        this.buttonData.active = true;
      } else {
        this.buttonData.active = false;
      }
    },
    handleOnChange(value) {
      // console.log(value)
      // console.log('OTP changed: ', value);
    },
    handleClearInput(ref) {
      this.$refs[ref].clearInput();
    },
    handleCountDown() {
      const date1 = dayjs(this.OTP.expire);
      let TimeCount = date1.diff(dayjs(), "second");
      if (TimeCount > 0) {
        this.CountDown.minute =
          Math.floor(TimeCount / 60) < 10
            ? `0${Math.floor(TimeCount / 60)}`
            : Math.floor(TimeCount / 60);
        this.CountDown.second =
          TimeCount - this.CountDown.minute * 60 < 10
            ? `0${TimeCount - this.CountDown.minute * 60}`
            : TimeCount - this.CountDown.minute * 60;

        this.OTPisValid = true;

        this.timer = setTimeout(() => {
          this.handleCountDown();
        }, 1000);
      } else {
        this.OTPisValid = false;
      }
    },
    async RequestForgotPassword() {
      let RequestData = {
        mobile: this.OTP.mobile,
      };
      let RequestResp = await RequestForgotPassword(RequestData);
      if (RequestResp.result == "complete") {
        this.OTP = RequestResp.otpinfo;
        if (this.OTP.otp && this.OTP.otp !== "") {
          this.$message(`OTP ของคุณคือ ${this.OTP.otp}`);
        }
        this.$store.commit("profile/OTPinfo", RequestResp.otpinfo);
        this.$forceUpdate();
      } else {
        this.$message(RequestResp.resultdetail);
        this.$store.commit("BUTTON_LOADING", false);
      }
    },
    async ForgotCheckOTP() {
      let RequestData = {
        mobile: this.OTP.mobile,
        otp: this.InputOTP,
        refcode: this.OTP.refcode,
      };
      let CheckOtpResp = await ForgotCheckOTP(RequestData);
      this.$store.commit("BUTTON_LOADING", false);
      if (CheckOtpResp.result == "complete") {
        clearTimeout(this.timer);
        this.$store.commit(
          "profile/SetExchangeCode",
          CheckOtpResp.exchangecode
        );
        this.$router.push({ name: "ForgotCheckname" });
      } else {
        this.sendOTPData.error = "*รหัส OTP ไม่ถูกต้อง";
      }
    },
  },
};
