<style src="./ForgotOTP.scss" lang="scss" scoped></style>
<script src="./ForgotOTP.js"></script>

<template>
	<div class="content__view">
		<div class="content__view__inner">
			<BaseTitleComponent :title-name="titleName" />
			<BaseTextLineComponent :text-data="textLine" />
			<div class="Refcode" v-if="OTP">(Ref={{ OTP.refcode }})</div>

			<!-- <div class="Timenotice">(OTP will be valid for only 5 mins after being created)</div> -->
			<BaseInputOTPComponent
				ref="otpInput"
				input-classes="otp-input"
				separator="-"
				:num-inputs="5"
				:should-auto-focus="true"
				input-type="text"
				@on-change="handleOnChange"
				@on-complete="handleOnComplete"
			/>
			<div class="TimeCount" v-if="OTPisValid">
				{{ CountDown.minute }}:{{ CountDown.second }}
			</div>
			<div class="timeup" v-if="!OTPisValid">
				<p>The OTP you entered is invalid.</p>
				<p>Please enter the correct OTP.</p>
			</div>
			<BaseSendOTPComponent
				:send-data="sendOTPData"
				v-if="OTPisValid"
				@RequestApply="RequestForgotPassword"
			/>
			<BaseButtonComponent
				:button-data="buttonData"
				v-if="OTPisValid"
				@HandleButton="SubmitForm"
			/>
		</div>
	</div>
</template>