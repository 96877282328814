<style src="./BaseAvatarCameraComponent.scss" lang="scss" scoped></style>
<script src="./BaseAvatarCameraComponent.js"></script>

<template>
	<section>
		<div class="box__back__button">
			<span @click="goback()"><i class="el-icon-back"></i> Back</span>
		</div>
		<div
			class="submission__canvas"
			v-if="hasCameraAccess() && !cameraAccess && !cameraUndefined"
		>
			<BaseTitleComponent
				v-if="settingHeader"
				:title-name="settingHeader.setting.top"
				class="setting-header"
			/>
			<BaseTitleComponent
				v-if="settingHeader"
				:title-name="settingHeader.setting.bottom"
			/>
			<div
				class="settings-title"
				v-if="settingHeader"
				v-html="settingHeader.setting.title"
			></div>
			<div class="settings-steps">
				<div v-if="settingText" v-html="settingText"></div>
			</div>
			<div class="settings-refresh">
				หากอนุญาตให้ระบบเข้าถึงกล้องถ่ายรูปแล้ว
			</div>
			<div class="settings-refresh">
				กรุณาคลิก<span class="refresh-button" @click="pageRefresh"
					>รีเฟรชหน้านี้ใหม่</span
				>
			</div>
		</div>
		<div
			class="submission__canvas"
			v-if="!hasCameraAccess() || cameraUndefined"
		>
			<BaseTitleComponent
				v-if="settingHeader"
				:title-name="settingHeader.avatar.top"
				class="setting-header"
			/>
			<div
				class="settings-title"
				v-if="settingHeader"
				v-html="settingHeader.avatar.title"
			></div>
		</div>
		<div
			class="submission__camara"
			v-if="hasCameraAccess() && cameraAccess && !cameraUndefined"
		>
			<div class="webcam__inner">
				<video
					id="webcam"
					preload="none"
					autoplay
					playsinline
					width="640"
					height="480"
				></video>
				<canvas id="canvas"></canvas>
				<div class="flash"></div>
				<audio
					id="snapSound"
					:src="snapSound"
					preload="auto"
					crossOrigin="anonymous"
				></audio>
			</div>

			<div class="bt_flip" @click="cameraFilp">
				<Icon name="camera-flip" class="flip__icon" />
			</div>
		</div>

		<div class="submission__bar">
			<div class="submission__button gallery">
				<label for="inputGallery">Gallery</label>
				<input
					type="file"
					id="inputGallery"
					accept="image/*, .png, .jpeg, .jpg"
					@change.prevent="detectGalleryFiles($event)"
				/>
			</div>
			<button
				v-if="hasCameraAccess() && cameraAccess"
				:style="`background-color: ${configButton.backgroundColor}`"
				@click="handleTakePhoto"
			>
				<Icon name="camera" />
				<span :style="`color: ${configButton.color}`">
					{{ configButton.name }}
				</span>
			</button>
		</div>
	</section>
</template>
