<style src="./BaseButtonSimpleComponent.scss" lang="scss"></style>
<script src="./BaseButtonSimpleComponent.js"></script>

<template>
	<div
		class="box__button__simple"
		:class="`${
			buttonData.class && buttonClassChange
				? 'fixed__send__button'
				: buttonData.class
		}`"
		v-if="buttonData && !buttonClass"
	>
		<el-button
			class="button"
			:class="[
				buttonData.active ? 'active' : '',
				buttonData.outline ? 'outline' : '',
			]"
			:disabled="buttonData.disabled"
			@click.prevent="buttonClick(buttonData)"
			>{{ buttonData.title }}</el-button
		>
	</div>
	<div class="box__button__simple fixed__button" v-else>
		<el-button
			class="button"
			:class="[
				buttonData.active ? 'active' : '',
				buttonData.outline ? 'outline' : '',
			]"
			:disabled="buttonData.disabled"
			@click.prevent="buttonClick(buttonData)"
			>{{ buttonData.title }}</el-button
		>
	</div>
</template>