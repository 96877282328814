import {
  BaseProfileHeaderComponent,
  BasePointLegendComponent,
  BaseBackButtonComponent,
  BaseImageFeatureComponent,
  BaseDetailComponent,
  BaseButtonLegendComponent,
  BaseCampaignInfoComponent,
} from "@/components";

export default {
  name: "RewardDetails",
  components: {
    BaseProfileHeaderComponent,
    BasePointLegendComponent,
    BaseBackButtonComponent,
    BaseImageFeatureComponent,
    BaseDetailComponent,
    BaseButtonLegendComponent,
    BaseCampaignInfoComponent,
  },
  data() {
    return {
      profile: null,
      type: null,
      id: null,
      reward: null,
      campaign: null,
    };
  },
  created() {
    this.type = this.$route.params.type;
    this.id = this.$route.params.id;
    if (this.type !== undefined && this.id !== undefined) {
      this.getRewardDetail();
    }
  },
  methods: {
    async getRewardItemฺById(itemid) {
      const data = await this.$store.dispatch(
        "reward/UPDATE_REWARD_ITEM_BY_ID",
        itemid
      );
    },
    async getLuckydrawItemById(itemid) {
      await this.$store.dispatch(
        "reward/UPDATE_LUCKYDRAW_ITEM_BY_ID",
        itemid
      )
    },
    getProfileData(data) {
      this.profile = data;
      if (!this.reward.start) {
        if (this.profile.pointbalance >= this.reward.points) {
          this.campaign = this.action.waiting;
          this.campaign.info = this.reward.duration;
        } else {
          this.campaign = this.action.nopoints;
        }
      }
      else {
        if (this.type == "redemptions") {
          this.reward.action.all.limit = parseInt(this.reward.usermaxredeem)
        }
        else {
          this.reward.action.all.limit = ~~(this.profile.pointbalance / this.reward.points)
        }
      }
    },
    async getRewardDetail() {
      if (this.type == "luckydraws") {
        await this.getLuckydrawItemById(this.id).then(() => {
          this.reward = this.$store.getters['reward/UPDATE_LUCKYDRAW_ITEM_BY_ID'];
        });
      } else if (this.type == "redemptions") {
        await this.getRewardItemฺById(this.id).then(() => {
          this.reward = this.$store.getters['reward/UPDATE_REWARD_ITEM_BY_ID'];
        });
      }
    },
    buttonClick(privilege) {
      this.$store.dispatch("reward/PRIVILEGE", privilege.limit)
      this.$router.push({
        name: "PrivilegePoints",
        params: { type: this.type, id: this.id },
      });
    },
  },
};
