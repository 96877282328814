import $ from "jquery";
import { logger, validPhoneNumber } from "@/helpers";
import { onUpdated, onBeforeUnmount } from "vue";

import {
  BaseTitleComponent,
  BaseInputMobileComponent,
  BaseInputPasswordComponent,
  BaseErrorMsgComponent,
  BaseButtonComponent,
  FooterSignupComponent,
  BaseLinksContactComponent,
  // BaseInputCheckboxComponent
} from "@/components";

import { RequestLogin } from "@/api/profile";

export default {
  name: "Apply",
  data() {
    return {
      inputMobile: {
        name: "mobile",
        value: "",
        type: "number",
        placeholder: "เบอร์โทรศัพท์",
        maxlength: 9,
      },
      inputPassword: {
        name: "password",
        value: "",
        type: "password",
        placeholder: "กรอกรหัสผ่าน",
        maxlength: 50,
      },
      buttonData: {
        name: "เข้าสู่ระบบ",
        type: "submit",
        active: false,
      },
      titleName: "เข้าสู่ระบบ",
      errorMsg: {
        Mobile: "",
        Password: "",
        API: "",
        API2: "",
      },
      PasswordCheck: null,
      LineToken: localStorage.getItem("CrpLinetoken"),
      LinkData: {
        name: "linking_with_line",
        selected: false,
        title: "ผูกบัญชี LINE เข้ากับระบบ\nเพื่อความสะดวกในการเข้าใช้งาน",
      },
    };
  },
  components: {
    BaseTitleComponent,
    BaseInputMobileComponent,
    BaseInputPasswordComponent,
    BaseErrorMsgComponent,
    BaseButtonComponent,
    FooterSignupComponent,
    BaseLinksContactComponent,
    // BaseInputCheckboxComponent
  },
  inject: ["trackEvent"],
  watch: {
    inputMobile: {
      deep: true,
      handler(Mobile) {
        logger("inputMobile", validPhoneNumber(Mobile.value));
        if (
          Mobile.value.length === 9 &&
          validPhoneNumber(Mobile.value) &&
          this.inputPassword.value.length >= 6
        ) {
          this.errorMsg.Mobile = "";
          this.buttonData.active = true;
        } else if (Mobile.value.length > 0 && !validPhoneNumber(Mobile.value)) {
          this.errorMsg.Mobile = "* เบอร์โทรศัพท์ที่คุณกรอกไม่ถูกต้อง";
          this.buttonData.active = false;
        } else {
          this.errorMsg.Mobile = "";
          this.buttonData.active = false;
        }
        // this.inputMobile.error =false;
        // this.errorMsg.Mobile = ''
      },
    },
    inputPassword: {
      deep: true,
      handler(Password) {
        if (
          this.inputMobile.value.length === 9 &&
          validPhoneNumber(this.inputMobile.value) &&
          Password.value.length >= 6
        ) {
          this.buttonData.active = true;
          this.errorMsg.Password = "";
        } else if (Password.value.length > 0 && Password.value.length < 6) {
          this.buttonData.active = false;
          this.errorMsg.Password = "* รหัสผ่านต้องมีขั้นต่ำ 6 ตัวอักษร";
        } else {
          this.buttonData.active = false;
          this.errorMsg.Password = "";
        }
        // this.errorMsg.Password = ''
        this.StrengthChecker();
      },
    },
  },

  methods: {
    StrengthChecker(PasswordParameter) {
      // We then change the badge's color and text based on the password strength
      let password = this.inputPassword.value;

      var rating = [
        0,
        "Weak",
        25,
        "Good",
        50,
        "Medium",
        75,
        "Strong",
        100,
        "Very Strong",
      ];
      var score = 0;
      var pass = "";

      if (password.length > 8) {
        score += 25;
      }
      if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
        score += 25;
      }
      /* eslint-disable-next-line */
      if (password.match(/[\!\@\#\$\%\^\&\*\?\_\~\-\(\)]+/)) {
        score += 25;
      }
      if (password.match(/[0-9]/)) {
        score += 25;
      }

      for (var i = rating.length - 1; i >= 0; i -= 1) {
        if (score >= rating[i]) {
          pass = rating[i + 1];
          break;
        }
      }
      this.PasswordCheck = pass;
    },
    FormValid() {
      let FormCheck = true;
      if (
        this.inputMobile.value.length <= 9 &&
        !validPhoneNumber(this.inputMobile.value)
      ) {
        this.errorMsg.Mobile = "* เบอร์โทรศัพท์ที่คุณกรอกไม่ถูกต้อง";
        FormCheck = false;
      }

      if (this.inputPassword.value.length < 6) {
        this.errorMsg.Password = "* รหัสผ่านต้องมีขั้นต่ำ 6 ตัวอักษร";
        FormCheck = false;
      }

      return FormCheck;
    },
    SubmitForm() {
      let FormValid = this.FormValid();

      if (FormValid == true) {
        this.trackEvent("Login - Sign in", {
          event_category: "Login",
          event_label: "Sign in",
          value: "เข้าสู่ระบบ",
        });
        this.RequestLogin();
      } else {
        this.$store.commit("BUTTON_LOADING", false);
      }
    },
    async RequestLogin() {
      // if (this.inputMobile.value.slice(0, 1) === '0') {
      // console.log('begin 0')
      //   this.inputMobile.value = this.inputMobile.value.substring(1)
      // } else {
      // console.log('not begin 0')
      // }
      // console.log('mobile:', this.inputMobile.value)

      let RequestData = {
        mobile: "0" + this.inputMobile.value,
        password: this.inputPassword.value,
      };
      // console.log(RequestData)
      let CrossToken = this.$store.getters["profile/getCrossToken"];

      // console.log(this.LinkData.selected);

      RequestData.linetoken = this.LineToken;

      if (CrossToken) {
        RequestData.adaptertoken = CrossToken;
      }

      let RequestResp = await RequestLogin(RequestData);
      this.$store.commit("BUTTON_LOADING", false);

      if (RequestResp.result === "complete") {
        // console.log(RequestResp);
        this.$store.commit("profile/SetToken", RequestResp.token);
        localStorage.setItem("Crptoken", RequestResp.token);
        this.$router.push("/Home");
      } else {
        if (RequestResp.resultdetail === "cannot_login") {
          this.errorMsg.API = `* เบอร์โทรศัพท์หรือรหัสผ่านที่คุณกรอกไม่ถูกต้อง
* รหัสผ่านต้องมีขั้นต่ำ 6 ตัวอักษร`;
        } else if (RequestResp.resultdetail === "account_locked") {
          this.$store.commit(
            "verify/SetVerifyMobile",
            "0" + this.inputMobile.value
          );
          this.$store.commit(
            "verify/SetVerifyPassword",
            this.inputPassword.value
          );
          this.$router.push("/Verify");
        } else {
          this.$message({
            message: RequestResp.resultdetail,
            type: "error",
          });
        }
      }
    },
  },
  setup() {
    onUpdated(() => {
      // let vm = this;
      // console.log('Component is mounted!')
    });
    onBeforeUnmount(() => {
      // console.log('off emitter')
      // this.emitter.off(`button:${this.buttonType}`);
    });

    // watch([inputMobile], ([newinputMobile, previnputMobile]) => {
    //  console.log(newinputMobile)
    //   /* ... */
    // })
  },
  created() {},
};
