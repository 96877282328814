import {
  PointBalanceComponent,
  PointExpireComponent,
  PointStatusBarComponent
} from '@/components';

import { RequestGetInfo } from "@/api/profile.js";

export default {
    name: 'PointStatusLayout',
    data() {
      return {
        token: this.$store.getters["profile/token"],
        PointExpires:null
      }
    },
    components: {
      PointBalanceComponent,
      PointExpireComponent,
      PointStatusBarComponent,
    },
    created() {
      if (this.token) {
        this.GetInfo();
      } else {
        this.$message({
          message: "Invalid Token",
          type: "error",
        });

        this.$router.push("/Gateway");
      }
    },
    methods: {
      async GetInfo() {
        let RequestData = {
          token: this.token,
        };
        let RequestResp = await RequestGetInfo(RequestData);
        if (RequestResp.result == "complete") {
          this.PointExpires = RequestResp.pointexpirelist;
        } else {
          this.$message({
            message: RequestResp.resultdetail,
            type: "error",
          });
        }
      },
    },
  };