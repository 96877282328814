import * as dayjs from 'dayjs'
import { Icon,   IconShop} from '@/components';
import {
    abbThaiMonth
} from "@/helpers";
export default {
    name: 'PointhistoryListLuckydrawComponent',
    components: {
        IconShop, Icon
    },
    props: {
        HistoryList: {
            type: Object,
            required: true,
        },
        RewardType:{
            type: String,
            required: true,
        }
    },
    mounted() {
        
    },
    data() {
        return {
            
        }
    },
    methods: {
        GetDate(Date) {
            let FormatDate = dayjs(Date).format('DD')
            return FormatDate;
        },
        GetMonth(Date){
            let FormatDate = abbThaiMonth(dayjs(Date).format('M'))

            return FormatDate;
        }
        
    },
};

