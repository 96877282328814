<style src="./Verify.scss" lang="scss" scoped></style>
<script src="./Verify.js"></script>

<template>
	<div class="content__view">
		<div class="content__view__inner">
			<BaseTitleComponent :title-name="titleName" />
			<div class="gaping"></div>
			<BaseInputMobileComponent
				:input-mobile="inputMobile"
				v-model="inputMobile.value"
				:error="errorMsg.Mobile"
				class="mb__5"
			/>
			<div class="msg_verify">
				* กรุณากรอกเบอร์ที่คุณใช้เป็นเบอร์หลัก <br />ในการรับรหัส OTP
				เพื่อยืนยันตัวตน
			</div>

			<BaseErrorMsgComponent
				v-if="errorMsg.Mobile"
				:error-msg="errorMsg.Mobile"
			/>
			<BaseErrorMsgComponent
				v-if="errorMsg.Password"
				:error-msg="errorMsg.Password"
			/>
			<BaseErrorMsgComponent v-if="errorMsg.API" :error-msg="errorMsg.API" />

			<!-- <BaseButtonComponent
				:button-data="buttonData"
				@HandleButton="SubmitForm"
			/> -->

			<BaseButtonComponent
				:button-data="buttonData"
				:button-position="true"
				@HandleButton="SubmitForm"
			/>

			<!-- <FooterSignupComponent /> -->
			<FooterSignupComponent :button-position="true" />
			<BaseLinksContactComponent />
		</div>
	</div>
</template>