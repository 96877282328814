export function cameraSetting() {
  return {
    access: {
      setting: {
        top: "เปิดการเข้าถึงกล้อง",
        bottom: "เพื่อเริ่มใช้งาน",
        title: "วิธีเปิดการใช้งานกล้องถ่ายรูป",
      },
      info: {
        top: "อัปโหลดภาพใบเสร็จ",
        title:
          "แตะที่ปุ่ม “คลังภาพ” หรือ “ไฟล์”\nเพื่อค้นหารูปภาพใบเสร็จที่ต้องการส่งเข้าระบบ",
      },
      avatar: {
        top: "อัปโหลดภาพโปรไฟล์",
        title: "แตะที่ปุ่ม Gallery เพื่อค้นหารูปภาพ",
      },
    },
    steps: [
      {
        id: "android_chrome",
        text:
          '1. เปิดแอป <span class="text__bold">"การตั้งค่า"</span>\n2. เลือกเมนู <span class="text__bold">แอพ</span>\n3.เลือกเมนู <span class="text__bold">Chrome</span>\n4.เลือกเมนู <span class="text__bold">การอนุญาต</span>\n5.เลือกเมนู <span class="text__bold">กล้อง</span>\n6.กดอนุญาตที่เมนู <span class="text__bold"> กล้อง</span>',
      },
      {
        id: "ios_safari",
        text:
          '1. เปิดแอป <span class="text__bold">"การตั้งค่า"</span>\n2. เลือกเมนู <span class="text__bold">Safari</span>\n3. เลือกเมนู <span class="text__bold">กล้อง</span>\n4. กดเลือก <span class="text__bold">อนุญาต</span>',
      },
      {
        id: "ios_line",
        text:
          '1. เปิดแอป <span class="text__bold">"การตั้งค่า"</span>\n2. เลือกเมนู <span class="text__bold">LINE</span>\n3. กดอนุญาตที่เมนู <span class="text__bold">กล้อง</span>',
      },
      {
        id: "ios_chrome",
        text:
          '1. เปิดแอป <span class="text__bold">"การตั้งค่า"</span>\n2. เลือกเมนู <span class="text__bold">Chrome</span>\n3. กดอนุญาตที่เมนู <span class="text__bold">กล้อง</span>',
      },
      {
        id: "ios_facebook",
        text:
          '1. เปิดแอป <span class="text__bold">"การตั้งค่า"</span>\n2. เลือกเมนู <span class="text__bold">Facebook</span>\n3. กดอนุญาตที่เมนู <span class="text__bold">กล้อง</span>',
      },
      {
        id: "ios_messenger",
        text:
          '1. เปิดแอป <span class="text__bold">"การตั้งค่า"</span>\n2. เลือกเมนู <span class="text__bold">Messenger</span>\n3. กดอนุญาติที่เมนู <span class="text__bold">กล้อง</span>',
      },
      {
        id: "ios_other",
        text:
          '1. เปิดแอป <span class="text__bold">"การตั้งค่า"</span>\n2. เลือกเมนู <span class="text__bold">Other</span>\n3. แตะเปิดการใช้งานที่รูป <span class="text__bold">กล้องถ่ายรูป</span>',
      },
    ],
  };
}
