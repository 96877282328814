import { inject } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "BaseLinks",
  props: {
    buttonPosition: Boolean,
  },
  setup() {
    const router = useRouter();
    const trackEvent = inject("trackEvent");
    const handleClick = () => {
      trackEvent("Apply - Login", {
        event_category: "Apply",
        event_label: "Login",
        value: "เข้าสู่ระบบ",
      });
      router.push({
        name: "Login",
      });
    };
    return {
      handleClick,
    };
  },
};
