<style src="./PointStatusBarComponent.scss" lang="scss" scoped></style>
<script src="./PointStatusBarComponent.js"></script>

<template>
	<nav class="pointstatusbar">
		<router-link to="/point-status/earned" class="pointstatusbar__link"
			>พอยท์ที่ได้รับ</router-link
		>
		<router-link to="/point-status/used" class="pointstatusbar__link"
			>พอยท์ที่ใช้แล้ว</router-link
		>
		<!-- <router-link to="/point-status/redeem" class="pointstatusbar__link"
			>แลกรางวัล</router-link
		> -->
	</nav>
</template>