export default {
  name: "BaseCarousel",
  data() {
    return {
      banners: [{
        name: 'Banner 01',
        source: require("@/assets/images/vector1.jpg")
      },
      {
        name: 'Banner 02',
        source: require("@/assets/images/vector2.jpg")
      }]
    };
  }
};